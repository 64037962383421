import React, { useState } from 'react';
import { Link, useHistory, useParams, useLocation } from 'react-router-dom';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { BiCircle } from 'react-icons/bi';
import { ImEye, ImEyeBlocked } from 'react-icons/im';

import Input from '../../components/Input';
import PageHeaderAuth from '../../components/PageHeaderAuth';

import api from '../../services/api'

import '../../styles/pages/auth/ResetPassword.css';

const ResetPassword = () => {
   const [loading, setLoading] = useState('default')
   const [seePassword, setSeePassword] = useState(false)

   const [password, setPassword] = useState('')
   const [confirmPassword, setConfirmPassword] = useState('')

   const history = useHistory()

   function useQuery() {
      return new URLSearchParams(useLocation().search)
   }

   const query = useQuery()
   const email = query.get("email")
   const { token } = useParams()

   async function handleResetPassword(e) {
      e.preventDefault()

      setLoading('processing')

      const data = { email, token, password, confirmPassword }

      try {
         const res = await api.post('resetPassword', data)

         toast.success(res.data.message)

         history.push('/login')

      } catch (e) {
         setLoading('default')

         e.response.data.errors.forEach(error => toast.error(error))
      }
   }

   const popover = (
      <Popover id="popover-basic">
         <Popover.Title
            as="h2"
            style={{ fontFamily: 'Montserrat', fontSize: 12, fontWeight: 500, margin: 7, backgroundColor: '#FFFF' }}
         >
            Password must contain:
         </Popover.Title>

         <Popover.Content style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
               <BiCircle />
               <span style={{ marginLeft: 5 }}>At least 8 characters</span>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 6 }}>
               <BiCircle />
               <span style={{ marginLeft: 5 }}>At least one uppercase letter</span>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 6 }}>
               <BiCircle />
               <span style={{ marginLeft: 5 }}>At least one lowercase letter</span>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 6 }}>
               <BiCircle />
               <span style={{ marginLeft: 5 }}> At least one symbol</span>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 6 }}>
               <BiCircle />
               <span style={{ marginLeft: 5 }}>At least one number</span>
            </div>
         </Popover.Content>
      </Popover >
   )



   return (
      <div id="page-reset-password">
         <PageHeaderAuth />
         <div className="red-line" />

         <div className='page-reset-password-container'>

            <div className="content-container">
               <main>
                  <h1>KMEX / ALTAMIRA</h1>
                  <h2>TERMINAL CONTROL</h2>

                  <span>Change your password</span>
                  <div className="line" />

                  <p>Hello! Type your new password bellow</p>

                  <form onSubmit={handleResetPassword}>
                     <fieldset>
                        <div className="input-container">

                           <OverlayTrigger placement="right" overlay={popover}>
                              <Input
                                 name='password'
                                 label='New password'
                                 type={seePassword ? 'text' : 'password'}
                                 placeholder="Type your new password"
                                 value={password}
                                 onChange={e => setPassword(e.target.value)}
                              />
                           </OverlayTrigger>

                           <Input
                              name='confirmPassword'
                              label="Confirm new passowrd"
                              type={seePassword ? 'text' : 'password'}
                              placeholder='Retype your new password'
                              value={confirmPassword}
                              onChange={e => setConfirmPassword(e.target.value)}
                           />
                           {seePassword ? (
                              <ImEyeBlocked size={20} onClick={() => setSeePassword(false)} />
                           ) : (
                              <ImEye size={20} onClick={() => setSeePassword(true)} />
                           )}
                        </div>

                        <div className="buttons-container">
                           {loading === 'default' ?
                              <button
                                 type='submit'
                                 className='enter-button'
                              >
                                 Send
                              </button>
                              :
                              <button
                                 className='enter-button'
                                 style={{ background: '#4B81E8', color: '#FFF', border: 0 }}
                                 disabled
                              >
                                 <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                                 <span className="sr-only"></span>
                              </button>
                           }

                           <Link to='/login' className='cancel-button'>
                              Cancel
                           </Link>
                        </div>

                     </fieldset>
                  </form>
               </main>
            </div>
         </div>


      </div>
   );
};

export default ResetPassword;
