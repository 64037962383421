import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import { IoIosArrowDown } from 'react-icons/io';

import '../../styles/components/DropdownPageHeader.css';

const DropdownReport = () => {
   const [showReportOptions, setShowReportOptions] = useState(false);

   const dropdownReportOptions = useRef(null);

   const handleShowReportOptions = useCallback((e) => {
      if (dropdownReportOptions.current && showReportOptions) {
         if (!dropdownReportOptions.current.contains(e.target)) {
            setShowReportOptions(false);
         }

      } else if (!dropdownReportOptions.current && showReportOptions) {
         setShowReportOptions(false);

      } else {
         setShowReportOptions(true);
      }

   }, [showReportOptions]);

   useEffect(() => {
      if (showReportOptions) {
         document.addEventListener("click", handleShowReportOptions);

         return () => document.removeEventListener("click", handleShowReportOptions);
      }

   }, [showReportOptions, handleShowReportOptions]);


   return (
      <div id="dropdown-page-header">
         <button
            type="button"
            className='button-report'
            onClick={handleShowReportOptions}
         >
            Extract a Report
            <IoIosArrowDown size={20} color='#4B81E8' />
         </button>
         <div ref={dropdownReportOptions} className='menu-select'>
            {showReportOptions &&
               <div className="select-container-report">
                  <div className="title">
                     <h3>Reports:</h3>
                  </div>

                  <Link
                     to='/app/reports/schedulingRequest'
                  >
                     ATP (Scheduling)
                  </Link>

                  <Link
                     to='/app/reports/gmInTransitList'
                  >
                     GM In Transit
                  </Link>

                  <Link
                     to='/app/reports/schedulingList'
                  >
                     Scheduling List
                  </Link>

                  <Link
                     to='/app/reports/ingresoDeMadrinas'
                  >
                     Ingreso de Madrinas
                  </Link>

                  <Link
                     to='/app/reports/vvo242'
                  >
                     VVO242
                  </Link>

                  <Link
                     to='/app/reports/gmInventoryList'
                  >
                     GM Inventory
                  </Link>

                  <Link
                     to='/app/reports/capacidadSimplificada'
                  >
                     Capacidad Simplificada
                  </Link>

                  <Link
                     to='/app/reports/globalDamage'
                  >
                     Global Damage Report
                  </Link>

                  <Link
                     to='/app/reports/repairList'
                  >
                     Repair List
                  </Link>

                  <Link
                     to='/app/reports/dfYoung'
                  >
                     DF Young
                  </Link>

                  <Link
                     to='/app/reports/tarja2'
                  >
                     Tarja 2
                  </Link>

                  <Link
                     to='/app/reports/handlingRequest'
                  >
                     ATP (Handling)
                  </Link>

                  <Link
                     to='/app/reports/loadingRequest'
                  >
                     ATP (Loading)
                  </Link>

                  <Link
                     to='/app/reports/loadingList'
                  >
                     Loading List
                  </Link>

               </div>
            }
         </div>
      </div>
   );
};

export default DropdownReport;
