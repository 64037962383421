import React, { useState, useEffect, useCallback, useContext, useRef, forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FiX, FiTool } from 'react-icons/fi';
import { HiCalendar, HiOutlineCalendar } from 'react-icons/hi';
import crypto from 'crypto-js';
import InfiniteScroll from 'react-infinite-scroll-component';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import PageHeader from '../../components/PageHeader';
import Loading from '../../components/Loading';
import LoadingInfiniteScroll from '../../components/LoadingInfiniteScroll';
import ButtonsNavigation from '../../components/ButtonsNavigation';
import ModalAddVesselVoyage from '../../components/modals/ModalAddVesselVoyage';
import DropdownFilterReceived from '../../components/dropdowns/DropdownFilterReceived';

import { RegisterContext } from '../../services/contexts/RegisterContext';

import api from '../../services/api';
import { AllLetterDown } from '../../utils/functions';

import '../../styles/pages/app/Received.css';

const Received = () => {
   const [loadingButton, setLoadingButton] = useState('default');

   const [modalAddVesselIsOpen, setModalAddVesselIsOpen] = useState(false);
   const [startDate, setStartDate] = useState(new Date());

   const [showFilterChassis, setShowFilterChassis] = useState(false);
   const [showFilterSlot, setShowFilterSlot] = useState(false);
   const [showFilterAging, setShowFilterAging] = useState(false);
   const [showFilterVessel, setShowFilterVessel] = useState(false);
   const [showFilterPod, setShowFilterPod] = useState(false);

   const [searchChassis, setSearchChassis] = useState('');
   const [searchArrivalDate, setSearchArrivalDate] = useState('');
   const [searchSlot, setSearchSlot] = useState('');
   const [searchAging, setSearchAging] = useState('');
   const [searchVessel, setSearchVessel] = useState('');
   const [searchPod, setSearchPod] = useState('');

   const [chassisSelection, setChassisSelection] = useState([]);

   const dropdownFilterChassisRef = useRef(null);
   const dropdownFilterSlotRef = useRef(null);
   const dropdownFilterAgingRef = useRef(null);
   const dropdownFilterVesselRef = useRef(null);
   const dropdownFilterPodRef = useRef(null);

   const {
      loadingScreen,
      setLoadingScreen,
      loadingMorePages,
      setLoadingMorePages,

      receivedVehiclesQuantity,
      setPageOnRoad,
      setPageScheduled,
      setPageReceived,
      setPageDamaged,
      setPageManifested,
      setPageLoaded,
      setPageInvoiced,

      loadOnRoadVehicles,
      loadScheduledVehicles,
      loadReceivedVehicles,
      loadDamagedVehicles,
      loadManifestedVehicles,
      loadLoadedVehicles,
      loadInvoicedVehicles,

      setReceivedVehiclesData,
      receivedVehiclesData,

      vesselId,
      setVesselId,
      vesselVoyage,
      setVesselVoyage,
      searchSelect,
      setSearchSelect,
   } = useContext(RegisterContext);


   // eslint-disable-next-line array-callback-return
   const chassisSelected = chassisSelection.map(item => {
      if (item[1] === true) {
         return item
      }
   }).filter(Boolean);

   useEffect(() => {
      if (searchChassis !== '' || searchArrivalDate !== '' || searchSlot !== ''
         || searchVessel !== '' || searchPod !== '' || searchAging !== '') {
         async function loadReceivedVehicles() {
            const res = await api.get(`received?pageSize=100000&chassis=${AllLetterDown(
               searchChassis)}&arrivalDate=${AllLetterDown(searchArrivalDate)}&slot=${AllLetterDown(
                  searchSlot)}&vesselName=${AllLetterDown(searchVessel)}&pod=${AllLetterDown(
                     searchPod)}&agingFilter=${searchAging}`);

            setReceivedVehiclesData(res.data);

            setLoadingScreen(false);
         };

         loadReceivedVehicles();

      } else {
         loadReceivedVehicles();
      }

   }, [loadReceivedVehicles, setReceivedVehiclesData, setLoadingScreen, searchChassis, searchArrivalDate, searchSlot, searchVessel, searchPod, searchAging]);

   useEffect(() => {
      setLoadingMorePages(true);
      setPageOnRoad(1);
      setPageScheduled(1);
      setPageDamaged(1);
      setPageManifested(1);
      setPageLoaded(1);
      setPageInvoiced(1);

   }, [setPageOnRoad, setPageScheduled, setPageDamaged, setPageManifested, setPageLoaded, setPageInvoiced, setLoadingMorePages]);

   useEffect(() => {
      loadOnRoadVehicles();
      loadScheduledVehicles();
      loadDamagedVehicles();
      loadManifestedVehicles();
      loadLoadedVehicles();
      loadInvoicedVehicles();

   }, [loadOnRoadVehicles, loadScheduledVehicles, loadDamagedVehicles, loadManifestedVehicles, loadLoadedVehicles, loadInvoicedVehicles]);


   const receivedVehiclesDataConfigured = receivedVehiclesData.map(vehicle => {
      return {
         chassi_id: vehicle.chassi_id,
         chassi_number: vehicle.chassi_number,
         arrival_date: moment(new Date(vehicle.arrival_date)).format('YYYY-MM-DD'),
         model_name: vehicle.model_name,
         aging: vehicle.aging,
         pod: vehicle.pod,
         repaired: vehicle.repaired,
         vessel_voyage: vehicle.vessel_voyage === null || vehicle.vessel_voyage === undefined ? 'TBC' : vehicle.vessel_voyage,
         yard_slot: vehicle.yard_slot,
      };
   });

   const handleShowFilterChassis = useCallback((e) => {
      if (dropdownFilterChassisRef.current && showFilterChassis) {
         if (!dropdownFilterChassisRef.current.contains(e.target)) {
            setShowFilterChassis(false);
         }

      } else if (!dropdownFilterChassisRef.current && showFilterChassis) {
         setShowFilterChassis(false);

      } else {
         setShowFilterChassis(true);
      }

   }, [showFilterChassis]);

   useEffect(() => {
      if (showFilterChassis) {
         document.addEventListener("click", handleShowFilterChassis);

         return () => document.removeEventListener("click", handleShowFilterChassis);
      }

   }, [showFilterChassis, handleShowFilterChassis]);

   const handleShowFilterSlot = useCallback((e) => {
      if (dropdownFilterSlotRef.current && showFilterSlot) {
         if (!dropdownFilterSlotRef.current.contains(e.target)) {
            setShowFilterSlot(false);
         }

      } else if (!dropdownFilterSlotRef.current && showFilterSlot) {
         setShowFilterSlot(false);

      } else {
         setShowFilterSlot(true);
      }

   }, [showFilterSlot]);

   useEffect(() => {
      if (showFilterSlot) {
         document.addEventListener("click", handleShowFilterSlot);

         return () => document.removeEventListener("click", handleShowFilterSlot);
      }

   }, [showFilterSlot, handleShowFilterSlot]);

   const handleShowFilterVessel = useCallback((e) => {
      if (dropdownFilterVesselRef.current && showFilterVessel) {
         if (!dropdownFilterVesselRef.current.contains(e.target)) {
            setShowFilterVessel(false);
         }

      } else if (!dropdownFilterVesselRef.current && showFilterVessel) {
         setShowFilterVessel(false);

      } else {
         setShowFilterVessel(true);
      }

   }, [showFilterVessel]);

   useEffect(() => {
      if (showFilterVessel) {
         document.addEventListener("click", handleShowFilterVessel);

         return () => document.removeEventListener("click", handleShowFilterVessel);
      }

   }, [showFilterVessel, handleShowFilterVessel]);

   const handleShowFilterPod = useCallback((e) => {
      if (dropdownFilterPodRef.current && showFilterPod) {
         if (!dropdownFilterPodRef.current.contains(e.target)) {
            setShowFilterPod(false);
         }

      } else if (!dropdownFilterPodRef.current && showFilterPod) {
         setShowFilterPod(false);

      } else {
         setShowFilterPod(true);
      }

   }, [showFilterPod]);

   useEffect(() => {
      if (showFilterPod) {
         document.addEventListener("click", handleShowFilterPod);

         return () => document.removeEventListener("click", handleShowFilterPod);
      }

   }, [showFilterPod, handleShowFilterPod]);

   const handleShowFilterAging = useCallback((e) => {
      if (dropdownFilterAgingRef.current && showFilterAging) {
         if (!dropdownFilterAgingRef.current.contains(e.target)) {
            setShowFilterAging(false);
         }

      } else if (!dropdownFilterAgingRef.current && showFilterAging) {
         setShowFilterAging(false);

      } else {
         setShowFilterAging(true);
      }

   }, [showFilterAging]);

   useEffect(() => {
      if (showFilterAging) {
         document.addEventListener("click", handleShowFilterAging);

         return () => document.removeEventListener("click", handleShowFilterAging);
      }

   }, [showFilterAging, handleShowFilterAging]);


   // async function handleCheckAll() {
   //    let checklistId = [];
   //    const checkboxes = document.querySelectorAll('input[type="checkbox"]');

   //    checkboxes.forEach((checkbox) => {
   //       if (checkbox.value !== 'on') {
   //          checklistId.push([checkbox.value, !isCheckAll])
   //       }
   //    });

   //    setChassisSelection(checklistId);
   // }

   function handleCheckIdConcat(e) {
      let checklistId = [];
      const checkboxes = document.querySelectorAll('input[type="checkbox"]');

      checkboxes.forEach((checkbox) => {
         if (checkbox.value !== 'on') {
            checklistId.push([checkbox.value, checkbox.checked])
         }
      });

      setChassisSelection(checklistId);
   };

   async function handleChassisAddVessel(e) {
      e.preventDefault();

      setLoadingButton('processing');

      const data = { vessel_voyage_id: vesselId };

      for (let i = 0; i < chassisSelected.length; i++) {
         try {
            await api.put(`chassis/${Number(chassisSelected[i][0])}/vessel`, data);

         } catch (e) {
            setLoadingButton('default');

            e.response.data.errors.forEach(error => toast.error(error));
         };
      };

      toast.success('The vessel and voyage for selected vehicles has been registered');

      setVesselId();
      setChassisSelection([]);

      const checkboxes = document.querySelectorAll('input[type="checkbox"]');

      for (let i = 0; i < checkboxes.length; i++) {
         checkboxes[i].checked = false;
      };

      await loadReceivedVehicles();

      setModalAddVesselIsOpen(false);
      setLoadingButton('default');

   };

   const onChangeDatePicker = (date) => {
      setStartDate(date);
      setSearchArrivalDate(moment(new Date(date)).format('YYYY-MM-DD'));
   };

   const CalendarCustomButton = forwardRef(({ onClick }, ref) => {
      return (
         <button
            type="button"
            className='button-filter'
            onClick={onClick}
            ref={ref}
         >
            {searchArrivalDate !== '' ?
               <HiCalendar size={20} color="#4B81E8" />
               :
               <HiOutlineCalendar size={20} color="#5D5D5D" />
            }
         </button>
      );
   });

   function handleRemoveFilterChassi() {
      setLoadingScreen(true);

      setSearchChassis('');
      setPageReceived(1);
      setLoadingMorePages(true);
   };

   function handleRemoveFilterDate() {
      setLoadingScreen(true);

      setSearchArrivalDate('');
      setPageReceived(1);
      setLoadingMorePages(true);
   };

   function handleRemoveFilterSlot() {
      setLoadingScreen(true);

      setSearchSlot('');
      setPageReceived(1);
      setLoadingMorePages(true);
   };

   function handleRemoveFilterVessel() {
      setLoadingScreen(true);

      setSearchVessel('');
      setPageReceived(1);
      setLoadingMorePages(true);
   };

   function handleRemoveFilterPod() {
      setLoadingScreen(true);

      setSearchPod('');
      setPageReceived(1);
      setLoadingMorePages(true);
   };

   function handleRemoveFilterAging() {
      setLoadingScreen(true);

      setSearchAging('');
      setPageReceived(1);
      setLoadingMorePages(true);
   };

   function handleOpenModalAddVessel() {
      setModalAddVesselIsOpen(!modalAddVesselIsOpen);

      setVesselVoyage();
      setVesselId();
      setSearchSelect();
   };

   function handleFetchMore() {
      if (receivedVehiclesData.length >= Number(receivedVehiclesQuantity)) {
         setLoadingMorePages(false);
         return;
      }

      if (searchChassis !== '' || searchArrivalDate !== '' || searchSlot !== ''
         || searchVessel !== '' || searchPod !== '' || searchAging !== '') {
         setLoadingMorePages(false);
         return;
      }

      setTimeout(() => {
         setPageReceived(oldValue => oldValue + 1);

         loadReceivedVehicles();
      }, 1000);
   };

   return (
      <>
         <PageHeader />
         <ButtonsNavigation />

         <div id="page-received">
            <div className="container">
               <div className="height">
                  {loadingScreen ?
                     <Loading
                        type='balls'
                     />
                     :
                     <main>
                        {searchChassis === '' && searchArrivalDate === '' && searchSlot === ''
                           && searchVessel === '' && searchPod === '' && searchAging === '' ?
                           null
                           :
                           <div className="filter-selected">
                              <span className='filter-title'>Selected filters:</span>

                              {searchChassis !== '' && (
                                 <div className="filter-selected-container">
                                    <FiX size={15} color='#5D5D5D' onClick={handleRemoveFilterChassi} />
                                    <span className='filter-uppercase'>{searchChassis}</span>
                                 </div>
                              )}

                              {searchArrivalDate !== '' && (
                                 <div className="filter-selected-container">
                                    <FiX size={15} color='#5D5D5D' onClick={handleRemoveFilterDate} />
                                    <span>{searchArrivalDate}</span>
                                 </div>
                              )}

                              {searchSlot !== '' && (
                                 <div className="filter-selected-container">
                                    <FiX size={15} color='#5D5D5D' onClick={handleRemoveFilterSlot} />
                                    <span className='filter-uppercase'>{searchSlot}</span>
                                 </div>
                              )}

                              {searchVessel !== '' && (
                                 <div className="filter-selected-container">
                                    <FiX size={15} color='#5D5D5D' onClick={handleRemoveFilterVessel} />
                                    <span className='filter-uppercase'>{searchVessel}</span>
                                 </div>
                              )}

                              {searchPod !== '' && (
                                 <div className="filter-selected-container">
                                    <FiX size={15} color='#5D5D5D' onClick={handleRemoveFilterPod} />
                                    <span className='filter-uppercase'>{searchPod}</span>
                                 </div>
                              )}

                              {searchAging !== '' && (
                                 <div className="filter-selected-container">
                                    <FiX size={15} color='#5D5D5D' onClick={handleRemoveFilterAging} />
                                    <span>{searchAging}</span>
                                 </div>
                              )}
                           </div>
                        }

                        {receivedVehiclesDataConfigured.length === 0 ?
                           <div className="no-vehicles">
                              <span>
                                 There are no vehicles stored at the port at this moment.
                                 <br />
                                 Please, register an ATP Inventory File.
                              </span>
                           </div>
                           :
                           <div className="vehicles-list">
                              <div className="label-container">
                                 <div
                                    className='select-chassi'
                                 ></div>
                                 {/* <input
                              type="checkbox"
                              id='select-all'
                              onChange={e => handleCheckAll(e.target.value)}
                           /> */}

                                 <DropdownFilterReceived
                                    label='VIN Number'
                                    dropdownFilterRef={dropdownFilterChassisRef}
                                    search={searchChassis}
                                    setSearch={setSearchChassis}
                                    handleShowFilter={handleShowFilterChassis}
                                    setShowFilter={setShowFilterChassis}
                                    showFilter={showFilterChassis}
                                    classContainer='chassi'
                                 />

                                 <div className="date-container">
                                    <label htmlFor="arrivalDate">Arrival Date</label>
                                    <DatePicker
                                       selected={startDate}
                                       onChange={onChangeDatePicker}
                                       customInput={<CalendarCustomButton />}
                                       showPopperArrow={false}
                                       todayButton='Today'
                                    />
                                 </div>

                                 <div className="model-container">
                                    <label htmlFor="model">Model</label>
                                 </div>

                                 <DropdownFilterReceived
                                    label='Slot'
                                    dropdownFilterRef={dropdownFilterSlotRef}
                                    search={searchSlot}
                                    setSearch={setSearchSlot}
                                    handleShowFilter={handleShowFilterSlot}
                                    setShowFilter={setShowFilterSlot}
                                    showFilter={showFilterSlot}
                                    classContainer='slot'
                                 />

                                 <DropdownFilterReceived
                                    label='Aging'
                                    dropdownFilterRef={dropdownFilterAgingRef}
                                    search={searchAging}
                                    setSearch={setSearchAging}
                                    handleShowFilter={handleShowFilterAging}
                                    setShowFilter={setShowFilterAging}
                                    showFilter={showFilterAging}
                                    classContainer='aging'
                                 />

                                 <DropdownFilterReceived
                                    label='Ship Assigned'
                                    dropdownFilterRef={dropdownFilterVesselRef}
                                    search={searchVessel}
                                    setSearch={setSearchVessel}
                                    handleShowFilter={handleShowFilterVessel}
                                    setShowFilter={setShowFilterVessel}
                                    showFilter={showFilterVessel}
                                    classContainer='vessel'
                                 />

                                 <DropdownFilterReceived
                                    label='POD'
                                    dropdownFilterRef={dropdownFilterPodRef}
                                    search={searchPod}
                                    setSearch={setSearchPod}
                                    handleShowFilter={handleShowFilterPod}
                                    setShowFilter={setShowFilterPod}
                                    showFilter={showFilterPod}
                                    classContainer='pod'
                                 />

                              </div>
                              <InfiniteScroll
                                 dataLength={receivedVehiclesData.length}
                                 next={handleFetchMore}
                                 hasMore={loadingMorePages}
                                 loader={
                                    <LoadingInfiniteScroll
                                       height={30}
                                       width='100%'
                                       type='spin'
                                    />
                                 }
                                 scrollThreshold={0.8}
                                 scrollableTarget="page-received"
                              >

                                 {receivedVehiclesDataConfigured.map(vehicle => {
                                    return (
                                       <div key={vehicle.chassi_id} className="cards">
                                          <input
                                             type="checkbox"
                                             value={[vehicle.chassi_id]}
                                             id={vehicle.chassi_id}
                                             onChange={e => handleCheckIdConcat(e.target.value)}
                                          // defaultChecked={isCheck}
                                          />
                                          <Link
                                             to={`/app/vehicle/${encodeURIComponent(crypto.DES.encrypt(String(vehicle.chassi_id), 'idChassi').toString())}/tracking`}
                                             className="card-click"
                                          >
                                             <span className="chassi-container">{vehicle.chassi_number}</span>
                                             <span className="date-container">{vehicle.arrival_date}</span>
                                             <span className="model-container">{vehicle.model_name}</span>
                                             <span className="slot-container">{vehicle.yard_slot}</span>
                                             <span className="aging-container">{`${vehicle.aging} day(s)`}</span>
                                             <span className="vessel-container">{vehicle.vessel_voyage}</span>
                                             <span className="pod-container">{vehicle.pod}</span>
                                             {vehicle.repaired ? (
                                                <div className="repaired-container">
                                                   <FiTool size={12} color='#555555' />
                                                </div>
                                             ) : (
                                                <div className="not-repaired-container"></div>
                                             )}
                                          </Link>
                                       </div>
                                    )
                                 })}
                              </InfiniteScroll>
                           </div>
                        }
                     </main>
                  }
               </div>
            </div>

            {chassisSelected.length !== 0 &&
               <div className="footer-container">
                  <div className="container">
                     <button
                        type='button'
                        onClick={handleOpenModalAddVessel}
                     >
                        Add Vessel & Voyage
                     </button>

                     <span>{chassisSelected.length} vehicles(s) selected</span>
                  </div>
               </div>
            }

            <ModalAddVesselVoyage
               isOpen={modalAddVesselIsOpen}
               toggle={handleOpenModalAddVessel}
               vesselId={vesselId}
               setVesselId={setVesselId}
               searchSelect={searchSelect}
               setSearchSelect={setSearchSelect}
               vesselVoyage={vesselVoyage}
               chassisSelected={chassisSelected}
               handleChassisAddVessel={handleChassisAddVessel}
               loadingButton={loadingButton}
            />
         </div>
      </>
   );
};

export default Received;
