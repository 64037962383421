import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import crypto from 'crypto-js';
import { FaRegTrashAlt } from 'react-icons/fa';
import { BiPencil } from 'react-icons/bi';
import { FiCheck } from 'react-icons/fi';

import PageHeader from '../../../components/PageHeader';
import Loading from '../../../components/Loading';

import api from '../../../services/api';

import '../../../styles/pages/app/settings/Users.css';

const Users = () => {
   const [loading, setLoading] = useState(true);
   const [usersData, setUsersData] = useState([]);

   useEffect(() => {
      async function loadUsers() {
         const res = await api.get('users');

         setUsersData(res.data);

         setLoading(false);
      };

      loadUsers();
   }, []);

   async function loadUsers() {
      const res = await api.get('users');

      setUsersData(res.data);

      setLoading(false);
   };

   async function handleDeleteUser(e, userId) {
      e.preventDefault();

      try {
         const res = await api.delete(`users/${userId}`);

         toast.success(res.data.message);

         loadUsers();

      } catch (e) {
         setLoading(false);

         e.response.data.errors.forEach(error => toast.error(error));
      };
   }

   return (
      <div id="page-users">
         <PageHeader settings={true} />

         <div className="container">
            {loading ?
               <Loading
                  type='balls'
               />
               :
               <div className="content">

                  <div className="header">
                     <div className="header-titles">
                        <h1>Manage Users</h1>
                        <span>Here you can change email and password of users and enable or disable access credentials.</span>
                        <br />
                        <span>The full name of users cannot be changed due to reasons.</span>
                     </div>

                     {usersData.length !== 0 &&
                        <div className="button-container">
                           <Link className='add-new-button' to='/app/settings/admin/newUser'>
                              Add New
                           </Link>
                           <Link to='/app/settings/admin' className='back-button'>
                              Back
                           </Link>
                        </div>
                     }
                  </div>

                  {usersData.length === 0 ? (
                     <div className="no-users-container">
                        <span>There is no registered users yet.
                     <br />
                     Click 'Add New' to register a new user.</span>

                        <div className="button-container">
                           <Link className='add-new-button' to='/app/settings/admin/newUser'>
                              Add New
                        </Link>
                           <Link to='/app/settings/admin' className='back-button'>
                              Back
                           </Link>
                        </div>
                     </div>
                  ) : (
                     <div className="users-container">
                        <div className='label-container'>
                           <label className='label-email' htmlFor="email">Email</label>
                           <label className='label-name' htmlFor="name">Name</label>
                           <label className='label-admin' htmlFor="admin">Admin</label>
                        </div>
                        {usersData.map(user => {
                           return (
                              <div key={user.id} className="user">
                                 <div className='user-info'>
                                    <div className='user-email'>{user.email}</div>
                                    <div className='user-name'>{user.name}</div>
                                    <div className='user-admin'>{user.admin && <FiCheck size={20} color='green' />}</div>
                                 </div>

                                 <div className='user-buttons'>
                                    <Link className='icons' to={`/app/settings/admin/users/${encodeURIComponent(crypto.DES.encrypt(String(user.id), 'idUser').toString())}`}>
                                       <BiPencil size={15} color='#747475' />
                                    </Link>

                                    <button className='icons' onClick={e => handleDeleteUser(e, user.id)}>
                                       <FaRegTrashAlt size={15} color='#747475' />
                                    </button>
                                 </div>
                              </div>
                           )
                        })}
                     </div>
                  )}
               </div>
            }
         </div>
      </div>
   );
};

export default Users;
