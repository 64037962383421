import React, { useCallback, useEffect, useState } from 'react';
import Chart from 'react-google-charts';

import LinksNavigationDashboard from '../../../components/dashboard/LinksNavigation';
import PageHeader from '../../../components/PageHeader';
import Loading from '../../../components/Loading';

import api from '../../../services/api';

import '../../../styles/pages/app/dashboard/Damaged.css';


const DamagedDashboard = () => {
   const [loading, setLoading] = useState(true);

   const [damagedInStockData, setDamagedInStockData] = useState('');
   const [repairMeanCycleTimeData, setRepairMeanCycleTimeData] = useState('');
   const [byDamageClassification, setByDamageClassification] = useState([]);
   const [byDamageResponsible, setByDamageResponsible] = useState([]);
   const [byDamageArea, setByDamageArea] = useState([]);

   const loadDamagedInStockData = useCallback(async () => {
      const res = await api.get('dashboard/damaged/damagedInStock');

      setDamagedInStockData(res.data);

   }, []);

   const loadRepairMeanCycleTimeData = useCallback(async () => {
      const res = await api.get('dashboard/damaged/repairMeanCycleTime');

      setRepairMeanCycleTimeData(res.data);

   }, []);

   const loadByDamageClassificationData = useCallback(async () => {
      const res = await api.get('dashboard/damaged/byDamageClassification');

      if (res.data.length === 0) {
         setByDamageClassification(res.data);

      } else {
         let arrayAdjusted = [];
         const header = ['Damage Classification', 'Quantity', { role: 'annotation' }];

         arrayAdjusted.push(header);

         res.data.map(item => {
            const data = [item.damageClassification, item.quantity, item.quantity];

            return arrayAdjusted.push(data);
         });

         setByDamageClassification(arrayAdjusted);
      }

   }, []);

   const loadByDamageResponsibleData = useCallback(async () => {
      const res = await api.get('dashboard/damaged/byDamageResponsible');

      if (res.data.length === 0) {
         setByDamageResponsible(res.data);

      } else {
         let arrayAdjusted = [];
         const header = ['Damage Responsible', 'Quantity', { role: 'annotation' }];

         arrayAdjusted.push(header);

         res.data.map(item => {
            const data = [item.damageResponsability, item.quantity, item.quantity];

            return arrayAdjusted.push(data);
         });

         setByDamageResponsible(arrayAdjusted);
      }

   }, []);

   const loadByDamageAreaData = useCallback(async () => {
      const res = await api.get('dashboard/damaged/byDamageArea');

      if (res.data.length === 0) {
         setByDamageArea(res.data);

      } else {

         let arrayAdjusted = [];
         const header = ['Damage Area', 'Quantity', { role: 'annotation' }];

         arrayAdjusted.push(header);

         res.data.map(item => {
            const data = [item.damageArea, item.quantity, item.quantity];

            return arrayAdjusted.push(data);
         });

         setByDamageArea(arrayAdjusted);
      }

   }, []);

   useEffect(() => {
      async function loadAllDatas() {
         await loadDamagedInStockData();
         await loadRepairMeanCycleTimeData();
         await loadByDamageClassificationData();
         await loadByDamageResponsibleData();
         await loadByDamageAreaData();

         setLoading(false);
      };

      loadAllDatas();

   }, [loadDamagedInStockData, loadRepairMeanCycleTimeData, loadByDamageClassificationData, loadByDamageResponsibleData, loadByDamageAreaData]);


   return (
      <>
         <PageHeader
            backButton={true}
         />

         <LinksNavigationDashboard />

         <div id='page-damaged-dashboard'>
            <div className="container">
               <div className="height">
                  {loading ?
                     <Loading />
                     :
                     <>
                        <h2>CURRENT FIGURES</h2>

                        <div className='in-line'>
                           <div className='info-container'>
                              <h3>Damaged In Stock (units)</h3>

                              <span>{damagedInStockData ? damagedInStockData : '-'}</span>
                           </div>
                           <div className='info-container'>
                              <h3>Repair Mean Cycle Time (days)</h3>

                              <span>{repairMeanCycleTimeData ? repairMeanCycleTimeData : '-'}</span>
                           </div>
                        </div>

                        <div className="chart-container-big">
                           <div className="chart-container-small">
                              <h3>By Damage Classification</h3>

                              {byDamageClassification.length !== 0 &&
                                 <Chart
                                    chartType='BarChart'
                                    data={byDamageClassification}
                                    options={{
                                       width: 320,
                                       height: 210,
                                       bars: 'horizontal',
                                       bar: { groupWidth: 21 },
                                       legend: { position: 'none' },
                                       backgroundColor: {
                                          fill: 'transparent'
                                       },
                                       fontName: 'Montserrat',
                                       fontSize: 11,
                                       chartArea: {
                                          backgroundColor: 'transparent',
                                          left: 80,
                                          top: 10,
                                          right: 10,
                                          bottom: 10,
                                       },
                                       hAxis: {
                                          baseline: 'none',
                                          gridlines: {
                                             color: 'transparent',
                                          },
                                          textStyle: {
                                             color: 'transparent',
                                          }
                                       },
                                       colors: ['#4B81E8'],
                                       tooltip: {
                                          text: 'quantity',
                                          trigger: 'focus',
                                          textStyle: {
                                             color: '#000000',
                                             fontName: 'Montserrat',
                                             fontSize: 12,
                                          }
                                       },
                                    }}
                                 />
                              }
                           </div>

                           <div className="chart-container-small">
                              <h3>By Damage Responsible</h3>

                              {byDamageResponsible.length !== 0 &&
                                 <Chart
                                    chartType='BarChart'
                                    data={byDamageResponsible}
                                    options={{
                                       width: 320,
                                       height: 210,
                                       bars: 'horizontal',
                                       bar: { groupWidth: 21 },
                                       legend: { position: 'none' },
                                       backgroundColor: {
                                          fill: 'transparent'
                                       },
                                       fontName: 'Montserrat',
                                       fontSize: 11,
                                       chartArea: {
                                          backgroundColor: 'transparent',
                                          left: 80,
                                          top: 10,
                                          right: 10,
                                          bottom: 10,
                                       },
                                       hAxis: {
                                          baseline: 'none',
                                          gridlines: {
                                             color: 'transparent',
                                          },
                                          textStyle: {
                                             color: 'transparent',
                                          }
                                       },
                                       colors: ['#4B81E8'],
                                       tooltip: {
                                          text: 'quantity',
                                          trigger: 'focus',
                                          textStyle: {
                                             color: '#000000',
                                             fontName: 'Montserrat',
                                             fontSize: 12,
                                          }
                                       },
                                    }}
                                 />
                              }
                           </div>

                           <div className="chart-container-small">
                              <h3>By Damaged Area</h3>

                              {byDamageArea.length !== 0 &&
                                 <Chart
                                    chartType='BarChart'
                                    data={byDamageArea}
                                    options={{
                                       width: 320,
                                       height: 210,
                                       bars: 'horizontal',
                                       bar: { groupWidth: 21 },
                                       legend: { position: 'none' },
                                       backgroundColor: {
                                          fill: 'transparent'
                                       },
                                       fontName: 'Montserrat',
                                       fontSize: 11,
                                       chartArea: {
                                          backgroundColor: 'transparent',
                                          left: 80,
                                          top: 10,
                                          right: 10,
                                          bottom: 10,
                                       },
                                       hAxis: {
                                          baseline: 'none',
                                          gridlines: {
                                             color: 'transparent',
                                          },
                                          textStyle: {
                                             color: 'transparent',
                                          }
                                       },
                                       colors: ['#4B81E8'],
                                       tooltip: {
                                          text: 'quantity',
                                          trigger: 'focus',
                                          textStyle: {
                                             color: '#000000',
                                             fontName: 'Montserrat',
                                             fontSize: 12,
                                          }
                                       },
                                    }}
                                 />
                              }
                           </div>

                        </div>
                     </>
                  }
               </div>
            </div>
         </div>
      </>
   );
};

export default DamagedDashboard;
