import React, { useState, useEffect, useCallback, useContext, useRef, forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { FiX } from 'react-icons/fi';
import { HiCalendar, HiOutlineCalendar } from 'react-icons/hi';
import crypto from 'crypto-js';
import InfiniteScroll from 'react-infinite-scroll-component';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import PageHeader from '../../components/PageHeader';
import Loading from '../../components/Loading';
import LoadingInfiniteScroll from '../../components/LoadingInfiniteScroll';
import ButtonsNavigation from '../../components/ButtonsNavigation';
import DropdownFilterDamaged from '../../components/dropdowns/DropdownFilterDamaged';
import DropdownFilterDamagedRepairable from '../../components/dropdowns/DropdownFilterDamagedRepairable';

import { RegisterContext } from '../../services/contexts/RegisterContext';

import api from '../../services/api';
import { AllLetterDown } from '../../utils/functions';

import '../../styles/pages/app/Damaged.css';

const Damaged = () => {
   const [startDate, setStartDate] = useState(new Date());

   const [showFilterChassis, setShowFilterChassis] = useState(false);
   const [showFilterRepairable, setShowFilterRepairable] = useState(false);

   const [searchChassis, setSearchChassis] = useState('');
   const [searchArrivalDate, setSearchArrivalDate] = useState('');
   const [searchUploadDate, setSearchUploadDate] = useState('');
   const [searchRepairable, setSearchRepairable] = useState('');
   const [searchRepairReceived, setSearchRepairReceived] = useState('');
   const [searchEstimateRelease, setSearchEstimateRelease] = useState('');

   const dropdownFilterChassisRef = useRef(null);
   const dropdownFilterRepairableRef = useRef(null);

   const {
      loadingScreen,
      setLoadingScreen,
      loadingMorePages,
      setLoadingMorePages,

      damagedVehiclesQuantity,
      setPageOnRoad,
      setPageScheduled,
      setPageReceived,
      setPageDamaged,
      setPageManifested,
      setPageLoaded,
      setPageInvoiced,

      loadOnRoadVehicles,
      loadScheduledVehicles,
      loadReceivedVehicles,
      loadDamagedVehicles,
      loadManifestedVehicles,
      loadLoadedVehicles,
      loadInvoicedVehicles,

      damagedVehiclesData,
      setDamagedVehiclesData,
   } = useContext(RegisterContext);


   useEffect(() => {
      if (searchChassis !== '' || searchArrivalDate !== '' || searchUploadDate !== ''
         || searchRepairable !== '' || searchRepairReceived !== '' || searchEstimateRelease !== '') {

         async function loadDamagedVehicles() {
            const res = await api.get(`damaged?pageSize=100000&chassis=${AllLetterDown(
               searchChassis)}&arrivalDate=${searchArrivalDate}&uploadDate=${searchUploadDate}&repairable=${searchRepairable}&
                  repairReceivedDate=${searchRepairReceived}&estimateReleaseDate=${searchEstimateRelease}`);

            setDamagedVehiclesData(res.data);

            setLoadingScreen(false);
         };

         loadDamagedVehicles();

      } else {
         loadDamagedVehicles();
      }

   }, [loadDamagedVehicles, setDamagedVehiclesData, setLoadingScreen, searchChassis, searchArrivalDate, searchUploadDate, searchRepairable, searchRepairReceived, searchEstimateRelease]);

   useEffect(() => {
      setLoadingMorePages(true);
      setPageOnRoad(1);
      setPageScheduled(1);
      setPageReceived(1);
      setPageManifested(1);
      setPageLoaded(1);
      setPageInvoiced(1);

   }, [setPageOnRoad, setPageScheduled, setPageReceived, setPageManifested, setPageLoaded, setPageInvoiced, setLoadingMorePages]);

   useEffect(() => {
      loadOnRoadVehicles();
      loadScheduledVehicles();
      loadReceivedVehicles();
      loadManifestedVehicles();
      loadLoadedVehicles();
      loadInvoicedVehicles();

   }, [loadOnRoadVehicles, loadScheduledVehicles, loadReceivedVehicles, loadManifestedVehicles, loadLoadedVehicles, loadInvoicedVehicles]);

   const damageVehiclesDataConfigurated = damagedVehiclesData.map(vehicle => {
      return {
         damage_id: vehicle.damage_id,
         chassi_id: vehicle.chassi_id,
         chassi_number: vehicle.chassi_number,
         arrival_date: moment(vehicle.arrival_date).format('YYYY-MM-DD'),
         model_name: vehicle.model_name,
         upload_date: moment(vehicle.upload_date).format('YYYY-MM-DD'),
         damage_area: vehicle.damage_area,
         damage_type: vehicle.damage_type,
         damage_severity: vehicle.damage_severity,
         repairable: vehicle.repairable ? 'Yes' : 'No',
         repair_received_date: vehicle.repair_received_date === null ? '-' : moment(vehicle.repair_received_date).format('YYYY-MM-DD'),
         estimated_release_date: vehicle.estimated_release_date === null ? '-' : moment(vehicle.estimated_release_date).format('YYYY-MM-DD'),
      };
   });

   const handleShowFilterChassis = useCallback((e) => {
      if (dropdownFilterChassisRef.current && showFilterChassis) {
         if (!dropdownFilterChassisRef.current.contains(e.target)) {
            setShowFilterChassis(false);
         }

      } else if (!dropdownFilterChassisRef.current && showFilterChassis) {
         setShowFilterChassis(false);

      } else {
         setShowFilterChassis(true);
      }

   }, [showFilterChassis]);

   useEffect(() => {
      if (showFilterChassis) {
         document.addEventListener("click", handleShowFilterChassis);

         return () => document.removeEventListener("click", handleShowFilterChassis);
      }

   }, [showFilterChassis, handleShowFilterChassis]);

   const handleShowFilterRepairable = useCallback((e) => {
      if (dropdownFilterRepairableRef.current && showFilterRepairable) {
         if (!dropdownFilterRepairableRef.current.contains(e.target)) {
            setShowFilterRepairable(false);
         }

      } else if (!dropdownFilterRepairableRef.current && showFilterRepairable) {
         setShowFilterRepairable(false);

      } else {
         setShowFilterRepairable(true);
      }

   }, [showFilterRepairable]);

   useEffect(() => {
      if (showFilterRepairable) {
         document.addEventListener("click", handleShowFilterRepairable);

         return () => document.removeEventListener("click", handleShowFilterRepairable);
      }

   }, [showFilterRepairable, handleShowFilterRepairable]);


   const onChangeArrivalDatePicker = (date) => {
      setStartDate(date);
      setSearchArrivalDate(moment(new Date(date)).format('YYYY-MM-DD'));
   };

   const CalendarCustomButtonArrivalDate = forwardRef(({ onClick }, ref) => {
      return (
         <button
            type="button"
            className='button-filter'
            onClick={onClick}
            ref={ref}
         >
            {searchArrivalDate !== '' ?
               <HiCalendar size={20} color="#4B81E8" />
               :
               <HiOutlineCalendar size={20} color="#5D5D5D" />
            }
         </button>
      );
   });

   const onChangeUploadDatePicker = (date) => {
      setStartDate(date);
      setSearchUploadDate(moment(new Date(date)).format('YYYY-MM-DD'));
   };

   const CalendarCustomButtonUploadDate = forwardRef(({ onClick }, ref) => {
      return (
         <button
            type="button"
            className='button-filter'
            onClick={onClick}
            ref={ref}
         >
            {searchUploadDate !== '' ?
               <HiCalendar size={20} color="#4B81E8" />
               :
               <HiOutlineCalendar size={20} color="#5D5D5D" />
            }
         </button>
      );
   });

   const onChangeRepairReceivedPicker = (date) => {
      setStartDate(date);
      setSearchRepairReceived(moment(new Date(date)).format('YYYY-MM-DD'));
   };

   const CalendarCustomButtonRepairReceived = forwardRef(({ onClick }, ref) => {
      return (
         <button
            type="button"
            className='button-filter'
            onClick={onClick}
            ref={ref}
         >
            {searchRepairReceived !== '' ?
               <HiCalendar size={20} color="#4B81E8" />
               :
               <HiOutlineCalendar size={20} color="#5D5D5D" />
            }
         </button>
      );
   });

   const onChangeEstimateReleasePicker = (date) => {
      setStartDate(date);
      setSearchEstimateRelease(moment(new Date(date)).format('YYYY-MM-DD'));
   };

   const CalendarCustomButtonEstimateRelease = forwardRef(({ onClick }, ref) => {
      return (
         <button
            type="button"
            className='button-filter'
            onClick={onClick}
            ref={ref}
         >
            {searchEstimateRelease !== '' ?
               <HiCalendar size={20} color="#4B81E8" />
               :
               <HiOutlineCalendar size={20} color="#5D5D5D" />
            }
         </button>
      );
   });

   function handleRemoveFilterChassi() {
      setLoadingScreen(true);

      setSearchChassis('');
      setPageDamaged(1);
      setLoadingMorePages(true);
   };

   function handleRemoveFilterArrivalDate() {
      setLoadingScreen(true);

      setSearchArrivalDate('');
      setPageDamaged(1);
      setLoadingMorePages(true);
   };

   function handleRemoveFilterUploadDate() {
      setLoadingScreen(true);

      setSearchUploadDate('');
      setPageDamaged(1);
      setLoadingMorePages(true);
   };

   function handleRemoveFilterRepairable() {
      setLoadingScreen(true);

      setSearchRepairable('');
      setPageDamaged(1);
      setLoadingMorePages(true);
   };

   function handleRemoveFilterRepairReceived() {
      setLoadingScreen(true);

      setSearchRepairReceived('');
      setPageDamaged(1);
      setLoadingMorePages(true);
   };

   function handleRemoveFilterEstimateRelease() {
      setLoadingScreen(true);

      setSearchEstimateRelease('');
      setPageDamaged(1);
      setLoadingMorePages(true);
   };

   function handleFetchMore() {
      if (damagedVehiclesData.length >= Number(damagedVehiclesQuantity)) {
         setLoadingMorePages(false);
         return;
      }

      if (searchChassis !== '' || searchArrivalDate !== '' || searchUploadDate !== ''
         || searchRepairable !== '' || searchRepairReceived !== '' || searchEstimateRelease !== '') {
         setLoadingMorePages(false);
         return;
      }

      setTimeout(() => {
         setPageDamaged(oldValue => oldValue + 1);

         loadDamagedVehicles();
      }, 1000);
   };


   return (
      <>
         <PageHeader />
         <ButtonsNavigation />

         <div id="page-damaged">
            <div className="container">
               <div className="height">
                  {loadingScreen ?
                     <Loading
                        type='balls'
                     />
                     :
                     <main>
                        {searchChassis === '' && searchArrivalDate === '' && searchUploadDate === ''
                           && searchRepairable === '' && searchRepairReceived === '' && searchEstimateRelease === '' ?
                           null
                           :
                           <div className="filter-selected">
                              <span className='filter-title'>Selected filters:</span>

                              {searchChassis !== '' && (
                                 <div className="filter-selected-container">
                                    <FiX size={15} color='#5D5D5D' onClick={handleRemoveFilterChassi} />
                                    <span className='chassi-filtered'>{searchChassis}</span>
                                 </div>
                              )}

                              {searchArrivalDate !== '' && (
                                 <div className="filter-selected-container">
                                    <FiX size={15} color='#5D5D5D' onClick={handleRemoveFilterArrivalDate} />
                                    <span>{searchArrivalDate}</span>
                                 </div>
                              )}

                              {searchUploadDate !== '' && (
                                 <div className="filter-selected-container">
                                    <FiX size={15} color='#5D5D5D' onClick={handleRemoveFilterUploadDate} />
                                    <span>{searchUploadDate}</span>
                                 </div>
                              )}

                              {searchRepairable !== '' && (
                                 <div className="filter-selected-container">
                                    <FiX size={15} color='#5D5D5D' onClick={handleRemoveFilterRepairable} />
                                    <span>{searchRepairable ? 'Yes' : 'No'}</span>
                                 </div>
                              )}

                              {searchRepairReceived !== '' && (
                                 <div className="filter-selected-container">
                                    <FiX size={15} color='#5D5D5D' onClick={handleRemoveFilterRepairReceived} />
                                    <span>{searchRepairReceived}</span>
                                 </div>
                              )}

                              {searchEstimateRelease !== '' && (
                                 <div className="filter-selected-container">
                                    <FiX size={15} color='#5D5D5D' onClick={handleRemoveFilterEstimateRelease} />
                                    <span>{searchEstimateRelease}</span>
                                 </div>
                              )}
                           </div>
                        }

                        {damageVehiclesDataConfigurated.length === 0 ?
                           <div className="no-vehicles">
                              <span>
                                 There are no damaged vehicles at this moment.
                              </span>
                           </div>
                           :
                           <div className="vehicles-list">
                              <div className="label-container">

                                 <DropdownFilterDamaged
                                    label='VIN Number'
                                    dropdownFilterRef={dropdownFilterChassisRef}
                                    search={searchChassis}
                                    setSearch={setSearchChassis}
                                    handleShowFilter={handleShowFilterChassis}
                                    setShowFilter={setShowFilterChassis}
                                    showFilter={showFilterChassis}
                                    classContainer='chassi'
                                 />

                                 <div className="date-container">
                                    <label htmlFor="arrivalDate">Arrival Date</label>
                                    <DatePicker
                                       selected={startDate}
                                       onChange={onChangeArrivalDatePicker}
                                       customInput={<CalendarCustomButtonArrivalDate />}
                                       showPopperArrow={false}
                                       todayButton='Today'
                                    />
                                 </div>

                                 <div className="model-container">
                                    <label htmlFor="model">Model</label>
                                 </div>

                                 <div className="date-container">
                                    <label htmlFor="uploadDate">Damage Report Date</label>
                                    <DatePicker
                                       selected={startDate}
                                       onChange={onChangeUploadDatePicker}
                                       customInput={<CalendarCustomButtonUploadDate />}
                                       showPopperArrow={false}
                                       todayButton='Today'
                                    />
                                 </div>

                                 <div className="damage-label-container">
                                    <label htmlFor="damage">Damage</label>
                                 </div>

                                 <DropdownFilterDamagedRepairable
                                    label='Repairable'
                                    dropdownFilterRef={dropdownFilterRepairableRef}
                                    search={searchRepairable}
                                    setSearch={setSearchRepairable}
                                    handleShowFilter={handleShowFilterRepairable}
                                    setShowFilter={setShowFilterRepairable}
                                    showFilter={showFilterRepairable}
                                    classContainer='repairable'
                                 />

                                 <div className="date-container">
                                    <label htmlFor="repairReceived">Repair Received Date</label>
                                    <DatePicker
                                       selected={startDate}
                                       onChange={onChangeRepairReceivedPicker}
                                       customInput={<CalendarCustomButtonRepairReceived />}
                                       showPopperArrow={false}
                                       todayButton='Today'
                                    />
                                 </div>

                                 <div className="date-container">
                                    <label htmlFor="estimateRelease">Estimated Release Date</label>
                                    <DatePicker
                                       selected={startDate}
                                       onChange={onChangeEstimateReleasePicker}
                                       customInput={<CalendarCustomButtonEstimateRelease />}
                                       showPopperArrow={false}
                                       todayButton='Today'
                                    />
                                 </div>

                              </div>

                              <InfiniteScroll
                                 dataLength={damagedVehiclesData.length}
                                 next={handleFetchMore}
                                 hasMore={loadingMorePages}
                                 loader={
                                    <LoadingInfiniteScroll
                                       height={30}
                                       width='100%'
                                       type='spin'
                                    />
                                 }
                                 scrollThreshold={0.8}
                                 scrollableTarget="page-damaged"
                              >
                                 {damageVehiclesDataConfigurated.map(vehicle => {
                                    return (
                                       <div key={vehicle.damage_id} className="cards">
                                          <Link
                                             to={`/app/vehicle/${encodeURIComponent(crypto.DES.encrypt(String(vehicle.chassi_id), 'idChassi').toString())}/tracking`}
                                             className="card-click"
                                          >
                                             <span className="chassi-container">{vehicle.chassi_number}</span>
                                             <span className="date-container">{vehicle.arrival_date}</span>
                                             <span className="model-container">{vehicle.model_name}</span>
                                             <span className="date-container">{vehicle.upload_date}</span>
                                             <span className="damage-container">{vehicle.damage_area}</span>
                                             <span className="damage-container">{vehicle.damage_type}</span>
                                             <span className="damage-container">{vehicle.damage_severity}</span>
                                             <span className="repairable-container">{vehicle.repairable}</span>
                                             <span className="date-container">{vehicle.repair_received_date}</span>
                                             <span className="date-container">{vehicle.estimated_release_date}</span>
                                          </Link>
                                       </div>
                                    )
                                 })}
                              </InfiniteScroll>
                           </div>

                        }
                     </main>
                  }
               </div>
            </div>
         </div>
      </>
   );
};

export default Damaged;
