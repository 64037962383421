import React, { useState, useEffect } from 'react';

import Loading from '../components/Loading';
import { useAuth } from '../services/contexts/auth';

import AuthRoutes from './auth.routes';
import AppAdminRoutes from './app.admin.routes';
import AppNotAdminRoutes from './app.not.admin.routes';

import { RegisterProvider } from '../services/contexts/RegisterContext';
import { ChassiDetailsProvider } from '../services/contexts/ChassiDetailsContext';

const Routes = () => {
   const [userLoggedIsAdmin, setUserLoggedIsAdmin] = useState(false);

   const { userAdmin, adminStorage, tokenValid, loading } = useAuth();

   useEffect(() => {
      if (userAdmin) {
         setUserLoggedIsAdmin(userAdmin);

      } else {
         setUserLoggedIsAdmin(adminStorage);

      }

   }, [userAdmin, adminStorage]);


   if (loading) {
      return (
         <Loading type='balls' />
      );
   }

   return (
      tokenValid ?
         userLoggedIsAdmin ?
            <RegisterProvider>
               <ChassiDetailsProvider>
                  <AppAdminRoutes />
               </ChassiDetailsProvider>
            </RegisterProvider>
            :
            <RegisterProvider>
               <ChassiDetailsProvider>
                  <AppNotAdminRoutes />
               </ChassiDetailsProvider>
            </RegisterProvider>
         :
         <AuthRoutes />

   );
}

export default Routes;
