import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import crypto from 'crypto-js';

import PageHeader from '../../../components/PageHeader';
import Loading from '../../../components/Loading';
import ChassiDetailsHeader from '../../../components/ChassiDetails/ChassiDetailsHeader';
import LinksNavigation from '../../../components/ChassiDetails/LinksNavigation';
import ChassiHighlights from '../../../components/ChassiDetails/ChassiHighlights';
import InputChassiDetails from '../../../components/ChassiDetails/InputChassiDetails';

import api from '../../../services/api';
import { ChassiDetailsContext } from '../../../services/contexts/ChassiDetailsContext';

import '../../../styles/pages/app/ChassiDetails/Services.css';

const ChassiDetailsServices = () => {
   const [towHookInstallIsDisabled, setTowHookInstallIsDisabled] = useState(true);
   const [washIsDisabled, setWashIsDisabled] = useState(true);
   const [batteryChargeIsDisabled, setBatteryChargeIsDisabled] = useState(true);
   const [tireReplacementIsDisabled, setTireReplacementIsDisabled] = useState(true);
   const [airTireSuppliedIsDisabled, setAirTireSuppliedIsDisabled] = useState(true);
   const [refuelIsDisabled, setRefuelIsDisabled] = useState(true);
   const [batteryReviewIsDisabled, setBatteryReviewIsDisabled] = useState(true);

   const [towHookInstallEdit, setTowHookInstallEdit] = useState();
   const [washEdit, setWashEdit] = useState();
   const [batteryChargeEdit, setBatteryChargeEdit] = useState();
   const [tireReplacementEdit, setTireReplacementEdit] = useState();
   const [airTireSuppliedEdit, setAirTireSuppliedEdit] = useState();
   const [refuelEdit, setRefuelEdit] = useState();
   const [batteryReviewEdit, setBatteryReviewEdit] = useState();


   const { id } = useParams();
   const chassi_id = crypto.DES.decrypt(decodeURIComponent(id), 'idChassi').toString(crypto.enc.Utf8);

   const {
      loadingScreen,
      setChassiId,
      chassiDetailsInfoAdjusted,
      loadChassiDetails,
   } = useContext(ChassiDetailsContext);

   useEffect(() => {
      setChassiId(chassi_id);

   }, [setChassiId, chassi_id]);

   async function handleChassiPdiServiceUpdate() {
      const data = {
         towHookInstallQuantity: towHookInstallEdit === undefined ? chassiDetailsInfoAdjusted.pdi_service[0].pdi_quantity : towHookInstallEdit,
         washQuantity: washEdit === undefined ? chassiDetailsInfoAdjusted.pdi_service[1].pdi_quantity : washEdit,
         batteryChargeQuantity: batteryChargeEdit === undefined ? chassiDetailsInfoAdjusted.pdi_service[2].pdi_quantity : batteryChargeEdit,
         tireReplacementQuantity: tireReplacementEdit === undefined ? chassiDetailsInfoAdjusted.pdi_service[3].pdi_quantity : tireReplacementEdit,
         airTireSuppliedQuantity: airTireSuppliedEdit === undefined ? chassiDetailsInfoAdjusted.pdi_service[4].pdi_quantity : airTireSuppliedEdit,
         refuelQuantity: refuelEdit === undefined ? chassiDetailsInfoAdjusted.pdi_service[5].pdi_quantity : refuelEdit,
         batteryReviewQuantity: batteryReviewEdit === undefined ? chassiDetailsInfoAdjusted.pdi_service[6].pdi_quantity : batteryReviewEdit,
      };

      try {
         const res = await api.put(`chassis/${chassi_id}/services`, data);

         await loadChassiDetails();

         toast.success(res.data.message);

      } catch (e) {
         e.response.data.errors.forEach(error => toast.error(error));
      }

      setTowHookInstallIsDisabled(true);
      setWashIsDisabled(true);
      setBatteryChargeIsDisabled(true);
      setTireReplacementIsDisabled(true);
      setAirTireSuppliedIsDisabled(true);
      setRefuelIsDisabled(true);
      setBatteryReviewIsDisabled(true);

      setTowHookInstallEdit();
      setWashEdit();
      setBatteryChargeEdit();
      setTireReplacementEdit();
      setAirTireSuppliedEdit();
      setRefuelEdit();
      setBatteryReviewEdit();
   };


   function handleAllowEditionTowHookInstall() {
      setTowHookInstallIsDisabled(!towHookInstallIsDisabled);
   };

   function handleAllowEditionWash() {
      setWashIsDisabled(!washIsDisabled);
   };

   function handleAllowEditionBatteryCharge() {
      setBatteryChargeIsDisabled(!batteryChargeIsDisabled);
   };

   function handleAllowEditionTireReplacement() {
      setTireReplacementIsDisabled(!tireReplacementIsDisabled);
   };

   function handleAllowEditionAirTireSupplied() {
      setAirTireSuppliedIsDisabled(!airTireSuppliedIsDisabled);
   };

   function handleAllowEditionRefuel() {
      setRefuelIsDisabled(!refuelIsDisabled);
   };

   function handleAllowEditionBatteryReview() {
      setBatteryReviewIsDisabled(!batteryReviewIsDisabled);
   };

   return (
      <>
         <PageHeader
            backButton={true}
         />

         <ChassiDetailsHeader
            chassiNumber={chassiDetailsInfoAdjusted.chassi_number}
            modelName={chassiDetailsInfoAdjusted.model_name}
            modelYear={chassiDetailsInfoAdjusted.model_year}
            pod={chassiDetailsInfoAdjusted.pod}
            currentStatus={chassiDetailsInfoAdjusted.status}
         />

         <LinksNavigation />

         <div id="page-details-services">
            {loadingScreen ?
               <Loading
                  type='balls'
               />
               :
               <div className="container">
                  <div className="container-left">
                     <ChassiHighlights
                        modelCode={chassiDetailsInfoAdjusted.model_code}
                        modelMaker={chassiDetailsInfoAdjusted.maker}
                        assemblyPlant={chassiDetailsInfoAdjusted.assembly_plant}
                        currentStatus={chassiDetailsInfoAdjusted.status}
                        aging={chassiDetailsInfoAdjusted.aging}
                     />
                  </div>

                  <div className="container-right">
                     <h2>PDI Services Info</h2>

                     <div className="form">
                        <InputChassiDetails
                           label={chassiDetailsInfoAdjusted.pdi_service[0].pdi_name}
                           handleAllowEdition={handleAllowEditionTowHookInstall}
                           value={chassiDetailsInfoAdjusted.pdi_service[0].pdi_quantity}
                           valueEdited={towHookInstallEdit}
                           onChange={setTowHookInstallEdit}
                           isDisabled={towHookInstallIsDisabled}
                           editable={true}
                           onBlur={() => handleChassiPdiServiceUpdate()}
                           type='number'
                        />

                        <InputChassiDetails
                           label={`${chassiDetailsInfoAdjusted.pdi_service[0].pdi_name} (Amount - USD)`}
                           value={chassiDetailsInfoAdjusted.pdi_service[0].pdi_quantity * chassiDetailsInfoAdjusted.pdi_service[0].pdi_unit_price}
                           valueEdited={undefined}
                           isDisabled={true}
                           editable={false}
                        />

                        <InputChassiDetails
                           label={chassiDetailsInfoAdjusted.pdi_service[1].pdi_name}
                           handleAllowEdition={handleAllowEditionWash}
                           value={chassiDetailsInfoAdjusted.pdi_service[1].pdi_quantity}
                           valueEdited={washEdit}
                           onChange={setWashEdit}
                           isDisabled={washIsDisabled}
                           editable={true}
                           onBlur={() => handleChassiPdiServiceUpdate()}
                           type='number'
                        />

                        <InputChassiDetails
                           label={`${chassiDetailsInfoAdjusted.pdi_service[1].pdi_name} (Amount - USD)`}
                           value={chassiDetailsInfoAdjusted.pdi_service[1].pdi_quantity * chassiDetailsInfoAdjusted.pdi_service[1].pdi_unit_price}
                           valueEdited={undefined}
                           isDisabled={true}
                           editable={false}
                        />

                        <InputChassiDetails
                           label={chassiDetailsInfoAdjusted.pdi_service[2].pdi_name}
                           handleAllowEdition={handleAllowEditionBatteryCharge}
                           value={chassiDetailsInfoAdjusted.pdi_service[2].pdi_quantity}
                           valueEdited={batteryChargeEdit}
                           onChange={setBatteryChargeEdit}
                           isDisabled={batteryChargeIsDisabled}
                           editable={true}
                           onBlur={() => handleChassiPdiServiceUpdate()}
                           type='number'
                        />

                        <InputChassiDetails
                           label={`${chassiDetailsInfoAdjusted.pdi_service[2].pdi_name} (Amount - USD)`}
                           value={chassiDetailsInfoAdjusted.pdi_service[2].pdi_quantity * chassiDetailsInfoAdjusted.pdi_service[2].pdi_unit_price}
                           valueEdited={undefined}
                           isDisabled={true}
                           editable={false}
                        />

                        <InputChassiDetails
                           label={chassiDetailsInfoAdjusted.pdi_service[3].pdi_name}
                           handleAllowEdition={handleAllowEditionTireReplacement}
                           value={chassiDetailsInfoAdjusted.pdi_service[3].pdi_quantity}
                           valueEdited={tireReplacementEdit}
                           onChange={setTireReplacementEdit}
                           isDisabled={tireReplacementIsDisabled}
                           editable={true}
                           onBlur={() => handleChassiPdiServiceUpdate()}
                           type='number'
                        />

                        <InputChassiDetails
                           label={`${chassiDetailsInfoAdjusted.pdi_service[3].pdi_name} (Amount - USD)`}
                           value={chassiDetailsInfoAdjusted.pdi_service[3].pdi_quantity * chassiDetailsInfoAdjusted.pdi_service[3].pdi_unit_price}
                           valueEdited={undefined}
                           isDisabled={true}
                           editable={false}
                        />

                        <InputChassiDetails
                           label={chassiDetailsInfoAdjusted.pdi_service[4].pdi_name}
                           handleAllowEdition={handleAllowEditionAirTireSupplied}
                           value={chassiDetailsInfoAdjusted.pdi_service[4].pdi_quantity}
                           valueEdited={airTireSuppliedEdit}
                           onChange={setAirTireSuppliedEdit}
                           isDisabled={airTireSuppliedIsDisabled}
                           editable={true}
                           onBlur={() => handleChassiPdiServiceUpdate()}
                           type='number'
                        />

                        <InputChassiDetails
                           label={`${chassiDetailsInfoAdjusted.pdi_service[4].pdi_name} (Amount - USD)`}
                           value={chassiDetailsInfoAdjusted.pdi_service[4].pdi_quantity * chassiDetailsInfoAdjusted.pdi_service[4].pdi_unit_price}
                           valueEdited={undefined}
                           isDisabled={true}
                           editable={false}
                        />

                        <InputChassiDetails
                           label={chassiDetailsInfoAdjusted.pdi_service[5].pdi_name}
                           handleAllowEdition={handleAllowEditionRefuel}
                           value={chassiDetailsInfoAdjusted.pdi_service[5].pdi_quantity}
                           valueEdited={refuelEdit}
                           onChange={setRefuelEdit}
                           isDisabled={refuelIsDisabled}
                           editable={true}
                           onBlur={() => handleChassiPdiServiceUpdate()}
                           type='number'
                        />

                        <InputChassiDetails
                           label={`${chassiDetailsInfoAdjusted.pdi_service[5].pdi_name} (Amount - USD)`}
                           value={chassiDetailsInfoAdjusted.pdi_service[5].pdi_quantity * chassiDetailsInfoAdjusted.pdi_service[5].pdi_unit_price}
                           valueEdited={undefined}
                           isDisabled={true}
                           editable={false}
                        />

                        <InputChassiDetails
                           label={chassiDetailsInfoAdjusted.pdi_service[6].pdi_name}
                           handleAllowEdition={handleAllowEditionBatteryReview}
                           value={chassiDetailsInfoAdjusted.pdi_service[6].pdi_quantity}
                           valueEdited={batteryReviewEdit}
                           onChange={setBatteryReviewEdit}
                           isDisabled={batteryReviewIsDisabled}
                           editable={true}
                           onBlur={() => handleChassiPdiServiceUpdate()}
                           type='number'
                        />

                        <InputChassiDetails
                           label={`${chassiDetailsInfoAdjusted.pdi_service[6].pdi_name} (Amount - USD)`}
                           value={chassiDetailsInfoAdjusted.pdi_service[6].pdi_quantity * chassiDetailsInfoAdjusted.pdi_service[6].pdi_unit_price}
                           valueEdited={undefined}
                           isDisabled={true}
                           editable={false}
                        />
                     </div>
                  </div>
               </div>
            }
         </div>
      </>
   );
};

export default ChassiDetailsServices;
