import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import OnRoad from '../pages/app/OnRoad';
import Scheduled from '../pages/app/Scheduled';
import Received from '../pages/app/Received';
import Damaged from '../pages/app/Damaged';
import Manifested from '../pages/app/Manifested';
import Loaded from '../pages/app/Loaded';
import Invoiced from '../pages/app/Invoiced';

import ChassiDetailsTracking from '../pages/app/chassiDetails/Tracking';
import ChassiDetailsGeneral from '../pages/app/chassiDetails/General';
import ChassiDetailsRoadTransport from '../pages/app/chassiDetails/RoadTransport';
import ChassiDetailsPortTerminal from '../pages/app/chassiDetails/PortTerminal';
import ChassiDetailsDamage from '../pages/app/chassiDetails/Damage';
import ChassiDetailsRepair from '../pages/app/chassiDetails/Repair';
import ChassiDetailsServices from '../pages/app/chassiDetails/Services';
import ChassiDetailsSeaTransport from '../pages/app/chassiDetails/SeaTransport';

import ATPSchedulingRequest from '../pages/app/reports/ATPSchedulingRequest';
import ATPHandlingRequest from '../pages/app/reports/ATPHandlingRequest';
import ATPLoadingRequest from '../pages/app/reports/ATPLoadingRequest';
import DfYoung from '../pages/app/reports/DfYoung';
import GlobalDamageReport from '../pages/app/reports/GlobalDamageReport';
import LoadingList from '../pages/app/reports/LoadingList';
import RepairList from '../pages/app/reports/RepairList';
import SchedulingList from '../pages/app/reports/SchedulingList';
import Tarja2 from '../pages/app/reports/Tarja2';
import CapacidadSimplificada from '../pages/app/reports/CapacidadSimplificada';
import IngresoDeMadrinas from '../pages/app/reports/IngresoDeMadrinas';
import VVO242 from '../pages/app/reports/VVO242';
import GMInventoryList from '../pages/app/reports/GMInventoryList';
import GMInTransitList from '../pages/app/reports/GMInTransitList';

import ToBeReceived from '../pages/app/dashboard/ToBeReceived';
import InStock from '../pages/app/dashboard/InStock';
import YardCapacity from '../pages/app/dashboard/YardCapacity';
import DamagedDashboard from '../pages/app/dashboard/Damaged';
import LoadedDashboard from '../pages/app/dashboard/Loaded';

import SettingsAdmin from '../pages/app/settings/SettingsAdmin';
import Error401 from '../pages/auth/Error401';

import Models from '../pages/app/settings/Models';
import ModelNew from '../pages/app/settings/ModelNew';
import ModelEdit from '../pages/app/settings/ModelEdit';

import Users from '../pages/app/settings/Users';
import UserNew from '../pages/app/settings/UserNew';
import UserEdit from '../pages/app/settings/UserEdit';

import Vessels from '../pages/app/settings/Vessels';
import VesselNew from '../pages/app/settings/VesselNew';
import VesselEdit from '../pages/app/settings/VesselEdit';

import PdiServices from '../pages/app/settings/PdiServices';
import PdiEdit from '../pages/app/settings/PdiEdit';

import ResetPassword from '../pages/auth/ResetPassword';

function AppAdminRoutes() {
   return (
      <Switch>
         <Redirect exact from='/' to='/app/onRoad' />
         <Route path='/app/onRoad' component={OnRoad} />
         <Route path='/app/scheduled' component={Scheduled} />
         <Route path='/app/received' component={Received} />
         <Route path='/app/damaged' component={Damaged} />
         <Route path='/app/manifested' component={Manifested} />
         <Route path='/app/loaded' component={Loaded} />
         <Route path='/app/invoiced' component={Invoiced} />

         <Route path='/app/vehicle/:id/tracking' component={ChassiDetailsTracking} />
         <Route path='/app/vehicle/:id/general' component={ChassiDetailsGeneral} />
         <Route path='/app/vehicle/:id/roadTransport' component={ChassiDetailsRoadTransport} />
         <Route path='/app/vehicle/:id/portTerminal' component={ChassiDetailsPortTerminal} />
         <Route path='/app/vehicle/:id/damage' component={ChassiDetailsDamage} />
         <Route path='/app/vehicle/:id/repair' component={ChassiDetailsRepair} />
         <Route path='/app/vehicle/:id/services' component={ChassiDetailsServices} />
         <Route path='/app/vehicle/:id/seaTransport' component={ChassiDetailsSeaTransport} />

         <Route path='/app/reports/schedulingRequest' component={ATPSchedulingRequest} />
         <Route path='/app/reports/handlingRequest' component={ATPHandlingRequest} />
         <Route path='/app/reports/loadingRequest' component={ATPLoadingRequest} />
         <Route path='/app/reports/dfYoung' component={DfYoung} />
         <Route path='/app/reports/globalDamage' component={GlobalDamageReport} />
         <Route path='/app/reports/loadingList' component={LoadingList} />
         <Route path='/app/reports/repairList' component={RepairList} />
         <Route path='/app/reports/schedulingList' component={SchedulingList} />
         <Route path='/app/reports/tarja2' component={Tarja2} />
         <Route path='/app/reports/capacidadSimplificada' component={CapacidadSimplificada} />
         <Route path='/app/reports/ingresoDeMadrinas' component={IngresoDeMadrinas} />
         <Route path='/app/reports/vvo242' component={VVO242} />
         <Route path='/app/reports/gmInventoryList' component={GMInventoryList} />
         <Route path='/app/reports/gmInTransitList' component={GMInTransitList} />

         <Route path='/app/dashboard/toBeReceived' component={ToBeReceived} />
         <Route path='/app/dashboard/inStock' component={InStock} />
         <Route path='/app/dashboard/yardCapacity' component={YardCapacity} />
         <Route path='/app/dashboard/damaged' component={DamagedDashboard} />
         <Route path='/app/dashboard/loaded' component={LoadedDashboard} />

         <Route exact path='/app/settings/admin' component={SettingsAdmin} />

         <Route exact path='/app/settings/admin/users' component={Users} />
         <Route path='/app/settings/admin/newUser' component={UserNew} />
         <Route path='/app/settings/admin/users/:id' component={UserEdit} />

         <Route exact path='/app/settings/admin/models' component={Models} />
         <Route path='/app/settings/admin/newModel' component={ModelNew} />
         <Route path='/app/settings/admin/models/:id' component={ModelEdit} />

         <Route exact path='/app/settings/admin/vessels' component={Vessels} />
         <Route path='/app/settings/admin/newVessel' component={VesselNew} />
         <Route path='/app/settings/admin/vessels/:id' component={VesselEdit} />

         <Route exact path='/app/settings/admin/pdiServices' component={PdiServices} />
         <Route path='/app/settings/admin/pdiServices/:id' component={PdiEdit} />

         <Route path='/resetPassword/:token' component={ResetPassword} />

         <Route exact path='/error' component={Error401} />

         <Redirect to='/error' />
      </Switch>
   );
};

export default AppAdminRoutes;
