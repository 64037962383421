import React, { useState, useContext, forwardRef } from 'react';
import { toast } from 'react-toastify';
import { CSVLink } from 'react-csv';
import Select from 'react-select';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { HiOutlineCalendar } from 'react-icons/hi';

import PageHeader from '../../../components/PageHeader';
import Loading from '../../../components/Loading';

import api from '../../../services/api';
import { RegisterContext } from '../../../services/contexts/RegisterContext';
import { AllLettersUp, AllFirstLettersUp } from '../../../utils/functions';

import { customReportSelectStyles } from '../../../styles/components/ChassiDetails/SelectStyles';
import '../../../styles/pages/app/reports/LoadingList.css';

const LoadingList = () => {
   const [loadingButton, setLoadingButton] = useState('default');

   const [dateRange, setDateRange] = useState([null, null]);
   const [startDate, endDate] = dateRange;

   const [vesselId, setVesselId] = useState();
   const [searchSelect, setSearchSelect] = useState();
   const [selectDisabled, setSelectDisabled] = useState(false);

   const [loadingListData, setLoadingListData] = useState([]);

   const { loadingScreen, vesselVoyageData } = useContext(RegisterContext);

   const initialShipmentDate = startDate && moment(startDate).format('YYYY-MM-DD');
   const finishShipmentDate = endDate && moment(endDate).format('YYYY-MM-DD');

   const optionsVesselVoyage = vesselVoyageData.map(vessel => {
      return (
         { value: vessel.id, label: `${AllFirstLettersUp(vessel.vessel_name)} - ${AllLettersUp(vessel.voyage)}` }
      );
   });

   async function handleLoadDataToLoadingReport() {
      setLoadingButton('processing');

      try {
         if (vesselId) {
            const { data } = await api.get(`reports/loadingList?initialDate=${initialShipmentDate}&finishDate=${finishShipmentDate}&vesselVoyage=${vesselId}`);

            if (data.length === 0) {
               toast.warning('No data from vessel/voyage on this range date selected.');

            } else {
               setSelectDisabled(true);

               setLoadingListData(data);
            }

         } else {
            const { data } = await api.get(`reports/loadingList?initialDate=${initialShipmentDate}&finishDate=${finishShipmentDate}`);

            if (data.length === 0) {
               toast.warning('No data from this range date selected.');

            } else {
               setSelectDisabled(true);

               setLoadingListData(data);
            }
         }

         setVesselId();
         setLoadingButton('default');

      } catch (e) {
         setLoadingButton('default');
         setSelectDisabled(false);

         e.response.data.errors.forEach(error => toast.error(error));
      }
   };

   const onChangeDatePicker = (dates) => {
      setDateRange(dates);
   };

   const CalendarCustomButton = forwardRef(({ onClick }, ref) => {
      return (
         <div className="date-input">
            <button
               type="button"
               className='button-shipment-date'
               onClick={onClick}
               ref={ref}
            >
               {!startDate
                  ?
                  <span className='placeholder'>from</span>
                  :
                  <span className='date-selected'>{initialShipmentDate}</span>
               }
            </button>

            <button
               type="button"
               className='button-shipment-date'
               onClick={onClick}
               ref={ref}
            >
               {!endDate
                  ?
                  <span className='placeholder'>to</span>
                  :
                  <span className='date-selected'>{finishShipmentDate}</span>
               }
            </button>
         </div>
      );
   });

   return (
      <>
         <PageHeader
            backButton={true}
         />

         <div id="page-loading-list">
            <div className="container">
               {loadingScreen ?
                  <Loading
                     type='balls'
                  />
                  :
                  <>
                     <div className="header-title">
                        <h1>Loading List</h1>
                        <span>Please set the parameters to generate this report:</span>
                     </div>

                     <main>
                        <div className="shipment-date-input-container">
                           <div className="label-container">
                              <label htmlFor="shipmentDate">Shipment Date</label>
                              <HiOutlineCalendar size={20} color="#5D5D5D" />
                           </div>

                           <DatePicker
                              startDate={startDate}
                              endDate={endDate}
                              customInput={<CalendarCustomButton />}
                              onChange={onChangeDatePicker}
                              monthsShown={2}
                              selectsRange={true}
                              todayButton='Today'
                              withPortal
                           />

                        </div>

                        <div className="vessel-input-container">
                           <div className="input-block">
                              <div className="label-container">
                                 <label htmlFor='vesselVoyage'>Vessel & Voyage</label>
                                 <span>optional</span>
                              </div>

                              <Select
                                 isSearchable={true}
                                 isClearable={false}
                                 backspaceRemovesValue={true}
                                 styles={customReportSelectStyles}
                                 options={optionsVesselVoyage}
                                 className='react-select-container'
                                 classNamePrefix="react-select"
                                 name="vesselVoyage"
                                 id="vesselVoyage"
                                 placeholder={searchSelect === undefined ? 'Type or select a registered Voyage' : ''}
                                 defaultValue={vesselId}
                                 onChange={e => setVesselId(e.value)}
                                 defaultInputValue={searchSelect}
                                 onInputChange={e => setSearchSelect(e.value)}
                                 onFocus={() => setSearchSelect()}
                                 isDisabled={selectDisabled}
                              />
                           </div>
                        </div>

                        {loadingButton === 'default' ? (
                           loadingListData.length === 0 ?
                              <button
                                 type="button"
                                 className='button-load'
                                 onClick={handleLoadDataToLoadingReport}
                              >
                                 Load Data
                              </button>
                              :
                              <CSVLink
                                 filename={`${moment().format('YYYY-MM-DD HHmm')}-LoadingList.csv`}
                                 className='button-submit'
                                 data={loadingListData}
                                 headers={[
                                    { label: "VIN", key: "chassiNumber" },
                                    { label: "Receivement Date", key: "receivementDate" },
                                    { label: "Model Name", key: "vehicleModel" },
                                    { label: "Plant of Origin", key: "plantOfOrigin" },
                                    { label: "Dealer", key: "dealerName" },
                                    { label: "Dealer Code", key: "dealerCode" },
                                    { label: "Destination Country", key: "destinationCountry" },
                                    { label: "Location", key: "location" },
                                    { label: "Shipment Date", key: "shipmentDate" },
                                    { label: "Aging", key: "aging" },
                                    { label: "Vessel / Voyage", key: "vesselVoyage" },
                                    { label: "Tow Hook Install", key: "towHookInstall" },
                                    { label: "Wash", key: "wash" },
                                    { label: "Battery Charge", key: "batteryCharge" },
                                    { label: "Tire Replacement", key: "tireReplacement" },
                                    { label: "Air Tire Supplier", key: "airTireSupplied" },
                                    { label: "Refuel", key: "refuel" },
                                    { label: "Battery Voltage Review", key: "batteryVoltageReview" },
                                    { label: "Storage Fee > 15 days", key: "storageFeeMore15Days" },
                                    { label: "Storage Fee 16-45 days", key: "storageBetween16And45" },
                                    { label: "Storage Fee > 45 days", key: "storageMore45Days" },
                                 ]}
                              >
                                 Download XLSX
                              </CSVLink>
                        ) : (
                           <button
                              className='button-load-disabled'
                              style={{ background: '#4B81E8', color: '#FFF', border: 0 }}
                              disabled
                           >
                              <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                              <span className="sr-only"></span>
                           </button>
                        )}

                     </main>
                  </>
               }
            </div>
         </div>
      </>
   );
};

export default LoadingList;
