import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { HiOutlinePencilAlt } from 'react-icons/hi';
import Select from 'react-select';
import crypto from 'crypto-js';

import PageHeader from '../../../components/PageHeader';
import Loading from '../../../components/Loading';
import ChassiDetailsHeader from '../../../components/ChassiDetails/ChassiDetailsHeader';
import LinksNavigation from '../../../components/ChassiDetails/LinksNavigation';
import ChassiHighlights from '../../../components/ChassiDetails/ChassiHighlights';
import InputChassiDetails from '../../../components/ChassiDetails/InputChassiDetails';

import api from '../../../services/api';
import { ChassiDetailsContext } from '../../../services/contexts/ChassiDetailsContext';

import { customSelectStyles } from '../../../styles/components/ChassiDetails/SelectStyles';
import '../../../styles/pages/app/ChassiDetails/Damage.css';

const ChassiDetailsDamage = () => {
   const [areaIsDisabled, setAreaIsDisabled] = useState(true);
   const [typeIsDisabled, setTypeIsDisabled] = useState(true);
   const [severityIsDisabled, setSeverityIsDisabled] = useState(true);
   const [classificationIsDisabled, setClassificationIsDisabled] = useState(true);
   const [responsibilityIsDisabled, setResponsibilityIsDisabled] = useState(true);
   const [statusVtimsIsDisabled, setStatusVtimsIsDisabled] = useState(true);
   const [statusDfYoungIsDisabled, setStatusDfYoungIsDisabled] = useState(true);

   const [damageAreasData, setDamageAreasData] = useState([]);
   const [damageTypesData, setDamageTypesData] = useState([]);
   const [damageSeveritiesData, setDamageSeveritiesData] = useState([]);

   const [damageAreaEdit, setDamageAreaEdit] = useState();
   const [damageSeverityEdit, setDamageSeverityEdit] = useState();
   const [damageTypeEdit, setDamageTypeEdit] = useState();
   const [classificationEdit, setClassificationEdit] = useState();
   const [responsibilityEdit, setResponsibilityEdit] = useState();
   const [statusVtimsEdit, setStatusVtimsEdit] = useState();
   const [statusDfYoungEdit, setStatusDfYoungEdit] = useState();


   const { id } = useParams();
   const chassi_id = crypto.DES.decrypt(decodeURIComponent(id), 'idChassi').toString(crypto.enc.Utf8);

   const {
      loadingScreen,
      setChassiId,
      chassiDetailsInfoAdjusted,
      loadChassiDetails,
   } = useContext(ChassiDetailsContext);

   useEffect(() => {
      setChassiId(chassi_id);

   }, [setChassiId, chassi_id]);


   const loadDamageAreasList = useCallback(async () => {
      const { data } = await api.get('damages/area');

      setDamageAreasData(data);
   }, []);

   const loadDamageTypesList = useCallback(async () => {
      const { data } = await api.get('damages/type');

      setDamageTypesData(data);
   }, []);

   const loadDamageSeveritiesList = useCallback(async () => {
      const { data } = await api.get('damages/severity');

      setDamageSeveritiesData(data);
   }, []);

   useEffect(() => {
      loadDamageAreasList();
      loadDamageTypesList();
      loadDamageSeveritiesList();

   }, [loadDamageAreasList, loadDamageTypesList, loadDamageSeveritiesList]);

   const damageAreasOptions = damageAreasData.map(area => {
      return { value: area.id, label: `${area.id} - ${area.area_description}` };
   });

   const damageTypesOptions = damageTypesData.map(type => {
      return { value: type.id, label: `${type.id} - ${type.type_description}` };
   });

   const damageSeveritiesOptions = damageSeveritiesData.map(severity => {
      return { value: severity.id, label: `${severity.id} - ${severity.severity_description}` };
   });

   async function handleChassiDamageDetailsUpdate() {
      const data = {
         damage_area: damageAreaEdit === undefined ? chassiDetailsInfoAdjusted.damage_area : damageAreaEdit,
         damage_type: damageTypeEdit === undefined ? chassiDetailsInfoAdjusted.damage_type : damageTypeEdit,
         damage_severity: damageSeverityEdit === undefined ? chassiDetailsInfoAdjusted.damage_severity : damageSeverityEdit,
         damageClassification: classificationEdit === undefined ? chassiDetailsInfoAdjusted.damage_classification : classificationEdit,
         damageResponsability: responsibilityEdit === undefined ? chassiDetailsInfoAdjusted.damage_responsability : responsibilityEdit,
         statusVtims: statusVtimsEdit === undefined ? chassiDetailsInfoAdjusted.status_vitms : statusVtimsEdit,
         statusDfyoung: statusDfYoungEdit === undefined ? chassiDetailsInfoAdjusted.status_df_young : statusDfYoungEdit,
      };

      try {
         const res = await api.put(`chassis/${chassi_id}/damage`, data);

         await loadChassiDetails();

         toast.success(res.data.message);

      } catch (e) {
         e.response.data.errors.forEach(error => toast.error(error));
      }

      setAreaIsDisabled(true);
      setTypeIsDisabled(true);
      setSeverityIsDisabled(true);
      setClassificationIsDisabled(true);
      setResponsibilityIsDisabled(true);
      setStatusVtimsIsDisabled(true);
      setStatusDfYoungIsDisabled(true);

      setDamageAreaEdit();
      setDamageSeverityEdit();
      setDamageTypeEdit();
      setClassificationEdit();
      setResponsibilityEdit();
      setStatusVtimsEdit();
      setStatusDfYoungEdit();
   };

   function handleAllowEditionArea() {
      setAreaIsDisabled(!areaIsDisabled);
   };

   function handleAllowEditionType() {
      setTypeIsDisabled(!typeIsDisabled);
   };

   function handleAllowEditionSeverity() {
      setSeverityIsDisabled(!severityIsDisabled);
   };

   function handleAllowEditionClassification() {
      setClassificationIsDisabled(!classificationIsDisabled);
   };

   function handleAllowEditionResponsibility() {
      setResponsibilityIsDisabled(!responsibilityIsDisabled);
   };

   function handleAllowEditionStatusVtims() {
      setStatusVtimsIsDisabled(!statusVtimsIsDisabled);
   };

   function handleAllowEditionStatusDfYoung() {
      setStatusDfYoungIsDisabled(!statusDfYoungIsDisabled);
   };


   return (
      <>
         <PageHeader
            backButton={true}
         />

         <ChassiDetailsHeader
            chassiNumber={chassiDetailsInfoAdjusted.chassi_number}
            modelName={chassiDetailsInfoAdjusted.model_name}
            modelYear={chassiDetailsInfoAdjusted.model_year}
            pod={chassiDetailsInfoAdjusted.pod}
            currentStatus={chassiDetailsInfoAdjusted.status}
         />

         <LinksNavigation />

         <div id="page-details-damage">
            {loadingScreen ?
               <Loading
                  type='balls'
               />
               :
               <div className="container">
                  <div className="container-left">
                     <ChassiHighlights
                        modelCode={chassiDetailsInfoAdjusted.model_code}
                        modelMaker={chassiDetailsInfoAdjusted.maker}
                        assemblyPlant={chassiDetailsInfoAdjusted.assembly_plant}
                        currentStatus={chassiDetailsInfoAdjusted.status}
                        aging={chassiDetailsInfoAdjusted.aging}
                     />
                  </div>

                  <div className="container-right">
                     <h2>Damage Info</h2>

                     <div className="form">
                        <div className="form-left">

                           <div id="input-block">
                              <div className="label-container">
                                 <label htmlFor='area'>Area</label>
                                 <button
                                    type='button'
                                    className='button-edit'
                                    onClick={handleAllowEditionArea}
                                 >
                                    <HiOutlinePencilAlt size={20} color='#5D5D5D' />
                                 </button>
                              </div>

                              <Select
                                 isSearchable={false}
                                 isClearable={false}
                                 styles={customSelectStyles}
                                 options={damageAreasOptions}
                                 className='react-select-container'
                                 classNamePrefix="react-select"
                                 name="area"
                                 id="area"
                                 placeholder={`${chassiDetailsInfoAdjusted.damage_area} - ${chassiDetailsInfoAdjusted.area_description}`}
                                 defaultValue={chassiDetailsInfoAdjusted.damage_area}
                                 onChange={e => setDamageAreaEdit(e.value)}
                                 isDisabled={areaIsDisabled}
                                 onBlur={() => handleChassiDamageDetailsUpdate()}
                              />
                           </div>

                           <div id="input-block">
                              <div className="label-container">
                                 <label htmlFor='type'>Type</label>
                                 <button
                                    type='button'
                                    className='button-edit'
                                    onClick={handleAllowEditionType}
                                 >
                                    <HiOutlinePencilAlt size={20} color='#5D5D5D' />
                                 </button>
                              </div>

                              <Select
                                 isSearchable={false}
                                 isClearable={false}
                                 styles={customSelectStyles}
                                 options={damageTypesOptions}
                                 className='react-select-container'
                                 classNamePrefix="react-select"
                                 name="type"
                                 id="type"
                                 placeholder={`${chassiDetailsInfoAdjusted.damage_type} - ${chassiDetailsInfoAdjusted.type_description}`}
                                 defaultValue={chassiDetailsInfoAdjusted.damage_type}
                                 onChange={e => setDamageTypeEdit(e.value)}
                                 isDisabled={typeIsDisabled}
                                 onBlur={() => handleChassiDamageDetailsUpdate()}
                              />
                           </div>

                           <div id="input-block">
                              <div className="label-container">
                                 <label htmlFor='severity'>Severity</label>
                                 <button
                                    type='button'
                                    className='button-edit'
                                    onClick={handleAllowEditionSeverity}
                                 >
                                    <HiOutlinePencilAlt size={20} color='#5D5D5D' />
                                 </button>
                              </div>

                              <Select
                                 isSearchable={false}
                                 isClearable={false}
                                 styles={customSelectStyles}
                                 options={damageSeveritiesOptions}
                                 className='react-select-container'
                                 classNamePrefix="react-select"
                                 name="severity"
                                 id="severity"
                                 placeholder={`${chassiDetailsInfoAdjusted.damage_severity} - ${chassiDetailsInfoAdjusted.severity_description}`}
                                 defaultValue={chassiDetailsInfoAdjusted.damage_severity}
                                 onChange={e => setDamageSeverityEdit(e.value)}
                                 isDisabled={severityIsDisabled}
                                 onBlur={() => handleChassiDamageDetailsUpdate()}
                              />
                           </div>

                           <InputChassiDetails
                              label='Classification'
                              handleAllowEdition={handleAllowEditionClassification}
                              value={chassiDetailsInfoAdjusted.damage_classification}
                              valueEdited={classificationEdit}
                              onChange={setClassificationEdit}
                              isDisabled={classificationIsDisabled}
                              onBlur={() => handleChassiDamageDetailsUpdate()}
                              editable={true}
                           />

                           <InputChassiDetails
                              label='Transport Mode'
                              value={chassiDetailsInfoAdjusted.mode}
                              valueEdited={undefined}
                              isDisabled={true}
                              editable={false}
                           />

                           <InputChassiDetails
                              label='Damage Responsible'
                              handleAllowEdition={handleAllowEditionResponsibility}
                              value={chassiDetailsInfoAdjusted.damage_responsability}
                              valueEdited={responsibilityEdit}
                              onChange={setResponsibilityEdit}
                              isDisabled={responsibilityIsDisabled}
                              onBlur={() => handleChassiDamageDetailsUpdate()}
                              editable={true}
                           />

                        </div>

                        <div className="form-right">
                           <InputChassiDetails
                              label='Status VTIMS'
                              handleAllowEdition={handleAllowEditionStatusVtims}
                              value={chassiDetailsInfoAdjusted.status_vitms}
                              valueEdited={statusVtimsEdit}
                              onChange={setStatusVtimsEdit}
                              isDisabled={statusVtimsIsDisabled}
                              onBlur={() => handleChassiDamageDetailsUpdate()}
                              editable={true}
                           />

                           <InputChassiDetails
                              label='Status Dfyoung'
                              handleAllowEdition={handleAllowEditionStatusDfYoung}
                              value={chassiDetailsInfoAdjusted.status_df_young}
                              valueEdited={statusDfYoungEdit}
                              onChange={setStatusDfYoungEdit}
                              isDisabled={statusDfYoungIsDisabled}
                              onBlur={() => handleChassiDamageDetailsUpdate()}
                              editable={true}
                           />


                        </div>
                     </div>
                  </div>
               </div>
            }
         </div>
      </>
   );
};

export default ChassiDetailsDamage;
