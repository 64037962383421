import React, { useState, useEffect, useCallback, useContext, useRef, forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { FiX } from 'react-icons/fi';
import { HiCalendar, HiOutlineCalendar } from 'react-icons/hi';
import crypto from 'crypto-js';
import InfiniteScroll from 'react-infinite-scroll-component';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import PageHeader from '../../components/PageHeader';
import Loading from '../../components/Loading';
import LoadingInfiniteScroll from '../../components/LoadingInfiniteScroll';
import ButtonsNavigation from '../../components/ButtonsNavigation';
import DropdownFilterScheduled from '../../components/dropdowns/DropdownFilterScheduled';

import { RegisterContext } from '../../services/contexts/RegisterContext';

import api from '../../services/api';
import { AllLetterDown } from '../../utils/functions';

import '../../styles/pages/app/Scheduled.css';

const Scheduled = () => {
   const [startDate, setStartDate] = useState(new Date());

   const [showFilterChassis, setShowFilterChassis] = useState(false);
   const [showFilterDriver, setShowFilterDriver] = useState(false);
   const [showFilterFolio, setShowFilterFolio] = useState(false);
   const [showFilterPlate, setShowFilterPlate] = useState(false);
   const [showFilterPod, setShowFilterPod] = useState(false);

   const [searchChassis, setSearchChassis] = useState('');
   const [searchShipmentDate, setSearchShipmentDate] = useState('');
   const [searchDriverName, setSearchDriverName] = useState('');
   const [searchFolio, setSearchFolio] = useState('');
   const [searchTruckPlate, setSearchTruckPlate] = useState('');
   const [searchPod, setSearchPod] = useState('');

   const dropdownFilterChassisRef = useRef(null);
   const dropdownFilterDriverRef = useRef(null);
   const dropdownFilterFolioRef = useRef(null);
   const dropdownFilterPlateRef = useRef(null);
   const dropdownFilterPodRef = useRef(null);

   const {
      loadingScreen,
      setLoadingScreen,
      loadingMorePages,
      setLoadingMorePages,

      scheduledVehiclesQuantity,
      setPageOnRoad,
      setPageScheduled,
      setPageReceived,
      setPageDamaged,
      setPageManifested,
      setPageLoaded,
      setPageInvoiced,

      loadOnRoadVehicles,
      loadScheduledVehicles,
      loadReceivedVehicles,
      loadDamagedVehicles,
      loadManifestedVehicles,
      loadLoadedVehicles,
      loadInvoicedVehicles,

      scheduledVehiclesData,
      setScheduledVehiclesData
   } = useContext(RegisterContext);


   useEffect(() => {
      if (searchChassis !== '' || searchShipmentDate !== '' || searchDriverName !== ''
         || searchFolio !== '' || searchTruckPlate !== '' || searchPod !== '') {

         async function loadScheduledVehicles() {
            const res = await api.get(`scheduled?pageSize=100000&chassis=${AllLetterDown(
               searchChassis)}&shipmentDate=${AllLetterDown(searchShipmentDate)}&folio=${searchFolio}&driveName=${AllLetterDown(
                  searchDriverName)}&truckPlate=${AllLetterDown(searchTruckPlate)}&pod=${AllLetterDown(searchPod)}`);

            setScheduledVehiclesData(res.data);

            setLoadingScreen(false);
         };

         loadScheduledVehicles();

      } else {
         loadScheduledVehicles();
      }

   }, [loadScheduledVehicles, setScheduledVehiclesData, setLoadingScreen, searchChassis, searchShipmentDate, searchDriverName, searchFolio, searchTruckPlate, searchPod]);

   useEffect(() => {
      setLoadingMorePages(true);

      setPageOnRoad(1);
      setPageReceived(1);
      setPageDamaged(1);
      setPageManifested(1);
      setPageLoaded(1);
      setPageInvoiced(1);

   }, [setPageOnRoad, setPageReceived, setPageDamaged, setPageManifested, setPageLoaded, setPageInvoiced, setLoadingMorePages]);

   useEffect(() => {
      loadOnRoadVehicles();
      loadReceivedVehicles();
      loadDamagedVehicles();
      loadManifestedVehicles();
      loadLoadedVehicles();
      loadInvoicedVehicles();

   }, [loadOnRoadVehicles, loadReceivedVehicles, loadDamagedVehicles, loadManifestedVehicles, loadLoadedVehicles, loadInvoicedVehicles]);

   const scheduledVehiclesDataConfigured = scheduledVehiclesData.map(vehicle => {
      return {
         chassi_id: vehicle.chassi_id,
         chassi_number: vehicle.chassi_number,
         shipment_date: moment(new Date(vehicle.shipment_date)).format('YYYY-MM-DD'),
         model_name: vehicle.model_name,
         pod: vehicle.pod,
         operator_name: vehicle.operator_name !== null ? vehicle.operator_name : '-',
         folio_de_ingreso: vehicle.folio_de_ingreso !== null ? vehicle.folio_de_ingreso : '-',
         truck_license_plate: vehicle.truck_license_plate !== null ? vehicle.truck_license_plate : '-',
      };
   });

   const handleShowFilterChassis = useCallback((e) => {
      if (dropdownFilterChassisRef.current && showFilterChassis) {
         if (!dropdownFilterChassisRef.current.contains(e.target)) {
            setShowFilterChassis(false);
         }

      } else if (!dropdownFilterChassisRef.current && showFilterChassis) {
         setShowFilterChassis(false);

      } else {
         setShowFilterChassis(true);
      }

   }, [showFilterChassis]);

   useEffect(() => {
      if (showFilterChassis) {
         document.addEventListener("click", handleShowFilterChassis);

         return () => document.removeEventListener("click", handleShowFilterChassis);
      }

   }, [showFilterChassis, handleShowFilterChassis]);

   const handleShowFilterDriver = useCallback((e) => {
      if (dropdownFilterDriverRef.current && showFilterDriver) {
         if (!dropdownFilterDriverRef.current.contains(e.target)) {
            setShowFilterDriver(false);
         }

      } else if (!dropdownFilterDriverRef.current && showFilterDriver) {
         setShowFilterDriver(false);

      } else {
         setShowFilterDriver(true);
      }

   }, [showFilterDriver]);

   useEffect(() => {
      if (showFilterDriver) {
         document.addEventListener("click", handleShowFilterDriver);

         return () => document.removeEventListener("click", handleShowFilterDriver);
      }

   }, [showFilterDriver, handleShowFilterDriver]);

   const handleShowFilterFolio = useCallback((e) => {
      if (dropdownFilterFolioRef.current && showFilterFolio) {
         if (!dropdownFilterFolioRef.current.contains(e.target)) {
            setShowFilterFolio(false);
         }

      } else if (!dropdownFilterFolioRef.current && showFilterFolio) {
         setShowFilterFolio(false);

      } else {
         setShowFilterFolio(true);
      }

   }, [showFilterFolio]);

   useEffect(() => {
      if (showFilterFolio) {
         document.addEventListener("click", handleShowFilterFolio);

         return () => document.removeEventListener("click", handleShowFilterFolio);
      }

   }, [showFilterFolio, handleShowFilterFolio]);

   const handleShowFilterPlate = useCallback((e) => {
      if (dropdownFilterPlateRef.current && showFilterPlate) {
         if (!dropdownFilterPlateRef.current.contains(e.target)) {
            setShowFilterPlate(false);
         }

      } else if (!dropdownFilterPlateRef.current && showFilterPlate) {
         setShowFilterPlate(false);

      } else {
         setShowFilterPlate(true);
      }

   }, [showFilterPlate]);

   useEffect(() => {
      if (showFilterPlate) {
         document.addEventListener("click", handleShowFilterPlate);

         return () => document.removeEventListener("click", handleShowFilterPlate);
      }

   }, [showFilterPlate, handleShowFilterPlate]);

   const handleShowFilterPod = useCallback((e) => {
      if (dropdownFilterPodRef.current && showFilterPod) {
         if (!dropdownFilterPodRef.current.contains(e.target)) {
            setShowFilterPod(false);
         }

      } else if (!dropdownFilterPodRef.current && showFilterPod) {
         setShowFilterPod(false);

      } else {
         setShowFilterPod(true);
      }

   }, [showFilterPod]);

   useEffect(() => {
      if (showFilterPod) {
         document.addEventListener("click", handleShowFilterPod);

         return () => document.removeEventListener("click", handleShowFilterPod);
      }

   }, [showFilterPod, handleShowFilterPod]);


   const onChangeDatePicker = (date) => {
      setStartDate(date);
      setSearchShipmentDate(moment(new Date(date)).format('YYYY-MM-DD'));
   };

   const CalendarCustomButton = forwardRef(({ onClick }, ref) => {
      return (
         <button
            type="button"
            className='button-filter'
            onClick={onClick}
            ref={ref}
         >
            {searchShipmentDate !== '' ?
               <HiCalendar size={20} color="#4B81E8" />
               :
               <HiOutlineCalendar size={20} color="#5D5D5D" />
            }
         </button>
      );
   });

   function handleRemoveFilterChassi() {
      setLoadingScreen(true);

      setSearchChassis('');
      setPageScheduled(1);
      setLoadingMorePages(true);
   };

   function handleRemoveFilterDate() {
      setLoadingScreen(true);

      setSearchShipmentDate('');
      setPageScheduled(1);
      setLoadingMorePages(true);
   };

   function handleRemoveFilterDriver() {
      setLoadingScreen(true);

      setSearchDriverName('');
      setPageScheduled(1);
      setLoadingMorePages(true);
   };

   function handleRemoveFilterFolio() {
      setLoadingScreen(true);

      setSearchFolio('');
      setPageScheduled(1);
      setLoadingMorePages(true);
   };

   function handleRemoveFilterPlate() {
      setLoadingScreen(true);

      setSearchTruckPlate('');
      setPageScheduled(1);
      setLoadingMorePages(true);
   };

   function handleRemoveFilterPod() {
      setLoadingScreen(true);

      setSearchPod('');
      setPageScheduled(1);
      setLoadingMorePages(true);
   };

   function handleFetchMore() {
      if (scheduledVehiclesData.length >= Number(scheduledVehiclesQuantity)) {
         setLoadingMorePages(false);
         return;
      }

      if (searchChassis !== '' || searchShipmentDate !== '' || searchDriverName !== ''
         || searchFolio !== '' || searchTruckPlate !== '' || searchPod !== '') {
         setLoadingMorePages(false);
         return;
      }

      setTimeout(() => {
         setPageScheduled(oldValue => oldValue + 1);

         loadScheduledVehicles();
      }, 1000);
   };


   return (
      <>
         <PageHeader />
         <ButtonsNavigation />

         <div id="page-scheduled">
            <div className="container">
               <div className="height">
                  {loadingScreen ?
                     <Loading
                        type='balls'
                     />
                     :
                     <main>
                        {searchChassis === '' && searchShipmentDate === '' && searchDriverName === ''
                           && searchFolio === '' && searchTruckPlate === '' && searchPod === '' ?
                           null
                           :
                           <div className="filter-selected">
                              <span className='filter-title'>Selected filters:</span>

                              {searchChassis !== '' && (
                                 <div className="filter-selected-container">
                                    <FiX size={15} color='#5D5D5D' onClick={handleRemoveFilterChassi} />
                                    <span className='filter-uppercase'>{searchChassis}</span>
                                 </div>
                              )}

                              {searchShipmentDate !== '' && (
                                 <div className="filter-selected-container">
                                    <FiX size={15} color='#5D5D5D' onClick={handleRemoveFilterDate} />
                                    <span>{searchShipmentDate}</span>
                                 </div>
                              )}

                              {searchDriverName !== '' && (
                                 <div className="filter-selected-container">
                                    <FiX size={15} color='#5D5D5D' onClick={handleRemoveFilterDriver} />
                                    <span className='filter-capitalize'>{searchDriverName}</span>
                                 </div>
                              )}

                              {searchFolio !== '' && (
                                 <div className="filter-selected-container">
                                    <FiX size={15} color='#5D5D5D' onClick={handleRemoveFilterFolio} />
                                    <span className='filter-uppercase'>{searchFolio}</span>
                                 </div>
                              )}

                              {searchTruckPlate !== '' && (
                                 <div className="filter-selected-container">
                                    <FiX size={15} color='#5D5D5D' onClick={handleRemoveFilterPlate} />
                                    <span className='filter-uppercase'>{searchTruckPlate}</span>
                                 </div>
                              )}

                              {searchPod !== '' && (
                                 <div className="filter-selected-container">
                                    <FiX size={15} color='#5D5D5D' onClick={handleRemoveFilterPod} />
                                    <span className='filter-uppercase'>{searchPod}</span>
                                 </div>
                              )}
                           </div>
                        }

                        {scheduledVehiclesDataConfigured.length === 0 ?
                           <div className="no-vehicles">
                              <span>
                                 There is no vehicles to be scheduled on their way to the terminal.
                                 <br />
                                 Please, add Folio de Ingreso on "On Road" page.
                              </span>
                           </div>
                           :
                           <div className="vehicles-list">
                              <div className="label-container">

                                 <DropdownFilterScheduled
                                    label='VIN Number'
                                    dropdownFilterRef={dropdownFilterChassisRef}
                                    search={searchChassis}
                                    setSearch={setSearchChassis}
                                    handleShowFilter={handleShowFilterChassis}
                                    setShowFilter={setShowFilterChassis}
                                    showFilter={showFilterChassis}
                                    classContainer='chassi'
                                 />

                                 <div className="date-container">
                                    <label htmlFor="uploadDate">Upload Date</label>
                                    <DatePicker
                                       selected={startDate}
                                       onChange={onChangeDatePicker}
                                       customInput={<CalendarCustomButton />}
                                       showPopperArrow={false}
                                       todayButton='Today'
                                    />
                                 </div>

                                 <div className="model-container">
                                    <label htmlFor="model">Model</label>
                                 </div>

                                 <DropdownFilterScheduled
                                    label='Driver Name'
                                    dropdownFilterRef={dropdownFilterDriverRef}
                                    search={searchDriverName}
                                    setSearch={setSearchDriverName}
                                    handleShowFilter={handleShowFilterDriver}
                                    setShowFilter={setShowFilterDriver}
                                    showFilter={showFilterDriver}
                                    classContainer='driver'
                                 />

                                 <DropdownFilterScheduled
                                    label='Folio'
                                    dropdownFilterRef={dropdownFilterFolioRef}
                                    search={searchFolio}
                                    setSearch={setSearchFolio}
                                    handleShowFilter={handleShowFilterFolio}
                                    setShowFilter={setShowFilterFolio}
                                    showFilter={showFilterFolio}
                                    classContainer='folio'
                                 />

                                 <DropdownFilterScheduled
                                    label='Truck Plate'
                                    dropdownFilterRef={dropdownFilterPlateRef}
                                    search={searchTruckPlate}
                                    setSearch={setSearchTruckPlate}
                                    handleShowFilter={handleShowFilterPlate}
                                    setShowFilter={setShowFilterPlate}
                                    showFilter={showFilterPlate}
                                    classContainer='plate'
                                 />

                                 <DropdownFilterScheduled
                                    label='POD'
                                    dropdownFilterRef={dropdownFilterPodRef}
                                    search={searchPod}
                                    setSearch={setSearchPod}
                                    handleShowFilter={handleShowFilterPod}
                                    setShowFilter={setShowFilterPod}
                                    showFilter={showFilterPod}
                                    classContainer='pod'
                                 />

                              </div>

                              <InfiniteScroll
                                 dataLength={scheduledVehiclesData.length}
                                 next={handleFetchMore}
                                 hasMore={loadingMorePages}
                                 loader={
                                    <LoadingInfiniteScroll
                                       height={30}
                                       width='100%'
                                       type='spin'
                                    />
                                 }
                                 scrollThreshold={0.8}
                                 scrollableTarget="page-scheduled"
                              >
                                 {scheduledVehiclesDataConfigured.map(vehicle => {
                                    return (
                                       <div key={vehicle.chassi_id} className="cards">
                                          <Link
                                             to={`/app/vehicle/${encodeURIComponent(crypto.DES.encrypt(String(vehicle.chassi_id), 'idChassi').toString())}/tracking`}
                                             className="card-click"
                                          >
                                             <span className="chassi-container">{vehicle.chassi_number}</span>
                                             <span className="date-container">{vehicle.shipment_date}</span>
                                             <span className="model-container">{vehicle.model_name}</span>
                                             <span className="driver-container">{vehicle.operator_name}</span>
                                             <span className="folio-container">{vehicle.folio_de_ingreso}</span>
                                             <span className="plate-container">{vehicle.truck_license_plate}</span>
                                             <span className="pod-container">{vehicle.pod}</span>
                                          </Link>
                                       </div>
                                    )
                                 })}
                              </InfiniteScroll>
                           </div>

                        }
                     </main>
                  }
               </div>
            </div>
         </div>
      </>
   );
};

export default Scheduled;
