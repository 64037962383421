import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { HiOutlinePencilAlt } from 'react-icons/hi';
import Select from 'react-select';
import crypto from 'crypto-js';

import api from '../../services/api';
import { ChassiDetailsContext } from '../../services/contexts/ChassiDetailsContext';

import { customSelectStyles } from '../../styles/components/ChassiDetails/SelectStyles';
import '../../styles/components/ChassiDetails/ChassiHighlights.css';

const ChassiHighlights = (props) => {
   const [modelCodeEdit, setModelCodeEdit] = useState('');
   const [currentStatusEdit, setCurrentStatusEdit] = useState();

   const [modelCodeIsDisabled, setModelCodeIsDisabled] = useState(true);
   const [statusIsDisabled, setStatusIsDisabled] = useState(true);

   const {
      loadChassiDetails
   } = useContext(ChassiDetailsContext);

   const { id } = useParams();
   const chassi_id = crypto.DES.decrypt(decodeURIComponent(id), 'idChassi').toString(crypto.enc.Utf8);

   const statusOptions = [
      { value: 'onRoad', label: `OnRoad` },
      { value: 'scheduled', label: `Scheduled` },
      { value: 'received', label: `Received` },
      { value: 'damaged', label: `Damaged` },
      { value: 'manifested', label: `Manifested` },
      { value: 'loaded', label: `Loaded` },
      { value: 'invoiced', label: `Invoiced` },
   ];

   async function handleChassiHighlightsUpdate() {
      const data = {
         modelCode: modelCodeEdit === '' ? props.modelCode : modelCodeEdit,
         status: currentStatusEdit === undefined ? props.currentStatus : currentStatusEdit
      };

      try {
         const res = await api.put(`chassis/${chassi_id}/highlights`, data);

         await loadChassiDetails();

         toast.success(res.data.message);

      } catch (e) {

         setModelCodeEdit(props.modelCode);
         setCurrentStatusEdit(props.currentStatus);

         e.response.data.errors.forEach(error => toast.error(error));
      }

      setModelCodeIsDisabled(true);
      setStatusIsDisabled(true);
   }

   return (
      <div id="chassi-highlights">
         <h2>Highlights</h2>

         <div className="form">
            <div className="input-block">
               <div className="label-container-model-code">
                  <label htmlFor='modelCode'>Model Code</label>

                  <button
                     type='button'
                     className='button-edit'
                     onClick={() => setModelCodeIsDisabled(!modelCodeIsDisabled)}
                  >
                     <HiOutlinePencilAlt size={20} color='#5D5D5D' />
                  </button>
               </div>

               <input
                  id='modelCode'
                  className='model-code'
                  value={modelCodeEdit === '' ? props.modelCode : modelCodeEdit}
                  onChange={e => setModelCodeEdit(e.target.value)}
                  disabled={modelCodeIsDisabled}
                  autoComplete="off"
                  onBlur={() => handleChassiHighlightsUpdate()}
               />
            </div>

            <div className="input-block">
               <div className="label-container">
                  <label htmlFor='modelMaker'>Maker</label>
               </div>

               <input
                  id='modelMaker'
                  className='model-maker'
                  value={props.modelMaker}
                  disabled={true}
                  autoComplete="off"
               />
            </div>

            <div className="input-block">
               <div className="label-container">
                  <label htmlFor='assemblyPlant'>Assembly Plant</label>
               </div>

               <input
                  id='assemblyPlant'
                  className='assembly-plant'
                  value={props.assemblyPlant}
                  disabled={true}
                  autoComplete="off"
               />
            </div>

            <div className="input-block">
               <div className="label-container-status">
                  <label htmlFor='currentStatus'>Current Status</label>

                  <button
                     type='button'
                     className='button-edit'
                     onClick={() => setStatusIsDisabled(!statusIsDisabled)}
                  >
                     <HiOutlinePencilAlt size={20} color='#5D5D5D' />
                  </button>
               </div>

               <Select
                  isSearchable={false}
                  isClearable={false}
                  styles={customSelectStyles}
                  options={statusOptions}
                  className='react-select-container'
                  classNamePrefix="react-select"
                  name="currentStatus"
                  id="currentStatus"
                  placeholder={currentStatusEdit === undefined ? props.currentStatus : currentStatusEdit}
                  value={currentStatusEdit === undefined ? props.currentStatus : currentStatusEdit}
                  defaultValue={props.currentStatus}
                  onChange={e => setCurrentStatusEdit(e.value)}
                  isDisabled={statusIsDisabled}
                  onBlur={() => handleChassiHighlightsUpdate()}
               />
            </div>

            <div className="input-block">
               <div className="label-container">
                  <label htmlFor='aging'>Aging</label>
               </div>

               <input
                  id='aging'
                  className='aging'
                  value={props.aging}
                  disabled={true}
                  autoComplete="off"
               />
            </div>

         </div>
      </div>
   );
};

export default ChassiHighlights;
