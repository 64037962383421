import React, { useState, useEffect, useCallback, createContext } from 'react';

import api from '../api';

export const RegisterContext = createContext({});


export function RegisterProvider({ children }) {
   const [loadingScreen, setLoadingScreen] = useState(true);
   const [loadingModal, setLoadingModal] = useState(false);
   const [loadingButton, setLoadingButton] = useState('default');

   const [loadingMorePages, setLoadingMorePages] = useState(true);
   const [pageOnRoad, setPageOnRoad] = useState(1);
   const [pageScheduled, setPageScheduled] = useState(1);
   const [pageReceived, setPageReceived] = useState(1);
   const [pageDamaged, setPageDamaged] = useState(1);
   const [pageManifested, setPageManifested] = useState(1);
   const [pageLoaded, setPageLoaded] = useState(1);
   const [pageInvoiced, setPageInvoiced] = useState(1);

   const [onRoadVehiclesData, setOnRoadVehiclesData] = useState([]);
   const [scheduledVehiclesData, setScheduledVehiclesData] = useState([]);
   const [receivedVehiclesData, setReceivedVehiclesData] = useState([]);
   const [damagedVehiclesData, setDamagedVehiclesData] = useState([]);
   const [manifestedVehiclesData, setManifestedVehiclesData] = useState([]);
   const [loadedVehiclesData, setLoadedVehiclesData] = useState([]);
   const [invoicedVehiclesData, setInvoicedVehiclesData] = useState([]);

   const [vesselVoyageData, setVesselVoyageData] = useState([]);
   const [vesselId, setVesselId] = useState();
   const [vesselVoyage, setVesselVoyage] = useState();
   const [searchSelect, setSearchSelect] = useState();
   const [selectedFiles, setSelectedFiles] = useState([]);

   const [uploadIsSuccess, setUploadIsSuccess] = useState(false);
   const [uploadIsFailed, setUploadIsFailed] = useState(false);
   const [errorMessage, setErrorMessage] = useState('');

   const [chassisRepeated, setChassisRepeated] = useState([]);
   const [modelsNotRegistered, setModelsNotRegistered] = useState([]);
   const [chassisNotRegistered, setChassisNotRegistered] = useState([]);
   const [chassisWithoutFolio, setChassisWithoutFolio] = useState([]);
   const [chassisNotOnYardYet, setChassisNotOnYardYet] = useState([]);
   const [chassisNotOnDamageYet, setChassisNotOnDamageYet,] = useState([]);
   const [chassisOnDamaged, setChassisOnDamaged] = useState([]);
   const [chassisNotReceivedOnYard, setChassisNotReceivedOnYard] = useState([]);
   const [chassisNotManifested, setChassisNotManifested] = useState([]);
   const [chassisNotLoaded, setChassisNotLoaded] = useState([]);
   const [chassisAlreadyInvoiced, setChassisAlreadyInvoiced] = useState([]);

   const [onRoadVehiclesQuantity, setOnRoadVehiclesQuantity] = useState(0);
   const [scheduledVehiclesQuantity, setScheduledVehiclesQuantity] = useState(0);
   const [receivedVehiclesQuantity, setReceivedVehiclesQuantity] = useState(0);
   const [damagedVehiclesQuantity, setDamagedVehiclesQuantity] = useState(0);
   const [manifestedVehiclesQuantity, setManifestedVehiclesQuantity] = useState(0);
   const [loadedVehiclesQuantity, setLoadedVehiclesQuantity] = useState(0);
   const [invoicedVehiclesQuantity, setInvoicedVehiclesQuantity] = useState(0);


   const loadOnRoadVehicles = useCallback(async () => {
      const res = await api.get(`onRoad?currentPage=${pageOnRoad}`);

      if (pageOnRoad > 1) {
         setOnRoadVehiclesData(oldValue => [...new Map([...oldValue, ...res.data].map(onRoad => [onRoad['chassi_id'], onRoad])).values()]);

      } else {
         setOnRoadVehiclesData(res.data);
      }

      const quantity = await api.get('quantity/onRoad');

      setOnRoadVehiclesQuantity(quantity.data);

      setTimeout(() => {
         setLoadingScreen(false);
      }, 1000);

   }, [pageOnRoad]);


   const loadScheduledVehicles = useCallback(async () => {
      const res = await api.get(`scheduled?currentPage=${pageScheduled}`);

      if (pageScheduled > 1) {
         setScheduledVehiclesData(oldValue => [...new Map([...oldValue, ...res.data].map(scheduled => [scheduled['chassi_id'], scheduled])).values()]);

      } else {
         setScheduledVehiclesData(res.data);
      }

      const quantity = await api.get('quantity/scheduled');

      setScheduledVehiclesQuantity(quantity.data);

      setTimeout(() => {
         setLoadingScreen(false);
      }, 1000);

   }, [pageScheduled]);


   const loadReceivedVehicles = useCallback(async () => {
      const res = await api.get(`received?currentPage=${pageReceived}`);

      if (pageReceived > 1) {
         setReceivedVehiclesData(oldValue => [...new Map([...oldValue, ...res.data].map(received => [received['chassi_id'], received])).values()]);

      } else {
         setReceivedVehiclesData(res.data);
      }

      const quantity = await api.get('quantity/received');

      setReceivedVehiclesQuantity(quantity.data);

      setTimeout(() => {
         setLoadingScreen(false);
      }, 1000);

   }, [pageReceived]);


   const loadDamagedVehicles = useCallback(async () => {
      const res = await api.get(`damaged?currentPage=${pageDamaged}`);

      if (pageDamaged > 1) {
         setDamagedVehiclesData(oldValue => [...new Map([...oldValue, ...res.data].map(damaged => [damaged['chassi_id'], damaged])).values()]);

      } else {
         setDamagedVehiclesData(res.data);
      }

      const quantity = await api.get('quantity/damaged');

      setDamagedVehiclesQuantity(quantity.data);

      setTimeout(() => {
         setLoadingScreen(false);
      }, 1000);

   }, [pageDamaged]);


   const loadManifestedVehicles = useCallback(async () => {
      const res = await api.get(`manifested?currentPage=${pageManifested}`);

      if (pageManifested > 1) {
         setManifestedVehiclesData(oldValue => [...new Map([...oldValue, ...res.data].map(manifested => [manifested['chassi_id'], manifested])).values()]);

      } else {
         setManifestedVehiclesData(res.data);
      }

      const quantity = await api.get('quantity/manifested');

      setManifestedVehiclesQuantity(quantity.data);

      setTimeout(() => {
         setLoadingScreen(false);
      }, 1000);

   }, [pageManifested]);


   const loadLoadedVehicles = useCallback(async () => {
      const res = await api.get(`loaded?currentPage=${pageLoaded}`);

      if (pageLoaded > 1) {
         setLoadedVehiclesData(oldValue => [...new Map([...oldValue, ...res.data].map(loaded => [loaded['chassi_id'], loaded])).values()]);

      } else {
         setLoadedVehiclesData(res.data);
      }

      const quantity = await api.get('quantity/loaded');

      setLoadedVehiclesQuantity(quantity.data);

      setTimeout(() => {
         setLoadingScreen(false);
      }, 1000);

   }, [pageLoaded]);


   const loadInvoicedVehicles = useCallback(async () => {
      const res = await api.get(`invoiced?currentPage=${pageInvoiced}`);

      if (pageInvoiced > 1) {
         setInvoicedVehiclesData(oldValue => [...new Map([...oldValue, ...res.data].map(invoiced => [invoiced['chassi_id'], invoiced])).values()]);

      } else {
         setInvoicedVehiclesData(res.data);
      }

      const quantity = await api.get('quantity/invoiced');

      setInvoicedVehiclesQuantity(quantity.data);

      setTimeout(() => {
         setLoadingScreen(false);
      }, 1000);

   }, [pageInvoiced]);

   const loadVesselVoyageData = useCallback(async () => {
      const res = await api.get('vesselsVoyage');

      setVesselVoyageData(res.data);
   }, []);

   useEffect(() => {
      async function loadAllData() {
         await loadOnRoadVehicles();
         await loadScheduledVehicles();
         await loadReceivedVehicles();
         await loadDamagedVehicles();
         await loadManifestedVehicles();
         await loadLoadedVehicles();
         await loadInvoicedVehicles();

         await loadVesselVoyageData();

         setLoadingScreen(false);
      };

      loadAllData();

   }, [loadOnRoadVehicles, loadScheduledVehicles, loadReceivedVehicles, loadDamagedVehicles, loadManifestedVehicles, loadLoadedVehicles, loadInvoicedVehicles, loadVesselVoyageData]);


   useEffect(() => {
      async function loadVesselName() {
         if (vesselId !== undefined) {
            const res = await api.get(`vesselsVoyage/${vesselId}`);

            setVesselVoyage(res.data.vessel_voyage);
         }
      };

      loadVesselName();

   }, [vesselId]);


   const onDragOver = useCallback(() => {
      setLoadingModal(true);

   }, []);

   const onDrop = useCallback(acceptedFiles => {
      const file = acceptedFiles;
      setLoadingModal(true);

      setSelectedFiles(file);

      setTimeout(() => {
         setLoadingModal(false);
      }, 1000)
   }, []);

   async function handleUploadGmmFile(e) {
      e.preventDefault();

      setLoadingButton('processing');

      const data = new FormData();

      selectedFiles.forEach(file => {
         data.append('files', file);
      });

      try {
         const res = await api.post('gmmFiles', data);
         setChassisRepeated(res.data.chassisRepeated);
         setModelsNotRegistered(res.data.modelsNotRegistered);

         setUploadIsSuccess(true);
         setLoadingButton('default');

         setPageOnRoad(1);

         await loadOnRoadVehicles();

      } catch (e) {
         setUploadIsFailed(true);
         setLoadingButton('default');

         e.response.data.errors.forEach(error => setErrorMessage(error));
      };
   };

   async function handleUploadTruckFile(e) {
      e.preventDefault();

      setLoadingButton('processing');

      const data = new FormData();

      selectedFiles.forEach(file => {
         data.append('files', file);
      });

      try {
         const res = await api.post('truckFiles', data);

         setChassisNotRegistered(res.data.chassisNotRegistered);

         setUploadIsSuccess(true);
         setLoadingButton('default');

         setPageOnRoad(1);

         await loadOnRoadVehicles();

      } catch (e) {
         setUploadIsFailed(true);
         setLoadingButton('default');

         e.response.data.errors.forEach(error => setErrorMessage(error));
      };
   };

   async function handleUploadInventoryFile(e) {
      e.preventDefault();

      setLoadingButton('processing');

      const data = new FormData();

      selectedFiles.forEach(file => {
         data.append('files', file);
      });

      try {
         const res = await api.post('inventory', data);

         setChassisNotRegistered(res.data.chassisNotRegistered);
         setChassisWithoutFolio(res.data.chassisWithoutFolio);

         setUploadIsSuccess(true);
         setLoadingButton('default');

         setPageScheduled(1);
         setPageReceived(1);

         await loadScheduledVehicles();
         await loadReceivedVehicles();

      } catch (e) {
         setUploadIsFailed(true);
         setLoadingButton('default');

         e.response.data.errors.forEach(error => setErrorMessage(error));
      };
   };

   async function handleUploadDamageFile(e) {
      e.preventDefault();

      setLoadingButton('processing');

      const data = new FormData();

      selectedFiles.forEach(file => {
         data.append('files', file);
      });

      try {
         const res = await api.post('damageReport', data);

         setChassisNotRegistered(res.data.chassisNotRegistered);
         setChassisNotOnYardYet(res.data.chassisNotOnYardYet);

         setUploadIsSuccess(true);
         setLoadingButton('default');

         setPageReceived(1);
         setPageDamaged(1);

         await loadReceivedVehicles();
         await loadDamagedVehicles();

      } catch (e) {
         setUploadIsFailed(true);
         setLoadingButton('default');

         e.response.data.errors.forEach(error => setErrorMessage(error));
      };
   };

   async function handleUploadRepairList(e) {
      e.preventDefault();

      setLoadingButton('processing');

      const data = new FormData();

      selectedFiles.forEach(file => {
         data.append('files', file);
      });

      try {
         const res = await api.post('repairList', data);

         setChassisNotRegistered(res.data.chassisNotRegistered);
         setChassisNotOnDamageYet(res.data.chassisNotOnDamageYet);

         setUploadIsSuccess(true);
         setLoadingButton('default');

         setPageReceived(1);
         setPageDamaged(1);

         await loadReceivedVehicles();
         await loadDamagedVehicles();

      } catch (e) {
         setUploadIsFailed(true);
         setLoadingButton('default');

         e.response.data.errors.forEach(error => setErrorMessage(error));
      };
   };

   async function handleUploadShipManifest(e) {
      e.preventDefault();

      setLoadingButton('processing');

      const data = new FormData();

      if (!vesselId) {
         setUploadIsFailed(true);

         return setErrorMessage('Please inform Vessel and Voyage.');
      }

      selectedFiles.forEach(file => {
         data.append('files', file);
         data.append('vessel_voyage_id', vesselId);
      });

      try {
         const res = await api.post('manifest', data);

         setChassisNotRegistered(res.data.chassisNotRegistered);
         setChassisOnDamaged(res.data.chassisOnDamaged);
         setChassisNotReceivedOnYard(res.data.chassisNotReceivedOnYard);

         setUploadIsSuccess(true);
         setLoadingButton('default');

         setVesselId();

         setPageReceived(1);
         setPageManifested(1);

         await loadReceivedVehicles();
         await loadManifestedVehicles();

      } catch (e) {
         setUploadIsFailed(true);
         setLoadingButton('default');

         e.response.data.errors.forEach(error => setErrorMessage(error));
      };
   };

   async function handleUploadLoadingReport(e) {
      e.preventDefault();

      setLoadingButton('processing');

      const data = new FormData();

      if (!vesselId) {
         setUploadIsFailed(true);

         return setErrorMessage('Please inform Vessel and Voyage.');
      }

      selectedFiles.forEach(file => {
         data.append('files', file);
         data.append('vessel_voyage_id', vesselId);
      });

      try {
         const res = await api.post('loaded', data);

         setChassisNotRegistered(res.data.chassisNotRegistered);
         setChassisNotManifested(res.data.chassisNotManifested);
         setChassisNotLoaded(res.data.chassisNotLoaded);

         setUploadIsSuccess(true);
         setLoadingButton('default');

         setVesselId();

         setPageReceived(1);
         setPageManifested(1);
         setPageLoaded(1);

         await loadReceivedVehicles();
         await loadManifestedVehicles();
         await loadLoadedVehicles();

      } catch (e) {
         setUploadIsFailed(true);
         setLoadingButton('default');

         e.response.data.errors.forEach(error => setErrorMessage(error));
      };
   };

   async function handleUploadPdiServices(e) {
      e.preventDefault();

      setLoadingButton('processing');

      const data = new FormData();

      selectedFiles.forEach(file => {
         data.append('files', file);
      });

      try {
         const res = await api.post('pdiServicesList', data);

         setChassisNotRegistered(res.data.chassisNotRegistered);
         setChassisNotReceivedOnYard(res.data.chassisNotReceivedOnYard);
         setChassisAlreadyInvoiced(res.data.chassisAlreadyInvoiced);

         setUploadIsSuccess(true);
         setLoadingButton('default');

         setPageLoaded(1);
         setPageInvoiced(1);

         await loadLoadedVehicles();
         await loadInvoicedVehicles();

      } catch (e) {
         setUploadIsFailed(true);
         setLoadingButton('default');

         e.response.data.errors.forEach(error => setErrorMessage(error));
      };
   };

   return (
      <RegisterContext.Provider
         value={{
            loadingScreen,
            setLoadingScreen,
            loadingModal,
            loadingButton,
            setLoadingButton,
            loadingMorePages,
            setLoadingMorePages,

            setPageOnRoad,
            setPageScheduled,
            setPageReceived,
            setPageDamaged,
            setPageManifested,
            setPageLoaded,
            setPageInvoiced,

            loadOnRoadVehicles,
            loadScheduledVehicles,
            loadReceivedVehicles,
            loadDamagedVehicles,
            loadManifestedVehicles,
            loadLoadedVehicles,
            loadInvoicedVehicles,

            loadVesselVoyageData,

            onRoadVehiclesData,
            setOnRoadVehiclesData,
            scheduledVehiclesData,
            setScheduledVehiclesData,
            receivedVehiclesData,
            setReceivedVehiclesData,
            damagedVehiclesData,
            setDamagedVehiclesData,
            manifestedVehiclesData,
            setManifestedVehiclesData,
            loadedVehiclesData,
            setLoadedVehiclesData,
            invoicedVehiclesData,
            setInvoicedVehiclesData,

            onRoadVehiclesQuantity,
            scheduledVehiclesQuantity,
            receivedVehiclesQuantity,
            damagedVehiclesQuantity,
            manifestedVehiclesQuantity,
            loadedVehiclesQuantity,
            invoicedVehiclesQuantity,

            vesselVoyageData,
            vesselId,
            setVesselId,
            vesselVoyage,
            setVesselVoyage,
            searchSelect,
            setSearchSelect,

            selectedFiles,
            setSelectedFiles,
            onDrop,
            onDragOver,

            errorMessage,
            uploadIsSuccess,
            setUploadIsSuccess,
            uploadIsFailed,
            setUploadIsFailed,

            chassisRepeated,
            setChassisRepeated,
            modelsNotRegistered,
            setModelsNotRegistered,
            chassisNotRegistered,
            setChassisNotRegistered,
            chassisWithoutFolio,
            setChassisWithoutFolio,
            chassisNotOnYardYet,
            setChassisNotOnYardYet,
            chassisNotOnDamageYet,
            setChassisNotOnDamageYet,
            chassisOnDamaged,
            setChassisOnDamaged,
            chassisNotReceivedOnYard,
            setChassisNotReceivedOnYard,
            chassisNotManifested,
            setChassisNotManifested,
            chassisNotLoaded,
            setChassisNotLoaded,
            chassisAlreadyInvoiced,
            setChassisAlreadyInvoiced,

            handleUploadGmmFile,
            handleUploadTruckFile,
            handleUploadInventoryFile,
            handleUploadDamageFile,
            handleUploadRepairList,
            handleUploadShipManifest,
            handleUploadLoadingReport,
            handleUploadPdiServices
         }}
      >
         {children}
      </RegisterContext.Provider>
   );
};
