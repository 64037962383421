import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import '../../styles/components/dashboard/LinksNavigationDashboard.css';

const LinksNavigationDashboard = () => {
   const [linkClicked, setLinkClicked] = useState('');

   const history = useHistory();

   useEffect(() => {
      setLinkClicked(history.location.pathname);

   }, [history.location.pathname]);

   return (
      <div id="links-navigation-dashboard">

         <div className="container">
            <h1>Dashboards</h1>

            <div className="links-container">
               <Link to='/app/dashboard/toBeReceived'>
                  <span className={linkClicked === '/app/dashboard/toBeReceived' ? 'link-selected' : 'link'}>
                     TO BE RECEIVED
                  </span>
                  <div className={linkClicked === '/app/dashboard/toBeReceived' ? 'line-selected' : 'line'}></div>
               </Link>

               <Link to='/app/dashboard/inStock'>
                  <span className={linkClicked === '/app/dashboard/inStock' ? 'link-selected' : 'link'}>
                     IN STOCK
                  </span>
                  <div className={linkClicked === '/app/dashboard/inStock' ? 'line-selected' : 'line'}></div>
               </Link>

               <Link to='/app/dashboard/yardCapacity'>
                  <span className={linkClicked === '/app/dashboard/yardCapacity' ? 'link-selected' : 'link'}>
                     YARD CAPACITY
                  </span>
                  <div className={linkClicked === '/app/dashboard/yardCapacity' ? 'line-selected' : 'line'}></div>
               </Link>

               <Link to='/app/dashboard/damaged'>
                  <span className={linkClicked === '/app/dashboard/damaged' ? 'link-selected' : 'link'}>
                     DAMAGED
                  </span>
                  <div className={linkClicked === '/app/dashboard/damaged' ? 'line-selected' : 'line'}></div>
               </Link>

               <Link to='/app/dashboard/loaded'>
                  <span className={linkClicked === '/app/dashboard/loaded' ? 'link-selected' : 'link'}>
                     LOADED
                  </span>
                  <div className={linkClicked === '/app/dashboard/loaded' ? 'line-selected' : 'line'}></div>
               </Link>
            </div>
         </div>
      </div>
   );
};

export default LinksNavigationDashboard;
