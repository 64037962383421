import React, { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import crypto from 'crypto-js';

import PageHeader from '../../../components/PageHeader';
import Loading from '../../../components/Loading';
import Input from '../../../components/Input';

import api from '../../../services/api';
import { AllFirstLettersUp, AllLettersUp } from '../../../utils/functions';

import '../../../styles/pages/app/settings/VesselEdit.css';

const VesselEdit = () => {
   const [loadingButton, setLoadingButton] = useState('default');
   const [loading, setLoading] = useState(true);

   const [vesselName, setVesselName] = useState('');
   const [vesselVoyage, setVesselVoyage] = useState('');
   const [vesselShipowner, setVesselShipowner] = useState('');

   const { id } = useParams();
   const history = useHistory();

   const vessel_id = crypto.DES.decrypt(decodeURIComponent(id), 'idVessel').toString(crypto.enc.Utf8);


   useEffect(() => {
      async function loadVesselInfo() {
         const res = await api.get(`vesselsVoyage/${vessel_id}`);

         setVesselName(res.data.vessel_name);
         setVesselVoyage(res.data.voyage);
         setVesselShipowner(res.data.shipowner);

         setLoading(false);
      };

      loadVesselInfo();

   }, [vessel_id]);


   async function loadVesselInfo() {
      const res = await api.get(`vesselsVoyage/${vessel_id}`);

      setVesselName(res.data.vessel_name);
      setVesselVoyage(res.data.voyage);
      setVesselShipowner(res.data.shipowner);

      setLoading(false);
   };

   async function handleEditVessel(e) {
      e.preventDefault();

      setLoadingButton('processing');

      const data = { vesselName, vesselVoyage, vesselShipowner };

      try {
         await api.put(`vesselsVoyage/${vessel_id}`, data);

         loadVesselInfo();

         setLoadingButton('default');

         history.goBack();

      } catch (e) {
         setLoadingButton('default');

         e.response.data.errors.forEach(error => toast.error(error));
      };
   };


   return (
      <div id="page-vessel-edit">
         <PageHeader settings={true} />

         <div className="container">
            {loading ?
               <Loading
                  type='balls'
               />
               :
               <div className="content">

                  <h1>Edit Voyage</h1>
                  <span>Here you can edit a registered voyage.</span>

                  <main>
                     <form onSubmit={handleEditVessel}>
                        <fieldset>

                           <div className="input-container">
                              <Input
                                 name='vesselName'
                                 type='text'
                                 label='Vessel'
                                 value={AllFirstLettersUp(vesselName)}
                                 onChange={e => setVesselName(e.target.value)}
                              />

                              <Input
                                 name='vesselVoyage'
                                 type='text'
                                 label='Voyage'
                                 value={AllLettersUp(vesselVoyage)}
                                 onChange={e => setVesselVoyage(e.target.value)}
                              />

                              <Input
                                 name='vesselShipowner'
                                 type='text'
                                 label='Ocean Carrier'
                                 value={AllLettersUp(vesselShipowner)}
                                 onChange={e => setVesselShipowner(e.target.value)}
                              />
                           </div>

                           <div className="buttons-container">
                              <Link
                                 to='/app/settings/admin/vessels'
                                 className='cancel-button'
                              >
                                 Cancel
                              </Link>

                              {loadingButton === 'default' ? (
                                 <button
                                    type='submit'
                                    className='submit-button'
                                 >
                                    Save
                                 </button>
                              ) : (
                                    <button
                                       className='submit-button'
                                       style={{ background: '#4B81E8', color: '#FFF', border: 0 }}
                                    >
                                       <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                                       <span className="sr-only"></span>
                                    </button>
                                 )
                              }
                           </div>

                        </fieldset>
                     </form>
                  </main>

               </div>
            }
         </div>
      </div>
   );
};

export default VesselEdit;
