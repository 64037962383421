import React from 'react';
import { useHistory } from 'react-router-dom';

import logo from '../../assets/K_line_logo.svg';
import errorImage from '../../assets/error_401.svg';

import '../../styles/pages/auth/Error401.css';

const Error401 = () => {

   const history = useHistory();

   function handleBack() {
      history.goBack();
   };


   return (
      <div id="page-error">
         <div className="left">
            <img src={logo} alt="K Line" />

            <h1>401. That is an error.</h1>

            <span>
               You have no access to this page.
               <br />
               Please contact a system administrator.
            </span>

            <button
               type="button"
               onClick={handleBack}
            >
               Back
            </button>
         </div>

         <img src={errorImage} alt="Error 401" />
      </div>
   );
};

export default Error401;
