import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import crypto from 'crypto-js';
import { FiUser } from 'react-icons/fi';
import { ImExit } from 'react-icons/im';

import { useAuth } from '../../../services/contexts/auth';

import PageHeader from '../../../components/PageHeader';

import '../../../styles/pages/app/settings/Settings.css';

const Settings = () => {
   const [idUser, setIdUser] = useState()

   const { onSignOut, userId, idStorage } = useAuth();

   useEffect(() => {
      if (userId) {
         setIdUser(userId);

      } else {
         setIdUser(idStorage);
      }

   }, [userId, idStorage])


   function handleSignOut(e) {
      e.preventDefault();

      onSignOut();
   };

   return (
      <div id="page-settings">
         <PageHeader settings={true} />

         <div className="container">
            <div className="content">
               <div className="header">
                  <div className="header-titles">
                     <h1>Settings</h1>
                     <span>Here you can manage your profile, change you email and password.</span>
                  </div>

                  <Link to='/app/onRoad' className='back-button'>
                     Back
                     </Link>
               </div>
               <Link
                  className='settings-button'
                  to={`/app/settings/users/${encodeURIComponent(crypto.DES.encrypt(String(idUser), 'idUser').toString())}`}
               >
                  <FiUser size={25} />
                  Profile
               </Link>

               <div className="settings-button" onClick={handleSignOut}>
                  <ImExit size={25} />
                  Log Out
               </div>
            </div>
         </div>
      </div>
   );
};

export default Settings;
