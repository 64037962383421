import React, { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

import '../../styles/components/ChassiDetails/LinksNavigation.css';

const LinksNavigation = () => {
   const [linkClicked, setLinkClicked] = useState('');

   const history = useHistory();
   const { id } = useParams();

   useEffect(() => {
      setLinkClicked(history.location.pathname);

   }, [history.location.pathname]);

   return (
      <div id="links-navigation">
         <div className="container">
            <Link to={`/app/vehicle/${id}/tracking`}>
               <span className={linkClicked === `/app/vehicle/${id}/tracking` ? 'link-selected' : 'link'}>
                  TRACKING
               </span>
               <div className={linkClicked === `/app/vehicle/${id}/tracking` ? 'line-selected' : 'line'}></div>
            </Link>

            <Link to={`/app/vehicle/${id}/general`}>
               <span className={linkClicked === `/app/vehicle/${id}/general` ? 'link-selected' : 'link'}>
                  GENERAL
               </span>
               <div className={linkClicked === `/app/vehicle/${id}/general` ? 'line-selected' : 'line'}></div>
            </Link>

            <Link to={`/app/vehicle/${id}/roadTransport`}>
               <span className={linkClicked === `/app/vehicle/${id}/roadTransport` ? 'link-selected' : 'link'}>
                  ROAD TRANSPORT
               </span>
               <div className={linkClicked === `/app/vehicle/${id}/roadTransport` ? 'line-selected' : 'line'}></div>
            </Link>

            <Link to={`/app/vehicle/${id}/portTerminal`}>
               <span className={linkClicked === `/app/vehicle/${id}/portTerminal` ? 'link-selected' : 'link'}>
                  PORT TERMINAL
                  </span>
               <div className={linkClicked === `/app/vehicle/${id}/portTerminal` ? 'line-selected' : 'line'}></div>
            </Link>

            <Link to={`/app/vehicle/${id}/damage`}>
               <span className={linkClicked === `/app/vehicle/${id}/damage` ? 'link-selected' : 'link'}>
                  DAMAGE
               </span>
               <div className={linkClicked === `/app/vehicle/${id}/damage` ? 'line-selected' : 'line'}></div>
            </Link>

            <Link to={`/app/vehicle/${id}/repair`}>
               <span className={linkClicked === `/app/vehicle/${id}/repair` ? 'link-selected' : 'link'}>
                  REPAIR
               </span>
               <div className={linkClicked === `/app/vehicle/${id}/repair` ? 'line-selected' : 'line'}></div>
            </Link>

            <Link to={`/app/vehicle/${id}/services`}>
               <span className={linkClicked === `/app/vehicle/${id}/services` ? 'link-selected' : 'link'}>
                  SERVICES
               </span>
               <div className={linkClicked === `/app/vehicle/${id}/services` ? 'line-selected' : 'line'}></div>
            </Link>

            <Link to={`/app/vehicle/${id}/seaTransport`}>
               <span className={linkClicked === `/app/vehicle/${id}/seaTransport` ? 'link-selected' : 'link'}>
                  SEA TRANSPORT
               </span>
               <div className={linkClicked === `/app/vehicle/${id}/seaTransport` ? 'line-selected' : 'line'}></div>
            </Link>

         </div>
      </div>
   );
};

export default LinksNavigation;
