import React, { useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { CSVLink } from 'react-csv';
import moment from 'moment';

import Loading from '../../../components/Loading';
import PageHeader from '../../../components/PageHeader';

import { RegisterContext } from '../../../services/contexts/RegisterContext';
import api from '../../../services/api';

import '../../../styles/pages/app/reports/GMInventoryList.css';

const GMInventoryList = () => {
   const [loadingButton, setLoadingButton] = useState('default');

   const [GMInventoryList, setGMInventoryListData] = useState([]);

   const { loadingScreen } = useContext(RegisterContext);


   async function handleLoadDataGMInventoryList() {
      setLoadingButton('processing');

      try {
         const { data } = await api.get(`reports/inventoryList`);

         setGMInventoryListData(data);

         setLoadingButton('default');

      } catch (e) {
         setLoadingButton('default');

         e.response.data.errors.forEach(error => toast.error(error));
      }
   };


   return (
      <>
         <PageHeader
            backButton={true}
         />

         <div id="page-inventory-list">
            <div className="container">
               {loadingScreen ?
                  <Loading
                     type='balls'
                  />
                  :
                  <>
                     <div className="header-title">
                        <h1>GM Inventory List</h1>
                        <span>This report show only vehicles that are stored on the terminal</span>
                     </div>

                     <main>
                        {loadingButton === 'default' ? (
                           GMInventoryList.length === 0 ?
                              <button
                                 type="button"
                                 className='button-load'
                                 onClick={handleLoadDataGMInventoryList}
                              >
                                 Load Data
                              </button>
                              :
                              <CSVLink
                                 filename={`${moment().format('YYYY-MM-DD HHmm')}-GMInventoryList.csv`}
                                 className='button-submit'
                                 data={GMInventoryList}
                                 headers={[
                                    { label: "#", key: "sequentialNumber" },
                                    { label: "VIN", key: "VIN" },
                                    { label: "ORIGEN", key: "plantOfOrigin" },
                                    { label: "LLEGADA", key: "arrivalDate" },
                                    { label: "PUERTO DESTINO", key: "pod" },
                                    { label: "MARKET", key: "market" },
                                    { label: "RAMPA ORIGINAL", key: "originRamp" },
                                    { label: "DEALER", key: "dealerCode" },
                                    { label: "MODEL ID", key: "modelCode" },
                                    { label: "DESCRIPTION MODEL", key: "modelName" },
                                    { label: "ESTADÍA", key: "aging" },
                                    { label: "ESTATUS", key: "status" },
                                    { label: "FECHA LIBERACIÓN", key: "releaseDate" },
                                    { label: "DFYOUNG", key: "dfYoung" },
                                    { label: "VTIMS", key: "vtims" },
                                    { label: "PUERTO", key: "puerto" },
                                    { label: "PATIO", key: "recintoCode" },
                                    { label: "UBICACIÓN", key: "yardSlot" },
                                 ]}
                              >
                                 Download XLSX
                              </CSVLink>
                        ) : (
                           <button
                              className='button-load-disabled'
                              style={{ background: '#4B81E8', color: '#FFF', border: 0 }}
                              disabled
                           >
                              <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                              <span className="sr-only"></span>
                           </button>
                        )}

                     </main>
                  </>
               }
            </div>
         </div>
      </>
   );
};

export default GMInventoryList;
