import React, { useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { CSVLink } from 'react-csv';
import Select from 'react-select';
import moment from 'moment';

import PageHeader from '../../../components/PageHeader';
import Loading from '../../../components/Loading';

import api from '../../../services/api';
import { RegisterContext } from '../../../services/contexts/RegisterContext';
import { AllLettersUp, AllFirstLettersUp } from '../../../utils/functions';

import { customReportSelectStyles } from '../../../styles/components/ChassiDetails/SelectStyles';
import '../../../styles/pages/app/reports/ATPLoadingRequest.css';

const ATPLoadingRequest = () => {
   const [loadingButton, setLoadingButton] = useState('default');

   const [vesselId, setVesselId] = useState();
   const [searchSelect, setSearchSelect] = useState();
   const [selectDisabled, setSelectDisabled] = useState(false);

   const [loadingRequestData, setLoadingRequestData] = useState([]);

   const { loadingScreen, vesselVoyageData } = useContext(RegisterContext);

   const optionsVesselVoyage = vesselVoyageData.map(vessel => {
      return (
         { value: vessel.id, label: `${AllFirstLettersUp(vessel.vessel_name)} - ${AllLettersUp(vessel.voyage)}` }
      );
   });

   async function handleLoadDataToLoadingReport() {
      setLoadingButton('processing');

      try {
         const { data } = await api.get(`reports/atpRequest?vesselId=${vesselId}`);

         setLoadingRequestData(data);

         if (data.length === 0) {
            setLoadingButton('default');

            setVesselId();

            return toast.warning('No data from vessel/voyage selected.');
         }

         setSelectDisabled(true);
         setVesselId();
         setLoadingButton('default');

      } catch (e) {
         setLoadingButton('default');
         setSelectDisabled(false);

         e.response.data.errors.forEach(error => toast.error(error));
      }
   };

   return (
      <>
         <PageHeader
            backButton={true}
         />

         <div id="page-loading-request">
            <div className="container">
               {loadingScreen ?
                  <Loading
                     type='balls'
                  />
                  :
                  <>
                     <div className="header-title">
                        <h1>ATP Loading Request</h1>
                        {loadingRequestData.length === 0 ?
                           <span>Please inform vessel & voyage of this request:</span>
                           :
                           <span>The data linked to the selected voyage has been processed!</span>
                        }
                     </div>

                     <main>
                        <div className="vessel-input-container">
                           <div className="input-block">
                              <label htmlFor='vesselVoyage'>Vessel & Voyage</label>
                              <Select
                                 isSearchable={true}
                                 isClearable={false}
                                 backspaceRemovesValue={true}
                                 styles={customReportSelectStyles}
                                 options={optionsVesselVoyage}
                                 className='react-select-container'
                                 classNamePrefix="react-select"
                                 name="vesselVoyage"
                                 id="vesselVoyage"
                                 placeholder={searchSelect === undefined ? 'Type or select a registered Voyage' : ''}
                                 defaultValue={vesselId}
                                 onChange={e => setVesselId(e.value)}
                                 defaultInputValue={searchSelect}
                                 onInputChange={e => setSearchSelect(e.value)}
                                 onFocus={() => setSearchSelect('')}
                                 isDisabled={selectDisabled}
                              />
                           </div>
                        </div>

                        {loadingButton === 'default' ? (
                           loadingRequestData.length === 0 ?
                              <button
                                 type="button"
                                 className={vesselId ? 'button-load' : 'button-load-disabled'}
                                 onClick={handleLoadDataToLoadingReport}
                                 disabled={vesselId ? false : true}
                              >
                                 Load Data
                              </button>
                              :
                              <CSVLink
                                 filename={`${moment().format('YYYY-MM-DD HHmm')}-ATPLoadingRequest.csv`}
                                 className='button-submit'
                                 data={loadingRequestData}
                                 headers={[
                                    { label: "CANTIDAD UNIDADES", key: "quantity" },
                                    { label: "MODELO", key: "model_name" },
                                    { label: "PESO BRUTO", key: "model_weight" },
                                    { label: "LOC", key: "loc" },
                                    { label: "BUQUE", key: "vessel_voyage" },
                                    { label: "LINEA NAVIERA", key: "shipowner" },
                                 ]}
                              >
                                 Download XLSX
                              </CSVLink>
                        ) : (
                           <button
                              className='button-load-disabled'
                              style={{ background: '#4B81E8', color: '#FFF', border: 0 }}
                              disabled
                           >
                              <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                              <span className="sr-only"></span>
                           </button>
                        )}

                     </main>
                  </>
               }
            </div>
         </div>
      </>
   );
};

export default ATPLoadingRequest;
