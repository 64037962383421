import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import Chart from 'react-google-charts';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { HiOutlineCalendar } from 'react-icons/hi';

import LinksNavigationDashboard from '../../../components/dashboard/LinksNavigation';
import PageHeader from '../../../components/PageHeader';
import Loading from '../../../components/Loading';

import api from '../../../services/api';

import '../../../styles/pages/app/dashboard/YardCapacity.css';

const YardCapacity = () => {
   const [loading, setLoading] = useState(true);

   const [dateRange, setDateRange] = useState([null, null]);
   const [startDate, endDate] = dateRange;

   const [stockHistoryData, setStockHistoryData] = useState([]);

   const initialDate = startDate && moment(startDate).format('YYYY-MM-DD');
   const finishDate = endDate && moment(endDate).format('YYYY-MM-DD');

   const loadStockHistoryData = useCallback(async () => {
      const res = await api.get(`dashboard/inStock/stockHistory?initialDate=${initialDate}&finishDate=${finishDate}`);

      let arrayAdjusted = [];
      const header = ['Date', 'Percentage', { role: 'annotation' }];

      arrayAdjusted.push(header);

      res.data.map(item => {
         const data = [item.date, (item.percentage / 100), `${(item.percentage)}%`];

         return arrayAdjusted.push(data);
      });

      setStockHistoryData(arrayAdjusted);

   }, [initialDate, finishDate]);


   useEffect(() => {
      async function loadAllDatas() {
         await loadStockHistoryData();

         setLoading(false);
      };

      loadAllDatas();

   }, [loadStockHistoryData]);

   const onChangeDatePicker = (dates) => {
      setDateRange(dates);
   };

   const CalendarCustomButton = forwardRef(({ onClick }, ref) => {
      return (
         <div className="date-input">
            <button
               type="button"
               className='button-date'
               onClick={onClick}
               ref={ref}
            >
               {!startDate
                  ?
                  <span className='placeholder'>from</span>
                  :
                  <span className='date-selected'>{initialDate}</span>
               }
            </button>

            <button
               type="button"
               className='button-date'
               onClick={onClick}
               ref={ref}
            >
               {!endDate
                  ?
                  <span className='placeholder'>to</span>
                  :
                  <span className='date-selected'>{finishDate}</span>
               }
            </button>
         </div>
      );
   });

   return (
      <>
         <PageHeader
            backButton={true}
         />

         <LinksNavigationDashboard />

         <div id='page-yard-capacity'>
            <div className="container">
               <div className="height">
                  {loading ?
                     <Loading />
                     :
                     <>
                        <div className="header">
                           <h2>FIGURES ABOUT:</h2>

                           <div className="date-container">
                              <div className="label-container">
                                 <label htmlFor="dateRange">Date Range</label>
                                 <HiOutlineCalendar size={20} color="#5D5D5D" />
                              </div>

                              <DatePicker
                                 startDate={startDate}
                                 endDate={endDate}
                                 customInput={<CalendarCustomButton />}
                                 onChange={onChangeDatePicker}
                                 monthsShown={2}
                                 selectsRange={true}
                                 todayButton='Today'
                                 withPortal
                              />
                           </div>
                        </div>

                        <div className="chart-container-big">
                           <h3>Stock History</h3>

                           {!endDate ?
                              <span>Please select range dates</span>
                              :
                              <Chart
                                 chartType='LineChart'
                                 data={stockHistoryData}
                                 options={{
                                    width: 1000,
                                    height: 300,
                                    legend: { position: 'none' },
                                    backgroundColor: {
                                       fill: 'transparent'
                                    },
                                    fontName: 'Montserrat',
                                    fontSize: 11,
                                    chartArea: {
                                       backgroundColor: 'transparent',
                                       left: 50,
                                       top: 30,
                                       right: 10,
                                       bottom: 80,
                                    },
                                    colors: ['#4B81E8'],
                                    tooltip: {
                                       text: 'quantity',
                                       trigger: 'focus',
                                       textStyle: {
                                          color: '#000000',
                                          fontName: 'Montserrat',
                                          fontSize: 12,
                                       }
                                    },
                                    hAxis: {
                                       title: 'Range Date',
                                       titleTextStyle: {
                                          color: '#000',
                                          fontName: 'Montserrat',
                                          fontSize: 12,
                                          italic: false,
                                          bold: true,
                                       },
                                       baselineColor: 'transparent',
                                    },
                                    vAxis: {
                                       title: 'Percentage',
                                       titleTextStyle: {
                                          color: '#000',
                                          fontName: 'Montserrat',
                                          fontSize: 12,
                                          italic: false,
                                          bold: true,
                                       },
                                       baselineColor: 'transparent',
                                       maxValue: 1,
                                       minValue: 0,
                                       format: 'percent',
                                       gridlines: {
                                          count: -1,
                                       },
                                    },
                                 }}
                              />
                           }
                        </div>
                     </>
                  }
               </div>
            </div>
         </div>
      </>
   );
};

export default YardCapacity;
