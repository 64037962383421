import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import { IoIosArrowDown } from 'react-icons/io';

import ModalUploadFiles from '../modals/ModalUploadFiles';

import { RegisterContext } from '../../services/contexts/RegisterContext';

import '../../styles/components/DropdownPageHeader.css';


const DropdownRegister = () => {
   const [modalGmmIsOpen, setModaGmmIsOpen] = useState(false);
   const [modalTruckIsOpen, setModaTruckIsOpen] = useState(false);
   const [modalInventoryIsOpen, setModalInventoryIsOpen] = useState(false);
   const [modalDamageIsOpen, setModalDamageIsOpen] = useState(false);
   const [modalRepairListIsOpen, setModalRepairListIsOpen] = useState(false);
   const [modalShipManifestIsOpen, setModalShipManifestIsOpen] = useState(false);
   const [modalLoadingReportIsOpen, setModalLoadingReportIsOpen] = useState(false);
   const [modalAddPdiServiceIsOpen, setModalAddPdiServiceIsOpen] = useState(false);

   const [showRegisterOptions, setShowRegisterOptions] = useState(false);

   const dropdownRegisterOptions = useRef(null);

   const {
      selectedFiles,
      setSelectedFiles,

      uploadIsSuccess,
      setUploadIsSuccess,
      uploadIsFailed,
      setUploadIsFailed,

      setChassisRepeated,
      setModelsNotRegistered,
      setChassisNotRegistered,
      setChassisWithoutFolio,
      setChassisNotOnYardYet,
      setChassisNotOnDamageYet,
      setChassisOnDamaged,
      setChassisNotReceivedOnYard,
      setChassisNotManifested,
      setChassisNotLoaded,
      setChassisAlreadyInvoiced,

      handleUploadGmmFile,
      handleUploadTruckFile,
      handleUploadInventoryFile,
      handleUploadDamageFile,
      handleUploadRepairList,
      handleUploadShipManifest,
      handleUploadLoadingReport,
      handleUploadPdiServices,

      setVesselVoyage,
      setVesselId,
      setSearchSelect,
      loadingButton,
      setLoadingButton,

   } = useContext(RegisterContext);

   const handleShowRegisterOptions = useCallback((e) => {
      if (dropdownRegisterOptions.current && showRegisterOptions) {
         if (!dropdownRegisterOptions.current.contains(e.target)) {
            setShowRegisterOptions(false);
         }

      } else if (!dropdownRegisterOptions.current && showRegisterOptions) {
         setShowRegisterOptions(false);

      } else {
         setShowRegisterOptions(true);
      }

   }, [showRegisterOptions]);

   useEffect(() => {
      if (showRegisterOptions) {
         document.addEventListener("click", handleShowRegisterOptions);

         return () => document.removeEventListener("click", handleShowRegisterOptions);
      }

   }, [showRegisterOptions, handleShowRegisterOptions]);

   function handleOpenModalGmm() {
      setShowRegisterOptions(false);
      setSelectedFiles([]);

      setChassisRepeated([]);
      setModelsNotRegistered([]);

      setUploadIsSuccess(false);
      setUploadIsFailed(false);

      setLoadingButton('default');

      setModaGmmIsOpen(!modalGmmIsOpen);
   };

   function handleOpenModalTruck() {
      setShowRegisterOptions(false);
      setSelectedFiles([]);

      setChassisNotRegistered([]);

      setUploadIsSuccess(false);
      setUploadIsFailed(false);

      setLoadingButton('default');

      setModaTruckIsOpen(!modalTruckIsOpen);
   };

   function handleOpenModalInventory() {
      setShowRegisterOptions(false);
      setSelectedFiles([]);

      setChassisNotRegistered([]);
      setChassisWithoutFolio([]);

      setUploadIsSuccess(false);
      setUploadIsFailed(false);

      setLoadingButton('default');

      setModalInventoryIsOpen(!modalInventoryIsOpen);
   };

   function handleOpenModalDamage() {
      setShowRegisterOptions(false);
      setSelectedFiles([]);

      setChassisNotRegistered([]);
      setChassisNotOnYardYet([]);

      setUploadIsSuccess(false);
      setUploadIsFailed(false);

      setLoadingButton('default');

      setModalDamageIsOpen(!modalDamageIsOpen);
   };

   function handleOpenModalRepairList() {
      setShowRegisterOptions(false);
      setSelectedFiles([]);

      setChassisNotRegistered([]);
      setChassisNotOnDamageYet([]);

      setUploadIsSuccess(false);
      setUploadIsFailed(false);

      setLoadingButton('default');

      setModalRepairListIsOpen(!modalRepairListIsOpen);
   };

   function handleOpenModalShipManifest() {
      setShowRegisterOptions(false);
      setSelectedFiles([]);

      setChassisNotRegistered([]);
      setChassisOnDamaged([]);
      setChassisNotReceivedOnYard([]);

      setVesselVoyage();
      setVesselId();
      setSearchSelect();

      setUploadIsSuccess(false);
      setUploadIsFailed(false);

      setLoadingButton('default');

      setModalShipManifestIsOpen(!modalShipManifestIsOpen);
   };

   function handleOpenModalLoadingReport() {
      setShowRegisterOptions(false);
      setSelectedFiles([]);

      setChassisNotRegistered([]);
      setChassisNotManifested([]);
      setChassisNotLoaded([]);

      setVesselVoyage();
      setVesselId();
      setSearchSelect();

      setUploadIsSuccess(false);
      setUploadIsFailed(false);

      setLoadingButton('default');

      setModalLoadingReportIsOpen(!modalLoadingReportIsOpen);
   };

   function handleOpenModalAddPDIServices() {
      setShowRegisterOptions(false);
      setSelectedFiles([]);

      setChassisNotRegistered([]);
      setChassisNotReceivedOnYard([]);
      setChassisAlreadyInvoiced([]);

      setUploadIsSuccess(false);
      setUploadIsFailed(false);

      setLoadingButton('default');

      setModalAddPdiServiceIsOpen(!modalAddPdiServiceIsOpen);
   };

   return (
      <div id="dropdown-page-header">
         <button
            type="button"
            className='button-register'
            onClick={handleShowRegisterOptions}
         >
            Register a File
            <IoIosArrowDown size={20} color='#FFF' />
         </button>
         <div ref={dropdownRegisterOptions} className='menu-select'>
            {showRegisterOptions &&

               <div className="select-container-register">
                  <div className="title">
                     <h3>Files:</h3>
                  </div>

                  <button type='button' onClick={handleOpenModalGmm}>
                     1. GMM
                  </button>

                  <button type='button' onClick={handleOpenModalTruck}>
                     2. Truck
                  </button>

                  <button type='button' onClick={handleOpenModalInventory}>
                     3. ATP Inventory
                  </button>

                  <button type='button' onClick={handleOpenModalDamage}>
                     4. Unicar
                  </button>

                  <button type='button' onClick={handleOpenModalRepairList}>
                     5. Repair List
                  </button>

                  <button type='button' onClick={handleOpenModalShipManifest}>
                     6. GM Ship Manif.
                  </button>

                  <button type='button' onClick={handleOpenModalLoadingReport}>
                     7. Loading Report
                  </button>

                  <button type='button' onClick={handleOpenModalAddPDIServices} className='button-add-pdi'>
                     Add PDI Service
                  </button>

               </div>
            }
         </div>

         <ModalUploadFiles
            modalFile='GMM Files'
            handleUpload={handleUploadGmmFile}
            isOpen={modalGmmIsOpen}
            toggle={handleOpenModalGmm}
            selectedFiles={selectedFiles}
            uploadIsSuccess={uploadIsSuccess}
            uploadIsFailed={uploadIsFailed}
            loadingButton={loadingButton}
         />

         <ModalUploadFiles
            modalFile='Truck Files'
            handleUpload={handleUploadTruckFile}
            isOpen={modalTruckIsOpen}
            toggle={handleOpenModalTruck}
            selectedFiles={selectedFiles}
            uploadIsSuccess={uploadIsSuccess}
            uploadIsFailed={uploadIsFailed}
            loadingButton={loadingButton}
         />

         <ModalUploadFiles
            modalFile='ATP Inventory Files'
            handleUpload={handleUploadInventoryFile}
            isOpen={modalInventoryIsOpen}
            toggle={handleOpenModalInventory}
            selectedFiles={selectedFiles}
            uploadIsSuccess={uploadIsSuccess}
            uploadIsFailed={uploadIsFailed}
            loadingButton={loadingButton}
         />

         <ModalUploadFiles
            modalFile='Unicar Files'
            handleUpload={handleUploadDamageFile}
            isOpen={modalDamageIsOpen}
            toggle={handleOpenModalDamage}
            selectedFiles={selectedFiles}
            uploadIsSuccess={uploadIsSuccess}
            uploadIsFailed={uploadIsFailed}
            loadingButton={loadingButton}
         />

         <ModalUploadFiles
            modalFile='Repair List'
            handleUpload={handleUploadRepairList}
            isOpen={modalRepairListIsOpen}
            toggle={handleOpenModalRepairList}
            selectedFiles={selectedFiles}
            uploadIsSuccess={uploadIsSuccess}
            uploadIsFailed={uploadIsFailed}
            loadingButton={loadingButton}
         />

         <ModalUploadFiles
            modalFile='GM Ship Manifest'
            handleUpload={handleUploadShipManifest}
            isOpen={modalShipManifestIsOpen}
            toggle={handleOpenModalShipManifest}
            selectedFiles={selectedFiles}
            uploadIsSuccess={uploadIsSuccess}
            uploadIsFailed={uploadIsFailed}
            loadingButton={loadingButton}
         />

         <ModalUploadFiles
            modalFile='Loading Report'
            handleUpload={handleUploadLoadingReport}
            isOpen={modalLoadingReportIsOpen}
            toggle={handleOpenModalLoadingReport}
            selectedFiles={selectedFiles}
            uploadIsSuccess={uploadIsSuccess}
            uploadIsFailed={uploadIsFailed}
            loadingButton={loadingButton}
         />

         <ModalUploadFiles
            modalFile='PDI Services List Files'
            handleUpload={handleUploadPdiServices}
            isOpen={modalAddPdiServiceIsOpen}
            toggle={handleOpenModalAddPDIServices}
            selectedFiles={selectedFiles}
            uploadIsSuccess={uploadIsSuccess}
            uploadIsFailed={uploadIsFailed}
            loadingButton={loadingButton}
         />

      </div>
   );
};

export default DropdownRegister;
