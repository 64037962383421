import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FiX, FiTool } from 'react-icons/fi';
import crypto from 'crypto-js';
import InfiniteScroll from 'react-infinite-scroll-component';

import PageHeader from '../../components/PageHeader';
import Loading from '../../components/Loading';
import LoadingInfiniteScroll from '../../components/LoadingInfiniteScroll';
import ButtonsNavigation from '../../components/ButtonsNavigation';
import DropdownFilterManifested from '../../components/dropdowns/DropdownFilterManifested';

import { RegisterContext } from '../../services/contexts/RegisterContext';

import api from '../../services/api';
import { AllLetterDown } from '../../utils/functions';

import '../../styles/pages/app/Manifested.css';

const Manifested = () => {

   const [showFilterChassis, setShowFilterChassis] = useState(false);
   const [showFilterAging, setShowFilterAging] = useState(false);
   const [showFilterVessel, setShowFilterVessel] = useState(false);
   const [showFilterPod, setShowFilterPod] = useState(false);
   const [showFilterDealer, setShowFilterDealer] = useState(false);

   const [searchChassis, setSearchChassis] = useState('');
   const [searchAging, setSearchAging] = useState('');
   const [searchVessel, setSearchVessel] = useState('');
   const [searchPod, setSearchPod] = useState('');
   const [searchDealer, setSearchDealer] = useState('');

   const dropdownFilterChassisRef = useRef(null);
   const dropdownFilterAgingRef = useRef(null);
   const dropdownFilterVesselRef = useRef(null);
   const dropdownFilterPodRef = useRef(null);
   const dropdownFilterDealerRef = useRef(null);

   const {
      loadingScreen,
      setLoadingScreen,
      loadingMorePages,
      setLoadingMorePages,

      manifestedVehiclesQuantity,
      setPageOnRoad,
      setPageScheduled,
      setPageReceived,
      setPageDamaged,
      setPageManifested,
      setPageLoaded,
      setPageInvoiced,

      loadOnRoadVehicles,
      loadScheduledVehicles,
      loadReceivedVehicles,
      loadDamagedVehicles,
      loadManifestedVehicles,
      loadLoadedVehicles,
      loadInvoicedVehicles,

      manifestedVehiclesData,
      setManifestedVehiclesData,
   } = useContext(RegisterContext);


   useEffect(() => {
      if (searchChassis !== '' || searchVessel !== '' || searchPod !== ''
         || searchAging !== '' || searchDealer !== '') {

         async function loadManifestedVehicles() {
            const res = await api.get(`manifested?pageSize=100000&chassis=${AllLetterDown(
               searchChassis)}&vesselName=${AllLetterDown(searchVessel)}&pod=${AllLetterDown(
                  searchPod)}&agingFilter=${searchAging}&dealer=${searchDealer}`);

            setManifestedVehiclesData(res.data);

            setLoadingScreen(false);
         };

         loadManifestedVehicles();

      } else {
         loadManifestedVehicles();
      }

   }, [loadManifestedVehicles, setManifestedVehiclesData, setLoadingScreen, searchChassis, searchVessel, searchPod, searchAging, searchDealer]);

   useEffect(() => {
      setLoadingMorePages(true);
      setPageOnRoad(1);
      setPageScheduled(1);
      setPageReceived(1);
      setPageDamaged(1);
      setPageLoaded(1);
      setPageInvoiced(1);

   }, [setPageOnRoad, setPageScheduled, setPageReceived, setPageDamaged, setPageLoaded, setPageInvoiced, setLoadingMorePages]);

   useEffect(() => {
      loadOnRoadVehicles();
      loadScheduledVehicles();
      loadReceivedVehicles();
      loadDamagedVehicles();
      loadLoadedVehicles();
      loadInvoicedVehicles();

   }, [loadOnRoadVehicles, loadScheduledVehicles, loadReceivedVehicles, loadDamagedVehicles, loadLoadedVehicles, loadInvoicedVehicles]);

   const manifestedVehiclesDataConfigured = manifestedVehiclesData.map(vehicle => {
      return {
         chassi_id: vehicle.chassi_id,
         chassi_number: vehicle.chassi_number,
         model_name: vehicle.model_name,
         dealer_code: vehicle.dealer_code,
         pod: vehicle.pod,
         repaired: vehicle.repaired,
         vessel_voyage: vehicle.vessel_voyage,
         aging: vehicle.aging,
      };
   });

   const handleShowFilterChassis = useCallback((e) => {
      if (dropdownFilterChassisRef.current && showFilterChassis) {
         if (!dropdownFilterChassisRef.current.contains(e.target)) {
            setShowFilterChassis(false);
         }

      } else if (!dropdownFilterChassisRef.current && showFilterChassis) {
         setShowFilterChassis(false);

      } else {
         setShowFilterChassis(true);
      }

   }, [showFilterChassis]);

   useEffect(() => {
      if (showFilterChassis) {
         document.addEventListener("click", handleShowFilterChassis);

         return () => document.removeEventListener("click", handleShowFilterChassis);
      }

   }, [showFilterChassis, handleShowFilterChassis]);

   const handleShowFilterDealer = useCallback((e) => {
      if (dropdownFilterDealerRef.current && showFilterDealer) {
         if (!dropdownFilterDealerRef.current.contains(e.target)) {
            setShowFilterDealer(false);
         }

      } else if (!dropdownFilterDealerRef.current && showFilterDealer) {
         setShowFilterDealer(false);

      } else {
         setShowFilterDealer(true);
      }

   }, [showFilterDealer]);

   useEffect(() => {
      if (showFilterDealer) {
         document.addEventListener("click", handleShowFilterDealer);

         return () => document.removeEventListener("click", handleShowFilterDealer);
      }

   }, [showFilterDealer, handleShowFilterDealer]);

   const handleShowFilterVessel = useCallback((e) => {
      if (dropdownFilterVesselRef.current && showFilterVessel) {
         if (!dropdownFilterVesselRef.current.contains(e.target)) {
            setShowFilterVessel(false);
         }

      } else if (!dropdownFilterVesselRef.current && showFilterVessel) {
         setShowFilterVessel(false);

      } else {
         setShowFilterVessel(true);
      }

   }, [showFilterVessel]);

   useEffect(() => {
      if (showFilterVessel) {
         document.addEventListener("click", handleShowFilterVessel);

         return () => document.removeEventListener("click", handleShowFilterVessel);
      }

   }, [showFilterVessel, handleShowFilterVessel]);

   const handleShowFilterPod = useCallback((e) => {
      if (dropdownFilterPodRef.current && showFilterPod) {
         if (!dropdownFilterPodRef.current.contains(e.target)) {
            setShowFilterPod(false);
         }

      } else if (!dropdownFilterPodRef.current && showFilterPod) {
         setShowFilterPod(false);

      } else {
         setShowFilterPod(true);
      }

   }, [showFilterPod]);

   useEffect(() => {
      if (showFilterPod) {
         document.addEventListener("click", handleShowFilterPod);

         return () => document.removeEventListener("click", handleShowFilterPod);
      }

   }, [showFilterPod, handleShowFilterPod]);

   const handleShowFilterAging = useCallback((e) => {
      if (dropdownFilterAgingRef.current && showFilterAging) {
         if (!dropdownFilterAgingRef.current.contains(e.target)) {
            setShowFilterAging(false);
         }

      } else if (!dropdownFilterAgingRef.current && showFilterAging) {
         setShowFilterAging(false);

      } else {
         setShowFilterAging(true);
      }

   }, [showFilterAging]);

   useEffect(() => {
      if (showFilterAging) {
         document.addEventListener("click", handleShowFilterAging);

         return () => document.removeEventListener("click", handleShowFilterAging);
      }

   }, [showFilterAging, handleShowFilterAging]);


   function handleRemoveFilterChassi() {
      setLoadingScreen(true);

      setSearchChassis('');
      setPageManifested(1);
      setLoadingMorePages(true);
   };

   function handleRemoveFilterVessel() {
      setLoadingScreen(true);

      setSearchVessel('');
      setPageManifested(1);
      setLoadingMorePages(true);
   };

   function handleRemoveFilterPod() {
      setLoadingScreen(true);

      setSearchPod('');
      setPageManifested(1);
      setLoadingMorePages(true);
   };

   function handleRemoveFilterAging() {
      setLoadingScreen(true);

      setSearchAging('');
      setPageManifested(1);
      setLoadingMorePages(true);
   };

   function handleRemoveFilterDealer() {
      setLoadingScreen(true);

      setSearchDealer('');
      setPageManifested(1);
      setLoadingMorePages(true);
   };

   function handleFetchMore() {
      if (manifestedVehiclesData.length >= Number(manifestedVehiclesQuantity)) {
         setLoadingMorePages(false);
         return;
      }

      if (searchChassis !== '' || searchVessel !== '' || searchPod !== ''
         || searchAging !== '' || searchDealer !== '') {
         setLoadingMorePages(false);
         return;
      }

      setTimeout(() => {
         setPageManifested(oldValue => oldValue + 1);

         loadManifestedVehicles();
      }, 1000);
   };

   return (
      <>
         <PageHeader />
         <ButtonsNavigation />

         <div id="page-manifested">
            <div className="container">
               <div className="height">
                  {loadingScreen ?
                     <Loading
                        type='balls'
                     />
                     :
                     <main>
                        {searchChassis === '' && searchVessel === '' && searchPod === ''
                           && searchAging === '' && searchDealer === '' ?
                           null
                           :
                           <div className="filter-selected">
                              <span className='filter-title'>Selected filters:</span>

                              {searchChassis !== '' && (
                                 <div className="filter-selected-container">
                                    <FiX size={15} color='#5D5D5D' onClick={handleRemoveFilterChassi} />
                                    <span className='filter-uppercase'>{searchChassis}</span>
                                 </div>
                              )}

                              {searchVessel !== '' && (
                                 <div className="filter-selected-container">
                                    <FiX size={15} color='#5D5D5D' onClick={handleRemoveFilterVessel} />
                                    <span className='filter-uppercase'>{searchVessel}</span>
                                 </div>
                              )}

                              {searchPod !== '' && (
                                 <div className="filter-selected-container">
                                    <FiX size={15} color='#5D5D5D' onClick={handleRemoveFilterPod} />
                                    <span className='filter-uppercase'>{searchPod}</span>
                                 </div>
                              )}

                              {searchAging !== '' && (
                                 <div className="filter-selected-container">
                                    <FiX size={15} color='#5D5D5D' onClick={handleRemoveFilterAging} />
                                    <span>{searchAging}</span>
                                 </div>
                              )}

                              {searchDealer !== '' && (
                                 <div className="filter-selected-container">
                                    <FiX size={15} color='#5D5D5D' onClick={handleRemoveFilterDealer} />
                                    <span className='filter-uppercase'>{searchDealer}</span>
                                 </div>
                              )}
                           </div>
                        }

                        {manifestedVehiclesDataConfigured.length === 0 ?
                           <div className="no-vehicles">
                              <span>
                                 There are no manifested vehicles at this moment.
                                 <br />
                                 Please, register an GM Ship Manifest File.
                              </span>
                           </div>
                           :
                           <div className="vehicles-list">
                              <div className="label-container">

                                 <DropdownFilterManifested
                                    label='VIN Number'
                                    dropdownFilterRef={dropdownFilterChassisRef}
                                    search={searchChassis}
                                    setSearch={setSearchChassis}
                                    handleShowFilter={handleShowFilterChassis}
                                    setShowFilter={setShowFilterChassis}
                                    showFilter={showFilterChassis}
                                    classContainer='chassi'
                                 />

                                 <div className="model-container">
                                    <label htmlFor="model">Model</label>
                                 </div>

                                 <DropdownFilterManifested
                                    label='Aging'
                                    dropdownFilterRef={dropdownFilterAgingRef}
                                    search={searchAging}
                                    setSearch={setSearchAging}
                                    handleShowFilter={handleShowFilterAging}
                                    setShowFilter={setShowFilterAging}
                                    showFilter={showFilterAging}
                                    classContainer='aging'
                                 />

                                 <DropdownFilterManifested
                                    label='Ship Assigned'
                                    dropdownFilterRef={dropdownFilterVesselRef}
                                    search={searchVessel}
                                    setSearch={setSearchVessel}
                                    handleShowFilter={handleShowFilterVessel}
                                    setShowFilter={setShowFilterVessel}
                                    showFilter={showFilterVessel}
                                    classContainer='vessel'
                                 />

                                 <DropdownFilterManifested
                                    label='POD'
                                    dropdownFilterRef={dropdownFilterPodRef}
                                    search={searchPod}
                                    setSearch={setSearchPod}
                                    handleShowFilter={handleShowFilterPod}
                                    setShowFilter={setShowFilterPod}
                                    showFilter={showFilterPod}
                                    classContainer='pod'
                                 />

                                 <DropdownFilterManifested
                                    label='Dealer'
                                    dropdownFilterRef={dropdownFilterDealerRef}
                                    search={searchDealer}
                                    setSearch={setSearchDealer}
                                    handleShowFilter={handleShowFilterDealer}
                                    setShowFilter={setShowFilterDealer}
                                    showFilter={showFilterDealer}
                                    classContainer='dealer'
                                 />
                              </div>

                              <InfiniteScroll
                                 dataLength={manifestedVehiclesData.length}
                                 next={handleFetchMore}
                                 hasMore={loadingMorePages}
                                 loader={
                                    <LoadingInfiniteScroll
                                       height={30}
                                       width='100%'
                                       type='spin'
                                    />
                                 }
                                 scrollThreshold={0.8}
                                 scrollableTarget="page-manifested"
                              >
                                 {manifestedVehiclesDataConfigured.map(vehicle => {
                                    return (
                                       <div key={vehicle.chassi_id} className="cards">
                                          <Link
                                             to={`/app/vehicle/${encodeURIComponent(crypto.DES.encrypt(String(vehicle.chassi_id), 'idChassi').toString())}/tracking`}
                                             className="card-click"
                                          >
                                             <span className="chassi-container">{vehicle.chassi_number}</span>
                                             <span className="model-container">{vehicle.model_name}</span>
                                             <span className="aging-container">{`${vehicle.aging} day(s)`}</span>
                                             <span className="vessel-container">{vehicle.vessel_voyage}</span>
                                             <span className="pod-container">{vehicle.pod}</span>
                                             <span className="dealer-container">{vehicle.dealer_code}</span>
                                             {vehicle.repaired ? (
                                                <div className="repaired-container">
                                                   <FiTool size={12} color='#555555' />
                                                </div>
                                             ) : (
                                                <div className="not-repaired-container"></div>
                                             )}
                                          </Link>
                                       </div>
                                    )
                                 })}
                              </InfiniteScroll>
                           </div>

                        }
                     </main>
                  }
               </div>
            </div>
         </div>
      </>
   );
};

export default Manifested;
