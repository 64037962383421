import React, { useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { CSVLink } from 'react-csv';
import moment from 'moment';

import PageHeader from '../../../components/PageHeader';
import Loading from '../../../components/Loading';

import { RegisterContext } from '../../../services/contexts/RegisterContext';
import api from '../../../services/api';

import '../../../styles/pages/app/reports/SchedulingList.css';

const SchedulingList = () => {
   const [loadingButton, setLoadingButton] = useState('default');

   const [schedulingListData, setSchedulingListData] = useState([]);

   const { loadingScreen } = useContext(RegisterContext);


   async function handleLoadDataToLoadingReport() {
      setLoadingButton('processing');

      try {
         const { data } = await api.get(`reports/schedulingList`);

         setSchedulingListData(data);

         setLoadingButton('default');

      } catch (e) {
         setLoadingButton('default');

         e.response.data.errors.forEach(error => toast.error(error));
      }
   };

   return (
      <>
         <PageHeader
            backButton={true}
         />
         <div id="page-scheduling-list">
            <div className="container">
               {loadingScreen ?
                  <Loading
                     type='balls'
                  />
                  :
                  <>
                     <div className="header-title">
                        <h1>Scheduling List</h1>
                        <span>This report show only vehicles that are on their road to the terminal:</span>
                     </div>

                     <main>
                        {loadingButton === 'default' ? (
                           schedulingListData.length === 0 ?
                              <button
                                 type="button"
                                 className='button-load'
                                 onClick={handleLoadDataToLoadingReport}
                              >
                                 Load Data
                              </button>
                              :
                              <CSVLink
                                 filename={`${moment().format('YYYY-MM-DD HHmm')}-SchedulingList.csv`}
                                 className='button-submit'
                                 data={schedulingListData}
                                 headers={[
                                    { label: "Shipment Date", key: "shipmentDate" },
                                    { label: "Truck ID", key: "truckId" },
                                    { label: "Operator Name", key: "operatorName" },
                                    { label: "Operator License ID", key: "operatorLicenseId" },
                                    { label: "Truck License Plate", key: "truckLicensePlate" },
                                    { label: "VIN", key: "chassiNumber" },
                                    { label: "Origin Ramp", key: "originRamp" },
                                    { label: "Weight", key: "modelWeight" },
                                    { label: "Model Name", key: "modelName" },
                                    { label: "Model Year", key: "modelYear" },
                                    { label: "Dealer Code", key: "dealerCode" },
                                    { label: "POD Name", key: "podName" },
                                    { label: "POD Country", key: "podCountry" },
                                    { label: "Assign", key: "assign" },
                                    { label: "Route", key: "route" },
                                    { label: "Location", key: "location" },
                                 ]}
                              >
                                 Download XLSX
                              </CSVLink>
                        ) : (
                           <button
                              className='button-load-disabled'
                              style={{ background: '#4B81E8', color: '#FFF', border: 0 }}
                              disabled
                           >
                              <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                              <span className="sr-only"></span>
                           </button>
                        )}

                     </main>
                  </>
               }
            </div>
         </div>
      </>
   );
};

export default SchedulingList;
