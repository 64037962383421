import React, { useState, useEffect, useCallback, useContext, useRef, forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { FiX, FiTool } from 'react-icons/fi';
import { HiCalendar, HiOutlineCalendar } from 'react-icons/hi';
import crypto from 'crypto-js';
import InfiniteScroll from 'react-infinite-scroll-component';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import PageHeader from '../../components/PageHeader';
import Loading from '../../components/Loading';
import LoadingInfiniteScroll from '../../components/LoadingInfiniteScroll';
import ButtonsNavigation from '../../components/ButtonsNavigation';
import DropdownFilterInvoiced from '../../components/dropdowns/DropdownFilterInvoiced';

import { RegisterContext } from '../../services/contexts/RegisterContext';

import api from '../../services/api';
import { AllLetterDown } from '../../utils/functions';

import '../../styles/pages/app/Invoiced.css';

const Invoiced = () => {
   const [startDate, setStartDate] = useState(new Date());

   const [showFilterChassis, setShowFilterChassis] = useState(false);
   const [showFilterVessel, setShowFilterVessel] = useState(false);
   const [showFilterPod, setShowFilterPod] = useState(false);

   const [searchChassis, setSearchChassis] = useState('');
   const [searchVessel, setSearchVessel] = useState('');
   const [searchPod, setSearchPod] = useState('');
   const [searchInvoiceDate, setSearchInvoiceDate] = useState('');

   const dropdownFilterChassisRef = useRef(null);
   const dropdownFilterVesselRef = useRef(null);
   const dropdownFilterPodRef = useRef(null);

   const {
      loadingScreen,
      setLoadingScreen,
      loadingMorePages,
      setLoadingMorePages,

      invoicedVehiclesQuantity,
      setPageOnRoad,
      setPageScheduled,
      setPageReceived,
      setPageDamaged,
      setPageManifested,
      setPageLoaded,
      setPageInvoiced,

      loadOnRoadVehicles,
      loadScheduledVehicles,
      loadReceivedVehicles,
      loadDamagedVehicles,
      loadManifestedVehicles,
      loadLoadedVehicles,
      loadInvoicedVehicles,

      invoicedVehiclesData,
      setInvoicedVehiclesData,
   } = useContext(RegisterContext);


   useEffect(() => {
      if (searchChassis !== '' || searchVessel !== ''
         || searchPod !== '' || searchInvoiceDate !== '') {

         async function loadInvoicedVehicles() {
            const res = await api.get(`invoiced?pageSize=100000&chassis=${AllLetterDown(
               searchChassis)}&vesselName=${AllLetterDown(searchVessel)}&pod=${AllLetterDown(
                  searchPod)}&invoicedDate=${AllLetterDown(searchInvoiceDate)}`);

            setInvoicedVehiclesData(res.data);

            setLoadingScreen(false);
         };

         loadInvoicedVehicles();

      } else {
         loadInvoicedVehicles();
      }

   }, [loadInvoicedVehicles, setInvoicedVehiclesData, setLoadingScreen, searchChassis, searchVessel, searchPod, searchInvoiceDate]);

   useEffect(() => {
      setLoadingMorePages(true);
      setPageOnRoad(1);
      setPageScheduled(1);
      setPageReceived(1);
      setPageDamaged(1);
      setPageManifested(1);
      setPageLoaded(1);

   }, [setPageOnRoad, setPageScheduled, setPageReceived, setPageDamaged, setPageManifested, setPageLoaded, setLoadingMorePages]);

   useEffect(() => {
      loadOnRoadVehicles();
      loadScheduledVehicles();
      loadReceivedVehicles();
      loadDamagedVehicles();
      loadManifestedVehicles();
      loadLoadedVehicles();

   }, [loadOnRoadVehicles, loadScheduledVehicles, loadReceivedVehicles, loadDamagedVehicles, loadManifestedVehicles, loadLoadedVehicles]);

   const invoiceVehiclesDataConfigured = invoicedVehiclesData.map(vehicle => {
      return {
         chassi_id: vehicle.chassi_id,
         chassi_number: vehicle.chassi_number,
         model_name: vehicle.model_name,
         vessel_voyage: vehicle.vessel_voyage,
         pod: vehicle.pod,
         invoiced_date: moment(vehicle.invoiced_date).format('YYYY-MM-DD'),
         repaired: vehicle.repaired,
         pdi_service: vehicle.pdi_service
      };
   });

   const handleShowFilterChassis = useCallback((e) => {
      if (dropdownFilterChassisRef.current && showFilterChassis) {
         if (!dropdownFilterChassisRef.current.contains(e.target)) {
            setShowFilterChassis(false);
         }

      } else if (!dropdownFilterChassisRef.current && showFilterChassis) {
         setShowFilterChassis(false);

      } else {
         setShowFilterChassis(true);
      }

   }, [showFilterChassis]);

   useEffect(() => {
      if (showFilterChassis) {
         document.addEventListener("click", handleShowFilterChassis);

         return () => document.removeEventListener("click", handleShowFilterChassis);
      }

   }, [showFilterChassis, handleShowFilterChassis]);

   const handleShowFilterVessel = useCallback((e) => {
      if (dropdownFilterVesselRef.current && showFilterVessel) {
         if (!dropdownFilterVesselRef.current.contains(e.target)) {
            setShowFilterVessel(false);
         }

      } else if (!dropdownFilterVesselRef.current && showFilterVessel) {
         setShowFilterVessel(false);

      } else {
         setShowFilterVessel(true);
      }

   }, [showFilterVessel]);

   useEffect(() => {
      if (showFilterVessel) {
         document.addEventListener("click", handleShowFilterVessel);

         return () => document.removeEventListener("click", handleShowFilterVessel);
      }

   }, [showFilterVessel, handleShowFilterVessel]);

   const handleShowFilterPod = useCallback((e) => {
      if (dropdownFilterPodRef.current && showFilterPod) {
         if (!dropdownFilterPodRef.current.contains(e.target)) {
            setShowFilterPod(false);
         }

      } else if (!dropdownFilterPodRef.current && showFilterPod) {
         setShowFilterPod(false);

      } else {
         setShowFilterPod(true);
      }

   }, [showFilterPod]);

   useEffect(() => {
      if (showFilterPod) {
         document.addEventListener("click", handleShowFilterPod);

         return () => document.removeEventListener("click", handleShowFilterPod);
      }

   }, [showFilterPod, handleShowFilterPod]);


   const onChangeDatePicker = (date) => {
      setStartDate(date);
      setSearchInvoiceDate(moment(new Date(date)).format('YYYY-MM-DD'));
   };

   const CalendarCustomButton = forwardRef(({ onClick }, ref) => {
      return (
         <button
            type="button"
            className='button-filter'
            onClick={onClick}
            ref={ref}
         >
            {searchInvoiceDate !== '' ?
               <HiCalendar size={20} color="#4B81E8" />
               :
               <HiOutlineCalendar size={20} color="#5D5D5D" />
            }
         </button>
      );
   });

   function handleRemoveFilterChassi() {
      setLoadingScreen(true);

      setSearchChassis('');
      setPageInvoiced(1);
      setLoadingMorePages(true);
   };

   function handleRemoveFilterDate() {
      setLoadingScreen(true);

      setSearchInvoiceDate('');
      setPageInvoiced(1);
      setLoadingMorePages(true);
   };

   function handleRemoveFilterVessel() {
      setLoadingScreen(true);

      setSearchVessel('');
      setPageInvoiced(1);
      setLoadingMorePages(true);
   };

   function handleRemoveFilterPod() {
      setLoadingScreen(true);

      setSearchPod('');
      setPageInvoiced(1);
      setLoadingMorePages(true);
   };

   function handleFetchMore() {
      if (invoicedVehiclesData.length >= Number(invoicedVehiclesQuantity)) {
         setLoadingMorePages(false);
         return;
      }

      if (searchChassis !== '' || searchVessel !== ''
         || searchPod !== '' || searchInvoiceDate !== '') {
         setLoadingMorePages(false);
         return;
      }

      setTimeout(() => {
         setPageInvoiced(oldValue => oldValue + 1);

         loadInvoicedVehicles();
      }, 1000);
   };

   return (
      <>
         <PageHeader />
         <ButtonsNavigation />

         <div id="page-invoiced">
            <div className="container">
               <div className="height">
                  {loadingScreen ?
                     <Loading
                        type='balls'
                     />
                     :
                     <main>
                        {searchChassis === '' && searchVessel === ''
                           && searchPod === '' && searchInvoiceDate === ''
                           ?
                           null
                           :
                           <div className="filter-selected">
                              <span className='filter-title'>Selected filters:</span>

                              {searchChassis !== '' && (
                                 <div className="filter-selected-container">
                                    <FiX size={15} color='#5D5D5D' onClick={handleRemoveFilterChassi} />
                                    <span className='filter-uppercase'>{searchChassis}</span>
                                 </div>
                              )}

                              {searchInvoiceDate !== '' && (
                                 <div className="filter-selected-container">
                                    <FiX size={15} color='#5D5D5D' onClick={handleRemoveFilterDate} />
                                    <span>{searchInvoiceDate}</span>
                                 </div>
                              )}

                              {searchVessel !== '' && (
                                 <div className="filter-selected-container">
                                    <FiX size={15} color='#5D5D5D' onClick={handleRemoveFilterVessel} />
                                    <span className='filter-uppercase'>{searchVessel}</span>
                                 </div>
                              )}

                              {searchPod !== '' && (
                                 <div className="filter-selected-container">
                                    <FiX size={15} color='#5D5D5D' onClick={handleRemoveFilterPod} />
                                    <span className='filter-uppercase'>{searchPod}</span>
                                 </div>
                              )}

                           </div>
                        }

                        {invoiceVehiclesDataConfigured.length === 0 ?
                           <div className="no-vehicles">
                              <span>
                                 There are no vehicles to show at this moment.
                                 <br />
                                 Please, click on button bellow to check if there are any registers hidden by users.
                              </span>
                           </div>
                           :
                           <div className="vehicles-list">
                              <div className="label-container">

                                 <DropdownFilterInvoiced
                                    label='VIN Number'
                                    dropdownFilterRef={dropdownFilterChassisRef}
                                    search={searchChassis}
                                    setSearch={setSearchChassis}
                                    handleShowFilter={handleShowFilterChassis}
                                    setShowFilter={setShowFilterChassis}
                                    showFilter={showFilterChassis}
                                    classContainer='chassi'
                                 />

                                 <div className="model-container">
                                    <label htmlFor="model">Model</label>
                                 </div>

                                 <DropdownFilterInvoiced
                                    label='Ship Assigned'
                                    dropdownFilterRef={dropdownFilterVesselRef}
                                    search={searchVessel}
                                    setSearch={setSearchVessel}
                                    handleShowFilter={handleShowFilterVessel}
                                    setShowFilter={setShowFilterVessel}
                                    showFilter={showFilterVessel}
                                    classContainer='vessel'
                                 />

                                 <DropdownFilterInvoiced
                                    label='POD'
                                    dropdownFilterRef={dropdownFilterPodRef}
                                    search={searchPod}
                                    setSearch={setSearchPod}
                                    handleShowFilter={handleShowFilterPod}
                                    setShowFilter={setShowFilterPod}
                                    showFilter={showFilterPod}
                                    classContainer='pod'
                                 />

                                 <div className="date-container">
                                    <label htmlFor="invoiceDate">Invoice Date</label>
                                    <DatePicker
                                       selected={startDate}
                                       onChange={onChangeDatePicker}
                                       customInput={<CalendarCustomButton />}
                                       showPopperArrow={false}
                                       todayButton='Today'
                                    />
                                 </div>

                                 <div className="service-container">
                                    <label htmlFor="towHookInstall">Tow hook install</label>
                                 </div>

                                 <div className="service-container">
                                    <label htmlFor="wash">Wash</label>
                                 </div>

                                 <div className="service-container">
                                    <label htmlFor="batteryCharge">Battery Charge</label>
                                 </div>

                                 <div className="service-container">
                                    <label htmlFor="tireReplacement">Tire Replac.</label>
                                 </div>

                                 <div className="service-container">
                                    <label htmlFor="airTireSupplied">Air tire supplied</label>
                                 </div>

                                 <div className="service-container">
                                    <label htmlFor="refuel">Refuel</label>
                                 </div>

                                 <div className="service-container">
                                    <label htmlFor="batteryReview">Battery voltage review</label>
                                 </div>

                              </div>

                              <InfiniteScroll
                                 dataLength={invoicedVehiclesData.length}
                                 next={handleFetchMore}
                                 hasMore={loadingMorePages}
                                 loader={
                                    <LoadingInfiniteScroll
                                       height={30}
                                       width='100%'
                                       type='spin'
                                    />
                                 }
                                 scrollThreshold={0.8}
                                 scrollableTarget="page-invoiced"
                              >
                                 {invoiceVehiclesDataConfigured.map(vehicle => {
                                    return (
                                       <div key={vehicle.chassi_id} className="cards">
                                          <Link
                                             to={`/app/vehicle/${encodeURIComponent(crypto.DES.encrypt(String(vehicle.chassi_id), 'idChassi').toString())}/tracking`}
                                             className="card-click"
                                          >
                                             <span className="chassi-container">{vehicle.chassi_number}</span>
                                             <span className="model-container">{vehicle.model_name}</span>
                                             <span className="vessel-container">{vehicle.vessel_voyage}</span>
                                             <span className="pod-container">{vehicle.pod}</span>
                                             <span className="date-container">{vehicle.invoiced_date}</span>
                                             <span className="service-container">{vehicle.pdi_service[0].pdi_quantity}</span>
                                             <span className="service-container">{vehicle.pdi_service[1].pdi_quantity}</span>
                                             <span className="service-container">{vehicle.pdi_service[2].pdi_quantity}</span>
                                             <span className="service-container">{vehicle.pdi_service[3].pdi_quantity}</span>
                                             <span className="service-container">{vehicle.pdi_service[4].pdi_quantity}</span>
                                             <span className="service-container">{vehicle.pdi_service[5].pdi_quantity}</span>
                                             <span className="service-container">{vehicle.pdi_service[6].pdi_quantity}</span>
                                             {vehicle.repaired ? (
                                                <div className="repaired-container">
                                                   <FiTool size={12} color='#555555' />
                                                </div>
                                             ) : (
                                                <div className="not-repaired-container"></div>
                                             )}
                                          </Link>
                                       </div>
                                    )
                                 })}
                              </InfiniteScroll>
                           </div>
                        }
                     </main>
                  }
               </div>
            </div>
         </div>
      </>
   );
};

export default Invoiced;
