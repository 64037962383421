import React, { useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { CSVLink } from 'react-csv';
import moment from 'moment';

import Loading from '../../../components/Loading';
import PageHeader from '../../../components/PageHeader';

import { RegisterContext } from '../../../services/contexts/RegisterContext';
import api from '../../../services/api';

import '../../../styles/pages/app/reports/RepairList.css';

const RepairList = () => {
   const [loadingButton, setLoadingButton] = useState('default');

   const [repairListData, setRepairListData] = useState([]);

   const { loadingScreen } = useContext(RegisterContext);


   async function handleLoadDataToLoadingReport() {
      setLoadingButton('processing');

      try {
         const { data } = await api.get(`reports/repairList`);

         setRepairListData(data);

         setLoadingButton('default');

      } catch (e) {
         setLoadingButton('default');

         e.response.data.errors.forEach(error => toast.error(error));
      }
   };


   return (
      <>
         <PageHeader
            backButton={true}
         />

         <div id="page-repair-list">
            <div className="container">
               {loadingScreen ?
                  <Loading
                     type='balls'
                  />
                  :
                  <>
                     <div className="header-title">
                        <h1>Repair List</h1>
                        <span>This report show only vehicles that has been repaired and was not marked as invoiced yet:</span>
                     </div>

                     <main>
                        {loadingButton === 'default' ? (
                           repairListData.length === 0 ?
                              <button
                                 type="button"
                                 className='button-load'
                                 onClick={handleLoadDataToLoadingReport}
                              >
                                 Load Data
                              </button>
                              :
                              <CSVLink
                                 filename={`${moment().format('YYYY-MM-DD HHmm')}-RepairList.csv`}
                                 className='button-submit'
                                 data={repairListData}
                                 headers={[
                                    { label: "Yard Received Date", key: "yardReceivedDate" },
                                    { label: "VIN", key: "chassiNumber" },
                                    { label: "Model Name", key: "modelName" },
                                    { label: "Damage Description", key: "damageDescription" },
                                    { label: "Damage Responsible", key: "damageResponsability" },
                                    { label: "Real Repaired Date", key: "realRepairDate" },
                                    { label: "Comments", key: "damageComments" },
                                    { label: "Invoice Amount", key: "invoiceAmount" },
                                    { label: "IVA", key: "iva" },
                                    { label: "Total", key: "total" },
                                    { label: "Input Cost", key: "inputCost" },
                                    { label: "Labor Cost per Unit", key: "laborCostPerUnit" },
                                    { label: "Adm Charges", key: "admCharges" },
                                    { label: "Total Cost", key: "totalCost" },
                                    { label: "Profit", key: "profit" },
                                    { label: "Spot Buy #", key: "spotBuy" },
                                    { label: "Concept", key: "concept" },
                                    { label: "Invoice Request Date", key: "invoiceRequestDate" },
                                 ]}
                              >
                                 Download XLSX
                              </CSVLink>
                        ) : (
                           <button
                              className='button-load-disabled'
                              style={{ background: '#4B81E8', color: '#FFF', border: 0 }}
                              disabled
                           >
                              <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                              <span className="sr-only"></span>
                           </button>
                        )}

                     </main>
                  </>
               }
            </div>
         </div>
      </>
   );
};

export default RepairList;
