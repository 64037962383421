import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import Login from '../pages/auth/Login';
import ForgotPassword from '../pages/auth/ForgotPassword';
import ResetPassword from '../pages/auth/ResetPassword';

function AuthRoutes() {
   return (
      <Switch>
         <Redirect exact from='/' to='/login' />
         <Route exact path='/login' component={Login} />
         <Route path='/login/:token' component={Login} />
         <Route path='/forgotPassword' component={ForgotPassword} />
         <Route path='/resetPassword/:token' component={ResetPassword} />
         <Redirect to='/' />
      </Switch>
   );
};

export default AuthRoutes;
