import React, { useState, useEffect, useContext, forwardRef } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { HiOutlinePencilAlt } from 'react-icons/hi';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import crypto from 'crypto-js';

import PageHeader from '../../../components/PageHeader';
import Loading from '../../../components/Loading';
import ChassiDetailsHeader from '../../../components/ChassiDetails/ChassiDetailsHeader';
import LinksNavigation from '../../../components/ChassiDetails/LinksNavigation';
import ChassiHighlights from '../../../components/ChassiDetails/ChassiHighlights';
import InputChassiDetails from '../../../components/ChassiDetails/InputChassiDetails';

import api from '../../../services/api';
import { ChassiDetailsContext } from '../../../services/contexts/ChassiDetailsContext';

import '../../../styles/pages/app/ChassiDetails/RoadTransport.css';

const ChassiDetailsRoadTransport = () => {
   const [shipmentDateIsDisabled, setShipmentDateIsDisabled] = useState(true);
   const [roadCarrierIsDisabled, setRoadCarrierIsDisabled] = useState(true);
   const [truckIdIsDisabled, setTruckIdIsDisabled] = useState(true);
   const [truckPlateIsDisabled, setTruckPlateIsDisabled] = useState(true);
   const [operatorLicenseIdIsDisabled, setOperatorLicenseIdIsDisabled] = useState(true);
   const [operatorNameIsDisabled, setOperatorNameIsDisabled] = useState(true);

   const [startDate, setStartDate] = useState(new Date());

   const [shipmentDateEdit, setShipmentDateEdit] = useState();
   const [roadCarrierEdit, setRoadCarrierEdit] = useState();
   const [truckIdEdit, setTruckIdEdit] = useState();
   const [truckPlateEdit, setTruckPlateEdit] = useState();
   const [operatorLicenseIdEdit, setOperatorLicenseIdEdit] = useState();
   const [operatorNameEdit, setOperatorNameEdit] = useState();


   const { id } = useParams();
   const chassi_id = crypto.DES.decrypt(decodeURIComponent(id), 'idChassi').toString(crypto.enc.Utf8);

   const {
      loadingScreen,
      setChassiId,
      chassiDetailsInfoAdjusted,
      loadChassiDetails,
   } = useContext(ChassiDetailsContext);

   useEffect(() => {
      setChassiId(chassi_id);

   }, [setChassiId, chassi_id]);

   function handleAllowEditionShipmentDate() {
      setShipmentDateIsDisabled(!shipmentDateIsDisabled);
   };

   function handleAllowEditionRoadCarrier() {
      setRoadCarrierIsDisabled(!roadCarrierIsDisabled);
   };

   function handleAllowEditionTruckId() {
      setTruckIdIsDisabled(!truckIdIsDisabled);
   };

   function handleAllowEditionTruckPlate() {
      setTruckPlateIsDisabled(!truckPlateIsDisabled);
   };

   function handleAllowEditionOperatorLicenseId() {
      setOperatorLicenseIdIsDisabled(!operatorLicenseIdIsDisabled);
   };

   function handleAllowEditionOperatorName() {
      setOperatorNameIsDisabled(!operatorNameIsDisabled);
   };

   const onChangeShipmentDatePicker = (date) => {
      setStartDate(date);
      setShipmentDateEdit(moment(new Date(date)).format('YYYY-MM-DD'));
   };

   const CalendarCustomButton = forwardRef(({ onClick }, ref) => {
      return (
         <button
            type="button"
            className='button-date-picker'
            onClick={onClick}
            ref={ref}
            disabled={shipmentDateIsDisabled}
         >
            {shipmentDateEdit === undefined ? chassiDetailsInfoAdjusted.shipment_date : shipmentDateEdit}
         </button>
      );
   });

   async function handleChassiRoadTranspDetailsUpdate() {
      const data = {
         shipmentDate: shipmentDateEdit === undefined ? chassiDetailsInfoAdjusted.shipment_date : shipmentDateEdit,
         truckCompany: roadCarrierEdit === undefined ? chassiDetailsInfoAdjusted.truck_company : roadCarrierEdit,
         truckId: truckIdEdit === undefined ? chassiDetailsInfoAdjusted.truck_id : truckIdEdit,
         truckLicensePlate: truckPlateEdit === undefined ? chassiDetailsInfoAdjusted.truck_license_plate : truckPlateEdit,
         operatorLicenseId: operatorLicenseIdEdit === undefined ? chassiDetailsInfoAdjusted.operator_license_id : operatorLicenseIdEdit,
         operatorName: operatorNameEdit === undefined ? chassiDetailsInfoAdjusted.operator_name : operatorNameEdit,
      };

      try {
         const res = await api.put(`chassis/${chassi_id}/roadTransport`, data);

         await loadChassiDetails();

         toast.success(res.data.message);

      } catch (e) {
         e.response.data.errors.forEach(error => toast.error(error));
      }

      setShipmentDateIsDisabled(true);
      setRoadCarrierIsDisabled(true);
      setTruckIdIsDisabled(true);
      setTruckPlateIsDisabled(true);
      setOperatorLicenseIdIsDisabled(true);
      setOperatorNameIsDisabled(true);

      setShipmentDateEdit();
      setRoadCarrierEdit();
      setTruckIdEdit();
      setTruckPlateEdit();
      setOperatorLicenseIdEdit();
      setOperatorNameEdit();
   };

   return (
      <>
         <PageHeader
            backButton={true}
         />

         <ChassiDetailsHeader
            chassiNumber={chassiDetailsInfoAdjusted.chassi_number}
            modelName={chassiDetailsInfoAdjusted.model_name}
            modelYear={chassiDetailsInfoAdjusted.model_year}
            pod={chassiDetailsInfoAdjusted.pod}
            currentStatus={chassiDetailsInfoAdjusted.status}
         />

         <LinksNavigation />

         <div id="page-details-roadTransport">
            {loadingScreen ?
               <Loading
                  type='balls'
               />
               :
               <div className="container">
                  <div className="container-left">
                     <ChassiHighlights
                        modelCode={chassiDetailsInfoAdjusted.model_code}
                        modelMaker={chassiDetailsInfoAdjusted.maker}
                        assemblyPlant={chassiDetailsInfoAdjusted.assembly_plant}
                        currentStatus={chassiDetailsInfoAdjusted.status}
                        aging={chassiDetailsInfoAdjusted.aging}
                     />
                  </div>

                  <div className="container-right">
                     <h2>Road Transport Info</h2>

                     <div className="form">

                        <div id="input-block">
                           <div className='label-container'>
                              <label htmlFor='inputChassiDetails'>Shipment Date</label>

                              <button
                                 type='button'
                                 className='button-edit'
                                 onClick={handleAllowEditionShipmentDate}
                              >
                                 <HiOutlinePencilAlt size={20} color='#5D5D5D' />
                              </button>
                           </div>
                           <DatePicker
                              selected={startDate}
                              onChange={onChangeShipmentDatePicker}
                              customInput={<CalendarCustomButton />}
                              showPopperArrow={false}
                              onCalendarClose={() => handleChassiRoadTranspDetailsUpdate()}
                              todayButton='Today'
                           />
                        </div>

                        <InputChassiDetails
                           label='Road Carrier'
                           handleAllowEdition={handleAllowEditionRoadCarrier}
                           value={chassiDetailsInfoAdjusted.truck_company}
                           valueEdited={roadCarrierEdit}
                           onChange={setRoadCarrierEdit}
                           isDisabled={roadCarrierIsDisabled}
                           onBlur={() => handleChassiRoadTranspDetailsUpdate()}
                           editable={true}
                        />

                        <InputChassiDetails
                           label='Truck ID'
                           handleAllowEdition={handleAllowEditionTruckId}
                           value={chassiDetailsInfoAdjusted.truck_id}
                           valueEdited={truckIdEdit}
                           onChange={setTruckIdEdit}
                           isDisabled={truckIdIsDisabled}
                           onBlur={() => handleChassiRoadTranspDetailsUpdate()}
                           editable={true}
                        />

                        <InputChassiDetails
                           label='Truck Plate'
                           handleAllowEdition={handleAllowEditionTruckPlate}
                           value={chassiDetailsInfoAdjusted.truck_license_plate}
                           valueEdited={truckPlateEdit}
                           onChange={setTruckPlateEdit}
                           isDisabled={truckPlateIsDisabled}
                           onBlur={() => handleChassiRoadTranspDetailsUpdate()}
                           editable={true}
                        />

                        <InputChassiDetails
                           label='Operator License ID'
                           handleAllowEdition={handleAllowEditionOperatorLicenseId}
                           value={chassiDetailsInfoAdjusted.operator_license_id}
                           valueEdited={operatorLicenseIdEdit}
                           onChange={setOperatorLicenseIdEdit}
                           isDisabled={operatorLicenseIdIsDisabled}
                           onBlur={() => handleChassiRoadTranspDetailsUpdate()}
                           editable={true}
                        />

                        <InputChassiDetails
                           label='Operator Name'
                           handleAllowEdition={handleAllowEditionOperatorName}
                           value={chassiDetailsInfoAdjusted.operator_name}
                           valueEdited={operatorNameEdit}
                           onChange={setOperatorNameEdit}
                           isDisabled={operatorNameIsDisabled}
                           onBlur={() => handleChassiRoadTranspDetailsUpdate()}
                           editable={true}
                        />
                     </div>
                  </div>
               </div>
            }
         </div>
      </>
   );
};

export default ChassiDetailsRoadTransport;
