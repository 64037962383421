import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import PageHeader from '../../../components/PageHeader';
import Input from '../../../components/Input';

import api from '../../../services/api';

import '../../../styles/pages/app/settings/VesselNew.css';

const VesselNew = () => {
   const [loadingButton, setLoadingButton] = useState('default');

   const [vesselName, setVesselName] = useState('');
   const [vesselVoyage, setVesselVoyage] = useState('');
   const [vesselShipowner, setVesselShipowner] = useState('');

   const history = useHistory();

   async function handleRegisterNewVessel(e) {
      e.preventDefault();

      setLoadingButton('processing');

      const data = { vesselName, vesselVoyage, vesselShipowner };

      try {
         const res = await api.post('vesselsVoyage', data);

         toast.success(res.data.message);

         history.goBack();

      } catch (e) {
         setLoadingButton('default');

         e.response.data.errors.forEach(error => toast.error(error));
      }
   };

   return (
      <div id="page-vessel-new">
         <PageHeader settings={true} />

         <div className="container">
            <div className="content">

               <h1>New Voyage</h1>
               <span>Here you can register new vessel & voyage.</span>

               <main>
                  <form onSubmit={handleRegisterNewVessel}>
                     <fieldset>

                        <div className="input-container">
                           <Input
                              name='vesselName'
                              type='text'
                              label='Vessel'
                              value={vesselName}
                              onChange={e => setVesselName(e.target.value)}
                           />

                           <Input
                              name='vesselVoyage'
                              type='text'
                              label='Voyage'
                              value={vesselVoyage}
                              onChange={e => setVesselVoyage(e.target.value)}
                           />

                           <Input
                              name='vesselShipowner'
                              type='text'
                              label='Ocean Carrier'
                              value={vesselShipowner}
                              onChange={e => setVesselShipowner(e.target.value)}
                           />
                        </div>

                        <div className="buttons-container">
                           <Link
                              to='/app/settings/admin/vessels'
                              className='cancel-button'
                           >
                              Cancel
                           </Link>

                           {loadingButton === 'default' ? (
                              <button
                                 type='submit'
                                 className='submit-button'
                              >
                                 Save
                              </button>
                           ) : (
                                 <button
                                    className='submit-button'
                                    style={{ background: '#4B81E8', color: '#FFF', border: 0 }}
                                 >
                                    <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                                    <span className="sr-only"></span>
                                 </button>
                              )
                           }
                        </div>

                     </fieldset>
                  </form>
               </main>

            </div>
         </div>
      </div>
   );
};

export default VesselNew;
