import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './services/contexts/auth';

import Routes from './routes';
import Message from './utils/Message';
import Loading from './components/Loading';

import './styles/global.css';

function App() {
   return (
      <BrowserRouter>
         <AuthProvider>
            <Suspense fallback={<Loading type='balls' />}>
               <Routes />
               <Message />
            </Suspense>
         </AuthProvider>
      </BrowserRouter >
   );
};

export default App;
