import React, { useState, useEffect, useContext, forwardRef } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { HiOutlinePencilAlt } from 'react-icons/hi';
import Select from 'react-select';
import PerfectScrollbar from "react-perfect-scrollbar";
import DatePicker from 'react-datepicker';
import moment from 'moment';
import crypto from 'crypto-js';

import PageHeader from '../../../components/PageHeader';
import Loading from '../../../components/Loading';
import ChassiDetailsHeader from '../../../components/ChassiDetails/ChassiDetailsHeader';
import LinksNavigation from '../../../components/ChassiDetails/LinksNavigation';
import ChassiHighlights from '../../../components/ChassiDetails/ChassiHighlights';
import InputChassiDetails from '../../../components/ChassiDetails/InputChassiDetails';

import api from '../../../services/api';
import { ChassiDetailsContext } from '../../../services/contexts/ChassiDetailsContext';

import { customSelectStyles } from '../../../styles/components/ChassiDetails/SelectStyles';
import '../../../styles/pages/app/ChassiDetails/Repair.css';

const ChassiDetailsRepair = () => {
   const [repairableIsDisabled, setRepairableIsDisabled] = useState(true);
   const [repairResponsabilityIsDisabled, setRepairResponsabilityIsDisabled] = useState(true);
   const [receivedDateIsDisabled, setReceivedDateIsDisabled] = useState(true);
   const [releaseDateIsDisabled, setReleaseDateIsDisabled] = useState(true);
   const [serviceOrderIsDisabled, setServiceOrderIsDisabled] = useState(true);
   const [partRequiredIsDisabled, setPartRequiredIsDisabled] = useState(true);
   const [partsOrderingDateIsDisabled, setPartsOrderingDateIsDisabled] = useState(true);
   const [partsOrderNumberIsDisabled, setPartsOrderNumberIsDisabled] = useState(true);
   const [partsDeliveryDateIsDisabled, setPartsDeliveryDateIsDisabled] = useState(true);
   const [repairStatusIsDisabled, setRepairStatusIsDisabled] = useState(true);
   const [repairDateIsDisabled, setRepairDateIsDisabled] = useState(true);
   const [commentsIsDisabled, setCommentsIsDisabled] = useState(true);

   const [startDate, setStartDate] = useState(new Date());

   const [repairableEdit, setRepairableEdit] = useState();
   const [repairResponsabilityEdit, setRepairResponsabilityEdit] = useState();
   const [receivedDateEdit, setReceivedDateEdit] = useState();
   const [releaseDateEdit, setReleaseDateEdit] = useState();
   const [serviceOrderEdit, setServiceOrderEdit] = useState();
   const [partsRequiredEdit, setPartsRequiredEdit] = useState();
   const [partsOrderingDateEdit, setPartsOrderingDateEdit] = useState();
   const [partsOrderNumberEdit, setPartsOrderNumberEdit] = useState();
   const [partsDeliveryDateEdit, setPartsDeliveryDateEdit] = useState();
   const [repairStatusEdit, setRepairStatusEdit] = useState();
   const [repairDateEdit, setRepairDateEdit] = useState();
   const [commentsEdit, setCommentsEdit] = useState();


   const { id } = useParams();
   const chassi_id = crypto.DES.decrypt(decodeURIComponent(id), 'idChassi').toString(crypto.enc.Utf8);

   const {
      loadingScreen,
      setChassiId,
      chassiDetailsInfoAdjusted,
      loadChassiDetails,
   } = useContext(ChassiDetailsContext);

   const yesOrNoOptions = [
      { value: true, label: `Yes` },
      { value: false, label: `No` },
   ];

   useEffect(() => {
      setChassiId(chassi_id);

   }, [setChassiId, chassi_id]);

   async function handleChassiRepairDetailsUpdate() {
      const data = {
         repairable: repairableEdit !== undefined ? repairableEdit : chassiDetailsInfoAdjusted.repairable,
         repairResponsability: repairResponsabilityEdit !== undefined ? repairResponsabilityEdit : chassiDetailsInfoAdjusted.repair_responsability,
         repairReceivedDate: receivedDateEdit !== undefined ? receivedDateEdit : chassiDetailsInfoAdjusted.repair_received_date === '-' ? null : chassiDetailsInfoAdjusted.repair_received_date,
         estimatedReleaseDate: releaseDateEdit !== undefined ? releaseDateEdit : chassiDetailsInfoAdjusted.estimated_release_date === '-' ? null : chassiDetailsInfoAdjusted.estimated_release_date,
         serviceOrder: serviceOrderEdit !== undefined ? serviceOrderEdit : chassiDetailsInfoAdjusted.service_order,
         part_required: partsRequiredEdit !== undefined ? partsRequiredEdit : chassiDetailsInfoAdjusted.part_required,
         partsOrderingDate: partsOrderingDateEdit !== undefined ? partsOrderingDateEdit : chassiDetailsInfoAdjusted.parts_ordering_date === '-' ? null : chassiDetailsInfoAdjusted.parts_ordering_date,
         partsOrderNumber: partsOrderNumberEdit !== undefined ? partsOrderNumberEdit : chassiDetailsInfoAdjusted.parts_order_number,
         partsDeliveryDate: partsDeliveryDateEdit !== undefined ? partsDeliveryDateEdit : chassiDetailsInfoAdjusted.parts_delivery_date === '-' ? null : chassiDetailsInfoAdjusted.parts_delivery_date,
         repairStatus: repairStatusEdit !== undefined ? repairStatusEdit : chassiDetailsInfoAdjusted.repair_status,
         realRepairDate: repairDateEdit !== undefined ? repairDateEdit : chassiDetailsInfoAdjusted.repair_date === '-' ? null : chassiDetailsInfoAdjusted.repair_date,
         generalComments: commentsEdit !== undefined ? commentsEdit : chassiDetailsInfoAdjusted.general_comments,
      };

      try {
         const res = await api.put(`chassis/${chassi_id}/repair`, data);

         await loadChassiDetails();

         toast.success(res.data.message);

      } catch (e) {
         e.response.data.errors.forEach(error => toast.error(error));
      }

      setRepairableIsDisabled(true);
      setRepairResponsabilityIsDisabled(true);
      setReceivedDateIsDisabled(true);
      setReleaseDateIsDisabled(true);
      setServiceOrderIsDisabled(true);
      setPartRequiredIsDisabled(true);
      setPartsOrderingDateIsDisabled(true);
      setPartsOrderNumberIsDisabled(true);
      setPartsDeliveryDateIsDisabled(true);
      setRepairStatusIsDisabled(true);
      setRepairDateIsDisabled(true);
      setCommentsIsDisabled(true);

      setRepairableEdit();
      setRepairResponsabilityEdit();
      setReceivedDateEdit();
      setReleaseDateEdit();
      setServiceOrderEdit();
      setPartsRequiredEdit();
      setPartsOrderingDateEdit();
      setPartsOrderNumberEdit();
      setPartsDeliveryDateEdit();
      setRepairStatusEdit();
      setRepairDateEdit();
      setCommentsEdit();
   };

   function handleAllowEditionRepairable() {
      setRepairableIsDisabled(!repairableIsDisabled);
   };

   function handleAllowEditionRepairResponsability() {
      setRepairResponsabilityIsDisabled(!repairResponsabilityIsDisabled);
   };

   function handleAllowEditionReceivedDate() {
      setReceivedDateIsDisabled(!receivedDateIsDisabled);
   };

   function handleAllowEditionReleaseDate() {
      setReleaseDateIsDisabled(!releaseDateIsDisabled);
   };

   function handleAllowEditionServiceOrder() {
      setServiceOrderIsDisabled(!serviceOrderIsDisabled);
   };

   function handleAllowEditionPartRequired() {
      setPartRequiredIsDisabled(!partRequiredIsDisabled);
   };

   function handleAllowEditionPartsOrderingDate() {
      setPartsOrderingDateIsDisabled(!partsOrderingDateIsDisabled);
   };

   function handleAllowEditionPartsOrderNumber() {
      setPartsOrderNumberIsDisabled(!partsOrderNumberIsDisabled);
   };

   function handleAllowEditionPartsDeliveryDate() {
      setPartsDeliveryDateIsDisabled(!partsDeliveryDateIsDisabled);
   };

   function handleAllowEditionRepairStatus() {
      setRepairStatusIsDisabled(!repairStatusIsDisabled);
   };

   function handleAllowEditionRepairDate() {
      setRepairDateIsDisabled(!repairDateIsDisabled);
   };

   function handleAllowEditionComments() {
      setCommentsIsDisabled(!commentsIsDisabled);
   };

   const onChangeReceivedDatePicker = (date) => {
      setStartDate(date);
      setReceivedDateEdit(moment(new Date(date)).format('YYYY-MM-DD'));
   };

   const onChangeReleaseDatePicker = (date) => {
      setStartDate(date);
      setReleaseDateEdit(moment(new Date(date)).format('YYYY-MM-DD'));
   };

   const onChangePartsOrderingDatePicker = (date) => {
      setStartDate(date);
      setPartsOrderingDateEdit(moment(new Date(date)).format('YYYY-MM-DD'));
   };

   const onChangePartsDeliveryDatePicker = (date) => {
      setStartDate(date);
      setPartsDeliveryDateEdit(moment(new Date(date)).format('YYYY-MM-DD'));
   };

   const onChangeRepairDatePicker = (date) => {
      setStartDate(date);
      setRepairDateEdit(moment(new Date(date)).format('YYYY-MM-DD'));
   };

   const CalendarCustomButtonReceivedDate = forwardRef(({ onClick }, ref) => {
      return (
         <button
            type="button"
            className='button-date-picker'
            onClick={onClick}
            ref={ref}
            disabled={receivedDateIsDisabled}
         >
            {receivedDateEdit === undefined ? chassiDetailsInfoAdjusted.repair_received_date : receivedDateEdit}
         </button>
      );
   });

   const CalendarCustomButtonReleaseDate = forwardRef(({ onClick }, ref) => {
      return (
         <button
            type="button"
            className='button-date-picker'
            onClick={onClick}
            ref={ref}
            disabled={releaseDateIsDisabled}
         >
            {releaseDateEdit === undefined ? chassiDetailsInfoAdjusted.estimated_release_date : releaseDateEdit}
         </button>
      );
   });

   const CalendarCustomButtonPartsOrderingDate = forwardRef(({ onClick }, ref) => {
      return (
         <button
            type="button"
            className='button-date-picker'
            onClick={onClick}
            ref={ref}
            disabled={partsOrderingDateIsDisabled}
         >
            {partsOrderingDateEdit === undefined ? chassiDetailsInfoAdjusted.parts_ordering_date : partsOrderingDateEdit}
         </button>
      );
   });
   const CalendarCustomButtonPartsDeliveryDate = forwardRef(({ onClick }, ref) => {
      return (
         <button
            type="button"
            className='button-date-picker'
            onClick={onClick}
            ref={ref}
            disabled={partsDeliveryDateIsDisabled}
         >
            {partsDeliveryDateEdit === undefined ? chassiDetailsInfoAdjusted.parts_delivery_date : partsDeliveryDateEdit}
         </button>
      );
   });

   const CalendarCustomButtonRepairDate = forwardRef(({ onClick }, ref) => {
      return (
         <button
            type="button"
            className='button-date-picker'
            onClick={onClick}
            ref={ref}
            disabled={repairDateIsDisabled}
         >
            {repairDateEdit === undefined ? chassiDetailsInfoAdjusted.repair_date : repairDateEdit}
         </button>
      );
   });

   return (
      <>
         <PageHeader
            backButton={true}
         />

         <ChassiDetailsHeader
            chassiNumber={chassiDetailsInfoAdjusted.chassi_number}
            modelName={chassiDetailsInfoAdjusted.model_name}
            modelYear={chassiDetailsInfoAdjusted.model_year}
            pod={chassiDetailsInfoAdjusted.pod}
            currentStatus={chassiDetailsInfoAdjusted.status}
         />

         <LinksNavigation />

         <div id="page-details-repair">
            {loadingScreen ?
               <Loading
                  type='balls'
               />
               :
               <div className="container">
                  <div className="container-left">
                     <ChassiHighlights
                        modelCode={chassiDetailsInfoAdjusted.model_code}
                        modelMaker={chassiDetailsInfoAdjusted.maker}
                        assemblyPlant={chassiDetailsInfoAdjusted.assembly_plant}
                        currentStatus={chassiDetailsInfoAdjusted.status}
                        aging={chassiDetailsInfoAdjusted.aging}
                     />
                  </div>

                  <div className="container-right">
                     <h2>Repair Info</h2>

                     <PerfectScrollbar
                        options={{ suppressScrollX: true, wheelPropagation: false, maxScrollbarLength: 20 }}
                        component='div'
                        className="form-scroll"
                     >
                        <div className="form">
                           <div className="form-left">
                              <div id="input-block">

                                 <div className="label-container">
                                    <label htmlFor='repairable'>Repairable</label>
                                    <button
                                       type='button'
                                       className='button-edit'
                                       onClick={handleAllowEditionRepairable}
                                    >
                                       <HiOutlinePencilAlt size={20} color='#5D5D5D' />
                                    </button>
                                 </div>

                                 <Select
                                    isSearchable={false}
                                    isClearable={false}
                                    styles={customSelectStyles}
                                    options={yesOrNoOptions}
                                    className='react-select-container'
                                    classNamePrefix="react-select"
                                    name="repairable"
                                    id="repairable"
                                    placeholder={repairableEdit === undefined ? chassiDetailsInfoAdjusted.repairable : repairableEdit}
                                    defaultValue={chassiDetailsInfoAdjusted.repairable}
                                    onChange={e => setRepairableEdit(e.value)}
                                    onBlur={() => handleChassiRepairDetailsUpdate()}
                                    isDisabled={repairableIsDisabled}
                                 />
                              </div>

                              <InputChassiDetails
                                 label='Repair Responsible'
                                 handleAllowEdition={handleAllowEditionRepairResponsability}
                                 value={chassiDetailsInfoAdjusted.repair_responsability}
                                 valueEdited={repairResponsabilityEdit}
                                 onChange={setRepairResponsabilityEdit}
                                 isDisabled={repairResponsabilityIsDisabled}
                                 onBlur={() => handleChassiRepairDetailsUpdate()}
                                 editable={true}
                              />

                              <div id="input-block">
                                 <div className='label-container'>
                                    <label htmlFor='inputChassiDetails'>Received Date</label>

                                    <button
                                       type='button'
                                       className='button-edit'
                                       onClick={handleAllowEditionReceivedDate}
                                    >
                                       <HiOutlinePencilAlt size={20} color='#5D5D5D' />
                                    </button>
                                 </div>
                                 <DatePicker
                                    selected={startDate}
                                    onChange={onChangeReceivedDatePicker}
                                    customInput={<CalendarCustomButtonReceivedDate />}
                                    showPopperArrow={false}
                                    onCalendarClose={() => handleChassiRepairDetailsUpdate()}
                                    todayButton='Today'
                                 />
                              </div>

                              <div id="input-block">
                                 <div className='label-container'>
                                    <label htmlFor='inputChassiDetails'>Estimated Release Date</label>

                                    <button
                                       type='button'
                                       className='button-edit'
                                       onClick={handleAllowEditionReleaseDate}
                                    >
                                       <HiOutlinePencilAlt size={20} color='#5D5D5D' />
                                    </button>
                                 </div>
                                 <DatePicker
                                    selected={startDate}
                                    onChange={onChangeReleaseDatePicker}
                                    customInput={<CalendarCustomButtonReleaseDate />}
                                    showPopperArrow={false}
                                    onCalendarClose={() => handleChassiRepairDetailsUpdate()}
                                    todayButton='Today'
                                 />
                              </div>

                              <InputChassiDetails
                                 label='Aging vs Repair Received'
                                 value={chassiDetailsInfoAdjusted.agingVsRepairReceived}
                                 valueEdited={undefined}
                                 isDisabled={true}
                                 editable={false}
                              />

                              <InputChassiDetails
                                 label='Service Order'
                                 handleAllowEdition={handleAllowEditionServiceOrder}
                                 value={chassiDetailsInfoAdjusted.service_order}
                                 valueEdited={serviceOrderEdit}
                                 onChange={setServiceOrderEdit}
                                 isDisabled={serviceOrderIsDisabled}
                                 onBlur={() => handleChassiRepairDetailsUpdate()}
                                 editable={true}
                              />

                           </div>

                           <div className="form-right">
                              <div id="input-block">

                                 <div className="label-container">
                                    <label htmlFor='parts-required'>Part Required</label>
                                    <button
                                       type='button'
                                       className='button-edit'
                                       onClick={handleAllowEditionPartRequired}
                                    >
                                       <HiOutlinePencilAlt size={20} color='#5D5D5D' />
                                    </button>
                                 </div>

                                 <Select
                                    isSearchable={false}
                                    isClearable={false}
                                    styles={customSelectStyles}
                                    options={yesOrNoOptions}
                                    className='react-select-container'
                                    classNamePrefix="react-select"
                                    name="parts-required"
                                    id="parts-required"
                                    placeholder={partsRequiredEdit === undefined ? chassiDetailsInfoAdjusted.part_required : partsRequiredEdit}
                                    defaultValue={chassiDetailsInfoAdjusted.part_required}
                                    onChange={e => setPartsRequiredEdit(e.value)}
                                    onBlur={() => handleChassiRepairDetailsUpdate()}
                                    isDisabled={partRequiredIsDisabled}
                                 />
                              </div>

                              <div id="input-block">
                                 <div className='label-container'>
                                    <label htmlFor='inputChassiDetails'>Parts Ordering Date</label>

                                    <button
                                       type='button'
                                       className='button-edit'
                                       onClick={handleAllowEditionPartsOrderingDate}
                                    >
                                       <HiOutlinePencilAlt size={20} color='#5D5D5D' />
                                    </button>
                                 </div>
                                 <DatePicker
                                    selected={startDate}
                                    onChange={onChangePartsOrderingDatePicker}
                                    customInput={<CalendarCustomButtonPartsOrderingDate />}
                                    showPopperArrow={false}
                                    onCalendarClose={() => handleChassiRepairDetailsUpdate()}
                                    todayButton='Today'
                                 />
                              </div>

                              <InputChassiDetails
                                 label='Parts Order #'
                                 handleAllowEdition={handleAllowEditionPartsOrderNumber}
                                 value={chassiDetailsInfoAdjusted.parts_order_number}
                                 valueEdited={partsOrderNumberEdit}
                                 onChange={setPartsOrderNumberEdit}
                                 isDisabled={partsOrderNumberIsDisabled}
                                 onBlur={() => handleChassiRepairDetailsUpdate()}
                                 editable={true}
                              />

                              <div id="input-block">
                                 <div className='label-container'>
                                    <label htmlFor='inputChassiDetails'>Parts Delivery ETA</label>

                                    <button
                                       type='button'
                                       className='button-edit'
                                       onClick={handleAllowEditionPartsDeliveryDate}
                                    >
                                       <HiOutlinePencilAlt size={20} color='#5D5D5D' />
                                    </button>
                                 </div>
                                 <DatePicker
                                    selected={startDate}
                                    onChange={onChangePartsDeliveryDatePicker}
                                    customInput={<CalendarCustomButtonPartsDeliveryDate />}
                                    showPopperArrow={false}
                                    onCalendarClose={() => handleChassiRepairDetailsUpdate()}
                                    todayButton='Today'
                                 />
                              </div>

                              <InputChassiDetails
                                 label='Repair Status'
                                 handleAllowEdition={handleAllowEditionRepairStatus}
                                 value={chassiDetailsInfoAdjusted.repair_status}
                                 valueEdited={repairStatusEdit}
                                 onChange={setRepairStatusEdit}
                                 isDisabled={repairStatusIsDisabled}
                                 onBlur={() => handleChassiRepairDetailsUpdate()}
                                 editable={true}
                              />

                              <div id="input-block">
                                 <div className='label-container'>
                                    <label htmlFor='inputChassiDetails'>Real Repaired Date</label>

                                    <button
                                       type='button'
                                       className='button-edit'
                                       onClick={handleAllowEditionRepairDate}
                                    >
                                       <HiOutlinePencilAlt size={20} color='#5D5D5D' />
                                    </button>
                                 </div>
                                 <DatePicker
                                    selected={startDate}
                                    onChange={onChangeRepairDatePicker}
                                    customInput={<CalendarCustomButtonRepairDate />}
                                    showPopperArrow={false}
                                    onCalendarClose={() => handleChassiRepairDetailsUpdate()}
                                    todayButton='Today'
                                 />
                              </div>

                              <div id="input-block">
                                 <div className='label-container'>
                                    <label htmlFor='textAreaChassiDetails'>Comments</label>

                                    <button
                                       type='button'
                                       className='button-edit'
                                       onClick={handleAllowEditionComments}
                                    >
                                       <HiOutlinePencilAlt size={20} color='#5D5D5D' />
                                    </button>
                                 </div>
                                 <textarea
                                    id='comment-input'
                                    rows='2'
                                    value={commentsEdit === undefined ? chassiDetailsInfoAdjusted.general_comments : commentsEdit}
                                    onChange={e => setCommentsEdit(e.target.value)}
                                    onBlur={() => handleChassiRepairDetailsUpdate()}
                                    disabled={commentsIsDisabled}
                                 />
                              </div>
                           </div>
                        </div>
                     </PerfectScrollbar>
                  </div>
               </div>
            }
         </div>
      </>
   );
};

export default ChassiDetailsRepair;
