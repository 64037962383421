import React, { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import crypto from 'crypto-js';

import PageHeader from '../../../components/PageHeader';
import Loading from '../../../components/Loading';
import Input from '../../../components/Input';

import api from '../../../services/api';
import { AllLettersUp } from '../../../utils/functions';

import '../../../styles/pages/app/settings/ModelEdit.css';

const ModelEdit = () => {
   const [loadingButton, setLoadingButton] = useState('default');
   const [loading, setLoading] = useState(true);

   const [modelCode, setModelCode] = useState('');
   const [modelName, setModelName] = useState('');
   const [modelMaker, setModelMaker] = useState('');
   const [modelWeight, setModelWeight] = useState('');
   const [modelVolume, setModelVolume] = useState(null);

   const { id } = useParams();
   const history = useHistory();

   const model_id = crypto.DES.decrypt(decodeURIComponent(id), 'idModel').toString(crypto.enc.Utf8);


   useEffect(() => {
      async function loadModelInfo() {
         const res = await api.get(`models/${model_id}`);

         setModelCode(res.data.model_code);
         setModelName(res.data.model_name);
         setModelMaker(res.data.maker);
         setModelWeight(res.data.model_weight);
         setModelVolume(res.data.model_volume);

         setLoading(false);
      };

      loadModelInfo();

   }, [model_id]);


   async function loadModelInfo() {
      const res = await api.get(`models/${model_id}`);

      setModelCode(res.data.model_code);
      setModelName(res.data.model_name);
      setModelMaker(res.data.maker);
      setModelWeight(res.data.model_weight);
      setModelVolume(res.data.model_volume);

      setLoading(false);
   };

   async function handleEditModel(e) {
      e.preventDefault();

      setLoadingButton('processing');

      const modelVolumeConfirmation = modelVolume === null || modelVolume === undefined || modelVolume === '' ? null : modelVolume;

      const data = { modelName, modelMaker, modelWeight, modelVolume: modelVolumeConfirmation };

      try {
         await api.put(`models/${model_id}`, data);

         loadModelInfo();

         setLoadingButton('default');

         history.goBack();

      } catch (e) {
         setLoadingButton('default');

         e.response.data.errors.forEach(error => toast.error(error));
      };
   };


   return (
      <div id="page-model-edit">
         <PageHeader settings={true} />

         <div className="container">
            {loading ?
               <Loading
                  type='balls'
               />
               :
               <div className="content">

                  <h1>Edit Model</h1>
                  <span>Here you can edit registered models.</span>

                  <main>
                     <form onSubmit={handleEditModel}>
                        <fieldset>

                           <div className="input-container">
                              <Input
                                 disabled
                                 name='modelCode'
                                 type='text'
                                 label='Model Code'
                                 value={AllLettersUp(modelCode)}
                                 onChange={e => setModelCode(e.target.value)}
                              />

                              <Input
                                 name='modelName'
                                 type='text'
                                 label='Name'
                                 value={AllLettersUp(modelName)}
                                 onChange={e => setModelName(e.target.value)}
                              />

                              <Input
                                 name='modelMaker'
                                 type='text'
                                 label='Maker'
                                 value={AllLettersUp(modelMaker)}
                                 onChange={e => setModelMaker(e.target.value)}
                              />
                           </div>

                           <div className="input-container">
                              <Input
                                 name='modelWeight'
                                 type='text'
                                 label='Weight (kg)'
                                 value={modelWeight}
                                 onChange={e => setModelWeight(e.target.value)}
                              />

                              <Input
                                 name='modelVolume'
                                 type='text'
                                 label='Volume (m³)'
                                 span='optional'
                                 value={modelVolume}
                                 onChange={e => setModelVolume(e.target.value)}
                              />
                           </div>

                           <div className="buttons-container">
                              <Link
                                 to='/app/settings/admin/models'
                                 className='cancel-button'
                              >
                                 Cancel
                              </Link>

                              {loadingButton === 'default' ? (
                                 <button
                                    type='submit'
                                    className='submit-button'
                                 >
                                    Save
                                 </button>
                              ) : (
                                 <button
                                    className='submit-button'
                                    style={{ background: '#4B81E8', color: '#FFF', border: 0 }}
                                 >
                                    <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                                    <span className="sr-only"></span>
                                 </button>
                              )
                              }
                           </div>

                        </fieldset>
                     </form>
                  </main>

               </div>
            }
         </div>
      </div>
   );
};

export default ModelEdit;
