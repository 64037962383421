import React, { useState, useEffect, useContext, forwardRef } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { HiOutlinePencilAlt } from 'react-icons/hi';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import crypto from 'crypto-js';

import PageHeader from '../../../components/PageHeader';
import Loading from '../../../components/Loading';
import ChassiDetailsHeader from '../../../components/ChassiDetails/ChassiDetailsHeader';
import LinksNavigation from '../../../components/ChassiDetails/LinksNavigation';
import ChassiHighlights from '../../../components/ChassiDetails/ChassiHighlights';
import InputChassiDetails from '../../../components/ChassiDetails/InputChassiDetails';

import api from '../../../services/api';
import { ChassiDetailsContext } from '../../../services/contexts/ChassiDetailsContext';

import '../../../styles/pages/app/ChassiDetails/PortTerminal.css';

const ChassiDetailsPortTerminal = () => {
   const [arrivalDateIsDisabled, setArrivalDateIsDisabled] = useState(true);
   const [folioIsDisabled, setFolioIsDisabled] = useState(true);
   const [parkingSlotIsDisabled, setParkingSlotIsDisabled] = useState(true);
   const [invoiceDateIsDisabled, setInvoiceDateIsDisabled] = useState(true);

   const [startDate, setStartDate] = useState(new Date());

   const [arrivalDateEdit, setArrivalDateEdit] = useState();
   const [folioDeIngresoEdit, setfolioDeIngresoEdit] = useState();
   const [parkingSlotEdit, setparkingSlotEdit] = useState();
   const [invoiceDateEdit, setInvoiceDateEdit] = useState();


   const { id } = useParams();
   const chassi_id = crypto.DES.decrypt(decodeURIComponent(id), 'idChassi').toString(crypto.enc.Utf8);

   const {
      loadingScreen,
      setChassiId,
      chassiDetailsInfoAdjusted,
      loadChassiDetails,
   } = useContext(ChassiDetailsContext);

   useEffect(() => {
      setChassiId(chassi_id);

   }, [setChassiId, chassi_id]);

   async function handleChassiPortTerminalDetailsUpdate() {
      const data = {
         arrivalDate: arrivalDateEdit === undefined ? chassiDetailsInfoAdjusted.yard_arrival_date : arrivalDateEdit,
         folioDeIngreso: folioDeIngresoEdit === undefined ? chassiDetailsInfoAdjusted.folio_de_ingreso : folioDeIngresoEdit,
         yardSlot: parkingSlotEdit === undefined ? chassiDetailsInfoAdjusted.yard_slot : parkingSlotEdit,
         invoiceDate: invoiceDateEdit === undefined ? chassiDetailsInfoAdjusted.invoice_issue_date : invoiceDateEdit,
      };

      try {
         const res = await api.put(`chassis/${chassi_id}/portTerminal`, data);

         await loadChassiDetails();

         toast.success(res.data.message);

      } catch (e) {
         e.response.data.errors.forEach(error => toast.error(error));
      }

      setArrivalDateIsDisabled(true);
      setFolioIsDisabled(true);
      setParkingSlotIsDisabled(true);
      setInvoiceDateIsDisabled(true);

      setArrivalDateEdit();
      setfolioDeIngresoEdit();
      setparkingSlotEdit();
      setInvoiceDateEdit();
   };

   function handleAllowEditionArrivalDate() {
      setArrivalDateIsDisabled(!arrivalDateIsDisabled);
   };

   function handleAllowEditionFolio() {
      setFolioIsDisabled(!folioIsDisabled);
   };

   function handleAllowEditionParkingSlot() {
      setParkingSlotIsDisabled(!parkingSlotIsDisabled);
   };

   function handleAllowEditionInvoiceDate() {
      setInvoiceDateIsDisabled(!invoiceDateIsDisabled);
   };

   const onChangeArrivalDatePicker = (date) => {
      setStartDate(date);
      setArrivalDateEdit(moment(new Date(date)).format('YYYY-MM-DD'));
   };

   const onChangeInvoiceDatePicker = (date) => {
      setStartDate(date);
      setInvoiceDateEdit(moment(new Date(date)).format('YYYY-MM-DD'));
   };

   const CalendarCustomButtonArrivalDate = forwardRef(({ onClick }, ref) => {
      return (
         <button
            type="button"
            className='button-date-picker'
            onClick={onClick}
            ref={ref}
            disabled={arrivalDateIsDisabled}
         >
            {arrivalDateEdit === undefined ? chassiDetailsInfoAdjusted.yard_arrival_date : arrivalDateEdit}
         </button>
      );
   });

   const CalendarCustomButtonInvoiceDate = forwardRef(({ onClick }, ref) => {
      return (
         <button
            type="button"
            className='button-date-picker'
            onClick={onClick}
            ref={ref}
            disabled={invoiceDateIsDisabled}
         >
            {invoiceDateEdit === undefined ? chassiDetailsInfoAdjusted.invoice_issue_date : invoiceDateEdit}
         </button>
      );
   });

   return (
      <>
         <PageHeader
            backButton={true}
         />

         <ChassiDetailsHeader
            chassiNumber={chassiDetailsInfoAdjusted.chassi_number}
            modelName={chassiDetailsInfoAdjusted.model_name}
            modelYear={chassiDetailsInfoAdjusted.model_year}
            pod={chassiDetailsInfoAdjusted.pod}
            currentStatus={chassiDetailsInfoAdjusted.status}
         />

         <LinksNavigation />

         <div id="page-details-portTerminal">
            {loadingScreen ?
               <Loading
                  type='balls'
               />
               :
               <div className="container">
                  <div className="container-left">
                     <ChassiHighlights
                        modelCode={chassiDetailsInfoAdjusted.model_code}
                        modelMaker={chassiDetailsInfoAdjusted.maker}
                        assemblyPlant={chassiDetailsInfoAdjusted.assembly_plant}
                        currentStatus={chassiDetailsInfoAdjusted.status}
                        aging={chassiDetailsInfoAdjusted.aging}
                     />
                  </div>

                  <div className="container-right">
                     <h2>Port Terminal Info</h2>

                     <div className="form">
                        <div className="form-left">

                           <div id="input-block">
                              <div className='label-container'>
                                 <label htmlFor='inputChassiDetails'>Arrival Date</label>

                                 <button
                                    type='button'
                                    className='button-edit'
                                    onClick={handleAllowEditionArrivalDate}
                                 >
                                    <HiOutlinePencilAlt size={20} color='#5D5D5D' />
                                 </button>
                              </div>
                              <DatePicker
                                 selected={startDate}
                                 onChange={onChangeArrivalDatePicker}
                                 customInput={<CalendarCustomButtonArrivalDate />}
                                 showPopperArrow={false}
                                 onCalendarClose={() => handleChassiPortTerminalDetailsUpdate()}
                                 todayButton='Today'
                              />
                           </div>


                           <InputChassiDetails
                              label={`"Folio de Ingreso"`}
                              handleAllowEdition={handleAllowEditionFolio}
                              value={chassiDetailsInfoAdjusted.folio_de_ingreso}
                              valueEdited={folioDeIngresoEdit}
                              onChange={setfolioDeIngresoEdit}
                              isDisabled={folioIsDisabled}
                              onBlur={() => handleChassiPortTerminalDetailsUpdate()}
                              editable={true}
                           />

                           <InputChassiDetails
                              label='Parking Slot'
                              handleAllowEdition={handleAllowEditionParkingSlot}
                              value={chassiDetailsInfoAdjusted.yard_slot}
                              valueEdited={parkingSlotEdit}
                              onChange={setparkingSlotEdit}
                              isDisabled={parkingSlotIsDisabled}
                              onBlur={() => handleChassiPortTerminalDetailsUpdate()}
                              editable={true}
                           />

                           <div id="input-block">
                              <div className='label-container'>
                                 <label htmlFor='inputChassiDetails'>Invoice Issue Date</label>

                                 <button
                                    type='button'
                                    className='button-edit'
                                    onClick={handleAllowEditionInvoiceDate}
                                 >
                                    <HiOutlinePencilAlt size={20} color='#5D5D5D' />
                                 </button>
                              </div>
                              <DatePicker
                                 selected={startDate}
                                 onChange={onChangeInvoiceDatePicker}
                                 customInput={<CalendarCustomButtonInvoiceDate />}
                                 showPopperArrow={false}
                                 onCalendarClose={() => handleChassiPortTerminalDetailsUpdate()}
                                 todayButton='Today'
                              />
                           </div>
                        </div>

                        <div className="form-right">
                           <InputChassiDetails
                              label='Storage fee > 15 DAYS'
                              value={chassiDetailsInfoAdjusted.storageFeeMore15Days}
                              valueEdited={undefined}
                              isDisabled={true}
                              editable={false}
                           />

                           <InputChassiDetails
                              label='16-45 Storages'
                              value={chassiDetailsInfoAdjusted.storageBetween16And45}
                              valueEdited={undefined}
                              isDisabled={true}
                              editable={false}
                           />

                           <InputChassiDetails
                              label='> 45 days'
                              value={chassiDetailsInfoAdjusted.storageMore45Days}
                              valueEdited={undefined}
                              isDisabled={true}
                              editable={false}
                           />

                        </div>
                     </div>
                  </div>
               </div>
            }
         </div>
      </>
   );
};

export default ChassiDetailsPortTerminal;
