import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import PageHeader from '../../../components/PageHeader';
import Input from '../../../components/Input';

import api from '../../../services/api';

import '../../../styles/pages/app/settings/ModelNew.css';

const ModelNew = () => {
   const [loadingButton, setLoadingButton] = useState('default');

   const [modelCode, setModelCode] = useState('');
   const [modelName, setModelName] = useState('');
   const [modelMaker, setModelMaker] = useState('');
   const [modelWeight, setModelWeight] = useState('');
   const [modelVolume, setModelVolume] = useState(null);

   const history = useHistory();

   async function handleRegisterNewModel(e) {
      e.preventDefault();

      setLoadingButton('processing');

      const data = { modelCode, modelName, modelMaker, modelWeight, modelVolume };

      try {
         const res = await api.post('models', data);

         toast.success(res.data.message);

         history.goBack();

      } catch (e) {
         setLoadingButton('default');

         e.response.data.errors.forEach(error => toast.error(error));
      }
   };

   return (
      <div id="page-model-new">
         <PageHeader settings={true} />

         <div className="container">
            <div className="content">

               <h1>New Model</h1>
               <span>Here you can register new models.</span>

               <main>
                  <form onSubmit={handleRegisterNewModel}>
                     <fieldset>

                        <div className="input-container">
                           <Input
                              name='modelCode'
                              type='text'
                              label='Model Code'
                              value={modelCode}
                              onChange={e => setModelCode(e.target.value)}
                           />

                           <Input
                              name='modelName'
                              type='text'
                              label='Name'
                              value={modelName}
                              onChange={e => setModelName(e.target.value)}
                           />

                           <Input
                              name='modelMaker'
                              type='text'
                              label='Maker'
                              value={modelMaker}
                              onChange={e => setModelMaker(e.target.value)}
                           />
                        </div>

                        <div className="input-container">
                           <Input
                              name='modelWeight'
                              type='text'
                              label='Weight (kg)'
                              value={modelWeight}
                              onChange={e => setModelWeight(e.target.value)}
                           />

                           <Input
                              name='modelVolume'
                              type='text'
                              label='Volume (m³)'
                              span='optional'
                              value={modelVolume}
                              onChange={e => setModelVolume(e.target.value)}
                           />
                        </div>

                        <div className="buttons-container">
                           <Link
                              to='/app/settings/admin/models'
                              className='cancel-button'
                           >
                              Cancel
                           </Link>

                           {loadingButton === 'default' ? (
                              <button
                                 type='submit'
                                 className='submit-button'
                              >
                                 Save
                              </button>
                           ) : (
                                 <button
                                    className='submit-button'
                                    style={{ background: '#4B81E8', color: '#FFF', border: 0 }}
                                 >
                                    <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                                    <span className="sr-only"></span>
                                 </button>
                              )
                           }
                        </div>

                     </fieldset>
                  </form>
               </main>

            </div>
         </div>
      </div>
   );
};

export default ModelNew;
