import React, { useState, useEffect, useContext, useRef } from 'react';
import { FiSearch } from 'react-icons/fi';
import { IoFunnelOutline, IoFunnel } from 'react-icons/io5';

import Input from '../Input';
import api from '../../services/api';
import { AllLetterDown } from '../../utils/functions';

import { RegisterContext } from '../../services/contexts/RegisterContext';

import '../../styles/components/DropdownFilter.css';

const DropdownFilterLoaded = (props) => {
   const [searchField, setSearchField] = useState('');
   const [loadedVehiclesDataSearch, setLoadedVehiclesDataSearch] = useState([]);

   const { setLoadingScreen } = useContext(RegisterContext);

   const optionsRef = useRef(null);


   useEffect(() => {
      async function loadLoadedVehicles() {

         const res = await api.get(`loaded?chassis=${AllLetterDown(searchField)}`);

         setLoadedVehiclesDataSearch(res.data);

         setLoadingScreen(false);
      }

      loadLoadedVehicles();

   }, [searchField, setLoadingScreen, props.label]);


   function handleLoadSearch(e) {
      e.preventDefault();

      setLoadingScreen(true);

      props.setSearch(searchField);

      props.setShowFilter(false);
   };

   const keyHandlerLoadSearch = (e) => {
      if (e.key === 'Enter' && !e.shiftKey) {
         e.preventDefault();

         handleLoadSearch(e);
      }
   };

   function handleLoadSearchSelect(e, value) {
      e.preventDefault();

      setLoadingScreen(true);

      props.setSearch(value);

      props.setShowFilter(false);
   };


   return (
      <div className="dropdown-filter">
         <div className={`${props.classContainer}-container`}>
            <label htmlFor={props.label}>{props.label}</label>

            <button
               type="button"
               className='button-filter'
               onClick={props.handleShowFilter}
            >
               {props.search !== '' ?
                  <IoFunnel size={20} color="#4B81E8" />
                  :
                  <IoFunnelOutline size={20} color="#5D5D5D" />
               }
            </button>


            <div ref={props.dropdownFilterRef} className='menu-select'>
               {props.showFilter &&
                  <div className="select-container-filter">
                     <h1>Data Filter ({props.label})</h1>

                     <div className="filter">
                        <Input
                           name='filter'
                           type='text'
                           value={searchField}
                           onChange={e => setSearchField(e.target.value)}
                           onKeyDown={e => keyHandlerLoadSearch(e)}
                           autoComplete="off"
                           autoFocus={true}

                        />
                        <button
                           type='button'
                           className="button-search"
                           onClick={handleLoadSearch}
                        >
                           <FiSearch size={20} color='#FFF' />
                        </button>
                     </div>
                     {searchField !== '' && props.label === 'VIN Number' &&
                        <div ref={optionsRef} className='menu-select-options'>
                           {loadedVehiclesDataSearch.map(data => {
                              return (
                                 <button
                                    className="options-container"
                                    key={data.chassi_id}
                                    onClick={e => handleLoadSearchSelect(e, data.chassi_number)}
                                 >
                                    <span>{data.chassi_number}</span>
                                 </button>
                              )
                           })}
                        </div>
                     }
                  </div>
               }
            </div>

         </div>
      </div>
   );
};

export default DropdownFilterLoaded;
