import React, { useState, useContext, forwardRef } from 'react';
import { toast } from 'react-toastify';
import { CSVLink } from 'react-csv';
import { HiOutlineCalendar } from 'react-icons/hi';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import PageHeader from '../../../components/PageHeader';
import Loading from '../../../components/Loading';

import api from '../../../services/api';
import { RegisterContext } from '../../../services/contexts/RegisterContext';

import '../../../styles/pages/app/reports/CapacidadSimplificada.css';

const CapacidadSimplificada = () => {
   const [loadingButton, setLoadingButton] = useState('default');

   const [dateRange, setDateRange] = useState([null, null]);
   const [startDate, endDate] = dateRange;

   const [capacidadSimplificadaData, setCapacidadSimplificadaData] = useState([]);

   const { loadingScreen } = useContext(RegisterContext);

   const initialDate = startDate && moment(startDate).format('YYYY-MM-DD');
   const finishDate = endDate && moment(endDate).format('YYYY-MM-DD');


   async function handleLoadDataCapacidadSimplificada() {
      setLoadingButton('processing');

      try {
         const { data } = await api.get(`reports/capacidadSimplificada?initialDate=${initialDate}&finishDate=${finishDate}`);

         setCapacidadSimplificadaData(data);

         setLoadingButton('default');

         if (data.length === 0) {
            toast.warning('No data from this range date selected.');
         }

      } catch (e) {
         setLoadingButton('default');

         e.response.data.errors.forEach(error => toast.error(error));
      }
   };

   const onChangeDatePicker = (dates) => {
      setDateRange(dates);
   };

   const CalendarCustomButton = forwardRef(({ onClick }, ref) => {
      return (
         <div className="date-input">
            <button
               type="button"
               className='button-shipment-date'
               onClick={onClick}
               ref={ref}
            >
               {!startDate
                  ?
                  <span className='placeholder'>from</span>
                  :
                  <span className='date-selected'>{initialDate}</span>
               }
            </button>

            <button
               type="button"
               className='button-shipment-date'
               onClick={onClick}
               ref={ref}
            >
               {!endDate
                  ?
                  <span className='placeholder'>to</span>
                  :
                  <span className='date-selected'>{finishDate}</span>
               }
            </button>
         </div>
      );
   });

   return (
      <>
         <PageHeader
            backButton={true}
         />

         <div id="page-capacidad-simplificada">
            <div className="container">
               {loadingScreen ?
                  <Loading
                     type='balls'
                  />
                  :
                  <>
                     <div className="header-title">
                        <h1>Capacidad Simplificada</h1>
                        {capacidadSimplificadaData.length === 0 ?
                           <span>Please enter the reference period to generate this report:</span>
                           :
                           <span>The data for the selected period has been processed!</span>
                        }
                     </div>

                     <main>
                        <div className="shipment-date-input-container">
                           <div className="label-container">
                              <label htmlFor="shipmentDate">Date Range</label>
                              <HiOutlineCalendar size={20} color="#5D5D5D" />
                           </div>

                           <DatePicker
                              startDate={startDate}
                              endDate={endDate}
                              customInput={<CalendarCustomButton />}
                              onChange={onChangeDatePicker}
                              monthsShown={2}
                              selectsRange={true}
                              todayButton='Today'
                              withPortal
                           />

                        </div>

                        {loadingButton === 'default' ? (
                           capacidadSimplificadaData.length === 0 ?
                              <button
                                 type="button"
                                 className='button-load'
                                 onClick={handleLoadDataCapacidadSimplificada}
                              >
                                 Load Data
                              </button>
                              :
                              <CSVLink
                                 filename={`${moment().format('YYYY-MM-DD HHmm')}-CapacidadSimplificada.csv`}
                                 className='button-submit'
                                 data={capacidadSimplificadaData}
                                 headers={[
                                    { label: "Date", key: "dateInput" },
                                    { label: "K Line Export Inventory", key: "quantities.kLineExport" },
                                    { label: "Truck Arrivals IPC", key: "quantities.truckIPC" },
                                    { label: "Truck Arrivals SS", key: "quantities.truckSS" },
                                    { label: "Vessel Departures IPC", key: "quantities.vesselIPC" },
                                    { label: "Vessel Departures SS", key: "quantities.vesselSS" },
                                    { label: "Final Export Inventory", key: "quantities.finalExpInventory" },
                                 ]}
                              >
                                 Download XLSX
                              </CSVLink>
                        ) : (
                           <button
                              className='button-load-disabled'
                              style={{ background: '#4B81E8', color: '#FFF', border: 0 }}
                              disabled
                           >
                              <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                              <span className="sr-only"></span>
                           </button>
                        )}

                     </main>
                  </>
               }
            </div>
         </div>
      </>
   );
};

export default CapacidadSimplificada;
