import React, { useState, useEffect, useCallback } from 'react';
import Chart from 'react-google-charts';

import LinksNavigationDashboard from '../../../components/dashboard/LinksNavigation';
import PageHeader from '../../../components/PageHeader';
import Loading from '../../../components/Loading';

import api from '../../../services/api';

import '../../../styles/pages/app/dashboard/ToBeReceived.css';

const ToBeReceived = () => {
   const [loading, setLoading] = useState(true);

   const [byTruckCompanyData, setByTruckCompanyData] = useState([]);
   const [pickUpMeanTimeData, setPickUpMeanTimeData] = useState('-');
   const [transitMeanTimeData, setTransitMeanTimeData] = useState('-');
   const [byAssemblyPlantData, setByAssemblyPlantData] = useState([]);


   const loadByTruckCompanyData = useCallback(async () => {
      const res = await api.get('dashboard/toBeReceived/byTruckCompany');

      let arrayAdjusted = [];
      const header = ['Truck Company', 'Quantity', { role: 'annotation' }];

      arrayAdjusted.push(header);

      res.data.map(item => {
         const data = [item.truckCompany, item.quantity, item.quantity];

         return arrayAdjusted.push(data);
      });

      setByTruckCompanyData(arrayAdjusted);

   }, []);

   const loadPickUpMeanTimeData = useCallback(async () => {
      const res = await api.get('dashboard/toBeReceived/pickUpMeanTime');

      setPickUpMeanTimeData(res.data);

   }, []);

   const loadTransitMeanTimeData = useCallback(async () => {
      const res = await api.get('dashboard/toBeReceived/transitMeanTime');

      setTransitMeanTimeData(res.data);

   }, []);

   const loadByAssemblyPlantData = useCallback(async () => {
      const res = await api.get('dashboard/toBeReceived/byAssemblyPlant');

      let arrayAdjusted = [];
      const header = ['Assembly Plant', 'Quantity', { role: 'annotation' }];

      arrayAdjusted.push(header);

      res.data.map(item => {
         const data = [item.plant, item.quantity, item.quantity];

         return arrayAdjusted.push(data);
      });

      setByAssemblyPlantData(arrayAdjusted);

   }, []);



   useEffect(() => {
      async function loadAllDatas() {
         await loadByTruckCompanyData();
         await loadPickUpMeanTimeData();
         await loadTransitMeanTimeData();
         await loadByAssemblyPlantData();

         setLoading(false);
      };

      loadAllDatas();

   }, [loadByTruckCompanyData, loadPickUpMeanTimeData, loadTransitMeanTimeData, loadByAssemblyPlantData]);

   return (
      <>
         <PageHeader
            backButton={true}
         />

         <LinksNavigationDashboard />

         <div id='page-to-be-received'>
            <div className="container">
               <div className="height">
                  {loading ?
                     <Loading />
                     :
                     <>
                        <h2>CURRENT FIGURES</h2>

                        <div className="chart-container-line">
                           <div className="chart-container-small">
                              <h3>By Truck Company</h3>

                              {byTruckCompanyData.length !== 0 &&
                                 <Chart
                                    chartType='BarChart'
                                    data={byTruckCompanyData}
                                    options={{
                                       width: 370,
                                       height: 210,
                                       bars: 'horizontal',
                                       bar: { groupWidth: 21 },
                                       legend: { position: 'none' },
                                       backgroundColor: {
                                          fill: 'transparent'
                                       },
                                       fontName: 'Montserrat',
                                       fontSize: 11,
                                       chartArea: {
                                          backgroundColor: 'transparent',
                                          left: 100,
                                          top: 10,
                                          right: 30,
                                          bottom: 20,
                                       },
                                       hAxis: {
                                          baseline: 'none',
                                          gridlines: {
                                             color: 'transparent',
                                          },
                                          textStyle: {
                                             color: 'transparent',
                                          }
                                       },
                                       colors: ['#4B81E8'],
                                       tooltip: {
                                          text: 'quantity',
                                          trigger: 'focus',
                                          textStyle: {
                                             color: '#000000',
                                             fontName: 'Montserrat',
                                             fontSize: 12,
                                          }
                                       },
                                    }}
                                 />
                              }
                           </div>

                           <div className='in-block'>
                              <div className='info-container'>
                                 <h3>Pick Up Mean Time (days)</h3>

                                 <span>{pickUpMeanTimeData ? pickUpMeanTimeData : '-'}</span>
                              </div>
                              <div className='info-container'>
                                 <h3>Transit Mean Time (days)</h3>

                                 <span>{transitMeanTimeData ? transitMeanTimeData : '-'}</span>
                              </div>
                           </div>

                           <div className='chart-container-small'>
                              <h3>By Assembly Plant</h3>

                              <Chart
                                 chartType='BarChart'
                                 data={byAssemblyPlantData}
                                 options={{
                                    width: 370,
                                    height: 210,
                                    bars: 'horizontal',
                                    bar: { groupWidth: 21 },
                                    legend: { position: 'none' },
                                    backgroundColor: {
                                       fill: 'transparent'
                                    },
                                    fontName: 'Montserrat',
                                    fontSize: 11,
                                    chartArea: {
                                       backgroundColor: 'transparent',
                                       left: 100,
                                       top: 10,
                                       right: 30,
                                       bottom: 20,
                                    },
                                    hAxis: {
                                       baseline: 'none',
                                       gridlines: {
                                          color: 'transparent',
                                       },
                                       textStyle: {
                                          color: 'transparent',
                                       }
                                    },
                                    colors: ['#4B81E8'],
                                    tooltip: {
                                       text: 'quantity',
                                       trigger: 'focus',
                                       textStyle: {
                                          color: '#000000',
                                          fontName: 'Montserrat',
                                          fontSize: 12,
                                       }
                                    },
                                 }}
                              />
                           </div>
                        </div>
                     </>
                  }
               </div>
            </div>
         </div>
      </>
   );
};

export default ToBeReceived;
