import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { HiOutlinePencilAlt } from 'react-icons/hi';
import Select from 'react-select';
import crypto from 'crypto-js';

import PageHeader from '../../../components/PageHeader';
import Loading from '../../../components/Loading';
import ChassiDetailsHeader from '../../../components/ChassiDetails/ChassiDetailsHeader';
import LinksNavigation from '../../../components/ChassiDetails/LinksNavigation';
import ChassiHighlights from '../../../components/ChassiDetails/ChassiHighlights';
import InputChassiDetails from '../../../components/ChassiDetails/InputChassiDetails';

import api from '../../../services/api';
import { ChassiDetailsContext } from '../../../services/contexts/ChassiDetailsContext';

import { customSelectStyles } from '../../../styles/components/ChassiDetails/SelectStyles';
import '../../../styles/pages/app/ChassiDetails/General.css';

const ChassiDetailsGeneral = () => {
   const [originRampIsDisabled, setOriginRampIsDisabled] = useState(true);
   const [assignCodeIsDisabled, setAssignCodeIsDisabled] = useState(true);
   const [dealerCodeIsDisabled, setDealerCodeIsDisabled] = useState(true);
   const [routeCodeIsDisabled, setRouteCodeIsDisabled] = useState(true);
   const [dealerNameIsDisabled, setDealerNameIsDisabled] = useState(true);
   const [locationCodeIsDisabled, setLocationCodeIsDisabled] = useState(true);
   const [demandAreaIsDisabled, setDemandAreaIsDisabled] = useState(true);
   const [podIsDisabled, setPodIsDisabled] = useState(true);
   const [orderNumberIsDisabled, setOrderNumberIsDisabled] = useState(true);
   const [holdReasonCodeIsDisabled, setHoldReasonCodeIsDisabled] = useState(true);
   const [holdRemarksIsDisabled, setHoldRemarksIsDisabled] = useState(true);

   const [podData, setPodData] = useState([]);

   const [originRampEdit, setOriginRampEdit] = useState();
   const [assignCodeEdit, setAssignCodeEdit] = useState();
   const [dealerCodeEdit, setDealerCodeEdit] = useState();
   const [routeCodeEdit, setRouteCodeEdit] = useState();
   const [dealerNameEdit, setDealerNameEdit] = useState();
   const [recintoCodeEdit, setRecintoCodeEdit] = useState();
   const [demandAreaEdit, setDemandAreaEdit] = useState();
   const [podEdit, setPodEdit] = useState();
   const [orderNumberEdit, setOrderNumberEdit] = useState();
   const [holdReasonCodeEdit, setHoldReasonCodeEdit] = useState();
   const [holdRemarksEdit, setHoldRemarksEdit] = useState();

   const [searchSelect, setSearchSelect] = useState('');

   const { id } = useParams();
   const chassi_id = crypto.DES.decrypt(decodeURIComponent(id), 'idChassi').toString(crypto.enc.Utf8);

   const {
      loadingScreen,
      setChassiId,
      chassiDetailsInfoAdjusted,
      loadChassiDetails,
   } = useContext(ChassiDetailsContext);

   useEffect(() => {
      setChassiId(chassi_id);

   }, [setChassiId, chassi_id]);

   useEffect(() => {
      async function loadPodData() {
         const { data } = await api.get('locate')

         setPodData(data);
      };

      loadPodData();
   }, []);

   const podOptions = podData.map(pod => {
      return { value: pod.code, label: pod.code };
   });

   async function handleChassiGeneralDetailsUpdate() {
      const data = {
         originRamp: originRampEdit === undefined ? chassiDetailsInfoAdjusted.origin_ramp : originRampEdit,
         assignCode: assignCodeEdit === undefined ? chassiDetailsInfoAdjusted.assign_code : assignCodeEdit,
         dealerCode: dealerCodeEdit === undefined ? chassiDetailsInfoAdjusted.dealer_code : dealerCodeEdit,
         routeCode: routeCodeEdit === undefined ? chassiDetailsInfoAdjusted.route_code : routeCodeEdit,
         dealerName: dealerNameEdit === undefined ? chassiDetailsInfoAdjusted.dealer_name : dealerNameEdit,
         recintoCode: recintoCodeEdit === undefined ? chassiDetailsInfoAdjusted.recinto_code : recintoCodeEdit,
         demandArea: demandAreaEdit === undefined ? chassiDetailsInfoAdjusted.demand_area : demandAreaEdit,
         portOfDestination: podEdit === undefined ? chassiDetailsInfoAdjusted.pod : podEdit,
         orderNumber: orderNumberEdit === undefined ? chassiDetailsInfoAdjusted.order_number : orderNumberEdit,
         holdReasonCode: holdReasonCodeEdit === undefined ? chassiDetailsInfoAdjusted.hold_reason_code : holdReasonCodeEdit,
         holdRemark: holdRemarksEdit === undefined ? chassiDetailsInfoAdjusted.hold_remark : holdRemarksEdit,
      };

      try {
         const res = await api.put(`chassis/${chassi_id}/general`, data);

         await loadChassiDetails();

         toast.success(res.data.message);

      } catch (e) {
         e.response.data.errors.forEach(error => toast.error(error));
      }

      setOriginRampIsDisabled(true);
      setAssignCodeIsDisabled(true);
      setDealerCodeIsDisabled(true);
      setRouteCodeIsDisabled(true);
      setDealerNameIsDisabled(true);
      setLocationCodeIsDisabled(true);
      setDemandAreaIsDisabled(true);
      setPodIsDisabled(true);
      setOrderNumberIsDisabled(true);
      setHoldReasonCodeIsDisabled(true);
      setHoldRemarksIsDisabled(true);

      setOriginRampEdit();
      setAssignCodeEdit();
      setDealerCodeEdit();
      setRouteCodeEdit();
      setDealerNameEdit();
      setRecintoCodeEdit();
      setDemandAreaEdit();
      setPodEdit();
      setOrderNumberEdit();
      setHoldReasonCodeEdit();
      setHoldRemarksEdit();
   };

   function handleAllowEditionOriginRamp() {
      setOriginRampIsDisabled(!originRampIsDisabled);
   };

   function handleAllowEditionAssignCode() {
      setAssignCodeIsDisabled(!assignCodeIsDisabled);
   };

   function handleAllowEditionDealerCode() {
      setDealerCodeIsDisabled(!dealerCodeIsDisabled);
   };

   function handleAllowEditionRouteCode() {
      setRouteCodeIsDisabled(!routeCodeIsDisabled);
   };

   function handleAllowEditionDealerName() {
      setDealerNameIsDisabled(!dealerNameIsDisabled);
   };

   function handleAllowEditionLocationCode() {
      setLocationCodeIsDisabled(!locationCodeIsDisabled);
   };

   function handleAllowEditionDemandArea() {
      setDemandAreaIsDisabled(!demandAreaIsDisabled);
   };

   function handleAllowEditionPod() {
      setPodIsDisabled(!podIsDisabled);
   };

   function handleAllowEditionOrderNumber() {
      setOrderNumberIsDisabled(!orderNumberIsDisabled);
   };

   function handleAllowEditionHoldReasonCode() {
      setHoldReasonCodeIsDisabled(!holdReasonCodeIsDisabled);
   };

   function handleAllowEditionHoldRemarks() {
      setHoldRemarksIsDisabled(!holdRemarksIsDisabled);
   };

   return (
      <>
         <PageHeader
            backButton={true}
         />

         <ChassiDetailsHeader
            chassiNumber={chassiDetailsInfoAdjusted.chassi_number}
            modelName={chassiDetailsInfoAdjusted.model_name}
            modelYear={chassiDetailsInfoAdjusted.model_year}
            pod={chassiDetailsInfoAdjusted.pod}
            currentStatus={chassiDetailsInfoAdjusted.status}
         />

         <LinksNavigation />

         <div id="page-details-general">
            {loadingScreen ?
               <Loading
                  type='balls'
               />
               :
               <div className="container">
                  <div className="container-left">
                     <ChassiHighlights
                        modelCode={chassiDetailsInfoAdjusted.model_code}
                        modelMaker={chassiDetailsInfoAdjusted.maker}
                        assemblyPlant={chassiDetailsInfoAdjusted.assembly_plant}
                        currentStatus={chassiDetailsInfoAdjusted.status}
                        aging={chassiDetailsInfoAdjusted.aging}
                     />
                  </div>

                  <div className="container-right">
                     <h2>Vehicle General Info</h2>

                     <div className="form">
                        <div className="form-left">

                           <InputChassiDetails
                              label='Origin Ramp'
                              handleAllowEdition={handleAllowEditionOriginRamp}
                              value={chassiDetailsInfoAdjusted.origin_ramp}
                              valueEdited={originRampEdit}
                              onChange={setOriginRampEdit}
                              isDisabled={originRampIsDisabled}
                              onBlur={() => handleChassiGeneralDetailsUpdate()}
                              editable={true}
                           />
                           <InputChassiDetails
                              label='Dealer Code'
                              handleAllowEdition={handleAllowEditionDealerCode}
                              value={chassiDetailsInfoAdjusted.dealer_code}
                              valueEdited={dealerCodeEdit}
                              onChange={setDealerCodeEdit}
                              isDisabled={dealerCodeIsDisabled}
                              onBlur={() => handleChassiGeneralDetailsUpdate()}
                              editable={true}
                           />

                           <InputChassiDetails
                              label='Dealer Name'
                              handleAllowEdition={handleAllowEditionDealerName}
                              value={chassiDetailsInfoAdjusted.dealer_name}
                              valueEdited={dealerNameEdit}
                              onChange={setDealerNameEdit}
                              isDisabled={dealerNameIsDisabled}
                              onBlur={() => handleChassiGeneralDetailsUpdate()}
                              editable={true}
                           />

                           <InputChassiDetails
                              label='Demand Area'
                              handleAllowEdition={handleAllowEditionDemandArea}
                              value={chassiDetailsInfoAdjusted.demand_area}
                              valueEdited={demandAreaEdit}
                              onChange={setDemandAreaEdit}
                              isDisabled={demandAreaIsDisabled}
                              onBlur={() => handleChassiGeneralDetailsUpdate()}
                              editable={true}
                           />

                           <div id="input-block">
                              <div className="label-container">
                                 <label htmlFor='pod'>POD</label>
                                 <button
                                    type='button'
                                    className='button-edit'
                                    onClick={handleAllowEditionPod}
                                 >
                                    <HiOutlinePencilAlt size={20} color='#5D5D5D' />
                                 </button>
                              </div>

                              <Select
                                 isSearchable={true}
                                 isClearable={false}
                                 backspaceRemovesValue={true}
                                 styles={customSelectStyles}
                                 options={podOptions}
                                 className='react-select-container'
                                 classNamePrefix="react-select"
                                 name="pod"
                                 id="pod"
                                 placeholder={chassiDetailsInfoAdjusted.pod}
                                 defaultValue={chassiDetailsInfoAdjusted.pod}
                                 onChange={e => setPodEdit(e.value)}
                                 defaultInputValue={searchSelect}
                                 onInputChange={e => setSearchSelect(e.value)}
                                 onFocus={e => setSearchSelect('')}
                                 isDisabled={podIsDisabled}
                                 onBlur={() => handleChassiGeneralDetailsUpdate()}
                              />
                           </div>

                           <InputChassiDetails
                              label='Order Number'
                              handleAllowEdition={handleAllowEditionOrderNumber}
                              value={chassiDetailsInfoAdjusted.order_number}
                              valueEdited={orderNumberEdit}
                              onChange={setOrderNumberEdit}
                              isDisabled={orderNumberIsDisabled}
                              onBlur={() => handleChassiGeneralDetailsUpdate()}
                              editable={true}
                           />
                        </div>

                        <div className="form-right">

                           <InputChassiDetails
                              label='Assign Code'
                              handleAllowEdition={handleAllowEditionAssignCode}
                              value={chassiDetailsInfoAdjusted.assign_code}
                              valueEdited={assignCodeEdit}
                              onChange={setAssignCodeEdit}
                              isDisabled={assignCodeIsDisabled}
                              onBlur={() => handleChassiGeneralDetailsUpdate()}
                              editable={true}
                           />

                           <InputChassiDetails
                              label='Route Code'
                              handleAllowEdition={handleAllowEditionRouteCode}
                              value={chassiDetailsInfoAdjusted.route_code}
                              valueEdited={routeCodeEdit}
                              onChange={setRouteCodeEdit}
                              isDisabled={routeCodeIsDisabled}
                              onBlur={() => handleChassiGeneralDetailsUpdate()}
                              editable={true}
                           />

                           <InputChassiDetails
                              label='Location Code'
                              handleAllowEdition={handleAllowEditionLocationCode}
                              value={chassiDetailsInfoAdjusted.recinto_code}
                              valueEdited={recintoCodeEdit}
                              onChange={setRecintoCodeEdit}
                              isDisabled={locationCodeIsDisabled}
                              onBlur={() => handleChassiGeneralDetailsUpdate()}
                              editable={true}
                           />

                           <InputChassiDetails
                              label='CDL Code'
                              value='AM'
                              valueEdited={undefined}
                              editable={false}
                              isDisabled={true}
                           />

                           <InputChassiDetails
                              label='SCAC Code'
                              value='KKLU'
                              valueEdited={undefined}
                              editable={false}
                              isDisabled={true}
                           />

                           <InputChassiDetails
                              label='Hold Reason Code'
                              handleAllowEdition={handleAllowEditionHoldReasonCode}
                              value={chassiDetailsInfoAdjusted.hold_reason_code}
                              valueEdited={holdReasonCodeEdit}
                              onChange={setHoldReasonCodeEdit}
                              isDisabled={holdReasonCodeIsDisabled}
                              onBlur={() => handleChassiGeneralDetailsUpdate()}
                              editable={true}
                           />

                           <InputChassiDetails
                              label='Hold Remarks'
                              handleAllowEdition={handleAllowEditionHoldRemarks}
                              value={chassiDetailsInfoAdjusted.hold_remark}
                              valueEdited={holdRemarksEdit}
                              onChange={setHoldRemarksEdit}
                              isDisabled={holdRemarksIsDisabled}
                              onBlur={() => handleChassiGeneralDetailsUpdate()}
                              editable={true}
                           />


                        </div>
                     </div>
                  </div>
               </div>
            }
         </div>
      </>
   );
};

export default ChassiDetailsGeneral;
