import React, { useState, useEffect, useContext, forwardRef } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { HiOutlinePencilAlt } from 'react-icons/hi';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import crypto from 'crypto-js';

import PageHeader from '../../../components/PageHeader';
import Loading from '../../../components/Loading';
import ChassiDetailsHeader from '../../../components/ChassiDetails/ChassiDetailsHeader';
import LinksNavigation from '../../../components/ChassiDetails/LinksNavigation';
import ChassiHighlights from '../../../components/ChassiDetails/ChassiHighlights';
import InputChassiDetails from '../../../components/ChassiDetails/InputChassiDetails';

import api from '../../../services/api';
import { ChassiDetailsContext } from '../../../services/contexts/ChassiDetailsContext';
import { RegisterContext } from '../../../services/contexts/RegisterContext';
import { AllLettersUp, AllFirstLettersUp } from '../../../utils/functions';

import { customSelectStyles } from '../../../styles/components/ChassiDetails/SelectStyles';
import '../../../styles/pages/app/ChassiDetails/SeaTransport.css';

const ChassiDetailsSeaTransport = () => {
   const [loadingDateIsDisabled, setLoadingDateIsDisabled] = useState(true);
   const [vesselVoyageIsDisabled, setVesselVoyageIsDisabled] = useState(true);

   const [startDate, setStartDate] = useState(new Date());

   const [loadingDateEdit, setLoadingDateEdit] = useState();
   const [vesselVoyageEdit, setVesselVoyageEdit] = useState();

   const { id } = useParams();
   const chassi_id = crypto.DES.decrypt(decodeURIComponent(id), 'idChassi').toString(crypto.enc.Utf8);

   const {
      loadingScreen,
      setChassiId,
      chassiDetailsInfoAdjusted,
      loadChassiDetails,
   } = useContext(ChassiDetailsContext);

   const { vesselVoyageData } = useContext(RegisterContext);

   const optionsVesselVoyage = vesselVoyageData.map(vessel => {
      return (
         { value: vessel.id, label: `${AllFirstLettersUp(vessel.vessel_name)} - ${AllLettersUp(vessel.voyage)}` }
      );
   });

   async function handleChassiSeaTranpDetailsUpdate() {
      const data = {
         loadingDate: loadingDateEdit === undefined ? chassiDetailsInfoAdjusted.loading_date_confirmation : loadingDateEdit,
         vesselId: vesselVoyageEdit === undefined ? chassiDetailsInfoAdjusted.vessel_id : vesselVoyageEdit,
      };

      try {
         const res = await api.put(`chassis/${chassi_id}/seaTransport`, data);

         await loadChassiDetails();

         toast.success(res.data.message);

      } catch (e) {
         e.response.data.errors.forEach(error => toast.error(error));
      }

      setLoadingDateIsDisabled(true);
      setVesselVoyageIsDisabled(true);

      setLoadingDateEdit();
      setVesselVoyageEdit();
   };


   useEffect(() => {
      setChassiId(chassi_id);

   }, [setChassiId, chassi_id]);

   function handleAllowEditionLoadingDate() {
      setLoadingDateIsDisabled(!loadingDateIsDisabled);
   };

   function handleAllowEditionVesselVoyage() {
      setVesselVoyageIsDisabled(!vesselVoyageIsDisabled);
   };

   const onChangeLoadingDatePicker = (date) => {
      setStartDate(date);
      setLoadingDateEdit(moment(new Date(date)).format('YYYY-MM-DD'));
   };

   const CalendarCustomButtonLoadingDate = forwardRef(({ onClick }, ref) => {
      return (
         <button
            type="button"
            className='button-date-picker'
            onClick={onClick}
            ref={ref}
            disabled={loadingDateIsDisabled}
         >
            {loadingDateEdit === undefined ? chassiDetailsInfoAdjusted.loading_date_confirmation : loadingDateEdit}
         </button>
      );
   });

   return (
      <>
         <PageHeader
            backButton={true}
         />

         <ChassiDetailsHeader
            chassiNumber={chassiDetailsInfoAdjusted.chassi_number}
            modelName={chassiDetailsInfoAdjusted.model_name}
            modelYear={chassiDetailsInfoAdjusted.model_year}
            pod={chassiDetailsInfoAdjusted.pod}
            currentStatus={chassiDetailsInfoAdjusted.status}
         />

         <LinksNavigation />

         <div id="page-details-seaTransport">
            {loadingScreen ?
               <Loading
                  type='balls'
               />
               :
               <div className="container">
                  <div className="container-left">
                     <ChassiHighlights
                        modelCode={chassiDetailsInfoAdjusted.model_code}
                        modelMaker={chassiDetailsInfoAdjusted.maker}
                        assemblyPlant={chassiDetailsInfoAdjusted.assembly_plant}
                        currentStatus={chassiDetailsInfoAdjusted.status}
                        aging={chassiDetailsInfoAdjusted.aging}
                     />
                  </div>

                  <div className="container-right">
                     <h2>Sea Transport Info</h2>

                     <div className="form">

                        <div id="input-block">
                           <div className='label-container'>
                              <label htmlFor='inputChassiDetails'>Loading Date</label>

                              <button
                                 type='button'
                                 className='button-edit'
                                 onClick={handleAllowEditionLoadingDate}
                              >
                                 <HiOutlinePencilAlt size={20} color='#5D5D5D' />
                              </button>
                           </div>
                           <DatePicker
                              selected={startDate}
                              onChange={onChangeLoadingDatePicker}
                              customInput={<CalendarCustomButtonLoadingDate />}
                              showPopperArrow={false}
                              onCalendarClose={() => handleChassiSeaTranpDetailsUpdate()}
                              todayButton='Today'
                           />
                        </div>

                        <InputChassiDetails
                           label='Ocean Carrier'
                           value={chassiDetailsInfoAdjusted.shipowner}
                           valueEdited={undefined}
                           isDisabled={true}
                           editable={false}
                        />

                        <div id="input-block">
                           <div className="label-container">
                              <label htmlFor='vesselVoyage'>Vessel & Voyage</label>
                              <button
                                 type='button'
                                 className='button-edit'
                                 onClick={handleAllowEditionVesselVoyage}
                              >
                                 <HiOutlinePencilAlt size={20} color='#5D5D5D' />
                              </button>
                           </div>

                           <Select
                              isSearchable={false}
                              isClearable={false}
                              styles={customSelectStyles}
                              options={optionsVesselVoyage}
                              className='react-select-container'
                              classNamePrefix="react-select"
                              name="vesselVoyage"
                              id="vesselVoyage"
                              placeholder={vesselVoyageEdit === undefined ? chassiDetailsInfoAdjusted.vessel_voyage : vesselVoyageEdit}
                              defaultValue={chassiDetailsInfoAdjusted.vessel_id}
                              onChange={e => setVesselVoyageEdit(e.value)}
                              onBlur={() => handleChassiSeaTranpDetailsUpdate()}
                              isDisabled={vesselVoyageIsDisabled}
                           />
                        </div>

                        <InputChassiDetails
                           label='Type of Shipping'
                           value={chassiDetailsInfoAdjusted.typeOfShipping}
                           valueEdited={undefined}
                           isDisabled={true}
                           editable={false}
                        />

                     </div>
                  </div>
               </div>
            }
         </div>
      </>
   );
};

export default ChassiDetailsSeaTransport;
