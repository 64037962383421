import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import crypto from 'crypto-js';
import NumberFormat from 'react-number-format';
import { BiPencil } from 'react-icons/bi';

import PageHeader from '../../../components/PageHeader';
import Loading from '../../../components/Loading';

import api from '../../../services/api';
import { AllFirstLettersUp } from '../../../utils/functions'

import '../../../styles/pages/app/settings/PdiServices.css';

const PdiServices = () => {
   const [loading, setLoading] = useState(true);
   const [pdiServicesData, setPdiServicesData] = useState([]);

   useEffect(() => {
      async function loadPdiServices() {
         const res = await api.get('pdiServices');

         setPdiServicesData(res.data);

         setLoading(false);
      };

      loadPdiServices();
   }, []);

   return (
      <div id="page-pdi">
         <PageHeader settings={true} />

         <div className="container">
            {loading ?
               <Loading
                  type='balls'
               />
               :
               <div className="content">

                  <div className="header">
                     <div className="header-titles">
                        <h1>PDI Services</h1>
                        <span>Here you can manage PDI services costs.</span>
                     </div>

                     <div className="button-container">
                        <Link to='/app/settings/admin' className='back-button'>
                           Back
                        </Link>
                     </div>
                  </div>

                  <div className="pdi-container">
                     <div className='label-container'>
                        <label className='label-service' htmlFor="pdi">PDI Service</label>
                        <label className='label-cost' htmlFor="cost">Cost per Unit</label>
                     </div>
                     {pdiServicesData.map(pdi => {
                        return (
                           <div key={pdi.id} className="pdi">
                              <div className='pdi-info'>
                                 <div className='pdi-service'>{AllFirstLettersUp(pdi.pdi_name)}</div>
                                 <NumberFormat
                                    value={pdi.unit_price}
                                    className='pdi-cost'
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    prefix={'$ '}
                                 />
                              </div>

                              <div className='pdi-buttons'>
                                 <Link className='icons' to={`/app/settings/admin/pdiServices/${encodeURIComponent(crypto.DES.encrypt(String(pdi.id), 'idPdi').toString())}`}>
                                    <BiPencil size={15} color='#747475' />
                                 </Link>
                              </div>
                           </div>
                        )
                     })}
                  </div>
               </div>
            }
         </div>
      </div>
   );
};

export default PdiServices;
