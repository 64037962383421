import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import crypto from 'crypto-js';
import { FaRegTrashAlt } from 'react-icons/fa';
import { BiPencil } from 'react-icons/bi';

import PageHeader from '../../../components/PageHeader';
import Loading from '../../../components/Loading';

import api from '../../../services/api';
import { AllLettersUp } from '../../../utils/functions'

import '../../../styles/pages/app/settings/Models.css';

const Models = () => {
   const [loading, setLoading] = useState(true);
   const [modelsData, setModelsData] = useState([]);

   useEffect(() => {
      async function loadModels() {
         const res = await api.get('models');

         setModelsData(res.data);

         setLoading(false);
      };

      loadModels();
   }, []);

   async function loadModels() {
      const res = await api.get('models');

      setModelsData(res.data);

      setLoading(false);
   };

   async function handleDeleteModel(e, modelId) {
      e.preventDefault();

      try {
         const res = await api.delete(`models/${modelId}`);

         toast.success(res.data.message);

         loadModels();

      } catch (e) {
         setLoading(false);

         e.response.data.errors.forEach(error => toast.error(error));
      };
   }

   return (
      <div id="page-models">
         <PageHeader settings={true} />

         <div className="container">
            {loading ?
               <Loading
                  type='balls'
               />
               :
               <div className="content">

                  <div className="header">
                     <div className="header-titles">
                        <h1>Manage Models</h1>
                        <span>Here you can manage models and their details.</span>
                     </div>

                     {modelsData.length !== 0 &&
                        <div className="button-container">
                           <Link className='add-new-button' to='/app/settings/admin/newModel'>
                              Add New
                           </Link>
                           <Link to='/app/settings/admin' className='back-button'>
                              Back
                           </Link>
                        </div>
                     }
                  </div>

                  {modelsData.length === 0 ? (
                     <div className="no-models-container">
                        <span>There is no models registered yet.
                     <br />
                        Click 'Add New' to register a new model.</span>

                        <div className="button-container">
                           <Link className='add-new-button' to='/app/settings/admin/newModel'>
                              Add New
                           </Link>
                           <Link to='/app/settings/admin' className='back-button'>
                              Back
                           </Link>
                        </div>
                     </div>
                  ) : (
                     <div className="models-container">
                        <div className='label-container'>
                           <label className='label-code' htmlFor="code">Model Code</label>
                           <label className='label-name' htmlFor="name">Name</label>
                           <label htmlFor="maker">Maker</label>
                        </div>
                        {modelsData.map(model => {
                           return (
                              <div key={model.id} className="model">
                                 <div className='model-info'>
                                    <div className='model-code'>{AllLettersUp(model.model_code)}</div>
                                    <div className='model-name'>{AllLettersUp(model.model_name)}</div>
                                    <div className='model-maker'>{AllLettersUp(model.maker)}</div>
                                 </div>

                                 <div className='model-buttons'>
                                    <Link className='icons' to={`/app/settings/admin/models/${encodeURIComponent(crypto.DES.encrypt(String(model.id), 'idModel').toString())}`}>
                                       <BiPencil size={15} color='#747475' />
                                    </Link>

                                    <button className='icons' onClick={e => handleDeleteModel(e, model.id)}>
                                       <FaRegTrashAlt size={15} color='#747475' />
                                    </button>
                                 </div>
                              </div>
                           )
                        })}
                     </div>
                  )}
               </div>
            }
         </div>
      </div>
   );
};

export default Models;
