import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { HiCog } from 'react-icons/hi';
import { SiGoogleanalytics } from 'react-icons/si';

import { useAuth } from '../services/contexts/auth';

import DropdownRegister from './dropdowns/DropdownRegister';
import DropdownReport from './dropdowns/DropdownReport';

import logo from '../assets/K_line_logo.svg';

import '../styles/components/PageHeader.css';

const PageHeader = ({ settings = false, backButton = false }) => {
   const [userLoggedIsAdmin, setUserLoggedIsAdmin] = useState(false);

   const { userAdmin, adminStorage } = useAuth();

   useEffect(() => {
      if (userAdmin) {
         setUserLoggedIsAdmin(userAdmin);

      } else {
         setUserLoggedIsAdmin(adminStorage);
      }

   }, [userAdmin, adminStorage]);


   return (
      <div id="page-header">
         <div className="container">
            <Link to='/app/onRoad'>
               <img src={logo} alt="K Line" />
            </Link>

            <div className="container-right">
               {!settings && !backButton ? (
                  <>
                     <DropdownRegister />

                     <DropdownReport />

                     <Link to="/app/dashboard/toBeReceived" className="dashboard-button">
                        <SiGoogleanalytics size={22} color='#4B81E8' />
                     </Link>

                     {userLoggedIsAdmin ? (
                        <Link to='/app/settings/admin'>
                           <HiCog size={25} />
                        </Link>
                     ) : (
                        < Link to='/app/settings'>
                           <HiCog size={25} />
                        </Link>
                     )
                     }
                  </>
               ) : !settings && backButton ? (
                  <div className="button-container">
                     <Link to='/app/onRoad' className='back-button'>
                        Back
                     </Link>
                  </div>
               ) : (
                  <div></div>
               )}
            </div>

         </div>


      </div >
   );
};

export default PageHeader;
