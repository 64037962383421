import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Switch from 'react-switch';

import PageHeader from '../../../components/PageHeader';
import Input from '../../../components/Input';

import api from '../../../services/api';
import { useAuth } from '../../../services/contexts/auth';

import '../../../styles/pages/app/settings/UserNew.css';

const UserNew = () => {
   const [loadingButton, setLoadingButton] = useState('default');
   const [name, setName] = useState('');
   const [email, setEmail] = useState('');
   const [isUserAdmin, setIsUserAdmin] = useState(false);

   const [userLoggedIsAdmin, setUserLoggedIsAdmin] = useState();

   const { userAdmin, adminStorage } = useAuth();

   const history = useHistory();

   useEffect(() => {
      if (userAdmin) {
         setUserLoggedIsAdmin(userAdmin);

      } else {
         setUserLoggedIsAdmin(adminStorage);
      }

   }, [userAdmin, adminStorage]);

   async function handleRegisterNewUser(e) {
      e.preventDefault();

      setLoadingButton('processing');

      const data = { name, email, admin: isUserAdmin };

      try {
         const res = await api.post('signup', data);

         toast.success(res.data.message);

         history.goBack();

      } catch (e) {
         setLoadingButton('default');

         e.response.data.errors.forEach(error => toast.error(error));
      }
   };

   function toggleSwitchAdmin() {
      setIsUserAdmin(!isUserAdmin);
   };

   return (
      <div id="page-user-new">
         <PageHeader settings={true} />

         <div className="container">
            <div className="content">

               <h1>New User</h1>
               <span>Here you can register new users and define access credentials.</span>

               <main>
                  <form onSubmit={handleRegisterNewUser}>
                     <fieldset>

                        <div className="input-container">
                           <Input
                              name='email'
                              type='email'
                              label='Email'
                              span='*'
                              placeholder='name@domain.com'
                              value={email}
                              onChange={e => setEmail(e.target.value)}
                           />

                           <Input
                              name='name'
                              type='text'
                              label='Name'
                              span='*'
                              placeholder='Full Name'
                              value={name}
                              onChange={e => setName(e.target.value)}
                           />
                        </div>

                        <div className="switch-container">
                           <Switch
                              checked={isUserAdmin}
                              onChange={toggleSwitchAdmin}
                              offColor='#B1B1B1'
                              onColor='#4B81E8'
                              offHandleColor='#4B81E8'
                              onHandleColor='#B1B1B1'
                              uncheckedIcon={false}
                              checkedIcon={false}
                              height={22}
                              width={42}
                           />
                           <span>Admin User</span>
                        </div>

                        <div className="buttons-container">
                           {userLoggedIsAdmin ? (
                              <Link
                                 to='/app/settings/admin/users'
                                 className='cancel-button'
                              >
                                 Cancel
                              </Link>
                           ) : (
                                 <Link
                                    to='/app/settings'
                                    className='cancel-button'
                                 >
                                    Cancel
                                 </Link>
                              )
                           }


                           {loadingButton === 'default' ? (
                              <button
                                 type='submit'
                                 className='submit-button'
                              >
                                 Send an Invite
                              </button>
                           ) : (
                                 <button
                                    className='submit-button'
                                    style={{ background: '#4B81E8', color: '#FFF', border: 0 }}
                                 >
                                    <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                                    <span className="sr-only"></span>
                                 </button>
                              )
                           }
                        </div>

                     </fieldset>
                  </form>
               </main>

            </div>
         </div>
      </div>
   );
};

export default UserNew;
