import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import Chart from 'react-google-charts';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { HiOutlineCalendar } from 'react-icons/hi';

import LinksNavigationDashboard from '../../../components/dashboard/LinksNavigation';
import PageHeader from '../../../components/PageHeader';
import Loading from '../../../components/Loading';

import api from '../../../services/api';

import '../../../styles/pages/app/dashboard/InStock.css';

const InStock = () => {
   const [loading, setLoading] = useState(true);

   const [startDate, setStartDate] = useState(new Date());

   const [totalInStock, setTotalInStock] = useState('');
   const [totalAvailable, setTotalAvailable] = useState('');
   const [totalOnHold, setTotalOnHold] = useState('');
   const [yardCapacity, setYardCapacity] = useState('');
   const [freeSlots, setFreeSlots] = useState('');
   const [agingAnalysis, setAgingAnalysis] = useState([]);

   const [notAssignedSelected, setNotAssignedSelected] = useState(true);
   const [IPCSelected, setIPCSelected] = useState(false);
   const [SSSelected, setSSSelected] = useState(false);
   const [isThereVesselId, setIsThereVesselId] = useState('false');
   const [dealerCode, setDealerCode] = useState();

   const dateSelected = startDate && moment(startDate).format('YYYY-MM-DD');
   const totalSlots = Number(3300).toLocaleString('en-US');


   const loadTotalInStockData = useCallback(async () => {
      const res = await api.get('dashboard/inStock/totalInStock');

      setTotalInStock(res.data);

   }, []);

   const loadTotalAvailableData = useCallback(async () => {
      const res = await api.get('dashboard/inStock/totalAvailable');

      setTotalAvailable(res.data);

   }, []);

   const loadTotalOnHoldData = useCallback(async () => {
      const res = await api.get('dashboard/inStock/totalOnHold');

      setTotalOnHold(res.data);

   }, []);

   const loadYardCapacityData = useCallback(async () => {
      const res = await api.get('dashboard/inStock/yardCapacity');

      setYardCapacity(res.data);

   }, []);

   const loadFreeSlotsData = useCallback(async () => {
      const res = await api.get('dashboard/inStock/freeSlots');

      setFreeSlots(res.data);

   }, []);


   const loadAgingAnalysisData = useCallback(async () => {
      const res = await api.get(`dashboard/inStock/agingAnalysis?dateSelected=${dateSelected}&vesselId=${isThereVesselId}&dealerCode=${dealerCode}`);

      let arrayAdjusted = [];
      const header = ['Range', 'Quantity', { role: 'annotation' }];

      arrayAdjusted.push(header);

      res.data.map(item => {
         const data = [item.range, item.quantity, item.quantity];

         return arrayAdjusted.push(data);
      });

      setAgingAnalysis(arrayAdjusted);

   }, [dateSelected, isThereVesselId, dealerCode]);

   useEffect(() => {
      async function loadAllDatas() {
         await loadTotalInStockData();
         await loadTotalAvailableData();
         await loadTotalOnHoldData();
         await loadYardCapacityData();
         await loadFreeSlotsData();
         await loadAgingAnalysisData();

         setLoading(false);
      };

      loadAllDatas();

   }, [loadTotalInStockData, loadTotalAvailableData, loadTotalOnHoldData, loadYardCapacityData, loadFreeSlotsData, loadAgingAnalysisData]);

   function handleAgingAnalysisNotAssigned() {
      setNotAssignedSelected(true);
      setIPCSelected(false);
      setSSSelected(false);

      setIsThereVesselId('false');
      setDealerCode();
   };

   function handleAgingAnalysisIPC() {
      setNotAssignedSelected(false);
      setIPCSelected(true);
      setSSSelected(false);

      setIsThereVesselId('true');
      setDealerCode('IPC');
   };

   function handleAgingAnalysisSS() {
      setNotAssignedSelected(false);
      setIPCSelected(false);
      setSSSelected(true);

      setIsThereVesselId('true');
      setDealerCode('SS');
   };


   const onChangeDatePicker = (date) => {
      setStartDate(date);
   };

   const CalendarCustomButton = forwardRef(({ onClick }, ref) => {
      return (
         <div className="date-input">
            <button
               type="button"
               className='button-date'
               onClick={onClick}
               ref={ref}
            >
               {!startDate
                  ?
                  <span className='placeholder'>Select a date</span>
                  :
                  <span className='date-selected'>{dateSelected}</span>
               }
            </button>
         </div>
      );
   });

   return (
      <>
         <PageHeader
            backButton={true}
         />

         <LinksNavigationDashboard />

         <div id='page-in-stock'>
            <div className="container">
               <div className="height">
                  {loading ?
                     <Loading />
                     :
                     <>
                        <div className="header">
                           <h2>FIGURES ABOUT:</h2>

                           <div className="date-container">
                              <div className="label-container">
                                 <label htmlFor="shipmentDate">Reference Date</label>
                                 <HiOutlineCalendar size={20} color="#5D5D5D" />
                              </div>

                              <DatePicker
                                 startDate={startDate}
                                 customInput={<CalendarCustomButton />}
                                 onChange={onChangeDatePicker}
                                 todayButton='Today'
                                 withPortal
                              />
                           </div>
                        </div>

                        <div className="chart-container-line">
                           <div className="chart-container-small">
                              <h3>Aging Analysis</h3>
                              <div className="chart-container">
                                 <button
                                    type='button'
                                    className={`btn-not-assigned ${notAssignedSelected ? 'selected' : ''}`}
                                    onClick={handleAgingAnalysisNotAssigned}
                                 >
                                    Not Assigned
                                 </button>
                                 <button
                                    type='button'
                                    className={`btn-ipc ${IPCSelected ? 'selected' : ''}`}
                                    onClick={handleAgingAnalysisIPC}
                                 >
                                    IPC
                                 </button>
                                 <button
                                    type='button'
                                    className={`btn-short-sea ${SSSelected ? 'selected' : ''}`}
                                    onClick={handleAgingAnalysisSS}
                                 >
                                    Short Sea
                                 </button>
                              </div>

                              <div className="chart-container">
                                 <Chart
                                    chartType='ColumnChart'
                                    data={agingAnalysis}
                                    options={{
                                       width: 435,
                                       height: 150,
                                       bars: 'horizontal',
                                       bar: { groupWidth: 35 },
                                       legend: { position: 'none' },
                                       backgroundColor: {
                                          fill: 'transparent'
                                       },
                                       fontName: 'Montserrat',
                                       fontSize: 11,
                                       chartArea: {
                                          backgroundColor: 'transparent',
                                          left: 0,
                                          top: 0,
                                          right: 0,
                                          bottom: 35,
                                       },
                                       hAxis: {
                                          baseline: 'none',
                                          gridlines: {
                                             color: 'transparent',
                                          },
                                          textStyle: {
                                             color: '#000',
                                             fontName: 'Montserrat',

                                          }
                                       },
                                       vAxis: {
                                          gridlines: {
                                             color: 'transparent',
                                          },
                                          textStyle: {
                                             color: '#5D5D5D',
                                             fontName: 'Montserrat',
                                          }
                                       },
                                       colors: ['#4B81E8'],
                                       tooltip: {
                                          text: 'quantity',
                                          trigger: 'focus',
                                          textStyle: {
                                             color: '#000000',
                                             fontName: 'Montserrat',
                                             fontSize: 12,
                                          }
                                       },
                                    }}
                                 />

                              </div>
                           </div>

                           <div className='in-block'>
                              <div className='info-container'>
                                 <h3>Total in Stock (units)</h3>

                                 <span>{totalInStock}</span>
                              </div>
                              <div className='info-container'>
                                 <h3>Yard Capacity (%)</h3>

                                 <span>{yardCapacity}</span>
                              </div>
                           </div>

                           <div className='in-block'>
                              <div className='info-container-small'>
                                 <span>{totalSlots}</span>

                                 <h3>Total Slots</h3>
                              </div>

                              <div className='info-container-small'>
                                 <span>{totalAvailable}</span>

                                 <h3>Ready</h3>
                              </div>

                              <div className='info-container-small'>
                                 <span>{totalOnHold}</span>

                                 <h3>On Hold</h3>
                              </div>

                              <div className='info-container-small'>
                                 <span>{freeSlots}</span>

                                 <h3>Free Slots</h3>
                              </div>
                           </div>

                        </div>
                     </>
                  }
               </div>
            </div>
         </div>
      </>
   );
};

export default InStock;
