import React, { useState, useEffect, createContext, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import api from '../api';
import { userKeyStorage, userTokenStorage, userIdStorage, userAdminStorage } from '../../utils/defaultValues';

const AuthContext = createContext({})

export function AuthProvider({ children }) {
   const [loading, setLoading] = useState(true);
   const [loadingButton, setLoadingButton] = useState('default');

   const [user, setUser] = useState({});
   const [userId, setUserId] = useState('');
   const [userAdmin, setUserAdmin] = useState(false);
   const [userStorage, setUserStorage] = useState({});
   const [idStorage, setIdStorage] = useState('');
   const [adminStorage, setAdminStorage] = useState(false);
   const [tokenValid, setTokenValid] = useState(false);

   const history = useHistory();

   useEffect(() => {
      async function loadStorageData() {
         const storageUser = localStorage.getItem(userKeyStorage);
         const storageToken = localStorage.getItem(userTokenStorage);
         const storageId = localStorage.getItem(userIdStorage);
         const storageAdmin = localStorage.getItem(userAdminStorage);

         if (storageUser && storageToken && storageId) {
            api.defaults.headers.Authorization = `bearer ${storageToken}`;

            setTokenValid(true);
            setIdStorage(Number(storageId));
            setUserStorage(JSON.parse(storageUser));
            setAdminStorage(storageAdmin === 'true' ? true : false);

            validateToken(storageToken);

            setLoading(false);

         } else {
            setLoading(false);
         }
      };

      loadStorageData();
   }, [])

   async function onSignin(email, password, token, passwordValid) {
      const data = { email, password, token, passwordValid };

      setLoadingButton('processing');

      try {
         const res = await api.post('signin', data);

         setUser(res.data);
         setUserId(res.data.id);
         setTokenValid(true);
         setUserAdmin(res.data.admin);

         api.defaults.headers.Authorization = `bearer ${res.data.tokenJwt}`;

         localStorage.setItem(userKeyStorage, JSON.stringify(res.data));
         localStorage.setItem(userTokenStorage, res.data.tokenJwt);
         localStorage.setItem(userIdStorage, JSON.stringify(res.data.id));
         localStorage.setItem(userAdminStorage, JSON.stringify(res.data.admin));

         history.push('/app/onRoad');

      } catch (e) {
         setLoadingButton('default');

         if (!e.response) {
            toast.error('We are having server problems, please contact the admin.');
         }

         e.response.data.errors.forEach(error => toast.error(error));

         setUser({});
         setUserId('');
         setTokenValid(false);
         setUserAdmin(false);

         localStorage.removeItem(userKeyStorage);
         localStorage.removeItem(userTokenStorage);
         localStorage.removeItem(userIdStorage);
         localStorage.removeItem(userAdminStorage);
      };
   };


   async function onSignOut() {
      setUser({});
      setUserId('');
      setTokenValid(false);
      setUserAdmin(false);

      setLoadingButton('default');

      localStorage.removeItem(userKeyStorage);
      localStorage.removeItem(userTokenStorage);
      localStorage.removeItem(userIdStorage);
      localStorage.removeItem(userAdminStorage);
   }

   async function validateToken(token) {
      if (token) {
         try {
            await api.post('validateToken', { token });

            setTokenValid(true);

         } catch (e) {
            toast.error(e.response.data);

            localStorage.removeItem(userKeyStorage);
            localStorage.removeItem(userTokenStorage);
            localStorage.removeItem(userIdStorage);
            localStorage.removeItem(userAdminStorage);

            setLoadingButton('default');

            setUser({});
            setUserId('');
            setTokenValid(false);
            setUserAdmin(false);

         }
      } else {
         localStorage.removeItem(userKeyStorage);
         localStorage.removeItem(userTokenStorage);
         localStorage.removeItem(userIdStorage);
         localStorage.removeItem(userAdminStorage);

         setLoadingButton('default');

         setUser({});
         setUserId('');
         setTokenValid(false);
         setUserAdmin(false);

      }
   };


   return (
      <AuthContext.Provider value={{
         tokenValid,
         user,
         userId,
         userAdmin,
         adminStorage,
         userStorage,
         idStorage,
         loading,
         loadingButton,
         onSignin,
         onSignOut,
      }}>
         {children}
      </AuthContext.Provider>
   )
}

export function useAuth() {
   const context = useContext(AuthContext);

   return context;
};
