import React, { useState } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { ImEye, ImEyeBlocked } from 'react-icons/im';

import Input from '../../components/Input';
import PageHeaderAuth from '../../components/PageHeaderAuth';
import { useAuth } from '../../services/contexts/auth';

import '../../styles/pages/auth/Login.css';

const Login = () => {
   const [email, setEmail] = useState('')
   const [password, setPassword] = useState('')

   const [seePassword, setSeePassword] = useState(false)

   const { loadingButton, onSignin } = useAuth()

   function useQuery() {
      return new URLSearchParams(useLocation().search)
   }

   const query = useQuery()
   const passwordValid = query.get("passwordValid")
   const { token } = useParams()

   function handleSignIn(e) {
      e.preventDefault()

      onSignin(email, password, token, passwordValid)

   }

   return (
      <div id="page-login">
         <PageHeaderAuth />
         <div className="red-line" />

         <div className='page-login-container'>

            <div className="content-container">
               <main>
                  <h1>KMEX / ALTAMIRA</h1>
                  <h2>TERMINAL CONTROL</h2>

                  <span>Log In</span>
                  <div className="line" />

                  <form onSubmit={handleSignIn}>
                     <fieldset>
                        <Input
                           name='email'
                           label='Email'
                           type='email'
                           placeholder='you@yourcompany.com'
                           value={email}
                           onChange={e => setEmail(e.target.value)}
                        />

                        <div className="input-container">
                           <Input
                              name='password'
                              label='Password'
                              type={seePassword ? 'text' : 'password'}
                              placeholder='Type your password'
                              value={password}
                              onChange={e => setPassword(e.target.value)}
                           />
                           {seePassword ? (
                              <ImEyeBlocked size={20} onClick={() => setSeePassword(false)} />
                           ) : (
                              <ImEye size={20} onClick={() => setSeePassword(true)} />
                           )}
                        </div>

                        <div className="buttons-container">
                           {loadingButton === 'default' ?
                              <button
                                 type='submit'
                                 className='enter-button'
                              >
                                 Enter
                              </button>
                              :
                              <button
                                 className='enter-button'
                                 style={{ background: '#4B81E8', color: '#FFF', border: 0 }}
                                 disabled
                              >
                                 <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                                 <span className="sr-only"></span>
                              </button>
                           }

                           <Link to='/forgotPassword' className='forgot-password-button'>
                              Forgot my password
                           </Link>
                        </div>

                     </fieldset>
                  </form>
               </main>
            </div>
         </div>


      </div>
   );
};

export default Login;
