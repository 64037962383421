import React, { useState, useEffect, useContext } from 'react';
import { Modal, ModalBody } from "reactstrap";
import Select from 'react-select';
import PerfectScrollbar from "react-perfect-scrollbar";

import api from '../../services/api';
import { RegisterContext } from '../../services/contexts/RegisterContext';
import { AllLettersUp, AllFirstLettersUp } from '../../utils/functions';

import { customSelectStyles } from '../../styles/components/ChassiDetails/SelectStyles';
import '../../styles/components/modals/ModalAddVesselVoyage.css';

const ModalAddVesselVoyage = (props) => {
   const [organizingData, setOrganizingData] = useState([]);

   const { vesselVoyageData } = useContext(RegisterContext);

   useEffect(() => {

      async function loadInfoData() {
         let organizingDataReceived = [];

         for (let i = 0; i < props.chassisSelected.length; i++) {
            const res = await api.get(`received/${Number(props.chassisSelected[i][0])}`);

            organizingDataReceived.push(res.data);
         }

         setOrganizingData(organizingDataReceived);
      }

      loadInfoData();

   }, [props.chassisSelected]);


   const optionsVesselVoyage = vesselVoyageData.map(vessel => {
      return (
         { value: vessel.id, label: `${AllFirstLettersUp(vessel.vessel_name)} - ${AllLettersUp(vessel.voyage)}` }
      );
   });

   return (
      <Modal
         isOpen={props.isOpen}
         toggle={props.toggle}
         id='modal-add-vessel'
         centered
         size='md'
      >
         <ModalBody className="justify-content-middle modal-body">
            <h1 className="mt-2">Add Vessel & Voyage</h1>
            <span>Here you can link a VIN to a Vessel / Voyage.</span>

            <div className="label-container">
               <label htmlFor="chassi" className="chassis-label">VIN Number</label>
               <label htmlFor="model" className="model-label">Model</label>
               <label htmlFor="pod" className="pod-label">POD</label>
            </div>

            <PerfectScrollbar
               options={{ suppressScrollX: true, wheelPropagation: false, maxScrollbarLength: 20 }}
               component='div'
               className="items-container"
            >
               {organizingData.map(item => {
                  return (
                     <div className="row" key={item.chassi_id}>
                        <span className="chassis">{item.chassi_number}</span>
                        <span className="model">{item.model_name}</span>
                        <span className="pod">{item.pod}</span>
                     </div>
                  )
               })}

            </PerfectScrollbar>

            <div className="vessel-input-container">
               <div className="input-block">
                  <label htmlFor='vesselVoyage'>Vessel & Voyage</label>
                  <Select
                     isSearchable={true}
                     isClearable={false}
                     backspaceRemovesValue={true}
                     styles={customSelectStyles}
                     options={optionsVesselVoyage}
                     className='react-select-container'
                     classNamePrefix="react-select"
                     name="vesselVoyage"
                     id="vesselVoyage"
                     placeholder={props.searchSelect === undefined && 'Type or select a registered Voyage'}
                     defaultValue={props.vesselId}
                     onChange={e => props.setVesselId(e.value)}
                     defaultInputValue={props.searchSelect}
                     onInputChange={e => props.setSearchSelect(e.value)}
                     onFocus={e => props.setSearchSelect('')}
                  />
               </div>
            </div>

            {props.loadingButton === 'default' ? (
               <div className="buttons-container">
                  <button
                     type="button"
                     className='button-cancel'
                     onClick={props.toggle}
                  >
                     Cancel
                  </button>

                  <button
                     type="button"
                     className={props.vesselId ? 'button-save' : 'button-save-disabled'}
                     onClick={props.handleChassisAddVessel}
                     disabled={props.vesselId ? false : true}

                  >
                     Save
                  </button>
               </div>
            ) : (
               <div className="buttons-container">
                  <button
                     type="button"
                     className='button-cancel'
                     disabled
                  >
                     Cancel
                  </button>

                  <button
                     className='button-save-disabled'
                     style={{ background: '#4B81E8', color: '#FFF', border: 0 }}
                     disabled
                  >
                     <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                     <span className="sr-only"></span>
                  </button>
               </div>
            )}

         </ModalBody>

      </Modal>
   );
};

export default ModalAddVesselVoyage;
