import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import Input from '../../components/Input';
import PageHeaderAuth from '../../components/PageHeaderAuth';

import api from '../../services/api';

import '../../styles/pages/auth/ForgotPassword.css';

const ForgotPassword = () => {
   const [loading, setLoading] = useState('default');

   const [email, setEmail] = useState('');

   const history = useHistory();

   async function handleForgotPassword(e) {
      e.preventDefault();

      setLoading('processing');

      const data = { email };

      try {
         const res = await api.post('forgotPassword', data);

         toast.success(res.data.message);

         history.push('/login');

      } catch (e) {
         setLoading('default');

         e.response.data.errors.forEach(error => toast.error(error));
      };
   };


   return (
      <div id="page-forgot-password">
         <PageHeaderAuth />
         <div className="red-line" />

         <div className='page-forgot-password-container'>

            <div className="content-container">
               <main>
                  <h1>KMEX / ALTAMIRA</h1>
                  <h2>TERMINAL CONTROL</h2>

                  <span>Forgot my Password</span>
                  <div className="line" />

                  <p>Type your email to receive a password recovery link</p>

                  <form onSubmit={handleForgotPassword}>
                     <fieldset>
                        <Input
                           name='email'
                           label='Email'
                           type='email'
                           placeholder='Type your email'
                           value={email}
                           onChange={e => setEmail(e.target.value)}
                        />

                        <div className="buttons-container">
                           {loading === 'default' ?
                              <button
                                 type='submit'
                                 className='enter-button'
                              >
                                 Send
                              </button>
                              :
                              <button
                                 className='enter-button'
                                 style={{ background: '#4B81E8', color: '#FFF', border: 0 }}
                                 disabled
                              >
                                 <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                                 <span className="sr-only"></span>
                              </button>
                           }

                           <Link to='/login' className='cancel-button'>
                              Cancel
                           </Link>
                        </div>

                     </fieldset>
                  </form>
               </main>
            </div>
         </div>


      </div>
   );
};

export default ForgotPassword;
