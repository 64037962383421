import React from 'react';
import { Link } from 'react-router-dom';
import { FiUser, FiTool } from 'react-icons/fi';
import { FaCar } from 'react-icons/fa';
import { IoBoatOutline } from 'react-icons/io5';
import { ImExit } from 'react-icons/im';

import { useAuth } from '../../../services/contexts/auth';

import PageHeader from '../../../components/PageHeader';

import '../../../styles/pages/app/settings/Settings.css';

const SettingsAdmin = () => {
   const { onSignOut } = useAuth();

   function handleSignOut(e) {
      e.preventDefault();

      onSignOut();
   }

   return (
      <div id="page-settings">
         <PageHeader settings={true} />

         <div className="container">
            <div className="content">
               <div className="header">
                  <div className="header-titles">
                     <h1>Settings</h1>
                     <span>Here you can manage system users, models, vessels & voyages and PDI services.</span>
                  </div>

                  <Link to='/app/onRoad' className='back-button'>
                     Back
                  </Link>
               </div>

               <Link className='settings-button' to='/app/settings/admin/users'>
                  <FiUser size={25} />
                  Users
               </Link>

               <Link className='settings-button' to='/app/settings/admin/models'>
                  <FaCar size={25} />
                  Models
               </Link>

               <Link className='settings-button' to='/app/settings/admin/vessels'>
                  <IoBoatOutline size={25} />
                  Vessel & Voyage
               </Link>

               <Link className='settings-button' to='/app/settings/admin/pdiServices'>
                  <FiTool size={25} />
                  PDI Services
               </Link>

               <div className="settings-button" onClick={handleSignOut}>
                  <ImExit size={25} />
                  Log Out
               </div>
            </div>
         </div>
      </div>
   );
};

export default SettingsAdmin;
