import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import crypto from 'crypto-js';
import { FaRegTrashAlt } from 'react-icons/fa';
import { BiPencil } from 'react-icons/bi';

import PageHeader from '../../../components/PageHeader';
import Loading from '../../../components/Loading';

import api from '../../../services/api';
import { AllFirstLettersUp, AllLettersUp } from '../../../utils/functions'

import '../../../styles/pages/app/settings/Vessels.css';

const Vessels = () => {
   const [loading, setLoading] = useState(true);
   const [vesselsData, setVesselsData] = useState([]);

   useEffect(() => {
      async function loadVessels() {
         const res = await api.get('vesselsVoyage');

         setVesselsData(res.data);

         setLoading(false);
      };

      loadVessels();
   }, []);

   async function loadVessels() {
      const res = await api.get('vesselsVoyage');

      setVesselsData(res.data);

      setLoading(false);
   };

   async function handleDeleteVessel(e, vesselId) {
      e.preventDefault();

      try {
         const res = await api.delete(`vesselsVoyage/${vesselId}`);

         toast.success(res.data.message);

         loadVessels();

      } catch (e) {
         setLoading(false);

         e.response.data.errors.forEach(error => toast.error(error));
      };
   }

   return (
      <div id="page-vessels">
         <PageHeader settings={true} />

         <div className="container">
            {loading ?
               <Loading
                  type='balls'
               />
               :
               <div className="content">

                  <div className="header">
                     <div className="header-titles">
                        <h1>Manage Vessels</h1>
                        <span>Here you can manage vessels & voyages and its details.</span>
                     </div>

                     {vesselsData.length !== 0 &&
                        <div className="button-container">
                           <Link className='add-new-button' to='/app/settings/admin/newVessel'>
                              Add New
                           </Link>
                           <Link to='/app/settings/admin' className='back-button'>
                              Back
                           </Link>
                        </div>
                     }
                  </div>

                  {vesselsData.length === 0 ? (
                     <div className="no-vessels-container">
                        <span>There is no voyages registered yet.
                     <br />
                        Click 'Add New' to register a new vessel & voyage.</span>

                        <div className="button-container">
                           <Link className='add-new-button' to='/app/settings/admin/newVessel'>
                              Add New
                        </Link>
                           <Link to='/app/settings/admin' className='back-button'>
                              Back
                           </Link>
                        </div>
                     </div>
                  ) : (
                     <div className="vessels-container">
                        <div className='label-container'>
                           <label className='label-vessel' htmlFor="vessel">Vessel</label>
                           <label className='label-voyage' htmlFor="voyage">Voyage</label>
                           <label htmlFor="shipowner">Ocean Carrier</label>
                        </div>
                        {vesselsData.map(vessel => {
                           return (
                              <div key={vessel.id} className="vessel">
                                 <div className='vessel-info'>
                                    <div className='vessel-name'>{AllFirstLettersUp(vessel.vessel_name)}</div>
                                    <div className='voyage'>{AllLettersUp(vessel.voyage)}</div>
                                    <div className='shipowner'>{AllLettersUp(vessel.shipowner)}</div>
                                 </div>

                                 <div className='vessel-buttons'>
                                    <Link className='icons' to={`/app/settings/admin/vessels/${encodeURIComponent(crypto.DES.encrypt(String(vessel.id), 'idVessel').toString())}`}>
                                       <BiPencil size={15} color='#747475' />
                                    </Link>

                                    <button className='icons' onClick={e => handleDeleteVessel(e, vessel.id)}>
                                       <FaRegTrashAlt size={15} color='#747475' />
                                    </button>
                                 </div>
                              </div>
                           )
                        })}
                     </div>
                  )}
               </div>
            }
         </div>
      </div>
   );
};

export default Vessels;
