import React, { useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { CSVLink } from 'react-csv';
import moment from 'moment';

import Loading from '../../../components/Loading';
import PageHeader from '../../../components/PageHeader';

import { RegisterContext } from '../../../services/contexts/RegisterContext';
import api from '../../../services/api';

import '../../../styles/pages/app/reports/GlobalDamageReport.css';

const GlobalDamageReport = () => {
   const [loadingButton, setLoadingButton] = useState('default');

   const [globalDamageData, setGlobalDamageData] = useState([]);

   const { loadingScreen } = useContext(RegisterContext);


   async function handleLoadDataToLoadingReport() {
      setLoadingButton('processing');

      try {
         const { data } = await api.get(`reports/globalDamageReport`);

         setGlobalDamageData(data);

         setLoadingButton('default');

      } catch (e) {
         setLoadingButton('default');

         e.response.data.errors.forEach(error => toast.error(error));
      }
   };


   return (
      <>
         <PageHeader
            backButton={true}
         />

         <div id="page-global-damage">
            <div className="container">
               {loadingScreen ?
                  <Loading
                     type='balls'
                  />
                  :
                  <>
                     <div className="header-title">
                        <h1>Global Damage Report</h1>
                        <span>This report show only damaged vehicles:</span>
                     </div>

                     <main>
                        {loadingButton === 'default' ? (
                           globalDamageData.length === 0 ?
                              <button
                                 type="button"
                                 className='button-load'
                                 onClick={handleLoadDataToLoadingReport}
                              >
                                 Load Data
                              </button>
                              :
                              <CSVLink
                                 filename={`${moment().format('YYYY-MM-DD HHmm')}-GlobalDamageReport.csv`}
                                 className='button-submit'
                                 data={globalDamageData}
                                 headers={[
                                    { label: "Yarn Received Date", key: "yardReceivedDate" },
                                    { label: "VIN", key: "chassiNumber" },
                                    { label: "Model Name", key: "modelName" },
                                    { label: "Status VTIMS", key: "statusVtims" },
                                    { label: "Status Dfyoung (ports only)", key: "statusDfyoung" },
                                    { label: "Country Destination", key: "podCountry" },
                                    { label: "Area", key: "areaCode" },
                                    { label: "Type", key: "typeCode" },
                                    { label: "Severity", key: "severityCode" },
                                    { label: "Damage Classification", key: "damageClassification" },
                                    { label: "Damage Description", key: "damageDescription" },
                                    { label: "Mode", key: "mode" },
                                    { label: "Carrier", key: "carrier" },
                                    { label: "Damage Responsible", key: "damageResponsability" },
                                    { label: "Repairable Yes / No (total loss)", key: "damageRepairable" },
                                    { label: "Repair Responsable", key: "repairResponsability" },
                                    { label: "Repair Received Date", key: "repairReceivedDate" },
                                    { label: "Estimated Release Date", key: "estimatedReleaseDate" },
                                    { label: "Aging vs Repair Received", key: "agingVsRepairReceived" },
                                    { label: "Service Order", key: "serviceOrder" },
                                    { label: "Part Required (Yes/No)", key: "partRequired" },
                                    { label: "Parts Ordering Date", key: "partsOrderingDate" },
                                    { label: "Parts Order Number", key: "partsOrderNumber" },
                                    { label: "Parts Delivery ETA", key: "partDeliveryEta" },
                                    { label: "Repair Status", key: "repairStatus" },
                                    { label: "Real Repaired Date", key: "realRepairDate" },
                                    { label: "Comments", key: "damageComments" },
                                 ]}
                              >
                                 Download XLSX
                              </CSVLink>
                        ) : (
                           <button
                              className='button-load-disabled'
                              style={{ background: '#4B81E8', color: '#FFF', border: 0 }}
                              disabled
                           >
                              <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                              <span className="sr-only"></span>
                           </button>
                        )}

                     </main>
                  </>
               }
            </div>
         </div>
      </>
   );
};

export default GlobalDamageReport;
