import React, { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import crypto from 'crypto-js';

import PageHeader from '../../../components/PageHeader';
import Loading from '../../../components/Loading';
import ChassiDetailsHeader from '../../../components/ChassiDetails/ChassiDetailsHeader';
import LinksNavigation from '../../../components/ChassiDetails/LinksNavigation';
import ChassiHighlights from '../../../components/ChassiDetails/ChassiHighlights';
import TrackingItems from '../../../components/TrackingItems';

import { ChassiDetailsContext } from '../../../services/contexts/ChassiDetailsContext';

import '../../../styles/pages/app/ChassiDetails/Tracking.css';

const ChassiDetailsTracking = () => {
   const { id } = useParams();
   const chassi_id = crypto.DES.decrypt(decodeURIComponent(id), 'idChassi').toString(crypto.enc.Utf8);

   const {
      loadingScreen,
      setChassiId,
      chassiDetailsInfoAdjusted
   } = useContext(ChassiDetailsContext);

   useEffect(() => {
      setChassiId(chassi_id);

   }, [setChassiId, chassi_id]);


   return (
      <>
         <PageHeader
            backButton={true}
         />

         <ChassiDetailsHeader
            chassiNumber={chassiDetailsInfoAdjusted.chassi_number}
            modelName={chassiDetailsInfoAdjusted.model_name}
            modelYear={chassiDetailsInfoAdjusted.model_year}
            pod={chassiDetailsInfoAdjusted.pod}
            currentStatus={chassiDetailsInfoAdjusted.status}
         />

         <LinksNavigation />

         <div id="page-details-tracking">
            {loadingScreen ?
               <Loading
                  type='balls'
               />
               :
               <div className="container">
                  <div className="container-left">
                     <ChassiHighlights
                        modelCode={chassiDetailsInfoAdjusted.model_code}
                        modelMaker={chassiDetailsInfoAdjusted.maker}
                        assemblyPlant={chassiDetailsInfoAdjusted.assembly_plant}
                        currentStatus={chassiDetailsInfoAdjusted.status}
                        aging={chassiDetailsInfoAdjusted.aging}
                     />
                  </div>

                  <div className="container-right">
                     <h2>Tracking</h2>

                     <div className="line"></div>
                     <TrackingItems
                        status='On Road'
                        statusExplanation='Picked up at Plant Yard'
                        date={chassiDetailsInfoAdjusted.onRoad_date}
                     />

                     <TrackingItems
                        status='Scheduled'
                        statusExplanation='‘Folio de Ingreso’ linked'
                        date={chassiDetailsInfoAdjusted.folio_date}
                     />

                     <TrackingItems
                        status='Received'
                        statusExplanation='Arrived at ATP Terminal'
                        date={chassiDetailsInfoAdjusted.yard_received_date}
                     />

                     <TrackingItems
                        status='Damaged'
                        statusExplanation='Unicar report uploded'
                        date={chassiDetailsInfoAdjusted.damage_date}
                     />

                     <TrackingItems
                        status='Repaired'
                        statusExplanation='Repair actual date'
                        date={chassiDetailsInfoAdjusted.real_repair_date}
                     />

                     <TrackingItems
                        status='Manifested'
                        statusExplanation='Ship Manifest uploaded'
                        date={chassiDetailsInfoAdjusted.manifest_date}
                     />

                     <TrackingItems
                        status='Loaded'
                        statusExplanation='Loading list uploaded'
                        date={chassiDetailsInfoAdjusted.loading_date}
                     />

                     <TrackingItems
                        status='Invoiced'
                        statusExplanation='Marked as invoiced'
                        date={chassiDetailsInfoAdjusted.invoice_date}
                     />

                  </div>
               </div>
            }
         </div>
      </>
   );
};

export default ChassiDetailsTracking;
