import React, { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Switch from 'react-switch';
import crypto from 'crypto-js';

import PageHeader from '../../../components/PageHeader';
import Loading from '../../../components/Loading';
import Input from '../../../components/Input';

import api from '../../../services/api';
import { useAuth } from '../../../services/contexts/auth';

import '../../../styles/pages/app/settings/UserEdit.css';

const UserEdit = () => {
   const [loadingButton, setLoadingButton] = useState('default');
   const [loading, setLoading] = useState(true);

   const [userLoggedIsAdmin, setUserLoggedIsAdmin] = useState();

   const [name, setName] = useState('');
   const [email, setEmail] = useState('');
   const [isUserAdmin, setIsUserAdmin] = useState();
   const [isUserBlocked, setIsUserBlocked] = useState();

   const { userAdmin, adminStorage } = useAuth();

   const { id } = useParams();
   const history = useHistory();

   const user_id = crypto.DES.decrypt(decodeURIComponent(id), 'idUser').toString(crypto.enc.Utf8);


   useEffect(() => {
      if (userAdmin) {
         setUserLoggedIsAdmin(userAdmin);

      } else {
         setUserLoggedIsAdmin(adminStorage);
      }

   }, [userAdmin, adminStorage]);

   useEffect(() => {
      async function loadUserInfo() {
         const res = await api.get(`users/${user_id}`);

         setName(res.data.name);
         setEmail(res.data.email);
         setIsUserAdmin(res.data.admin);
         setIsUserBlocked(res.data.blocked);

         setLoading(false);
      }

      loadUserInfo();

   }, [user_id]);


   async function loadUserInfo() {
      const res = await api.get(`users/${user_id}`);

      setName(res.data.name);
      setEmail(res.data.email);
      setIsUserAdmin(res.data.admin);
      setIsUserBlocked(res.data.blocked);

      setLoading(false);
   }

   async function handleEditUser(e) {
      e.preventDefault();

      setLoadingButton('processing');

      const data = { name, email, admin: isUserAdmin, blocked: isUserBlocked };

      try {
         await api.put(`users/${user_id}`, data);

         loadUserInfo();

         setLoadingButton('default');

         history.goBack();

      } catch (e) {
         setLoadingButton('default');

         e.response.data.errors.forEach(error => toast.error(error));
      };
   };

   async function handleResetPassword(e) {
      e.preventDefault();

      setLoading('processing');

      const data = { email };

      try {
         const res = await api.post('forgotPassword', data);

         toast.success(res.data.message);

         loadUserInfo();

      } catch (e) {
         setLoading('default');

         e.response.data.errors.forEach(error => toast.error(error));
      };
   };

   function toggleSwitchAdmin() {
      setIsUserAdmin(!isUserAdmin);
   };

   function toggleSwitchBlocked() {
      setIsUserBlocked(!isUserBlocked);
   };

   return (
      <div id="page-user-edit">
         <PageHeader settings={true} />

         <div className="container">
            {loading ?
               <Loading
                  type='balls'
               />
               :
               <div className="content">

                  <h1>Edit User</h1>
                  <span>Here you can edit registered users and define access credentials.</span>

                  <main>
                     <form onSubmit={handleEditUser}>
                        <fieldset>

                           <div className="input-container">
                              <Input
                                 name='email'
                                 type='email'
                                 label='Email'
                                 span='*'
                                 placeholder='name@domain.com'
                                 value={email}
                                 onChange={e => setEmail(e.target.value)}
                              />

                              <Input
                                 name='name'
                                 type='text'
                                 label='Name'
                                 span='*'
                                 placeholder='Full Name'
                                 value={name}
                                 onChange={e => setName(e.target.value)}
                              />

                              <button
                                 type='button'
                                 onClick={handleResetPassword}
                                 className='reset-password-button'
                              >
                                 Reset Password
                              </button>
                           </div>

                           {userLoggedIsAdmin && (
                              <div className="switch-container">
                                 <Switch
                                    checked={isUserBlocked}
                                    onChange={toggleSwitchBlocked}
                                    offColor='#B1B1B1'
                                    onColor='#96B3B1'
                                    offHandleColor='#96B3B1'
                                    onHandleColor='#B1B1B1'
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    height={22}
                                    width={42}
                                 />
                                 <span>Blocked</span>

                                 <Switch
                                    checked={isUserAdmin}
                                    onChange={toggleSwitchAdmin}
                                    offColor='#B1B1B1'
                                    onColor='#4B81E8'
                                    offHandleColor='#4B81E8'
                                    onHandleColor='#B1B1B1'
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    height={22}
                                    width={42}
                                 />
                                 <span>Admin User</span>
                              </div>
                           )}

                           <div className="buttons-container">
                              {userLoggedIsAdmin ? (
                                 <Link
                                    to='/app/settings/admin/users'
                                    className='cancel-button'
                                 >
                                    Cancel
                                 </Link>
                              ) : (
                                    <Link
                                       to='/app/settings'
                                       className='cancel-button'
                                    >
                                       Cancel
                                    </Link>
                                 )
                              }

                              {loadingButton === 'default' ? (
                                 <button
                                    type='submit'
                                    className='submit-button'
                                 >
                                    Save
                                 </button>
                              ) : (
                                    <button
                                       className='submit-button'
                                       style={{ background: '#4B81E8', color: '#FFF', border: 0 }}
                                    >
                                       <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                                       <span className="sr-only"></span>
                                    </button>
                                 )
                              }
                           </div>

                        </fieldset>
                     </form>
                  </main>

               </div>
            }
         </div>
      </div>
   );
};

export default UserEdit;
