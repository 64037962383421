import React from 'react';

import logo from '../assets/K_line_logo.svg';

import '../styles/components/PageHeaderAuth.css';

const PageHeaderAuth = () => {
   return (
      <div id="page-header-auth">
         <div className="container">
            <img src={logo} alt="K Line" />
         </div>
      </div>
   );
};

export default PageHeaderAuth;
