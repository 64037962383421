import React, { useContext } from 'react';
import { IoFunnelOutline, IoFunnel } from 'react-icons/io5';

import { RegisterContext } from '../../services/contexts/RegisterContext';

import '../../styles/components/DropdownFilter.css';

const DropdownFilterDamagedRepairable = (props) => {

   const { setLoadingScreen } = useContext(RegisterContext);

   function handleSearchButtonYes(e) {
      e.preventDefault();

      setLoadingScreen(true);

      props.setSearch(true);

      props.setShowFilter(false);
   };

   function handleSearchButtonNo(e) {
      e.preventDefault();

      setLoadingScreen(true);

      props.setSearch(false);

      props.setShowFilter(false);
   };


   return (
      <div className="dropdown-filter">
         <div className={`${props.classContainer}-container`}>
            <label htmlFor={props.label}>{props.label}</label>

            <button
               type="button"
               className='button-filter'
               onClick={props.handleShowFilter}
            >
               {props.search !== '' ?
                  <IoFunnel size={20} color="#4B81E8" />
                  :
                  <IoFunnelOutline size={20} color="#5D5D5D" />
               }
            </button>


            <div ref={props.dropdownFilterRef} className='menu-select'>
               {props.showFilter &&
                  <div className="select-container-filter">
                     <h1>Data Filter ({props.label})</h1>

                     <div className="filter">
                        <button
                           type='button'
                           className='yes-button'
                           onClick={handleSearchButtonYes}
                        >
                           Yes
                        </button>

                        <button
                           type='button'
                           className='no-button'
                           onClick={handleSearchButtonNo}
                        >
                           No
                        </button>
                     </div>

                  </div>
               }
            </div>

         </div>
      </div>
   );
};

export default DropdownFilterDamagedRepairable;
