import React from 'react';

import '../styles/components/TrackingItems.css';

const TrackingItems = (props) => {
   return (
      <div id="tracking-container">
         <div
            className="circle"
            style={props.date !== ''
               ? { border: '3px solid #6EE1B8' }
               : { border: '3px solid #D2D2D2' }
            }
         ></div>

         <div className="status-container">
            <span
               className='status'
               style={props.date !== ''
                  ? { color: '#5D5D5D' }
                  : { color: '#D2D2D2' }
               }
            >
               {props.status}
            </span>

            <span
               className='status-explanation'
               style={props.date !== ''
                  ? { color: '#5D5D5D' }
                  : { color: '#D2D2D2' }
               }
            >
               {props.statusExplanation}
            </span>
         </div>

         <div className="date-container">
            <span className='date'>{props.date}</span>
         </div>
      </div >
   );
};

export default TrackingItems;
