
export const customSelectStyles = {
   container: (provided, state) => ({
      ...provided,
      backgroundColor: state.isDisabled ? '#EDEFF5' : '#FFF',
   }),
   option: (provided, state) => ({
      ...provided,
      padding: 15,
      color: state.isSelected ? '#FFF' : '#000',
      backgroundColor: state.isSelected ? '#4B81E8' : '#FFF',
      borderColor: state.isSelected ? '#4B81E8' : '#FFF',
      borderWidth: 2,
      borderStyle: 'solid',
      "&:hover": {
         borderColor: state.isFocused ? '#4B81E8' : '#FFF',
         borderWidth: state.isFocused ? 2 : 2,
      },
   }),
   control: () => ({
      width: "100%",
   }),
   singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 1 : 1;
      const transition = 'opacity 300ms';
      const color = state.isDisabled ? '#404040' : '#404040';

      return { ...provided, opacity, transition, color };
   }
};

export const customReportSelectStyles = {
   container: (provided, state) => ({
      ...provided,
      backgroundColor: state.isDisabled ? '#E6E6F0' : '#FFF',
   }),
   option: (provided, state) => ({
      ...provided,
      padding: 15,
      color: state.isSelected ? '#FFF' : '#000',
      backgroundColor: state.isSelected ? '#4B81E8' : '#FFF',
      borderColor: state.isSelected ? '#4B81E8' : '#FFF',
      borderWidth: 2,
      borderStyle: 'solid',
      "&:hover": {
         borderColor: state.isFocused ? '#4B81E8' : '#FFF',
         borderWidth: state.isFocused ? 2 : 2,
      },
   }),
   control: () => ({
      width: "100%",
   }),
   singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 1 : 1;
      const transition = 'opacity 300ms';
      const color = state.isDisabled ? '#404040' : '#404040';

      return { ...provided, opacity, transition, color };
   }
};
