import React, { useState, useEffect, useCallback, createContext } from 'react';
import moment from 'moment';

import api from '../api';

export const ChassiDetailsContext = createContext({});


export function ChassiDetailsProvider({ children }) {
   const [loadingScreen, setLoadingScreen] = useState(true);
   const [loadingButton, setLoadingButton] = useState('default');

   const [chassiId, setChassiId] = useState();

   const [chassiDetailsInfo, setChassiDetailsInfo] = useState({});


   const loadChassiDetails = useCallback(async () => {
      if (chassiId !== undefined) {
         const { data } = await api.get(`chassis/${chassiId}`);

         setChassiDetailsInfo(data);

         setLoadingScreen(false);
      }
   }, [chassiId]);

   useEffect(() => {
      loadChassiDetails();

   }, [loadChassiDetails]);


   const chassiDetailsInfoAdjusted = {
      aging: chassiDetailsInfo.aging === null ? `${0} day(s)` : `${chassiDetailsInfo.aging} day(s)`,
      agingVsRepairReceived: chassiDetailsInfo.agingVsRepairReceived === null ? `${0} day(s)` : `${chassiDetailsInfo.agingVsRepairReceived} day(s)`,
      assembly_plant: chassiDetailsInfo.assembly_plant,
      assign_code: chassiDetailsInfo.assign_code,
      chassi_id: chassiDetailsInfo.chassi_id,
      chassi_number: chassiDetailsInfo.chassi_number,
      damage_area: chassiDetailsInfo.damage_area === null ? '-' : chassiDetailsInfo.damage_area,
      area_description: chassiDetailsInfo.area_description === null ? '-' : chassiDetailsInfo.area_description,
      damage_classification: chassiDetailsInfo.damage_classification === null ? '-' : chassiDetailsInfo.damage_classification,
      damage_date: chassiDetailsInfo.damage_date === null ? '' : moment(new Date(chassiDetailsInfo.damage_date)).format('ddd YYYY-MM-DD'),
      damage_responsability: chassiDetailsInfo.damage_responsability === null ? '-' : chassiDetailsInfo.damage_responsability,
      damage_severity: chassiDetailsInfo.damage_severity === null ? '-' : chassiDetailsInfo.damage_severity,
      severity_description: chassiDetailsInfo.severity_description === null ? '-' : chassiDetailsInfo.severity_description,
      damage_type: chassiDetailsInfo.damage_type === null ? '-' : chassiDetailsInfo.damage_type,
      type_description: chassiDetailsInfo.type_description === null ? '-' : chassiDetailsInfo.type_description,
      dealer_code: chassiDetailsInfo.dealer_code,
      dealer_name: chassiDetailsInfo.dealer_name,
      demand_area: chassiDetailsInfo.demand_area,
      hold_reason_code: chassiDetailsInfo.hold_reason_code === null ? '' : chassiDetailsInfo.hold_reason_code,
      hold_remark: chassiDetailsInfo.hold_remark === null ? '' : chassiDetailsInfo.hold_remark,
      estimated_release_date: chassiDetailsInfo.estimated_release_date === null ? '-' : moment(chassiDetailsInfo.estimated_release_date).format('YYYY-MM-DD'),
      folio_date: chassiDetailsInfo.folio_date === null ? '' : moment(new Date(chassiDetailsInfo.folio_date)).format('ddd YYYY-MM-DD'),
      folio_de_ingreso: chassiDetailsInfo.folio_de_ingreso === null ? '' : chassiDetailsInfo.folio_de_ingreso,
      general_comments: chassiDetailsInfo.general_comments === null ? '' : chassiDetailsInfo.general_comments,
      invoice_date: chassiDetailsInfo.invoice_date === null ? '' : moment(new Date(chassiDetailsInfo.invoice_date)).format('ddd YYYY-MM-DD'),
      invoice_issue_date: chassiDetailsInfo.invoice_date === null ? '' : moment(chassiDetailsInfo.invoice_date).format('YYYY-MM-DD'),
      loading_date: chassiDetailsInfo.loading_date === null ? '' : moment(chassiDetailsInfo.loading_date).format('ddd YYYY-MM-DD'),
      loading_date_confirmation: chassiDetailsInfo.loading_date === null ? '' : moment(chassiDetailsInfo.loading_date).format('YYYY-MM-DD'),
      maker: chassiDetailsInfo.maker,
      manifest_date: chassiDetailsInfo.manifest_date === null ? '' : moment(new Date(chassiDetailsInfo.manifest_date)).format('ddd YYYY-MM-DD'),
      mode: chassiDetailsInfo.mode === null ? '-' : chassiDetailsInfo.mode,
      model_code: chassiDetailsInfo.model_code,
      model_name: chassiDetailsInfo.model_name,
      model_year: chassiDetailsInfo.model_year,
      onRoad_date: moment(new Date(chassiDetailsInfo.onRoad_date)).format('ddd YYYY-MM-DD'),
      operator_license_id: chassiDetailsInfo.operator_license_id,
      operator_name: chassiDetailsInfo.operator_name,
      order_number: chassiDetailsInfo.order_number,
      origin_ramp: chassiDetailsInfo.origin_ramp,
      part_required: chassiDetailsInfo.part_required === null ? '-' : chassiDetailsInfo.part_required ? 'Yes' : 'No',
      parts_delivery_date: chassiDetailsInfo.parts_delivery_date === null ? '-' : moment(chassiDetailsInfo.parts_delivery_date).format('YYYY-MM-DD'),
      parts_order_number: chassiDetailsInfo.parts_order_number === null ? '-' : chassiDetailsInfo.parts_order_number,
      parts_ordering_date: chassiDetailsInfo.parts_ordering_date === null ? '-' : moment(chassiDetailsInfo.parts_ordering_date).format('YYYY-MM-DD'),
      pod: chassiDetailsInfo.pod,
      real_repair_date: chassiDetailsInfo.real_repair_date === null ? '' : moment(new Date(chassiDetailsInfo.real_repair_date)).format('ddd YYYY-MM-DD'),
      repair_date: chassiDetailsInfo.real_repair_date === null ? '' : moment(chassiDetailsInfo.real_repair_date).format('YYYY-MM-DD'),
      recinto_code: chassiDetailsInfo.recinto_code,
      repair_received_date: chassiDetailsInfo.repair_received_date === null ? '-' : moment(chassiDetailsInfo.repair_received_date).format('YYYY-MM-DD'),
      repair_responsability: chassiDetailsInfo.repair_responsability === null ? '-' : chassiDetailsInfo.repair_responsability,
      repair_status: chassiDetailsInfo.repair_status === null ? '-' : chassiDetailsInfo.repair_status,
      repairable: chassiDetailsInfo.repairable === null ? '-' : chassiDetailsInfo.repairable ? 'Yes' : 'No',
      route_code: chassiDetailsInfo.route_code,
      service_order: chassiDetailsInfo.service_order === null ? '-' : chassiDetailsInfo.service_order,
      shipment_date: chassiDetailsInfo.shipment_date === null ? '-' : moment(chassiDetailsInfo.shipment_date).format('YYYY-MM-DD'),
      shipowner: chassiDetailsInfo.shipowner === null ? '-' : chassiDetailsInfo.shipowner,
      status: chassiDetailsInfo.status,
      status_df_young: chassiDetailsInfo.status_df_young === null ? '-' : chassiDetailsInfo.status_df_young,
      status_vitms: chassiDetailsInfo.status_vitms === null ? '-' : chassiDetailsInfo.status_vitms,
      storageBetween16And45: chassiDetailsInfo.storageBetween16And45 === null ? `${0} day(s)` : `${chassiDetailsInfo.storageBetween16And45} day(s)`,
      storageFeeMore15Days: chassiDetailsInfo.storageFeeMore15Days === null ? `${0} day(s)` : `${chassiDetailsInfo.storageFeeMore15Days} day(s)`,
      storageMore45Days: chassiDetailsInfo.storageMore45Days === null ? `${0} day(s)` : `${chassiDetailsInfo.storageMore45Days} day(s)`,
      truck_company: chassiDetailsInfo.truck_company,
      truck_id: chassiDetailsInfo.truck_id,
      truck_license_plate: chassiDetailsInfo.truck_license_plate,
      typeOfShipping: chassiDetailsInfo.typeOfShipping,
      vessel_name: chassiDetailsInfo.vessel_name === null ? '-' : chassiDetailsInfo.vessel_name,
      vessel_voyage: chassiDetailsInfo.vessel_voyage === null ? '-' : chassiDetailsInfo.vessel_voyage,
      voyage: chassiDetailsInfo.voyage === null ? '-' : chassiDetailsInfo.voyage,
      yard_received_date: chassiDetailsInfo.yard_received_date === null ? '' : moment(new Date(chassiDetailsInfo.yard_received_date)).format('ddd YYYY-MM-DD'),
      yard_arrival_date: chassiDetailsInfo.yard_received_date === null ? '' : moment(chassiDetailsInfo.yard_received_date).format('YYYY-MM-DD'),
      yard_slot: chassiDetailsInfo.yard_slot === null ? '-' : chassiDetailsInfo.yard_slot,
      pdi_service: chassiDetailsInfo.pdi_service
   }

   return (
      <ChassiDetailsContext.Provider
         value={{
            loadingScreen,
            setLoadingScreen,
            loadingButton,
            setLoadingButton,

            chassiId,
            setChassiId,

            chassiDetailsInfoAdjusted,

            loadChassiDetails
         }}
      >
         {children}
      </ChassiDetailsContext.Provider>
   );
};
