import React, { useState, useContext, forwardRef } from 'react';
import { toast } from 'react-toastify';
import { CSVLink } from 'react-csv';
import { HiOutlineCalendar } from 'react-icons/hi';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import PageHeader from '../../../components/PageHeader';
import Loading from '../../../components/Loading';

import api from '../../../services/api';
import { RegisterContext } from '../../../services/contexts/RegisterContext';

import '../../../styles/pages/app/reports/IngresoDeMadrinas.css';

const IngresoDeMadrinas = () => {
   const [loadingButton, setLoadingButton] = useState('default');

   const [dateRange, setDateRange] = useState([null, null]);
   const [startDate, endDate] = dateRange;

   const [ingresoDeMadrinasData, setIngresoDeMadrinasData] = useState([]);

   const { loadingScreen } = useContext(RegisterContext);

   const initialShipmentDate = startDate && moment(startDate).format('YYYY-MM-DD');
   const finishShipmentDate = endDate && moment(endDate).format('YYYY-MM-DD');


   async function handleLoadDataIngresoDeMadrinas() {
      setLoadingButton('processing');

      try {
         const { data } = await api.get(`reports/ingresoDeMadrinas?initialDate=${initialShipmentDate}&finishDate=${finishShipmentDate}`);

         setIngresoDeMadrinasData(data);

         setLoadingButton('default');

         if (data.length === 0) {
            toast.warning('No data from this range date selected.');
         }

      } catch (e) {
         setLoadingButton('default');

         e.response.data.errors.forEach(error => toast.error(error));
      }
   };

   const onChangeDatePicker = (dates) => {
      setDateRange(dates);
   };

   const CalendarCustomButton = forwardRef(({ onClick }, ref) => {
      return (
         <div className="date-input">
            <button
               type="button"
               className='button-shipment-date'
               onClick={onClick}
               ref={ref}
            >
               {!startDate
                  ?
                  <span className='placeholder'>from</span>
                  :
                  <span className='date-selected'>{initialShipmentDate}</span>
               }
            </button>

            <button
               type="button"
               className='button-shipment-date'
               onClick={onClick}
               ref={ref}
            >
               {!endDate
                  ?
                  <span className='placeholder'>to</span>
                  :
                  <span className='date-selected'>{finishShipmentDate}</span>
               }
            </button>
         </div>
      );
   });

   return (
      <>
         <PageHeader
            backButton={true}
         />

         <div id="page-ingreso-madrinas">
            <div className="container">
               {loadingScreen ?
                  <Loading
                     type='balls'
                  />
                  :
                  <>
                     <div className="header-title">
                        <h1>Ingreso de Madrinas</h1>
                        {ingresoDeMadrinasData.length === 0 ?
                           <span>Please enter the reference period to generate this report:</span>
                           :
                           <span>The data for the selected period has been processed!</span>
                        }
                     </div>

                     <main>
                        <div className="shipment-date-input-container">
                           <div className="label-container">
                              <label htmlFor="shipmentDate">Shipment Date</label>
                              <HiOutlineCalendar size={20} color="#5D5D5D" />
                           </div>

                           <DatePicker
                              startDate={startDate}
                              endDate={endDate}
                              customInput={<CalendarCustomButton />}
                              onChange={onChangeDatePicker}
                              monthsShown={2}
                              selectsRange={true}
                              todayButton='Today'
                              withPortal
                           />

                        </div>

                        {loadingButton === 'default' ? (
                           ingresoDeMadrinasData.length === 0 ?
                              <button
                                 type="button"
                                 className='button-load'
                                 onClick={handleLoadDataIngresoDeMadrinas}
                              >
                                 Load Data
                              </button>
                              :
                              <CSVLink
                                 filename={`${moment().format('YYYY-MM-DD HHmm')}-IngresoDeMadrinas.csv`}
                                 className='button-submit'
                                 data={ingresoDeMadrinasData}
                                 headers={[
                                    { label: "Fecha", key: "shipmentDate" },
                                    { label: "Truck ID", key: "truckId" },
                                    { label: "Operator Name", key: "operatorName" },
                                    { label: "Operator License", key: "operatorLicense" },
                                    { label: "Placas", key: "truckPlate" },
                                    { label: "Weight", key: "modelWeight" },
                                    { label: "VIN", key: "chassiNumber" },
                                    { label: "Origin Ramp", key: "originRamp" },
                                    { label: "Model Name", key: "modelName" },
                                    { label: "Model Year", key: "modelYear" },
                                    { label: "Dealer", key: "dealerCode" },
                                    { label: "POD", key: "pod" },
                                    { label: "Final Destination", key: "finalDestination" },
                                    { label: "Assing", key: "assingCode" },
                                    { label: "Route", key: "routeCode" },
                                    { label: "Location", key: "recintoCode" },
                                    { label: "GM Data", key: "" },
                                    { label: "Folio de Ingreso", key: "folio" },
                                 ]}
                              >
                                 Download XLSX
                              </CSVLink>
                        ) : (
                           <button
                              className='button-load-disabled'
                              style={{ background: '#4B81E8', color: '#FFF', border: 0 }}
                              disabled
                           >
                              <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                              <span className="sr-only"></span>
                           </button>
                        )}

                     </main>
                  </>
               }
            </div>
         </div>
      </>
   );
};

export default IngresoDeMadrinas;
