import React, { useState, useContext } from 'react';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { CSVLink } from "react-csv";

import PageHeader from '../../../components/PageHeader';
import Loading from '../../../components/Loading';

import { RegisterContext } from '../../../services/contexts/RegisterContext';
import { AllLettersUp, AllFirstLettersUp } from '../../../utils/functions';

import { customReportSelectStyles } from '../../../styles/components/ChassiDetails/SelectStyles';
import '../../../styles/pages/app/reports/Tarja2.css';
import api from '../../../services/api';

const Tarja2 = () => {
   const [loadingButton, setLoadingButton] = useState('default');

   const [vesselId, setVesselId] = useState();
   const [searchSelect, setSearchSelect] = useState();
   const [selectDisabled, setSelectDisabled] = useState(false);

   const [tarja2Data, setTarja2Data] = useState([]);
   const [bookingTotal, setBookingTotal] = useState(0);

   const { loadingScreen, vesselVoyageData } = useContext(RegisterContext);

   const optionsVesselVoyage = vesselVoyageData.map(vessel => {
      return (
         { value: vessel.id, label: `${AllFirstLettersUp(vessel.vessel_name)} - ${AllLettersUp(vessel.voyage)}` }
      );
   });


   async function handleLoadDataTarja2() {
      setLoadingButton('processing');

      try {
         const { data } = await api.get(`reports/tarja2?vesselId=${vesselId}`);

         setTarja2Data(data.reportAdjusted);
         setBookingTotal(data.bookingQuantity);

         if (data.reportAdjusted.length === 0) {
            setLoadingButton('default');

            setVesselId();

            return toast.warning('No data from vessel/voyage selected.')
         }

         setSelectDisabled(true);
         setVesselId();
         setLoadingButton('default');

      } catch (e) {
         setLoadingButton('default');
         setSelectDisabled(false);

         e.response.data.errors.forEach(error => toast.error(error));
      }
   };


   return (
      <>
         <PageHeader
            backButton={true}
         />

         <div id="page-tarja2">
            <div className="container">
               {loadingScreen ?
                  <Loading
                     type='balls'
                  />
                  :
                  <>
                     <div className="header-title">
                        <h1>Tarja 2</h1>
                        {tarja2Data.length === 0 ?
                           <span>Please set the parameters to generate this report:</span>
                           :
                           <span>The data linked to the selected voyage has been processed!</span>
                        }
                     </div>

                     <main>
                        <div className="vessel-input-container">
                           <div className="input-block">
                              <label htmlFor='vesselVoyage'>Vessel & Voyage</label>
                              <Select
                                 isSearchable={true}
                                 isClearable={false}
                                 backspaceRemovesValue={true}
                                 styles={customReportSelectStyles}
                                 options={optionsVesselVoyage}
                                 className='react-select-container'
                                 classNamePrefix="react-select"
                                 name="vesselVoyage"
                                 id="vesselVoyage"
                                 placeholder={searchSelect === undefined ? 'Type or select a registered Voyage' : ''}
                                 defaultValue={vesselId}
                                 onChange={e => setVesselId(e.value)}
                                 defaultInputValue={searchSelect}
                                 onInputChange={e => setSearchSelect(e.value)}
                                 onFocus={() => setSearchSelect('')}
                                 isDisabled={selectDisabled}
                              />
                           </div>
                        </div>

                        {loadingButton === 'default' ? (
                           tarja2Data.length === 0 ?
                              <button
                                 type="button"
                                 className={vesselId ? 'button-load' : 'button-load-disabled'}
                                 onClick={handleLoadDataTarja2}
                                 disabled={vesselId ? false : true}
                              >
                                 Load Data
                              </button>
                              :
                              <CSVLink
                                 filename={`Bookings ${bookingTotal} units - Tarja2.csv`}
                                 className='button-submit'
                                 data={tarja2Data}
                                 headers={[
                                    { label: "BUQUE / VIAJE", key: "vessel_voyage" },
                                    { label: "POD", key: "pod" },
                                    { label: "BULTOS", key: "bultos" },
                                    { label: "VIN", key: "chassiNumber" },
                                    { label: "EMBALAJE", key: "embalaje" },
                                    { label: "CONTENIDO", key: "model_name" },
                                    { label: "PESO", key: "model_weight" },
                                    { label: "FOLIO", key: "folio" },
                                    { label: "FECHA RECIBO", key: "arrival_date" },
                                 ]}
                              >
                                 Download XLSX
                              </CSVLink>
                        ) : (
                           <button
                              className='button-load-disabled'
                              style={{ background: '#4B81E8', color: '#FFF', border: 0 }}
                              disabled
                           >
                              <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                              <span className="sr-only"></span>
                           </button>
                        )}

                     </main>
                  </>
               }
            </div>
         </div>
      </>
   );
};

export default Tarja2;
