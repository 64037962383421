import React, { useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { CSVLink } from 'react-csv';
import moment from 'moment';

import Loading from '../../../components/Loading';
import PageHeader from '../../../components/PageHeader';

import { RegisterContext } from '../../../services/contexts/RegisterContext';
import api from '../../../services/api';

import '../../../styles/pages/app/reports/DfYoung.css';

const DfYoung = () => {
   const [loadingButton, setLoadingButton] = useState('default');

   const [dfYoungData, setDfYoungData] = useState([]);

   const { loadingScreen } = useContext(RegisterContext);


   async function handleLoadDataToLoadingReport() {
      setLoadingButton('processing');

      try {
         const { data } = await api.get(`reports/dfYoungReport`);

         setDfYoungData(data);

         setLoadingButton('default');

      } catch (e) {
         setLoadingButton('default');

         e.response.data.errors.forEach(error => toast.error(error));
      }
   };



   return (
      <>
         <PageHeader
            backButton={true}
         />

         <div id="page-df-young">
            <div className="container">
               {loadingScreen ?
                  <Loading
                     type='balls'
                  />
                  :
                  <>
                     <div className="header-title">
                        <h1>dfYOUNG Report</h1>
                        <span>This report show only vehicles that are currently in stock at the terminal:</span>
                     </div>

                     <main>
                        {loadingButton === 'default' ? (
                           dfYoungData.length === 0 ?
                              <button
                                 type="button"
                                 className='button-load'
                                 onClick={handleLoadDataToLoadingReport}
                              >
                                 Load Data
                              </button>
                              :
                              <CSVLink
                                 filename={`${moment().format('YYYY-MM-DD HHmm')}-dfYoungReport.csv`}
                                 className='button-submit'
                                 data={dfYoungData}
                                 headers={[
                                    { label: "Shipper Identifier", key: "shipperIdentifier" },
                                    { label: "VIN", key: "chassiNumber" },
                                    { label: "Mkt Order No.", key: "" },
                                    { label: "Vehicle Event Code", key: "vehicleEventCode" },
                                    { label: "Vehicle Status Date", key: "vehicleStatusDate" },
                                    { label: "Dealer Code", key: "dealerCode" },
                                    { label: "Port of Loading Code", key: "polCode" },
                                    { label: "Port of Discharge Code", key: "podCode" },
                                    { label: "Vehicle Weight [Kg]", key: "vehicleWeight" },
                                    { label: "Vehicle Vol [M3]", key: "vehicleVolume" },
                                    { label: "Ship to Dealer Code", key: "shipToDealerCode" },
                                    { label: "Date Vehicle is Put in Storage", key: "dateVehicleStorage" },
                                    { label: "Hold Reason Code", key: "holdReasonCode" },
                                    { label: "Vehicle Storage Number", key: "" },
                                    { label: "Remark for Unit on Hold", key: "holdRemarks" },
                                    { label: "Carline", key: "vehicleModel" },
                                    { label: "Mkt Model Code", key: "vehicleCode" },
                                    { label: "Vehicle Transmission", key: "" },
                                    { label: "Engine", key: "" },
                                    { label: "Model Pkg", key: "" },
                                    { label: "", key: "" },
                                    { label: "Vessel Name", key: "vesselName" },
                                    { label: "Vessel Booking Number", key: "" },
                                    { label: "Voyage Number", key: "vesselVoyage" },
                                    { label: "Container Number", key: "" },
                                    { label: "Container Seal Number", key: "" },
                                    { label: "Time Qualifier", key: "" },
                                    { label: "File Generate Time", key: "fileGeneratedTime" },
                                    { label: "Sender", key: "sender" },
                                    { label: "Sequence Number", key: "sequenceNumber" },
                                 ]}
                              >
                                 Download XLSX
                              </CSVLink>
                        ) : (
                           <button
                              className='button-load-disabled'
                              style={{ background: '#4B81E8', color: '#FFF', border: 0 }}
                              disabled
                           >
                              <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                              <span className="sr-only"></span>
                           </button>
                        )}

                     </main>
                  </>
               }
            </div>
         </div>
      </>
   );
};

export default DfYoung;
