import React from 'react';
import { CSVLink } from "react-csv";
import { BsExclamationTriangle } from 'react-icons/bs';

const MessageSuccessButError = (props) => {
   return (
      <div className="success-but-error">
         <div className="info">
            <BsExclamationTriangle size={35} color='#000' />
            <span className='success-but-error-message'><strong>{props.titleStrong}</strong> {props.titleNotStrong}</span>
         </div>

         <CSVLink
            filename={props.filename}
            className='csv-button'
            data={props.fileData}
            headers={props.headers}
         >
            See details
         </CSVLink>

         <span className='footer-message'>{props.footerWithLink} {props.footer}</span>
      </div>
   )
}

export default MessageSuccessButError;
