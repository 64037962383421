import React from 'react';
import { ToastContainer } from 'react-toastify';

const Message = () => {
   return (
      <ToastContainer
         position="top-right"
         autoClose={4000}
         hideProgressBar={false}
         newestOnTop={true}
         closeOnClick={true}
         rtl={false}
         pauseOnFocusLoss={true}
         draggable={true}
         pauseOnHover={true}
         limit={5}
         style={{ fontFamily: 'Montserrat', fontWeight: 700, fontSize: 15 }}
      />
   );
};

export default Message;
