import React, { useContext, useEffect } from 'react';
import Select from 'react-select';
import { Modal, ModalBody } from "reactstrap";
import { Link } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { FiX, FiUploadCloud } from 'react-icons/fi';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { BsExclamationTriangle } from 'react-icons/bs';

import Loading from '../Loading';
import MessageSuccessButError from '../MessageSuccessButError';

import { RegisterContext } from '../../services/contexts/RegisterContext';
import { InitialCharacters, AllLettersUp, AllFirstLettersUp } from '../../utils/functions';

import { customSelectStyles } from '../../styles/components/ChassiDetails/SelectStyles';
import '../../styles/components/modals/ModalUploadFiles.css';

const ModalUploadFiles = (props) => {

   const {
      onDrop,
      onDragOver,

      loadingModal,
      errorMessage,

      chassisRepeated,
      modelsNotRegistered,
      chassisNotRegistered,
      chassisWithoutFolio,
      chassisNotOnYardYet,
      chassisNotOnDamageYet,
      chassisOnDamaged,
      chassisNotReceivedOnYard,
      chassisNotManifested,
      chassisNotLoaded,
      chassisAlreadyInvoiced,

      loadVesselVoyageData,

      searchSelect,
      setSearchSelect,
      vesselVoyageData,
      vesselId,
      setVesselId,
   } = useContext(RegisterContext);

   useEffect(() => {
      loadVesselVoyageData()

   }, [loadVesselVoyageData])

   const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDragOver,
      onDrop,
      multiple: true
   });

   const chassisRepeatedToUpperCase = chassisRepeated.length !== 0 && chassisRepeated.map(chassi => {
      return { chassi_number: AllLettersUp(chassi.chassi_number) };
   });

   const modelsNotRegisteredToUpperCase = modelsNotRegistered.length !== 0 && modelsNotRegistered.map(item => {
      return { model_code: AllLettersUp(item.model_code), chassi_number: AllLettersUp(item.chassi_number) }
   });

   const chassisNotRegisteredToUpperCase = chassisNotRegistered.length !== 0 && chassisNotRegistered.map(chassi => {
      return { chassi_number: AllLettersUp(chassi.chassi_number) };
   });

   const chassisWithoutFolioToUpperCase = chassisWithoutFolio.length !== 0 && chassisWithoutFolio.map(chassi => {
      return { chassi_number: AllLettersUp(chassi.chassi_number) };
   });

   const chassisNotOnYardYetToUpperCase = chassisNotOnYardYet.length !== 0 && chassisNotOnYardYet.map(chassi => {
      return { chassi_number: AllLettersUp(chassi.chassi_number) };
   });

   const chassisNotOnDamageYetToUpperCase = chassisNotOnDamageYet.length !== 0 && chassisNotOnDamageYet.map(chassi => {
      return { chassi_number: AllLettersUp(chassi.chassi_number) };
   });

   const chassisOnDamagedToUpperCase = chassisOnDamaged.length !== 0 && chassisOnDamaged.map(chassi => {
      return { chassi_number: AllLettersUp(chassi.chassi_number) };
   });

   const chassisNotReceivedOnYardToUpperCase = chassisNotReceivedOnYard.length !== 0 && chassisNotReceivedOnYard.map(chassi => {
      return { chassi_number: AllLettersUp(chassi.chassi_number) };
   });

   const chassisNotManifestedToUpperCase = chassisNotManifested.length !== 0 && chassisNotManifested.map(chassi => {
      return { chassi_number: AllLettersUp(chassi.chassi_number) };
   });

   const chassisNotLoadedToUpperCase = chassisNotLoaded.length !== 0 && chassisNotLoaded.map(chassi => {
      return { chassi_number: AllLettersUp(chassi.chassi_number) };
   });

   const chassisAlreadyInvoicedToUpperCase = chassisAlreadyInvoiced.length !== 0 && chassisAlreadyInvoiced.map(chassi => {
      return { chassi_number: AllLettersUp(chassi.chassi_number) };
   });

   const optionsVesselVoyage = vesselVoyageData.map(vessel => {
      return (
         { value: vessel.id, label: `${AllFirstLettersUp(vessel.vessel_name)} - ${AllLettersUp(vessel.voyage)}` }
      );
   });


   return (
      <Modal
         isOpen={props.isOpen}
         toggle={props.toggle}
         id='modal-upload'
         centered
         size='md'
      >
         <ModalBody className="justify-content-middle modal-body">
            <button
               type='button'
               onClick={props.toggle}
               className='button-x'
            >
               <FiX size={20} color='#404040' />
            </button>

            {props.uploadIsSuccess ?
               <div className="info-success">
                  <IoIosCheckmarkCircle size={70} color='#6EE1B8' />
                  <span className='success-message'>The files has been uploaded successfully.</span>

                  {chassisRepeated.length !== 0 &&
                     <MessageSuccessButError
                        titleStrong='Some VIN was already registered'
                        titleNotStrong='and could not be uploaded!'
                        filename='chassis-repeated.csv'
                        fileData={chassisRepeatedToUpperCase}
                        headers={[{ label: "VIN Number", key: "chassi_number" }]}
                        footer='If you want to edit some vehicle information, please select it from the current status list.'
                     />
                  }

                  {modelsNotRegistered.length !== 0 &&
                     <MessageSuccessButError
                        titleStrong='Model Code not registered'
                        titleNotStrong='and some VIN could not be uploaded!'
                        filename='model-code-not-registered.csv'
                        fileData={modelsNotRegisteredToUpperCase}
                        headers={[
                           { label: "VIN Number", key: "chassi_number" },
                           { label: "Model Code", key: "model_code" }
                        ]}
                        footerWithLink={
                           <Link to='/app/settings/admin/newModel'>
                              Please click here to register new model codes
                           </Link>
                        }
                        footer={'before attempting to upload this file again.'}
                     />
                  }

                  {chassisNotRegistered.length !== 0 &&
                     <MessageSuccessButError
                        titleStrong='GMM File was not found for some VINs'
                        filename='chassis-not-registered.csv'
                        fileData={chassisNotRegisteredToUpperCase}
                        headers={[{ label: "VIN Number", key: "chassi_number" }]}
                        footer='Please upload GMM file for these VINs before attempting to upload this file again.'
                     />
                  }

                  {chassisWithoutFolio.length !== 0 &&
                     <MessageSuccessButError
                        titleStrong='Some VINs was not linked to a ‘Folio de Ingreso’ yet'
                        filename='chassis-without-folio.csv'
                        fileData={chassisWithoutFolioToUpperCase}
                        headers={[{ label: "VIN Number", key: "chassi_number" }]}
                        footer='Please link these VINs to a ‘Folio de Ingreso’ before attempting to upload this file again.'
                     />
                  }

                  {chassisNotOnYardYet.length !== 0 &&
                     <MessageSuccessButError
                        titleStrong='Some VINs was not received yet'
                        filename='chassis-not-received.csv'
                        fileData={chassisNotOnYardYetToUpperCase}
                        headers={[{ label: "VIN Number", key: "chassi_number" }]}
                        footer='Please upload ATP Inventory file for there VINs before attempting to upload this file again.'
                     />
                  }

                  {chassisNotOnDamageYet.length !== 0 &&
                     <MessageSuccessButError
                        titleStrong='Some VIN were not listed as a damaged'
                        filename='chassis-not-damaged.csv'
                        fileData={chassisNotOnDamageYetToUpperCase}
                        headers={[{ label: "VIN Number", key: "chassi_number" }]}
                        footer='Please check if Unicar file may have been registered earlier or if they are in other status.'
                     />
                  }

                  {chassisOnDamaged.length !== 0 &&
                     <MessageSuccessButError
                        titleStrong='Some VINs could not be manifested because are damaged'
                        filename='chassis-damaged.csv'
                        fileData={chassisOnDamagedToUpperCase}
                        headers={[{ label: "VIN Number", key: "chassi_number" }]}
                        footer='Please upload Repair List file for these VINs before attempting to upload this file again.'
                     />
                  }

                  {chassisNotReceivedOnYard.length !== 0 &&
                     <MessageSuccessButError
                        titleStrong='Some VIN were not listed as a received'
                        filename='chassis-not-on-yard.csv'
                        fileData={chassisNotReceivedOnYardToUpperCase}
                        headers={[{ label: "VIN Number", key: "chassi_number" }]}
                        footer='Please upload ATP Inventory file for these VINs before attempting to upload this file again.'
                     />
                  }

                  {chassisNotManifested.length !== 0 &&
                     <MessageSuccessButError
                        titleStrong='Some VIN were not listed as a manifested.'
                        filename='chassis-not-manifested.csv'
                        fileData={chassisNotManifestedToUpperCase}
                        headers={[{ label: "VIN Number", key: "chassi_number" }]}
                        footer='Please upload Ship Manifest file for these VINs before attempting to upload this file again.'
                     />
                  }

                  {chassisNotLoaded.length !== 0 &&
                     <MessageSuccessButError
                        titleStrong={`Some VINs not listed in this file have been unassigned from this vessel's voyage`}
                        filename='chassis-not-loaded.csv'
                        fileData={chassisNotLoadedToUpperCase}
                        headers={[{ label: "VIN Number", key: "chassi_number" }]}
                        footer={`These VINs has been moved back to ‘Received‘ status.`}
                     />
                  }

                  {chassisAlreadyInvoiced.length !== 0 &&
                     <MessageSuccessButError
                        titleStrong={`Some VINs was already marked as invoiced`}
                        filename='chassis-already-invoiced.csv'
                        fileData={chassisAlreadyInvoicedToUpperCase}
                        headers={[{ label: "VIN Number", key: "chassi_number" }]}
                        footer={`If you want to edit some vehicle information, please select it from the current status list.`}
                     />
                  }
               </div>

               :
               props.uploadIsFailed ?
                  <div className="info-failed">
                     <div className="failed-svg-container">
                        <BsExclamationTriangle size={35} color='#FFF' />
                     </div>
                     <h3>Upload error.</h3>
                     <span>{errorMessage}</span>
                  </div>

                  :
                  props.modalFile === 'GM Ship Manifest' || props.modalFile === 'Loading Report' ?
                     <>
                        <div className="header">
                           <h1>Select the vessel’s voyage and drop you file here to upload</h1>
                        </div>

                        <div className="vessel-input-container">
                           <div className="input-block">
                              <label htmlFor='vesselVoyage'>Vessel & Voyage</label>
                              <Select
                                 isSearchable={true}
                                 isClearable={false}
                                 backspaceRemovesValue={true}
                                 styles={customSelectStyles}
                                 options={optionsVesselVoyage}
                                 className='react-select-container'
                                 classNamePrefix="react-select"
                                 name="vesselVoyage"
                                 id="vesselVoyage"
                                 placeholder={searchSelect === undefined && 'Type or select a registered Voyage'}
                                 defaultValue={vesselId}
                                 onChange={e => setVesselId(e.value)}
                                 defaultInputValue={searchSelect}
                                 onInputChange={e => setSearchSelect(e.value)}
                                 onFocus={e => setSearchSelect('')}
                              />
                           </div>
                        </div>

                        <div className="drag-drop-container" {...getRootProps()}>
                           {loadingModal && !isDragActive ?
                              <Loading
                                 height='100%'
                                 width={650}
                                 type='spokes'
                              />
                              :
                              <>
                                 <input {...getInputProps()} multiple={true} />

                                 {props.selectedFiles.length !== 0
                                    ?
                                    props.selectedFiles.map(file => {
                                       return (
                                          <div key={file.name} className="file">
                                             <RiFileExcel2Fill size={30} color='#28703A' />
                                             <span>{InitialCharacters(file.name)}</span>
                                          </div>
                                       )
                                    })
                                    :
                                    isDragActive
                                       ?
                                       <span className='drop-here'>Drop here...</span>
                                       :
                                       <div className='info'>
                                          {props.selectedFiles.length !== 0
                                             ?
                                             props.selectedFiles.map(file => {
                                                return (
                                                   <div key={file.name} className="file">
                                                      <RiFileExcel2Fill size={30} color='#28703A' />
                                                      <span>{InitialCharacters(file.name)}</span>
                                                   </div>
                                                )
                                             })
                                             :
                                             <div className="no-content">
                                                <div className="cloud">
                                                   <FiUploadCloud size={32} color='#FFF' />
                                                </div>
                                                <span>Drop {props.modalFile} here to upload all informations or click to search the files!</span>
                                             </div>
                                          }
                                       </div>
                                 }
                              </>
                           }
                        </div>

                        {props.loadingButton === 'default' ? (
                           <div className="button-container">
                              <button
                                 type='button'
                                 onClick={props.toggle}
                                 className='button-cancel'
                              >
                                 Cancel
                              </button>

                              <button
                                 type='button'
                                 onClick={props.handleUpload}
                                 className='button-submit'
                              >
                                 Upload
                              </button>
                           </div>
                        ) : (
                           <div className="button-container">
                              <button
                                 type="button"
                                 className='button-cancel'
                                 disabled
                              >
                                 Cancel
                              </button>

                              <button
                                 className='button-submit'
                                 style={{ background: '#4B81E8', color: '#FFF', border: 0 }}
                                 disabled
                              >
                                 <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                                 <span className="sr-only"></span>
                              </button>
                           </div>
                        )}
                     </>
                     :
                     <>
                        <div className="header">
                           <h1>Drop your file here to upload</h1>
                        </div>

                        <div className="drag-drop-container" {...getRootProps()}>
                           {loadingModal && !isDragActive ?
                              <Loading
                                 height='100%'
                                 width={650}
                                 type='spokes'
                              />

                              :
                              <>
                                 <input {...getInputProps()} multiple={true} />

                                 {props.selectedFiles.length !== 0
                                    ?
                                    props.selectedFiles.map(file => {
                                       return (
                                          <div key={file.name} className="file">
                                             <RiFileExcel2Fill size={30} color='#28703A' />
                                             <span>{InitialCharacters(file.name)}</span>
                                          </div>
                                       )
                                    })
                                    :
                                    isDragActive
                                       ?
                                       <span className='drop-here'>Drop here...</span>
                                       :
                                       <div className='info'>
                                          {props.selectedFiles.length !== 0
                                             ?
                                             props.selectedFiles.map(file => {
                                                return (
                                                   <div key={file.name} className="file">
                                                      <RiFileExcel2Fill size={30} color='#28703A' />
                                                      <span>{InitialCharacters(file.name)}</span>
                                                   </div>
                                                )
                                             })
                                             :
                                             <div className="no-content">
                                                <div className="cloud">
                                                   <FiUploadCloud size={32} color='#FFF' />
                                                </div>
                                                <span>Drop {props.modalFile} here to upload all informations or click to search the files!</span>
                                             </div>
                                          }
                                       </div>
                                 }
                              </>
                           }
                        </div>

                        {props.loadingButton === 'default' ? (
                           <div className="button-container">
                              <button
                                 type='button'
                                 onClick={props.toggle}
                                 className='button-cancel'
                              >
                                 Cancel
                              </button>

                              <button
                                 type='button'
                                 onClick={props.handleUpload}
                                 className='button-submit'
                              >
                                 Upload
                              </button>
                           </div>
                        ) : (
                           <div className="button-container">
                              <button
                                 type="button"
                                 className='button-cancel'
                                 disabled
                              >
                                 Cancel
                              </button>

                              <button
                                 className='button-submit'
                                 style={{ background: '#4B81E8', color: '#FFF', border: 0 }}
                                 disabled
                              >
                                 <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                                 <span className="sr-only"></span>
                              </button>
                           </div>
                        )}
                     </>
            }

         </ModalBody>

      </Modal>
   );
};

export default ModalUploadFiles;
