import React, { useState, useEffect } from 'react';
import { Modal, ModalBody } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

import Input from '../Input';
import api from '../../services/api';

import '../../styles/components/modals/ModalAddFolioDeIngreso.css';

const ModalAddFolioDeIngreso = (props) => {
   const [organizingData, setOrganizingData] = useState([]);


   useEffect(() => {

      async function loadInfoData() {
         let organizingDataReceived = [];

         for (let i = 0; i < props.chassisSelected.length; i++) {
            const res = await api.get(`onRoad/${Number(props.chassisSelected[i][0])}`);

            organizingDataReceived.push(res.data);
         }

         setOrganizingData(organizingDataReceived);
      }

      loadInfoData();

   }, [props.chassisSelected]);

   return (
      <Modal
         isOpen={props.isOpen}
         toggle={props.toggle}
         id='modal-add-folio'
         centered
         size='md'
      >
         <ModalBody className="justify-content-middle modal-body">
            <h1 className="mt-2">Add Folio de Ingreso</h1>
            <span>Here you can attach a ‘Folio de Ingreso’ to selected vehicles.</span>

            <div className="label-container">
               <label htmlFor="chassi" className="chassis-label">VIN Number</label>
               <label htmlFor="model" className="model-label">Model</label>
               <label htmlFor="pod" className="pod-label">POD</label>
            </div>

            <PerfectScrollbar
               options={{ suppressScrollX: true, wheelPropagation: false, maxScrollbarLength: 20 }}
               component='div'
               className="items-container"
            >
               {organizingData.map(item => {
                  return (
                     <div className="row" key={item.chassi_id}>
                        <span className="chassis">{item.chassi_number}</span>
                        <span className="model">{item.model_name}</span>
                        <span className="pod">{item.pod}</span>
                     </div>
                  )
               })}

            </PerfectScrollbar>

            <div className="folio-input-container">
               <Input
                  name='folioDeIngreso'
                  type='text'
                  label='Folio de Ingreso'
                  value={props.folioDeIngreso}
                  onChange={e => props.setFolioDeIngreso(e.target.value)}
                  autoComplete="off"
               />
            </div>

            {props.loadingButton === 'default' ? (
               <div className="buttons-container">
                  <button
                     type="button"
                     className='button-cancel'
                     onClick={props.toggle}
                  >
                     Cancel
                  </button>

                  <button
                     type="button"
                     className={props.folioDeIngreso !== '' ? 'button-save' : 'button-save-disabled'}
                     onClick={props.handleChassisSubmit}
                     disabled={props.folioDeIngreso !== '' ? false : true}
                  >
                     Save
                  </button>
               </div>
            ) : (
               <div className="buttons-container">
                  <button
                     type="button"
                     className='button-cancel'
                     disabled
                  >
                     Cancel
                  </button>

                  <button
                     className='button-save'
                     style={{ background: '#4B81E8', color: '#FFF', border: 0 }}
                     disabled
                  >
                     <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                     <span className="sr-only"></span>
                  </button>
               </div>
            )}

         </ModalBody>

      </Modal>
   );
};

export default ModalAddFolioDeIngreso;
