import React from 'react';

import '../styles/components/Input.css';

const Input = ({ label, name, span, ...rest }) => {
   return (
      <div className="input-block">
         <label htmlFor={name}>{label} <span>{span}</span></label>
         <input id={name} {...rest} />
      </div>
   );
};

export default Input;
