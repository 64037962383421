import React, { useState, useEffect, useCallback, useContext, useRef, forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FiX } from 'react-icons/fi';
import { HiCalendar, HiOutlineCalendar } from 'react-icons/hi';
import crypto from 'crypto-js';
import InfiniteScroll from 'react-infinite-scroll-component';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import PageHeader from '../../components/PageHeader';
import Loading from '../../components/Loading';
import LoadingInfiniteScroll from '../../components/LoadingInfiniteScroll';
import ButtonsNavigation from '../../components/ButtonsNavigation';
import ModalAddFolioDeIngreso from '../../components/modals/ModalAddFolioDeIngreso';
import DropdownFilterOnRoad from '../../components/dropdowns/DropdownFilterOnRoad';

import { RegisterContext } from '../../services/contexts/RegisterContext';

import api from '../../services/api';
import { AllLetterDown } from '../../utils/functions';

import '../../styles/pages/app/OnRoad.css';

const OnRoad = () => {
   const [loadingButton, setLoadingButton] = useState('default');

   const [modalAddFolioIsOpen, setModalAddFolioIsOpen] = useState(false);
   const [startDate, setStartDate] = useState(new Date());

   const [showFilterChassis, setShowFilterChassis] = useState(false);
   const [showFilterDriver, setShowFilterDriver] = useState(false);
   const [showFilterPlate, setShowFilterPlate] = useState(false);
   const [showFilterPod, setShowFilterPod] = useState(false);

   const [searchChassis, setSearchChassis] = useState('');
   const [searchShipmentDate, setSearchShipmentDate] = useState('');
   const [searchDriverName, setSearchDriverName] = useState('');
   const [searchTruckPlate, setSearchTruckPlate] = useState('');
   const [searchPod, setSearchPod] = useState('');

   const [folioDeIngreso, setFolioDeIngreso] = useState('');
   const [chassisSelection, setChassisSelection] = useState([]);

   const dropdownFilterChassisRef = useRef(null);
   const dropdownFilterDriverRef = useRef(null);
   const dropdownFilterPlateRef = useRef(null);
   const dropdownFilterPodRef = useRef(null);

   const {
      loadingScreen,
      setLoadingScreen,
      loadingMorePages,
      setLoadingMorePages,

      onRoadVehiclesQuantity,
      setPageOnRoad,
      setPageScheduled,
      setPageReceived,
      setPageDamaged,
      setPageManifested,
      setPageLoaded,
      setPageInvoiced,

      loadOnRoadVehicles,
      loadScheduledVehicles,
      loadReceivedVehicles,
      loadDamagedVehicles,
      loadManifestedVehicles,
      loadLoadedVehicles,
      loadInvoicedVehicles,

      onRoadVehiclesData,
      setOnRoadVehiclesData,
   } = useContext(RegisterContext);


   // eslint-disable-next-line array-callback-return
   const chassisSelected = chassisSelection.map(item => {
      if (item[1] === true) {
         return item
      }
   }).filter(Boolean);

   useEffect(() => {
      if (searchChassis !== '' || searchShipmentDate !== '' || searchDriverName !== ''
         || searchTruckPlate !== '' || searchPod !== '') {

         async function loadOnRoadVehiclesWithFilter() {
            const res = await api.get(`onRoad?pageSize=100000&chassis=${AllLetterDown(
               searchChassis)}&shipmentDate=${AllLetterDown(searchShipmentDate)}&driveName=${AllLetterDown(
                  searchDriverName)}&truckPlate=${AllLetterDown(searchTruckPlate)}&pod=${AllLetterDown(searchPod)}`);

            setOnRoadVehiclesData(res.data);

            setLoadingScreen(false);
         };

         loadOnRoadVehiclesWithFilter();

      } else {
         loadOnRoadVehicles();
      }

   }, [loadOnRoadVehicles, setOnRoadVehiclesData, setLoadingScreen, searchChassis, searchShipmentDate, searchDriverName, searchTruckPlate, searchPod]);

   useEffect(() => {
      setLoadingMorePages(true);
      setPageScheduled(1);
      setPageReceived(1);
      setPageDamaged(1);
      setPageManifested(1);
      setPageLoaded(1);
      setPageInvoiced(1);

   }, [setPageScheduled, setPageReceived, setPageDamaged, setPageManifested, setPageLoaded, setPageInvoiced, setLoadingMorePages]);

   useEffect(() => {
      loadScheduledVehicles();
      loadReceivedVehicles();
      loadDamagedVehicles();
      loadManifestedVehicles();
      loadLoadedVehicles();
      loadInvoicedVehicles();

   }, [loadScheduledVehicles, loadReceivedVehicles, loadDamagedVehicles, loadManifestedVehicles, loadLoadedVehicles, loadInvoicedVehicles]);


   const onRoadVehiclesDataConfigured = onRoadVehiclesData.map(vehicle => {
      return {
         chassi_id: vehicle.chassi_id,
         chassi_number: vehicle.chassi_number,
         model_name: vehicle.model_name,
         pod: vehicle.pod,
         shipment_date: moment(new Date(vehicle.shipment_date)).format('YYYY-MM-DD'),
         operator_name: vehicle.operator_name === null || vehicle.operator_name === undefined ? '-' : vehicle.operator_name,
         truck_license_plate: vehicle.truck_license_plate === null || vehicle.truck_license_plate === undefined ? '-' : vehicle.truck_license_plate,
      };
   });

   const handleShowFilterChassis = useCallback((e) => {
      if (dropdownFilterChassisRef.current && showFilterChassis) {
         if (!dropdownFilterChassisRef.current.contains(e.target)) {
            setShowFilterChassis(false);
         }

      } else if (!dropdownFilterChassisRef.current && showFilterChassis) {
         setShowFilterChassis(false);

      } else {
         setShowFilterChassis(true);
      }

   }, [showFilterChassis]);

   useEffect(() => {
      if (showFilterChassis) {
         document.addEventListener("click", handleShowFilterChassis);

         return () => document.removeEventListener("click", handleShowFilterChassis);
      }

   }, [showFilterChassis, handleShowFilterChassis]);

   const handleShowFilterDriver = useCallback((e) => {
      if (dropdownFilterDriverRef.current && showFilterDriver) {
         if (!dropdownFilterDriverRef.current.contains(e.target)) {
            setShowFilterDriver(false);
         }

      } else if (!dropdownFilterDriverRef.current && showFilterDriver) {
         setShowFilterDriver(false);

      } else {
         setShowFilterDriver(true);
      }

   }, [showFilterDriver]);

   useEffect(() => {
      if (showFilterDriver) {
         document.addEventListener("click", handleShowFilterDriver);

         return () => document.removeEventListener("click", handleShowFilterDriver);
      }

   }, [showFilterDriver, handleShowFilterDriver]);

   const handleShowFilterPlate = useCallback((e) => {
      if (dropdownFilterPlateRef.current && showFilterPlate) {
         if (!dropdownFilterPlateRef.current.contains(e.target)) {
            setShowFilterPlate(false);
         }

      } else if (!dropdownFilterPlateRef.current && showFilterPlate) {
         setShowFilterPlate(false);

      } else {
         setShowFilterPlate(true);
      }

   }, [showFilterPlate]);

   useEffect(() => {
      if (showFilterPlate) {
         document.addEventListener("click", handleShowFilterPlate);

         return () => document.removeEventListener("click", handleShowFilterPlate);
      }

   }, [showFilterPlate, handleShowFilterPlate]);

   const handleShowFilterPod = useCallback((e) => {
      if (dropdownFilterPodRef.current && showFilterPod) {
         if (!dropdownFilterPodRef.current.contains(e.target)) {
            setShowFilterPod(false);
         }

      } else if (!dropdownFilterPodRef.current && showFilterPod) {
         setShowFilterPod(false);

      } else {
         setShowFilterPod(true);
      }

   }, [showFilterPod]);

   useEffect(() => {
      if (showFilterPod) {
         document.addEventListener("click", handleShowFilterPod);

         return () => document.removeEventListener("click", handleShowFilterPod);
      }

   }, [showFilterPod, handleShowFilterPod]);


   // async function handleCheckAll() {
   //    let checklistId = [];
   //    const checkboxes = document.querySelectorAll('input[type="checkbox"]');

   //    checkboxes.forEach((checkbox) => {
   //       if (checkbox.value !== 'on') {
   //          checklistId.push([checkbox.value, !isCheckAll])
   //       }
   //    });

   //    setChassisSelection(checklistId);
   // }

   function handleCheckIdConcat(e) {
      let checklistId = [];
      const checkboxes = document.querySelectorAll('input[type="checkbox"]');

      checkboxes.forEach((checkbox) => {
         if (checkbox.value !== 'on') {
            checklistId.push([checkbox.value, checkbox.checked])
         }
      });

      setChassisSelection(checklistId);
   };

   async function handleChassisSubmit(e) {
      e.preventDefault();

      setLoadingButton('processing');

      const data = { folioDeIngreso };

      for (let i = 0; i < chassisSelected.length; i++) {
         try {
            await api.put(`chassis/${Number(chassisSelected[i][0])}/folio`, data);

         } catch (e) {
            setLoadingButton('default');

            e.response.data.errors.forEach(error => toast.error(error));
         };
      };

      toast.success('The selected vehicles has been scheduled');

      setChassisSelection([]);
      setFolioDeIngreso('');

      setPageOnRoad(1);

      setModalAddFolioIsOpen(false);
      setLoadingButton('default');

      await loadOnRoadVehicles();
      await loadScheduledVehicles();
   };

   const onChangeDatePicker = (date) => {
      setStartDate(date);
      setSearchShipmentDate(moment(new Date(date)).format('YYYY-MM-DD'));
   };

   const CalendarCustomButton = forwardRef(({ onClick }, ref) => {
      return (
         <button
            type="button"
            className='button-filter'
            onClick={onClick}
            ref={ref}
         >
            {searchShipmentDate !== '' ?
               <HiCalendar size={20} color="#4B81E8" />
               :
               <HiOutlineCalendar size={20} color="#5D5D5D" />
            }
         </button>
      );
   });

   function handleRemoveFilterChassi() {
      setLoadingScreen(true);

      setSearchChassis('');
      setPageOnRoad(1);
      setLoadingMorePages(true);
   };

   function handleRemoveFilterDate() {
      setLoadingScreen(true);

      setSearchShipmentDate('');
      setPageOnRoad(1);
      setLoadingMorePages(true);
   };

   function handleRemoveFilterDriver() {
      setLoadingScreen(true);

      setSearchDriverName('');
      setPageOnRoad(1);
      setLoadingMorePages(true);
   };

   function handleRemoveFilterPlate() {
      setLoadingScreen(true);

      setSearchTruckPlate('');
      setPageOnRoad(1);
      setLoadingMorePages(true);
   };

   function handleRemoveFilterPod() {
      setLoadingScreen(true);

      setSearchPod('');
      setPageOnRoad(1);
      setLoadingMorePages(true);
   };

   function handleOpenModalAddFolio() {
      setModalAddFolioIsOpen(!modalAddFolioIsOpen);

      setFolioDeIngreso('');
   };

   function handleFetchMore() {
      if (onRoadVehiclesData.length >= Number(onRoadVehiclesQuantity)) {
         setLoadingMorePages(false);
         return;
      }

      if (searchChassis !== '' || searchShipmentDate !== '' || searchDriverName !== ''
         || searchTruckPlate !== '' || searchPod !== '') {
         setLoadingMorePages(false);
         return;
      }

      setTimeout(() => {
         setPageOnRoad(oldValue => oldValue + 1);

         loadOnRoadVehicles();
      }, 1000);
   };


   return (
      <>
         <PageHeader />
         <ButtonsNavigation />

         <div id="page-onRoad">
            <div className="container">
               <div className="height">
                  {loadingScreen ?
                     <Loading
                        type='balls'
                     />
                     :
                     <main>
                        {searchChassis === '' && searchShipmentDate === '' && searchDriverName === ''
                           && searchTruckPlate === '' && searchPod === '' ?
                           null
                           :
                           <div className="filter-selected">
                              <span className='filter-title'>Selected filters:</span>

                              {searchChassis !== '' && (
                                 <div className="filter-selected-container">
                                    <FiX size={15} color='#5D5D5D' onClick={handleRemoveFilterChassi} />
                                    <span className='filter-uppercase'>{searchChassis}</span>
                                 </div>
                              )}

                              {searchShipmentDate !== '' && (
                                 <div className="filter-selected-container">
                                    <FiX size={15} color='#5D5D5D' onClick={handleRemoveFilterDate} />
                                    <span>{searchShipmentDate}</span>
                                 </div>
                              )}

                              {searchDriverName !== '' && (
                                 <div className="filter-selected-container">
                                    <FiX size={15} color='#5D5D5D' onClick={handleRemoveFilterDriver} />
                                    <span className='filter-capitalize'>{searchDriverName}</span>
                                 </div>
                              )}

                              {searchTruckPlate !== '' && (
                                 <div className="filter-selected-container">
                                    <FiX size={15} color='#5D5D5D' onClick={handleRemoveFilterPlate} />
                                    <span className='filter-uppercase'>{searchTruckPlate}</span>
                                 </div>
                              )}

                              {searchPod !== '' && (
                                 <div className="filter-selected-container">
                                    <FiX size={15} color='#5D5D5D' onClick={handleRemoveFilterPod} />
                                    <span className='filter-uppercase'>{searchPod}</span>
                                 </div>
                              )}
                           </div>
                        }

                        {onRoadVehiclesDataConfigured.length === 0 ?
                           <div className="no-vehicles">
                              <span>
                                 There is no vehicles on their way to the terminal.
                                 <br />
                                 Please, register a GMM File.
                              </span>
                           </div>
                           :
                           <div className="vehicles-list">
                              <div className="label-container">
                                 <div
                                    className='select-chassi'
                                 ></div>
                                 {/* <input
                                    type="checkbox"
                                    id='select-all'
                                    onChange={e => handleCheckAll(e.target.value)}
                                 /> */}

                                 <DropdownFilterOnRoad
                                    label='VIN Number'
                                    dropdownFilterRef={dropdownFilterChassisRef}
                                    search={searchChassis}
                                    setSearch={setSearchChassis}
                                    handleShowFilter={handleShowFilterChassis}
                                    setShowFilter={setShowFilterChassis}
                                    showFilter={showFilterChassis}
                                    classContainer='chassi'
                                 />

                                 <div className="date-container">
                                    <label htmlFor="uploadDate">Upload Date</label>
                                    <DatePicker
                                       selected={startDate}
                                       onChange={onChangeDatePicker}
                                       customInput={<CalendarCustomButton />}
                                       showPopperArrow={false}
                                       todayButton='Today'
                                    />
                                 </div>

                                 <div className="model-container">
                                    <label htmlFor="model">Model</label>
                                 </div>

                                 <DropdownFilterOnRoad
                                    label='Driver Name'
                                    dropdownFilterRef={dropdownFilterDriverRef}
                                    search={searchDriverName}
                                    setSearch={setSearchDriverName}
                                    handleShowFilter={handleShowFilterDriver}
                                    setShowFilter={setShowFilterDriver}
                                    showFilter={showFilterDriver}
                                    classContainer='driver'
                                 />

                                 <DropdownFilterOnRoad
                                    label='Truck Plate'
                                    dropdownFilterRef={dropdownFilterPlateRef}
                                    search={searchTruckPlate}
                                    setSearch={setSearchTruckPlate}
                                    handleShowFilter={handleShowFilterPlate}
                                    setShowFilter={setShowFilterPlate}
                                    showFilter={showFilterPlate}
                                    classContainer='plate'
                                 />

                                 <DropdownFilterOnRoad
                                    label='POD'
                                    dropdownFilterRef={dropdownFilterPodRef}
                                    search={searchPod}
                                    setSearch={setSearchPod}
                                    handleShowFilter={handleShowFilterPod}
                                    setShowFilter={setShowFilterPod}
                                    showFilter={showFilterPod}
                                    classContainer='pod'
                                 />

                              </div>
                              <InfiniteScroll
                                 dataLength={onRoadVehiclesData.length}
                                 next={handleFetchMore}
                                 hasMore={loadingMorePages}
                                 loader={
                                    <LoadingInfiniteScroll
                                       height={30}
                                       width='100%'
                                       type='spin'
                                    />
                                 }
                                 scrollThreshold={0.8}
                                 scrollableTarget="page-onRoad"
                              >
                                 {onRoadVehiclesDataConfigured.map(vehicle => {
                                    return (
                                       <div
                                          key={vehicle.chassi_id}
                                          className="cards"
                                       >
                                          {vehicle.truck_license_plate === '-' || vehicle.operator_name === '-'
                                             ?
                                             <div
                                                className='select-chassi'
                                             ></div>
                                             :
                                             vehicle.truck_license_plate === undefined || vehicle.operator_name === undefined ?
                                                <div
                                                   className='select-chassi'
                                                ></div>
                                                :
                                                <input
                                                   type="checkbox"
                                                   value={[vehicle.chassi_id]}
                                                   id={vehicle.chassi_id}
                                                   onChange={e => handleCheckIdConcat(e.target.value)}
                                                // defaultChecked={isCheck}
                                                />
                                          }
                                          <Link
                                             to={`/app/vehicle/${encodeURIComponent(crypto.DES.encrypt(String(vehicle.chassi_id), 'idChassi').toString())}/tracking`}
                                             className="card-click"
                                          >
                                             <span className="chassi-container">{vehicle.chassi_number}</span>
                                             <span className="date-container">{vehicle.shipment_date}</span>
                                             <span className="model-container">{vehicle.model_name}</span>
                                             <span className="driver-container">{vehicle.operator_name}</span>
                                             <span className="plate-container">{vehicle.truck_license_plate}</span>
                                             <span className="pod-container">{vehicle.pod}</span>
                                          </Link>
                                       </div>
                                    )
                                 })}
                              </InfiniteScroll>
                           </div>
                        }
                     </main>
                  }
               </div>
            </div>

            {chassisSelected.length !== 0 &&
               <div className="footer-container">
                  <div className="container">
                     <button
                        type='button'
                        onClick={handleOpenModalAddFolio}
                     >
                        Add Folio de Ingreso
                     </button>

                     <span>{chassisSelected.length} vehicles(s) selected</span>
                  </div>
               </div>
            }

            <ModalAddFolioDeIngreso
               isOpen={modalAddFolioIsOpen}
               toggle={handleOpenModalAddFolio}
               folioDeIngreso={folioDeIngreso}
               setFolioDeIngreso={setFolioDeIngreso}
               chassisSelected={chassisSelected}
               handleChassisSubmit={handleChassisSubmit}
               loadingButton={loadingButton}
            />

         </div>
      </>
   );
};

export default OnRoad;
