import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { RegisterContext } from '../services/contexts/RegisterContext';

import '../styles/components/ButtonsNavigation.css';

const ButtonsNavigation = () => {
   const [linkClicked, setLinkClicked] = useState('');

   const {
      onRoadVehiclesQuantity,
      scheduledVehiclesQuantity,
      receivedVehiclesQuantity,
      damagedVehiclesQuantity,
      manifestedVehiclesQuantity,
      loadedVehiclesQuantity,
      invoicedVehiclesQuantity,
   } = useContext(RegisterContext);

   const history = useHistory();

   useEffect(() => {
      setLinkClicked(history.location.pathname);

   }, [history.location.pathname]);


   return (
      <div id="buttons-navigation">
         <div className="container">
            <Link
               to='/app/onRoad'
               className={linkClicked === '/app/onRoad' ? 'button-selected' : 'button'}
            >
               <span className={linkClicked === '/app/onRoad' ? 'quantity-selected' : 'quantity'}>{onRoadVehiclesQuantity}</span>
              On Road
            </Link>

            <Link
               to='/app/scheduled'
               className={linkClicked === '/app/scheduled' ? 'button-selected' : 'button'}
            >
               <span className={linkClicked === '/app/scheduled' ? 'quantity-selected' : 'quantity'}>{scheduledVehiclesQuantity}</span>
               Scheduled
            </Link>

            <Link
               to='/app/received'
               className={linkClicked === '/app/received' ? 'button-selected' : 'button'}
            >
               <span className={linkClicked === '/app/received' ? 'quantity-selected' : 'quantity'}>{receivedVehiclesQuantity}</span>
              Received
            </Link>

            <Link
               to='/app/damaged'
               className={linkClicked === '/app/damaged' ? 'button-selected' : 'button'}
            >
               <span className={linkClicked === '/app/damaged' ? 'quantity-selected' : 'quantity'}>{damagedVehiclesQuantity}</span>
              Damaged
            </Link>

            <Link
               to='/app/manifested'
               className={linkClicked === '/app/manifested' ? 'button-selected' : 'button'}
            >
               <span className={linkClicked === '/app/manifested' ? 'quantity-selected' : 'quantity'}>{manifestedVehiclesQuantity}</span>
               Manifested
            </Link>

            <Link
               to='/app/loaded'
               className={linkClicked === '/app/loaded' ? 'button-selected' : 'button'}
            >
               <span className={linkClicked === '/app/loaded' ? 'quantity-selected' : 'quantity'}>{loadedVehiclesQuantity}</span>
               Loaded
            </Link>

            <Link
               to='/app/invoiced'
               className={linkClicked === '/app/invoiced' ? 'button-selected' : 'button'}
            >
               <span className={linkClicked === '/app/invoiced' ? 'quantity-selected' : 'quantity'}>{invoicedVehiclesQuantity}</span>
              Invoiced
            </Link>

         </div>
      </div>
   );
};

export default ButtonsNavigation;
