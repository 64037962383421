import React, { useState, useEffect, useCallback, useContext, useRef, forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FiX, FiTool } from 'react-icons/fi';
import { HiCalendar, HiOutlineCalendar } from 'react-icons/hi';
import crypto from 'crypto-js';
import InfiniteScroll from 'react-infinite-scroll-component';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import PageHeader from '../../components/PageHeader';
import Loading from '../../components/Loading';
import LoadingInfiniteScroll from '../../components/LoadingInfiniteScroll';
import ButtonsNavigation from '../../components/ButtonsNavigation';
import ModalConfirmIssueInvoice from '../../components/modals/ModalConfirmIssueInvoice';
import DropdownFilterLoaded from '../../components/dropdowns/DropdownFilterLoaded';

import { RegisterContext } from '../../services/contexts/RegisterContext';

import api from '../../services/api';
import { AllLetterDown } from '../../utils/functions';

import '../../styles/pages/app/Loaded.css';

const Loaded = () => {
   const [loadingButton, setLoadingButton] = useState('default');

   const [modalConfirmInvoice, setModalConfirmInvoice] = useState(false);
   const [invoiceIssueDate, setInvoiceIssueDate] = useState('');

   const [startDate, setStartDate] = useState(new Date());

   const [showFilterChassis, setShowFilterChassis] = useState(false);
   const [showFilterVessel, setShowFilterVessel] = useState(false);
   const [showFilterPod, setShowFilterPod] = useState(false);

   const [searchChassis, setSearchChassis] = useState('');
   const [searchVessel, setSearchVessel] = useState('');
   const [searchPod, setSearchPod] = useState('');
   const [searchLoadingDate, setSearchLoadingDate] = useState('');

   const [chassisSelection, setChassisSelection] = useState([]);

   const dropdownFilterChassisRef = useRef(null);
   const dropdownFilterVesselRef = useRef(null);
   const dropdownFilterPodRef = useRef(null);

   const {
      loadingScreen,
      setLoadingScreen,
      loadingMorePages,
      setLoadingMorePages,

      loadedVehiclesQuantity,
      setPageOnRoad,
      setPageScheduled,
      setPageReceived,
      setPageDamaged,
      setPageManifested,
      setPageLoaded,
      setPageInvoiced,

      loadOnRoadVehicles,
      loadScheduledVehicles,
      loadReceivedVehicles,
      loadDamagedVehicles,
      loadManifestedVehicles,
      loadLoadedVehicles,
      loadInvoicedVehicles,

      loadedVehiclesData,
      setLoadedVehiclesData,
   } = useContext(RegisterContext);


   // eslint-disable-next-line array-callback-return
   const chassisSelected = chassisSelection.map(item => {
      if (item[1] === true) {
         return item
      }
   }).filter(Boolean);

   useEffect(() => {
      if (searchChassis !== '' || searchVessel !== ''
         || searchPod !== '' || searchLoadingDate !== '') {

         async function loadLoadedVehicles() {
            const res = await api.get(`loaded?pageSize=100000&chassis=${AllLetterDown(
               searchChassis)}&vesselName=${AllLetterDown(searchVessel)}&pod=${AllLetterDown(
                  searchPod)}&loadingDate=${AllLetterDown(searchLoadingDate)}`);

            setLoadedVehiclesData(res.data);

            setLoadingScreen(false);
         };

         loadLoadedVehicles();

      } else {
         loadLoadedVehicles();
      }

   }, [loadLoadedVehicles, setLoadedVehiclesData, setLoadingScreen, searchChassis, searchVessel, searchPod, searchLoadingDate]);

   useEffect(() => {
      setLoadingMorePages(true);
      setPageOnRoad(1);
      setPageScheduled(1);
      setPageReceived(1);
      setPageDamaged(1);
      setPageManifested(1);
      setPageInvoiced(1);

   }, [setPageOnRoad, setPageScheduled, setPageReceived, setPageDamaged, setPageManifested, setPageInvoiced, setLoadingMorePages]);

   useEffect(() => {
      loadOnRoadVehicles();
      loadScheduledVehicles();
      loadReceivedVehicles();
      loadDamagedVehicles();
      loadManifestedVehicles();
      loadInvoicedVehicles();

   }, [loadOnRoadVehicles, loadScheduledVehicles, loadReceivedVehicles, loadDamagedVehicles, loadManifestedVehicles, loadInvoicedVehicles]);

   const loadedVehiclesDataConfigured = loadedVehiclesData.map(vehicle => {
      return {
         chassi_id: vehicle.chassi_id,
         chassi_number: vehicle.chassi_number,
         loading_date_confirmation: moment(new Date(vehicle.loading_date_confirmation)).format('YYYY-MM-DD'),
         model_name: vehicle.model_name,
         pod: vehicle.pod,
         repaired: vehicle.repaired,
         vessel_voyage: vehicle.vessel_voyage,
         pdi_service: vehicle.pdi_service
      };
   });

   const handleShowFilterChassis = useCallback((e) => {
      if (dropdownFilterChassisRef.current && showFilterChassis) {
         if (!dropdownFilterChassisRef.current.contains(e.target)) {
            setShowFilterChassis(false);
         }

      } else if (!dropdownFilterChassisRef.current && showFilterChassis) {
         setShowFilterChassis(false);

      } else {
         setShowFilterChassis(true);
      }

   }, [showFilterChassis]);

   useEffect(() => {
      if (showFilterChassis) {
         document.addEventListener("click", handleShowFilterChassis);

         return () => document.removeEventListener("click", handleShowFilterChassis);
      }

   }, [showFilterChassis, handleShowFilterChassis]);

   const handleShowFilterVessel = useCallback((e) => {
      if (dropdownFilterVesselRef.current && showFilterVessel) {
         if (!dropdownFilterVesselRef.current.contains(e.target)) {
            setShowFilterVessel(false);
         }

      } else if (!dropdownFilterVesselRef.current && showFilterVessel) {
         setShowFilterVessel(false);

      } else {
         setShowFilterVessel(true);
      }

   }, [showFilterVessel]);

   useEffect(() => {
      if (showFilterVessel) {
         document.addEventListener("click", handleShowFilterVessel);

         return () => document.removeEventListener("click", handleShowFilterVessel);
      }

   }, [showFilterVessel, handleShowFilterVessel]);

   const handleShowFilterPod = useCallback((e) => {
      if (dropdownFilterPodRef.current && showFilterPod) {
         if (!dropdownFilterPodRef.current.contains(e.target)) {
            setShowFilterPod(false);
         }

      } else if (!dropdownFilterPodRef.current && showFilterPod) {
         setShowFilterPod(false);

      } else {
         setShowFilterPod(true);
      }

   }, [showFilterPod]);

   useEffect(() => {
      if (showFilterPod) {
         document.addEventListener("click", handleShowFilterPod);

         return () => document.removeEventListener("click", handleShowFilterPod);
      }

   }, [showFilterPod, handleShowFilterPod]);


   // async function handleCheckAll() {
   //    let checklistId = [];
   //    const checkboxes = document.querySelectorAll('input[type="checkbox"]');

   //    checkboxes.forEach((checkbox) => {
   //       if (checkbox.value !== 'on') {
   //          checklistId.push([checkbox.value, !isCheckAll])
   //       }
   //    });

   //    setChassisSelection(checklistId);
   // }

   function handleCheckIdConcat(e) {
      let checklistId = [];
      const checkboxes = document.querySelectorAll('input[type="checkbox"]');

      checkboxes.forEach((checkbox) => {
         if (checkbox.value !== 'on') {
            checklistId.push([checkbox.value, checkbox.checked]);
         }
      });

      setChassisSelection(checklistId);
   };

   async function handleConfirmIssueInvoice(e) {
      e.preventDefault();

      setLoadingButton('processing');

      const data = { invoiceIssueDate };

      for (let i = 0; i < chassisSelected.length; i++) {

         try {
            await api.put(`chassis/${Number(chassisSelected[i][0])}/invoiced`, data);

         } catch (e) {
            setLoadingButton('default');

            e.response.data.errors.forEach(error => toast.error(error));
         };
      };

      toast.success('The selected vehicles has been marked as invoiced on the date indicated');

      setChassisSelection([]);
      setInvoiceIssueDate('');

      const checkboxes = document.querySelectorAll('input[type="checkbox"]');

      for (let i = 0; i < checkboxes.length; i++) {
         checkboxes[i].checked = false;
      };

      setModalConfirmInvoice(false);
      setLoadingButton('default');

      setPageInvoiced(1);
      setPageLoaded(1);

      await loadLoadedVehicles();
      await loadInvoicedVehicles();
   };

   const onChangeDatePicker = (date) => {
      setStartDate(date);
      setSearchLoadingDate(moment(new Date(date)).format('YYYY-MM-DD'));
   };

   const CalendarCustomButton = forwardRef(({ onClick }, ref) => {
      return (
         <button
            type="button"
            className='button-filter'
            onClick={onClick}
            ref={ref}
         >
            {searchLoadingDate !== '' ?
               <HiCalendar size={20} color="#4B81E8" />
               :
               <HiOutlineCalendar size={20} color="#5D5D5D" />
            }
         </button>
      );
   });

   function handleRemoveFilterChassi() {
      setLoadingScreen(true);

      setSearchChassis('');
      setPageLoaded(1);
      setLoadingMorePages(true);
   };

   function handleRemoveFilterDate() {
      setLoadingScreen(true);

      setSearchLoadingDate('');
      setPageLoaded(1);
      setLoadingMorePages(true);
   };

   function handleRemoveFilterVessel() {
      setLoadingScreen(true);

      setSearchVessel('');
      setPageLoaded(1);
      setLoadingMorePages(true);
   };

   function handleRemoveFilterPod() {
      setLoadingScreen(true);

      setSearchPod('');
      setPageLoaded(1);
      setLoadingMorePages(true);
   };

   function handleOpenModalConfirmInvoice() {
      setModalConfirmInvoice(!modalConfirmInvoice);

      setInvoiceIssueDate('');
   };

   function handleFetchMore() {
      if (loadedVehiclesData.length >= Number(loadedVehiclesQuantity)) {
         setLoadingMorePages(false);
         return;
      }

      if (searchChassis !== '' || searchVessel !== ''
         || searchPod !== '' || searchLoadingDate !== '') {
         setLoadingMorePages(false);
         return;
      }

      setTimeout(() => {
         setPageLoaded(oldValue => oldValue + 1);

         loadLoadedVehicles();
      }, 1000);
   };


   return (
      <>
         <PageHeader />
         <ButtonsNavigation />

         <div id="page-loaded">
            <div className="container">
               <div className="height">
                  {loadingScreen ?
                     <Loading
                        type='balls'
                     />
                     :
                     <main>
                        {searchChassis === '' && searchVessel === ''
                           && searchPod === '' && searchLoadingDate === ''
                           ?
                           null
                           :
                           <div className="filter-selected">
                              <span className='filter-title'>Selected filters:</span>

                              {searchChassis !== '' && (
                                 <div className="filter-selected-container">
                                    <FiX size={15} color='#5D5D5D' onClick={handleRemoveFilterChassi} />
                                    <span className='filter-uppercase'>{searchChassis}</span>
                                 </div>
                              )}

                              {searchLoadingDate !== '' && (
                                 <div className="filter-selected-container">
                                    <FiX size={15} color='#5D5D5D' onClick={handleRemoveFilterDate} />
                                    <span>{searchLoadingDate}</span>
                                 </div>
                              )}

                              {searchVessel !== '' && (
                                 <div className="filter-selected-container">
                                    <FiX size={15} color='#5D5D5D' onClick={handleRemoveFilterVessel} />
                                    <span className='filter-uppercase'>{searchVessel}</span>
                                 </div>
                              )}

                              {searchPod !== '' && (
                                 <div className="filter-selected-container">
                                    <FiX size={15} color='#5D5D5D' onClick={handleRemoveFilterPod} />
                                    <span className='filter-uppercase'>{searchPod}</span>
                                 </div>
                              )}

                           </div>
                        }

                        {loadedVehiclesDataConfigured.length === 0 ?
                           <div className="no-vehicles">
                              <span>
                                 There are no vehicles recently loaded on vessels.
                                 <br />
                                 Please, register a Loading Report
                              </span>
                           </div>
                           :
                           <div className="vehicles-list">
                              <div className="label-container">
                                 <div
                                    className='select-chassi'
                                 ></div>
                                 {/* <input
                              type="checkbox"
                              id='select-all'
                              onChange={e => handleCheckAll(e.target.value)}
                           /> */}

                                 <DropdownFilterLoaded
                                    label='VIN Number'
                                    dropdownFilterRef={dropdownFilterChassisRef}
                                    search={searchChassis}
                                    setSearch={setSearchChassis}
                                    handleShowFilter={handleShowFilterChassis}
                                    setShowFilter={setShowFilterChassis}
                                    showFilter={showFilterChassis}
                                    classContainer='chassi'
                                 />

                                 <div className="model-container">
                                    <label htmlFor="model">Model</label>
                                 </div>

                                 <DropdownFilterLoaded
                                    label='Ship Assigned'
                                    dropdownFilterRef={dropdownFilterVesselRef}
                                    search={searchVessel}
                                    setSearch={setSearchVessel}
                                    handleShowFilter={handleShowFilterVessel}
                                    setShowFilter={setShowFilterVessel}
                                    showFilter={showFilterVessel}
                                    classContainer='vessel'
                                 />

                                 <DropdownFilterLoaded
                                    label='POD'
                                    dropdownFilterRef={dropdownFilterPodRef}
                                    search={searchPod}
                                    setSearch={setSearchPod}
                                    handleShowFilter={handleShowFilterPod}
                                    setShowFilter={setShowFilterPod}
                                    showFilter={showFilterPod}
                                    classContainer='pod'
                                 />

                                 <div className="date-container">
                                    <label htmlFor="loadingDate">Loading Date</label>
                                    <DatePicker
                                       selected={startDate}
                                       onChange={onChangeDatePicker}
                                       customInput={<CalendarCustomButton />}
                                       showPopperArrow={false}
                                       todayButton='Today'
                                    />
                                 </div>

                                 <div className="service-container">
                                    <label htmlFor="towHookInstall">Tow hook install</label>
                                 </div>

                                 <div className="service-container">
                                    <label htmlFor="wash">Wash</label>
                                 </div>

                                 <div className="service-container">
                                    <label htmlFor="batteryCharge">Battery Charge</label>
                                 </div>

                                 <div className="service-container">
                                    <label htmlFor="tireReplacement">Tire Replac.</label>
                                 </div>

                                 <div className="service-container">
                                    <label htmlFor="airTireSupplied">Air tire supplied</label>
                                 </div>

                                 <div className="service-container">
                                    <label htmlFor="refuel">Refuel</label>
                                 </div>

                                 <div className="service-container">
                                    <label htmlFor="batteryReview">Battery voltage review</label>
                                 </div>
                              </div>

                              <InfiniteScroll
                                 dataLength={loadedVehiclesData.length}
                                 next={handleFetchMore}
                                 hasMore={loadingMorePages}
                                 loader={
                                    <LoadingInfiniteScroll
                                       height={30}
                                       width='100%'
                                       type='spin'
                                    />
                                 }
                                 scrollThreshold={0.8}
                                 scrollableTarget="page-loaded"
                              >
                                 {loadedVehiclesDataConfigured.map(vehicle => {
                                    return (
                                       <div key={vehicle.chassi_id} className="cards">
                                          <input
                                             type="checkbox"
                                             value={[vehicle.chassi_id]}
                                             id={vehicle.chassi_id}
                                             onChange={e => handleCheckIdConcat(e.target.value)}
                                          // defaultChecked={isCheck}
                                          />
                                          <Link
                                             to={`/app/vehicle/${encodeURIComponent(crypto.DES.encrypt(String(vehicle.chassi_id), 'idChassi').toString())}/tracking`}
                                             className="card-click"
                                          >
                                             <span className="chassi-container">{vehicle.chassi_number}</span>
                                             <span className="model-container">{vehicle.model_name}</span>
                                             <span className="vessel-container">{vehicle.vessel_voyage}</span>
                                             <span className="pod-container">{vehicle.pod}</span>
                                             <span className="date-container">{vehicle.loading_date_confirmation}</span>
                                             <span className="service-container">{vehicle.pdi_service[0].pdi_quantity}</span>
                                             <span className="service-container">{vehicle.pdi_service[1].pdi_quantity}</span>
                                             <span className="service-container">{vehicle.pdi_service[2].pdi_quantity}</span>
                                             <span className="service-container">{vehicle.pdi_service[3].pdi_quantity}</span>
                                             <span className="service-container">{vehicle.pdi_service[4].pdi_quantity}</span>
                                             <span className="service-container">{vehicle.pdi_service[5].pdi_quantity}</span>
                                             <span className="service-container">{vehicle.pdi_service[6].pdi_quantity}</span>
                                             {vehicle.repaired ? (
                                                <div className="repaired-container">
                                                   <FiTool size={12} color='#555555' />
                                                </div>
                                             ) : (
                                                <div className="not-repaired-container"></div>
                                             )}
                                          </Link>
                                       </div>
                                    )
                                 })}
                              </InfiniteScroll>
                           </div>

                        }
                     </main>
                  }
               </div>
            </div>

            {chassisSelected.length !== 0 &&
               <div className="footer-container">
                  <div className="container">
                     <button
                        type='button'
                        onClick={handleOpenModalConfirmInvoice}
                     >
                        Mark as Invoiced
                     </button>

                     <span>{chassisSelected.length} vehicles(s) selected</span>
                  </div>
               </div>
            }

            <ModalConfirmIssueInvoice
               isOpen={modalConfirmInvoice}
               toggle={handleOpenModalConfirmInvoice}
               invoiceIssueDate={invoiceIssueDate}
               setInvoiceIssueDate={setInvoiceIssueDate}
               chassisSelected={chassisSelected}
               handleConfirmIssueInvoice={handleConfirmIssueInvoice}
               loadingButton={loadingButton}
            />
         </div>
      </>
   );
};

export default Loaded;
