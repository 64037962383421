import React from 'react';
import { HiOutlinePencilAlt } from 'react-icons/hi';

import '../../styles/components/ChassiDetails/InputChassiDetails.css';

const InputChassiDetails = ({ label, editable, handleAllowEdition,
   valueEdited, value, onChange, isDisabled, type, ...rest }) => {
   return (
      <div id="input-block">
         <div className='label-container'>
            <label htmlFor='inputChassiDetails'>{label}</label>

            {editable &&
               <button
                  type='button'
                  className='button-edit'
                  onClick={handleAllowEdition}
               >
                  <HiOutlinePencilAlt size={20} color='#5D5D5D' />
               </button>
            }
         </div>

         <input
            id={`inputChassiDetails-${label}`}
            value={valueEdited === undefined ? value : valueEdited}
            onChange={e => onChange(e.target.value)}
            disabled={isDisabled}
            autoComplete="off"
            type={type || 'text'}
            {...rest}
         />
      </div>
   );
};

export default InputChassiDetails;
