import React from 'react';
import { IoLocationOutline, IoCarSportOutline } from 'react-icons/io5';

import '../../styles/components/ChassiDetails/ChassiDetailsHeader.css';

const ChassiDetailsHeader = (props) => {
   return (
      <div id='chassi-details-header'>
         <div className="container">
            <span className='chassi-label'>VIN</span>
            <span className='chassi-number'>{props.chassiNumber}</span>

            <IoCarSportOutline size={22} color='#FFF' />
            <span className='model-name'>{props.modelName}</span>
            <span className='model-year'>{props.modelYear}</span>

            <IoLocationOutline size={22} color='#FFF' />
            <span className='pod'>{props.pod}</span>

            <div className="status-container">
               <span>{props.currentStatus}</span>
            </div>
         </div>
      </div>
   );
};

export default ChassiDetailsHeader;
