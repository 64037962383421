import React, { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { toast } from 'react-toastify';
import crypto from 'crypto-js';

import PageHeader from '../../../components/PageHeader';
import Loading from '../../../components/Loading';
import Input from '../../../components/Input';

import api from '../../../services/api';
import { AllFirstLettersUp } from '../../../utils/functions';

import '../../../styles/pages/app/settings/PdiEdit.css';

const PdiEdit = () => {
   const [loadingButton, setLoadingButton] = useState('default');
   const [loading, setLoading] = useState(true);

   const [pdiName, setPdiName] = useState('');
   const [unitPrice, setUnitPrice] = useState();

   const { id } = useParams();
   const history = useHistory();

   const pdi_id = crypto.DES.decrypt(decodeURIComponent(id), 'idPdi').toString(crypto.enc.Utf8);


   useEffect(() => {
      async function loadPdiInfo() {
         const res = await api.get(`pdiServices/${pdi_id}`);

         setPdiName(res.data.pdi_name);
         setUnitPrice(res.data.unit_price);

         setLoading(false);
      };

      loadPdiInfo();

   }, [pdi_id]);


   async function loadPdiInfo() {
      const res = await api.get(`pdiServices/${pdi_id}`);

      setPdiName(res.data.pdi_name);
      setUnitPrice(res.data.unit_price);

      setLoading(false);
   };

   async function handleEditPdi(e) {
      e.preventDefault();

      setLoadingButton('processing');

      const data = { pdiName, unitPrice };

      try {
         await api.put(`pdiServices/${pdi_id}`, data);

         loadPdiInfo();

         setLoadingButton('default');

         history.goBack();

      } catch (e) {
         setLoadingButton('default');

         e.response.data.errors.forEach(error => toast.error(error));
      };
   };


   return (
      <div id="page-pdi-edit">
         <PageHeader settings={true} />

         <div className="container">
            {loading ?
               <Loading
                  type='balls'
               />
               :
               <div className="content">

                  <h1>Edit PDI Service</h1>
                  <span>Here you can edit the service cost.</span>

                  <main>
                     <form onSubmit={handleEditPdi}>
                        <fieldset>

                           <div className="input-container">
                              <Input
                                 name='pdiName'
                                 type='text'
                                 label='PDI Service'
                                 value={AllFirstLettersUp(pdiName)}
                                 onChange={e => setPdiName(e.target.value)}
                                 disabled
                              />

                              <NumberFormat
                                 value={unitPrice}
                                 displayType={'text'}
                                 thousandSeparator={true}
                                 decimalScale={2}
                                 fixedDecimalScale={true}
                                 // prefix={'$ '}
                                 renderText={value =>
                                    <Input
                                       defaultValue={value}
                                       onChange={e => setUnitPrice(Number(e.target.value))}
                                       name='unitPrice'
                                       type='text'
                                       label='Cost per Unit ($)'
                                    />}
                              />
                           </div>

                           <div className="buttons-container">
                              <Link
                                 to='/app/settings/admin/pdiServices'
                                 className='cancel-button'
                              >
                                 Cancel
                              </Link>

                              {loadingButton === 'default' ? (
                                 <button
                                    type='submit'
                                    className='submit-button'
                                 >
                                    Save
                                 </button>
                              ) : (
                                 <button
                                    className='submit-button'
                                    style={{ background: '#4B81E8', color: '#FFF', border: 0 }}
                                 >
                                    <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                                    <span className="sr-only"></span>
                                 </button>
                              )
                              }
                           </div>

                        </fieldset>
                     </form>
                  </main>

               </div>
            }
         </div>
      </div>
   );
};

export default PdiEdit;
