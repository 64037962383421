import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import Chart from 'react-google-charts';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { HiOutlineCalendar } from 'react-icons/hi';

import LinksNavigationDashboard from '../../../components/dashboard/LinksNavigation';
import PageHeader from '../../../components/PageHeader';
import Loading from '../../../components/Loading';

import api from '../../../services/api';

import '../../../styles/pages/app/dashboard/Loaded.css';

const LoadedDashboard = () => {
   const [loading, setLoading] = useState(true);

   const [startDate, setStartDate] = useState(new Date());

   const [totalLoaded, setTotalLoaded] = useState('');
   const [IPCLoaded, setIPCLoaded] = useState('');
   const [SSLoaded, setSSLoaded] = useState('');
   const [byAssemblyPlantData, setByAssemblyPlantData] = useState([]);
   const [byPodData, setByPodData] = useState([]);
   const [byDealerCodeData, setByDealerCodeData] = useState([]);

   const dateSelected = startDate && moment(startDate).format('YYYY-MM-DD');


   const loadTotalLoadedData = useCallback(async () => {
      const res = await api.get(`dashboard/loaded/totalLoaded?dateSelected=${dateSelected}`);

      const quantityIPC = res.data.IPC ? res.data.IPC.quantity : 0;
      const quantitySS = res.data.SS ? res.data.SS.quantity : 0;
      const totalLoaded = quantityIPC + quantitySS;

      setTotalLoaded(totalLoaded);
      setIPCLoaded(quantityIPC);
      setSSLoaded(quantitySS);

   }, [dateSelected]);

   const loadByAssemblyPlantData = useCallback(async () => {
      const res = await api.get(`dashboard/loaded/byAssemblyPlant?dateSelected=${dateSelected}`);

      let arrayAdjusted = [];
      const header = ['Assembly Plant', 'Quantity', { role: 'annotation' }];

      arrayAdjusted.push(header);

      res.data.map(item => {
         const data = [item.plant, item.quantity, item.quantity];

         return arrayAdjusted.push(data);
      });

      setByAssemblyPlantData(arrayAdjusted);

   }, [dateSelected]);

   const loadByPodData = useCallback(async () => {
      const res = await api.get(`dashboard/loaded/byPod?dateSelected=${dateSelected}`);

      if (res.data.length === 0) {
         setByPodData(res.data);

      } else {
         let arrayAdjusted = [];
         const header = ['POD', 'Quantity', { role: 'annotation' }];

         arrayAdjusted.push(header);

         res.data.map(item => {
            const data = [item.pod, item.quantity, item.quantity];

            return arrayAdjusted.push(data.length === 0 ? [] : data);
         });

         setByPodData(arrayAdjusted);
      }

   }, [dateSelected]);

   const loadByDealerCodeData = useCallback(async () => {
      const res = await api.get(`dashboard/loaded/byDealerCode?dateSelected=${dateSelected}`);

      if (res.data.length === 0) {
         setByDealerCodeData(res.data);

      } else {
         let arrayAdjusted = [];
         const header = ['Dealer Code', 'Quantity', { role: 'annotation' }];

         arrayAdjusted.push(header);

         res.data.map(item => {
            const data = [item.dealerCode, item.quantity, item.quantity];

            return arrayAdjusted.push(data);
         });

         setByDealerCodeData(arrayAdjusted);
      }

   }, [dateSelected]);


   useEffect(() => {
      async function loadAllDatas() {
         await loadTotalLoadedData();
         await loadByAssemblyPlantData();
         await loadByPodData();
         await loadByDealerCodeData();

         setLoading(false);
      };

      loadAllDatas();

   }, [loadTotalLoadedData, loadByAssemblyPlantData, loadByPodData, loadByDealerCodeData]);


   const onChangeDatePicker = (date) => {
      setStartDate(date);
   };

   const CalendarCustomButton = forwardRef(({ onClick }, ref) => {
      return (
         <div className="date-input">
            <button
               type="button"
               className='button-date'
               onClick={onClick}
               ref={ref}
            >
               {!startDate
                  ?
                  <span className='placeholder'>Select a date</span>
                  :
                  <span className='date-selected'>{dateSelected}</span>
               }
            </button>
         </div>
      );
   });


   return (
      <>
         <PageHeader
            backButton={true}
         />

         <LinksNavigationDashboard />

         <div id='page-loaded-dashboard'>
            <div className="container">
               <div className="height">
                  {loading ?
                     <Loading />
                     :
                     <>
                        <div className="header">
                           <h2>FIGURES ABOUT:</h2>

                           <div className="date-container">
                              <div className="label-container">
                                 <label htmlFor="shipmentDate">Reference Date</label>
                                 <HiOutlineCalendar size={20} color="#5D5D5D" />
                              </div>

                              <DatePicker
                                 startDate={startDate}
                                 customInput={<CalendarCustomButton />}
                                 onChange={onChangeDatePicker}
                                 todayButton='Today'
                                 withPortal
                              />
                           </div>
                        </div>

                        <div className="chart-container-line">
                           <div className='info-container'>
                              <h3>Total Loaded (units)</h3>

                              <span>{totalLoaded}</span>
                           </div>

                           <div className='in-block'>
                              <div className='info-container-small'>
                                 <span>{IPCLoaded}</span>

                                 <h3>IPC</h3>
                              </div>

                              <div className='info-container-small'>
                                 <span>{SSLoaded}</span>

                                 <h3>Short Sea</h3>
                              </div>
                           </div>
                        </div>

                        <div className="chart-container-big">
                           <div className="chart-container-small">
                              <h3>By Assembly Plant</h3>

                              <Chart
                                 chartType='BarChart'
                                 data={byAssemblyPlantData}
                                 options={{
                                    width: 320,
                                    height: 210,
                                    bars: 'horizontal',
                                    bar: { groupWidth: 21 },
                                    legend: { position: 'none' },
                                    backgroundColor: {
                                       fill: 'transparent'
                                    },
                                    fontName: 'Montserrat',
                                    fontSize: 11,
                                    chartArea: {
                                       backgroundColor: 'transparent',
                                       left: 80,
                                       top: 10,
                                       right: 10,
                                       bottom: 10,
                                    },
                                    hAxis: {
                                       baseline: 'none',
                                       gridlines: {
                                          color: 'transparent',
                                       },
                                       textStyle: {
                                          color: 'transparent',
                                       }
                                    },
                                    colors: ['#4B81E8'],
                                    tooltip: {
                                       text: 'quantity',
                                       trigger: 'focus',
                                       textStyle: {
                                          color: '#000000',
                                          fontName: 'Montserrat',
                                          fontSize: 12,
                                       }
                                    },
                                 }}
                              />
                           </div>

                           <div className="chart-container-small">
                              <h3>By POD</h3>

                              {byPodData.length !== 0 &&
                                 <Chart
                                    chartType='BarChart'
                                    data={byPodData}
                                    options={{
                                       width: 320,
                                       height: 210,
                                       bars: 'horizontal',
                                       bar: { groupWidth: 21 },
                                       legend: { position: 'none' },
                                       backgroundColor: {
                                          fill: 'transparent'
                                       },
                                       fontName: 'Montserrat',
                                       fontSize: 11,
                                       chartArea: {
                                          backgroundColor: 'transparent',
                                          left: 80,
                                          top: 10,
                                          right: 10,
                                          bottom: 10,
                                       },
                                       hAxis: {
                                          baseline: 'none',
                                          gridlines: {
                                             color: 'transparent',
                                          },
                                          textStyle: {
                                             color: 'transparent',
                                          }
                                       },
                                       colors: ['#4B81E8'],
                                       tooltip: {
                                          text: 'quantity',
                                          trigger: 'focus',
                                          textStyle: {
                                             color: '#000000',
                                             fontName: 'Montserrat',
                                             fontSize: 12,
                                          }
                                       },
                                    }}
                                 />
                              }
                           </div>

                           <div className="chart-container-small">
                              <h3>By Dealer Code</h3>

                              {byDealerCodeData.length !== 0 &&
                                 <Chart
                                    chartType='BarChart'
                                    data={byDealerCodeData}
                                    options={{
                                       width: 320,
                                       height: 210,
                                       bars: 'horizontal',
                                       bar: { groupWidth: 21 },
                                       legend: { position: 'none' },
                                       backgroundColor: {
                                          fill: 'transparent'
                                       },
                                       fontName: 'Montserrat',
                                       fontSize: 11,
                                       chartArea: {
                                          backgroundColor: 'transparent',
                                          left: 80,
                                          top: 10,
                                          right: 10,
                                          bottom: 10,
                                       },
                                       hAxis: {
                                          baseline: 'none',
                                          gridlines: {
                                             color: 'transparent',
                                          },
                                          textStyle: {
                                             color: 'transparent',
                                          }
                                       },
                                       colors: ['#4B81E8'],
                                       tooltip: {
                                          text: 'quantity',
                                          trigger: 'focus',
                                          textStyle: {
                                             color: '#000000',
                                             fontName: 'Montserrat',
                                             fontSize: 12,
                                          }
                                       },
                                    }}
                                 />
                              }
                           </div>

                        </div>
                     </>
                  }
               </div>
            </div>
         </div >
      </>
   );
};

export default LoadedDashboard;
